import { createSubscriber, createContainer } from '@atlassian/react-sweet-state';
import Store from './store';

export const NavigationUISubscriber = createSubscriber(Store, {
	displayName: 'NavigationUISubscriber',
	selector: null,
});

export const NavigationUIContainer = createContainer(Store);

export type NavigationUISubscriberType = typeof NavigationUISubscriber;
