import type { ComponentType } from 'react';
import { lazyAfterPaint } from 'react-loosely-lazy';
import { SERVICE_DESK_PROJECT } from '@atlassian/jira-common-constants/src/project-types.tsx';
import { globalLayout } from '@atlassian/jira-global-layout';
import { AsyncJsmCustomDomainChangeBoardingFlag } from '@atlassian/jira-help-center-custom-domain-change-boarding/src/ui/async.tsx';
import { projectLayout } from '@atlassian/jira-project-layout';
import { composeLayouts, withProjectContext, type Layout } from '@atlassian/jira-route-layout';
import { serviceProjectFirstImpressionsLayout } from '@atlassian/jira-service-project-first-impressions-layout';

// TODO Remove this whenever all service project route paths are no longer ambiguous
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const withServiceProjectContext = (Component: ComponentType<Record<any, any>>) =>
	withProjectContext(Component, {
		project: {
			style: 'any',
			type: SERVICE_DESK_PROJECT,
		},
	});

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const ProjectBuilder = lazyAfterPaint(
	() => import(/* webpackChunkName: "async-service-project-builder" */ './ui/project-builder'),
	{ ssr: false },
);

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const Onboarding = lazyAfterPaint(
	() => import(/* webpackChunkName: "async-service-project-onboarding" */ './ui/onboarding'),
	{ ssr: false },
);

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const RightSidebars = lazyAfterPaint(
	() =>
		import(/* webpackChunkName: "async-service-project-right-sidebars" */ './ui/right-sidebars'),
	{ ssr: false },
);

export const serviceProjectLayout: Layout = composeLayouts(projectLayout, {
	globalComponents: [
		withServiceProjectContext(Onboarding),
		...serviceProjectFirstImpressionsLayout.globalComponents.map(withServiceProjectContext),
		withServiceProjectContext(AsyncJsmCustomDomainChangeBoardingFlag),
		ProjectBuilder,
	],
	rightSidebars: [
		withServiceProjectContext(RightSidebars),
		...serviceProjectFirstImpressionsLayout.rightSidebars.map(withServiceProjectContext),
	],
});

// rightSidebarsLayout is a layout with only right sidebar and do not include other global components
export const rightSidebarsLayout = composeLayouts(globalLayout, {
	rightSidebars: [RightSidebars],
});
