import { ff } from '@atlassian/jira-feature-flagging';
import { type RouterContext, createResource } from '@atlassian/jira-router';
import { resourceWithCondition2 } from '@atlassian/jira-router-resources-utils/src/utils/resource-with-condition/index.tsx';
import {
	PRODUCT_JSM_ORGANIZATION_MEMBERS_LEGACY,
	PRODUCT_JSM_ORGANIZATION_MEMBERS,
} from './constants';

const TEN_SECONDS_IN_MILLIS = 1000 * 10;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const productsJsmOrganizationMembersResourceLegacy = createResource<any>({
	type: PRODUCT_JSM_ORGANIZATION_MEMBERS_LEGACY,
	getKey: (routerContext: RouterContext) =>
		`${routerContext.query.page}-${routerContext.query.query}`,
	getDataLoader: () =>
		import(
			/* webpackChunkName: "async-resource-products-jsm-organization-members-legacy", jiraSpaEntry: "async-resource-products-jsm-organization-members-legacy" */ './services/get-data'
		),
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const productsJsmOrganizationMembersResourceNew = createResource<any>({
	type: PRODUCT_JSM_ORGANIZATION_MEMBERS,
	getKey: (routerContext: RouterContext) =>
		`OrganizationMembers-${routerContext.match.params.organizationId}-${routerContext.query.page}-${routerContext.query.query}`,
	getDataLoader: () =>
		import(
			/* webpackChunkName: "async-resource-products-jsm-organization-members", jiraSpaEntry: "async-resource-products-jsm-organization-members" */ './services/get-data'
		),
	maxAge: TEN_SECONDS_IN_MILLIS,
});

export const productsJsmOrganizationMembersResource = resourceWithCondition2(
	() => ff('jsm-fission-move-customer-organizations-source-of-truth-ui'),
	productsJsmOrganizationMembersResourceNew,
	productsJsmOrganizationMembersResourceLegacy,
);
