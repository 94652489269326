import { ff } from '@atlassian/jira-feature-flagging';
import { fg } from '@atlassian/jira-feature-gating';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default {
	get(): boolean {
		return (
			ff('child-issues-total-count-performance-experiment_mt1zj') ||
			fg('jsc_-_m2_hierarchy_api_updates')
		);
	},
};
