import { createResource } from '@atlassian/jira-router';
import type { RequestSecuritySettingsResponse } from '@atlassian/jira-router-resources-service-desk-customer-permissions/src/services/customer-permissions-page/get-data/types.tsx';
import { RESOURCE_TYPE_CUSTOMER_PERMISSIONS } from '../../constants';

export const customerPermissionsPageDataResource = createResource<RequestSecuritySettingsResponse>({
	type: RESOURCE_TYPE_CUSTOMER_PERMISSIONS,
	getKey: ({ match }) => match.params.projectKey || '',
	getDataLoader: () =>
		import(
			/* webpackChunkName: "async-resource-customer-permissions", jiraSpaEntry: "async-resource-customer-permissions" */ './get-data'
		),
	maxAge: Infinity,
});

export type { RequestSecuritySettingsResponse } from '@atlassian/jira-router-resources-service-desk-customer-permissions/src/services/customer-permissions-page/get-data/types.tsx';
