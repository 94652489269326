import {
	ROUTE_GROUPS_GLOBAL_SETTINGS,
	ROUTE_NAMES_GLOBAL_MAIL_SETTINGS,
} from '@atlassian/jira-common-constants/src/spa-routes';

export const globalMailSettingsRoute = {
	group: ROUTE_GROUPS_GLOBAL_SETTINGS,
	name: ROUTE_NAMES_GLOBAL_MAIL_SETTINGS,
	path: '/secure/admin/global-mail-settings',
	exact: true,
};
