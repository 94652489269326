import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async';
import { globalSettingsLayout } from '@atlassian/jira-global-settings-layout';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarGlobalSettingsProducts from '@atlassian/jira-navigation-apps-sidebar-global-settings-products/src/async';
import { createEntry } from '@atlassian/jira-route-entry';
import type { Route } from '@atlassian/jira-router';
import { jsmGlobalCustomerAccessAffectedProjectsResource } from '@atlassian/jira-router-resources-admin-pages-jsm-customer-access/src/services/affected-projects/index.tsx';
import { jsmGlobalCustomerAccessSettingsResource } from '@atlassian/jira-router-resources-admin-pages-jsm-customer-access/src/services/settings/index.tsx';
import {
	getNavigationResources,
	getNavigationSidebarProductsSettingResource,
} from '@atlassian/jira-router-resources-navigation';
import { productsJsmCustomerAccessRoute } from '@atlassian/jira-router-routes-admin-pages-jsm-customer-access-routes';
import type CustomerAccessType from '@atlassian/jira-spa-apps-products-jsm-customer-access';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';

export const LazyCustomerAccess = lazyForPaint<typeof CustomerAccessType>(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-products-customer-access", jiraSpaEntry: "async-admin-pages-customer-access" */ '@atlassian/jira-spa-apps-products-jsm-customer-access'
		),
	),
);

export const ServiceDeskCustomerAccess = () => (
	<LazyPage
		Page={LazyCustomerAccess}
		pageId="spa-apps/admin-pages/customer-access"
		shouldShowSpinner={false}
	/>
);

export const customerAccessRouteEntries: Route[] = [
	createEntry(productsJsmCustomerAccessRoute, {
		ufoName: 'admin.jsm-customer-access',
		component: ServiceDeskCustomerAccess,
		layout: globalSettingsLayout,

		navigation: {
			sidebarId: SIDEBAR_ID.GLOBAL_PRODUCTS_SETTINGS,
			menuId: MENU_ID.SETTINGS,
			sidebar: AsyncSidebarGlobalSettingsProducts,
		},

		resources: [
			...getNavigationResources(),
			...getNavigationSidebarProductsSettingResource(),
			jsmGlobalCustomerAccessSettingsResource,
			jsmGlobalCustomerAccessAffectedProjectsResource,
		],

		forPaint: [LazyAtlassianNavigation, LazyCustomerAccess],
	}),
];
