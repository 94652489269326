import React, { useEffect, type ReactNode } from 'react';
import { fireTrackAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { useRouter } from '@atlassian/jira-router';
import { ScreenRedirect } from '@atlassian/jira-screen-redirect/src/ui/index.tsx';
import { getSettingsPath } from '@atlassian/jira-servicedesk-customer-service-common/src/utils/get-settings-path';
import { isHelpCenterSettingsPath } from '../../utils/is-help-center-settings-path';
import { useIsJCS } from '../../utils/use-is-jcs';

type Page =
	| 'kb'
	| 'customer-notifications'
	| 'email'
	| 'ticket-types-config'
	| 'ticket-type-fields';

type Props = {
	page: Page;
	children: ReactNode;
};

export const getPageForAnalytics = (page: Page, pathname: string) => {
	if (page === 'customer-notifications') {
		const customerNotificationsPage = pathname.split('/settings/customer-notifications')[1];

		if (customerNotificationsPage === '/template') {
			return 'customer-notifications-template';
		}
		if (customerNotificationsPage === '/account-notification/invite-customer') {
			return 'customer-notifications-invite-customer';
		}
		if (/rule\/\d+/.test(customerNotificationsPage)) {
			return 'customer-notifications-rule';
		}
	}

	return page;
};

export const getRedirectTo = (page: Page, pathname: string) => {
	switch (page) {
		case 'kb':
			return getSettingsPath(pathname.replace('/confluence-kb', '/kb'), true);
		default:
			return getSettingsPath(pathname, true);
	}
};

// This component is used for redirecting users from project settings pages to
// their help center settings equivalents
export const HelpCenterSettingsRedirect = ({ page, children }: Props) => {
	const [{ location }] = useRouter();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const isJCS = useIsJCS();

	useEffect(() => {
		if (isJCS && !isHelpCenterSettingsPath()) {
			fireTrackAnalytics(
				createAnalyticsEvent({}),
				'projectSettingsToHelpCenterSettings redirected',
				{
					page: getPageForAnalytics(page, location.pathname),
				},
			);
		}
	}, [createAnalyticsEvent, isJCS, location.pathname, page]);

	if (isJCS && !isHelpCenterSettingsPath()) {
		return <ScreenRedirect to={getRedirectTo(page, location.pathname)} persistSearchParams />;
	}

	return children;
};
