import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import type PresentationToolkitReportPageType from '@atlassian/jira-spa-apps-software-presentation-toolkit-report/src/ui/index.tsx';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';

export const LazySoftwarePresentationToolkitReportPage = lazyForPaint<
	typeof PresentationToolkitReportPageType
>(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-jira-spa-apps-software-presentation-toolkit-report", jiraSpaEntry: "async-jira-spa-apps-software-presentation-toolkit-report" */ '@atlassian/jira-spa-apps-software-presentation-toolkit-report'
		),
	),
);

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default () => (
	<LazyPage
		Page={LazySoftwarePresentationToolkitReportPage}
		pageId="jira-software-presentation-toolkit-report"
		shouldShowSpinner
	/>
);
