import {
	ROUTE_GROUPS_CLASSIC_SOFTWARE,
	ROUTE_NAMES_RAPIDBOARD_BOARD,
} from '@atlassian/jira-common-constants/src/spa-routes';

export const rapidboardBoardRoute = {
	group: ROUTE_GROUPS_CLASSIC_SOFTWARE,
	name: ROUTE_NAMES_RAPIDBOARD_BOARD,
	path: '/secure/RapidBoard.jspa',
	query: ['rapidView'],
};
