import {
	ROUTE_GROUPS_ERRORS,
	ROUTE_NAMES_ERRORS_NOT_FOUND,
} from '@atlassian/jira-common-constants/src/spa-routes';

export const errorsNotFoundRoute = {
	group: ROUTE_GROUPS_ERRORS,
	name: ROUTE_NAMES_ERRORS_NOT_FOUND,
	path: '/jira/:projectType(software|servicedesk|core)',
};
