import { createResource } from '@atlassian/jira-router';
import { MIN_MAX_AGE } from '@atlassian/jira-router-resources-utils/src/constants.tsx';

const RESOURCE_TYPE_DASHBOARDS_DIRECTORY = 'DASHBOARDS_DIRECTORY';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const dashboardsDirectoryResource = createResource<any>({
	type: RESOURCE_TYPE_DASHBOARDS_DIRECTORY,
	getKey: () => 'state',
	getDataLoader: () =>
		import(
			/* webpackChunkName: "async-resource-dashboards-directory", jiraSpaEntry: "async-resource-dashboards-directory" */ './get-data'
		),
	maxAge: MIN_MAX_AGE,
});
