import type { WorkflowIssuesData } from '@atlassian/jira-business-board-workflow-issues';
import { createResource, useResource } from '@atlassian/jira-router';
import { getProjectKeyId } from '@atlassian/jira-router-resources-utils/src/utils/get-project-key-id/index.tsx';
import fetchWorkflowIssues from './controllers/get-data';

const RESOURCE_TYPE_KEY = 'BUSINESS_WORKFLOW_ISSUES';

export const workflowIssuesResource = createResource<WorkflowIssuesData>({
	type: RESOURCE_TYPE_KEY,
	getKey: (matchedRoute) => {
		const { projectKey } = getProjectKeyId(matchedRoute.match, matchedRoute.query);
		return `${RESOURCE_TYPE_KEY}_${projectKey}`;
	},
	getDataLoader: () =>
		import(
			/* webpackChunkName: "async-business-workflows-prefetch", jiraSpaEntry: "async-business-common" */ './controllers/get-data'
		),
	getData: fetchWorkflowIssues,
	maxAge: 1000,
	isBrowserOnly: true,
});

export const useWorkflowIssues = () =>
	// forcing the return type due to react-resource-router's weird error types
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	useResource<WorkflowIssuesData>(workflowIssuesResource) as {
		data: WorkflowIssuesData | null | undefined;
		error: Error | null | undefined;
		loading: boolean;
	};
