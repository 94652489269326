import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async';
import { globalSettingsLayout } from '@atlassian/jira-global-settings-layout';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarGlobalSettingsProducts from '@atlassian/jira-navigation-apps-sidebar-global-settings-products/src/async';
import { createEntry } from '@atlassian/jira-route-entry';
import type { Route } from '@atlassian/jira-router';
import { jsmCustomerAuthenticationSettingsResource } from '@atlassian/jira-router-resources-jsm-authentication-settings/src/services/index.tsx';
import {
	getNavigationResources,
	getNavigationSidebarProductsSettingResource,
} from '@atlassian/jira-router-resources-navigation';
import { productsJsmAuthenticationSettingsRoute } from '@atlassian/jira-router-routes-admin-pages-jsm-authentication-settings-routes';
import AuthenticationSettings, {
	LazyAuthenticationSettings,
} from './ui/spa/admin-pages/jsm-authentication-settings/ui';

export const authenticationSettingRouteEntries: Route[] = [
	createEntry(productsJsmAuthenticationSettingsRoute, {
		ufoName: 'admin.jsm-authentication-settings',
		component: AuthenticationSettings,
		layout: globalSettingsLayout,

		navigation: {
			sidebarId: SIDEBAR_ID.GLOBAL_PRODUCTS_SETTINGS,
			menuId: MENU_ID.SETTINGS,
			sidebar: AsyncSidebarGlobalSettingsProducts,
		},

		resources: [
			...getNavigationResources(),
			...getNavigationSidebarProductsSettingResource(),
			jsmCustomerAuthenticationSettingsResource,
		],

		forPaint: [LazyAtlassianNavigation, LazyAuthenticationSettings],
	}),
];
