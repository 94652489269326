import {
	ROUTE_NAMES_SERVICEDESK_ORGANIZATIONS,
	ROUTE_NAMES_SERVICEDESK_ORGANIZATION_DETAILS,
} from '@atlassian/jira-common-constants/src/spa-routes';

export const servicedeskOrganizationsRoute = {
	name: ROUTE_NAMES_SERVICEDESK_ORGANIZATIONS,
	path: '/jira/servicedesk/projects/:projectKey/organizations',
};

export const servicedeskOrganizationDetailsRoute = {
	name: ROUTE_NAMES_SERVICEDESK_ORGANIZATION_DETAILS,
	path: '/jira/servicedesk/projects/:projectKey/organizations/:organizationId',
};
