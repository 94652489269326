export const APP_NAMES = {
	ACCOUNTS: 'servicedesk.accounts',
	APP_DISCOVERY: 'servicedesk.app-discovery',
	ATLASSIAN_ANALYTICS: 'servicedesk.atlassian-analytics',
	AUTOMATION_EDIT_RULE: 'servicedesk.automation.edit-rule',
	AUTOMATION_SUMMARY: 'servicedesk.automation.summary',
	AUTOMATION_VIEW_LOG: 'servicedesk.automation.view-log',
	BOARD: 'servicedesk.board',
	BOARD_SETTINGS: 'servicedesk.board-settings',
	CALENDAR: 'servicedesk.calendar',
	CHANGE_CALENDAR: 'servicedesk.change-calendar',
	CHANGE_MANAGEMENT_SETTINGS: 'servicedesk.change-management.settings',
	CREATE_PROJECT: 'servicedesk.create-project',
	CSAT_CONFIG: 'servicedesk.csat-configuration',
	CUSTOMER_PERMISSIONS: 'servicedesk.customer-permissions',
	CONTACTS: 'servicedesk.contacts',
	CUSTOMERS: 'servicedesk.customers',
	CUSTOMER_DETAILS: 'servicedesk.customer-details',
	CUSTOMER_SERVICE_ESCALATIONS: 'servicedesk.customer-service-escalations',
	CUSTOMER_SERVICE_PRODUCTS: 'servicedesk.customer-service-products',
	INVITE_TEAM_DIALOG: 'servicedesk.invite-team-dialog',
	ISSUE_SCHEDULE_CHANGE: 'servicedesk.issue.schedule-change',
	ITSM_FEATURES_SETTINGS: 'servicedesk.itsm-features.settings',
	ITSM_SAMPLE_SPACE: 'servicedesk.itsm-sample-space',
	JOURNEYS: 'servicedesk.journeys',
	GETTING_STARTED_PAGE: 'servicedesk.getting-started-page',
	KB_BROWSE_ARTICLES: 'servicedesk.knowledge-base.browse-articles',
	KB_CATEGORY: 'servicedesk.knowledge-base.category',
	KB_DRAFTS: 'servicedesk.knowledge-base.drafts',
	KB_BROWSE_CATEGORIES: 'servicedesk.knowledge-base.browse-categories',
	KB_SUGGESTED_ARTICLES: 'servicedesk.knowledge-base.suggested-articles',
	KB_EMBED_EDITOR: 'servicedesk.knowledge-article.embed-editor',
	KB_LANDING: 'servicedesk.knowledge-base.landing',
	KB_REPORTS_REQUESTS: 'servicedesk.kb-requests.reports',
	KB_SEARCH: 'servicedesk.knowledge-base.search',
	KB_SETTINGS: 'servicedesk.knowledge.settings',
	KB_SETUP: 'servicedesk.knowledge-base.setup',
	KB_VIEW_ARTICLE: 'servicedesk.knowledge-base.view-article',
	ORGANIZATION: 'servicedesk.organization',
	ORGANIZATIONS: 'servicedesk.organizations',
	ORGANIZATION_DETAILS: 'servicedesk.organization-details',
	PORTAL_SETTINGS: 'servicedesk.portal-settings',
	QUEUES_AGENT_VIEW: 'servicedesk.queues.agent-view',
	QUEUES_EDIT_VIEW: 'servicedesk.queues.edit-view',
	QUEUES_MAIN: 'servicedesk.queues.main',
	REPORTS_CUSTOM: 'servicedesk.reports.custom',
	REPORTS_CUSTOM_CREATE: 'servicedesk.reports.custom-create',
	REPORTS_CUSTOM_EDIT: 'servicedesk.reports.custom-edit',
	REPORTS_HOME_PAGE: 'servicedesk.reports.home-page',
	REPORTS_SATISFACTION: 'servicedesk.reports.satisfaction',
	REPORTS_WORKLOAD: 'servicedesk.reports.workload',
	REQUEST_TYPES_CONFIG: 'servicedesk.request-types-config',
	REQUEST_TYPE_FIELDS: 'servicedesk.request-type-fields',
	SLA_SETTINGS: 'servicedesk.sla-settings',
	VIRTUAL_AGENT_BROWSE_CONVERSATIONS: 'servicedesk.virtual-agent.browse-conversations',
	VIRTUAL_AGENT_BROWSE_INTENTS: 'servicedesk.virtual-agent.browse-intents',
	VIRTUAL_AGENT_BROWSE_STANDARD_FLOWS: 'servicedesk.virtual-agent.browse-standard-flows',
	VIRTUAL_AGENT_LANDING: 'servicedesk.virtual-agent.landing',
	VIRTUAL_AGENT_SETTINGS: 'servicedesk.virtual-agent.settings',
	VIRTUAL_AGENT_VIEW_INTENT: 'servicedesk.virtual-agent.view-intent',
	VIRTUAL_AGENT_VIEW_STANDARD_FLOW: 'servicedesk.virtual-agent.view-standard-flow',
	PRACTICE_CONFIG_MODULE: 'servicedesk.practice-config-module',
	CREATE_SERVICE_MODAL: 'servicedesk.create-service-modal',
	UPDATE_SERVICE_MODAL: 'servicedesk.update-service-modal',
	LANGUAGE_SETTINGS: 'servicedesk.language-settings',
	LINKED_ALERTS_MODAL: 'servicedesk.link-alerts-modal',
	INSIGHT_ASSETS_REPORTS: 'servicedesk.insight-cmdb-assets_reports',
	INSIGHT_ASSETS_REPORTS_DEFAULT_REDIRECT:
		'servicedesk.insight-cmdb-assets_reports_default_redirect',
	INSIGHT_ASSETS_REPORTS_UNSUBSCRIBE: 'servicedesk.insight-cmdb-assets_reports_unsubscribe',
	INSIGHT_OBJECT_VIEW: 'servicedesk.insight-cmdb-object',
	INSIGHT_OBJECT_SCHEMA: 'servicedesk.insight-cmdb-object-schema',
	INSIGHT_OBJECT_SCHEMA_CONFIGURATION: 'servicedesk.insight-cmdb-object-schema-configuration',
	INSIGHT_OBJECT_TYPE_CONFIGURATION: 'servicedesk.insight-object-type-configuration',
	INSIGHT_GLOBAL_CONFIGURATION: 'servicedesk.insight-cmdb-global-configuration',
	INSIGHT_LANDING_PAGE: 'servicedesk.insight-cmdb-landing-page',
	CUSTOMER_NOTIFICATIONS_MAIN_VIEW: 'servicedesk.customer-notifications-settings.main-view',
	CUSTOMER_NOTIFICATIONS_INVITE_CUSTOMER_VIEW:
		'servicedesk.customer-notifications-settings.invite-customer-view',
	CUSTOMER_NOTIFICATIONS_RULE_VIEW: 'servicedesk.customer-notifications-settings.rule-view',
	CUSTOMER_NOTIFICATIONS_TEMPLATE_VIEW: 'servicedesk.customer-notifications-settings.template-view',
	CUSTOMER_NOTIFICATIONS_UNKNOWN_VIEW: 'servicedesk.customer-notifications-settings.unknown-view',
	SERVICES_WITHOUT_PROJECT_VIEW: 'servicedesk.services-without-project-view',
	SERVICE_DETAIL_WITHOUT_PROJECT_VIEW: 'servicedesk.service-detail-without-project-view',
	WIDGET_SETTINGS: 'servicedesk.widget-settings',
} as const;

export type AppName =
	| 'servicedesk.accounts'
	| 'servicedesk.app-discovery'
	| 'servicedesk.atlassian-analytics'
	| 'servicedesk.automation.edit-rule'
	| 'servicedesk.automation.summary'
	| 'servicedesk.automation.view-log'
	| 'servicedesk.board'
	| 'servicedesk.board-settings'
	| 'servicedesk.calendar'
	| 'servicedesk.change-calendar'
	| 'servicedesk.change-management.settings'
	| 'servicedesk.create-project'
	| 'servicedesk.csat-configuration'
	| 'servicedesk.contacts'
	| 'servicedesk.customer-permissions'
	| 'servicedesk.customers'
	| 'servicedesk.customer-details'
	| 'servicedesk.customer-service-escalations'
	| 'servicedesk.customer-service-products'
	| 'servicedesk.invite-team-dialog'
	| 'servicedesk.issue.schedule-change'
	| 'servicedesk.itsm-features.settings'
	| 'servicedesk.itsm-sample-space'
	| 'servicedesk.journeys'
	| 'servicedesk.kb-requests.reports'
	| 'servicedesk.knowledge-article.embed-editor'
	| 'servicedesk.knowledge-base.browse-articles'
	| 'servicedesk.knowledge-base.browse-categories'
	| 'servicedesk.knowledge-base.category'
	| 'servicedesk.knowledge-base.drafts'
	| 'servicedesk.knowledge-base.suggested-articles'
	| 'servicedesk.knowledge-base.landing'
	| 'servicedesk.knowledge-base.search'
	| 'servicedesk.knowledge-base.setup'
	| 'servicedesk.knowledge-base.view-article'
	| 'servicedesk.knowledge.settings'
	| 'servicedesk.language-settings'
	| 'servicedesk.organization'
	| 'servicedesk.organizations'
	| 'servicedesk.organization-details'
	| 'servicedesk.portal-settings'
	| 'servicedesk.queues.agent-view'
	| 'servicedesk.queues.edit-view'
	| 'servicedesk.queues.main'
	| 'servicedesk.reports.custom'
	| 'servicedesk.reports.custom-create'
	| 'servicedesk.reports.custom-edit'
	| 'servicedesk.reports.home-page'
	| 'servicedesk.reports.satisfaction'
	| 'servicedesk.reports.workload'
	| 'servicedesk.request-types-config'
	| 'servicedesk.request-type-fields'
	| 'servicedesk.sla-settings'
	| 'servicedesk.virtual-agent.browse-conversations'
	| 'servicedesk.virtual-agent.browse-intents'
	| 'servicedesk.virtual-agent.browse-standard-flows'
	| 'servicedesk.virtual-agent.landing'
	| 'servicedesk.virtual-agent.settings'
	| 'servicedesk.virtual-agent.view-intent'
	| 'servicedesk.virtual-agent.view-standard-flow'
	| 'servicedesk.practice-config-module'
	| 'servicedesk.create-service-modal'
	| 'servicedesk.update-service-modal'
	| 'servicedesk.link-alerts-modal'
	| 'servicedesk.insight-cmdb-assets_reports'
	| 'servicedesk.insight-cmdb-assets_reports_default_redirect'
	| 'servicedesk.insight-cmdb-assets_reports_unsubscribe'
	| 'servicedesk.insight-object-type-configuration'
	| 'servicedesk.insight-cmdb-global-configuration'
	| 'servicedesk.insight-cmdb-object'
	| 'servicedesk.insight-cmdb-object-schema'
	| 'servicedesk.insight-cmdb-object-schema-configuration'
	| 'servicedesk.insight-cmdb-landing-page'
	| 'servicedesk.customer-notifications-settings.main-view'
	| 'servicedesk.customer-notifications-settings.invite-customer-view'
	| 'servicedesk.customer-notifications-settings.rule-view'
	| 'servicedesk.customer-notifications-settings.template-view'
	| 'servicedesk.customer-notifications-settings.unknown-view'
	| 'servicedesk.getting-started-page'
	| 'servicedesk.services-without-project-view'
	| 'servicedesk.service-detail-without-project-view'
	| 'servicedesk.widget-settings';

// To match the FE error analytics pipeline convention
const ANALYTICS_PACKAGE_NAMES = {
	[APP_NAMES.ACCOUNTS]: 'servicedeskAccounts',
	[APP_NAMES.APP_DISCOVERY]: 'serviceDeskAppDiscovery',
	[APP_NAMES.ATLASSIAN_ANALYTICS]: 'serviceDeskAtlassianAnalytics',
	[APP_NAMES.AUTOMATION_EDIT_RULE]: 'servicedeskAutomationEditView',
	[APP_NAMES.AUTOMATION_SUMMARY]: 'servicedeskAutomationEditRule',
	[APP_NAMES.AUTOMATION_VIEW_LOG]: 'servicedeskAutomationViewLog',
	[APP_NAMES.BOARD]: 'servicedeskBoard',
	[APP_NAMES.BOARD_SETTINGS]: 'servicedeskBoardSettings',
	[APP_NAMES.CALENDAR]: 'servicedeskCalendar',
	[APP_NAMES.CHANGE_CALENDAR]: 'servicedeskChangeCalendar',
	[APP_NAMES.CHANGE_MANAGEMENT_SETTINGS]: 'servicedeskChangeManagementSettings',
	[APP_NAMES.CREATE_PROJECT]: 'servicedeskCreateProject',
	[APP_NAMES.CSAT_CONFIG]: 'servicedeskCsatConfig',
	[APP_NAMES.CONTACTS]: 'servicedeskContacts',
	[APP_NAMES.CUSTOMER_PERMISSIONS]: 'servicedeskCustomerPermissions',
	[APP_NAMES.CUSTOMERS]: 'servicedeskCustomers',
	[APP_NAMES.CUSTOMER_DETAILS]: 'servicedeskCustomerDetails',
	[APP_NAMES.CUSTOMER_SERVICE_ESCALATIONS]: 'servicedeskCustomerServiceEscalations',
	[APP_NAMES.CUSTOMER_SERVICE_PRODUCTS]: 'servicedeskCustomerServiceProducts',
	[APP_NAMES.INVITE_TEAM_DIALOG]: 'servicedeskInviteTeamDialog',
	[APP_NAMES.ISSUE_SCHEDULE_CHANGE]: 'servicedeskIssueScheduleChange',
	[APP_NAMES.ITSM_FEATURES_SETTINGS]: 'servicedeskItsmFeaturesSettings',
	[APP_NAMES.ITSM_SAMPLE_SPACE]: 'servicedeskItsmSampleSpace',
	[APP_NAMES.JOURNEYS]: 'servicedeskJourneys',
	[APP_NAMES.KB_BROWSE_ARTICLES]: 'servicedeskKnowledgeBaseBrowseArticles',
	[APP_NAMES.KB_BROWSE_CATEGORIES]: 'servicedeskKnowledgeBaseBrowseCategories',
	[APP_NAMES.KB_CATEGORY]: 'servicedeskKnowledgeBaseCategory',
	[APP_NAMES.KB_DRAFTS]: 'servicedeskKnowledgeBaseDrafts',
	[APP_NAMES.KB_SUGGESTED_ARTICLES]: 'servicedeskKnowledgeBaseSuggestedArticles',
	[APP_NAMES.KB_EMBED_EDITOR]: 'servicedeskKnowledgeBaseEmbedEditor',
	[APP_NAMES.KB_LANDING]: 'servicedeskKnowledgeBaseLanding',
	[APP_NAMES.KB_REPORTS_REQUESTS]: 'servicedeskKbRequestsReports',
	[APP_NAMES.KB_SEARCH]: 'servicedeskKnowledgeBaseSearch',
	[APP_NAMES.KB_SETTINGS]: 'servicedeskKnowledgeSettings',
	[APP_NAMES.KB_SETUP]: 'servicedeskKnowledgeBaseSetup',
	[APP_NAMES.KB_VIEW_ARTICLE]: 'servicedeskKnowledgeBaseViewArticle',
	[APP_NAMES.LANGUAGE_SETTINGS]: 'servicedeskLanguageSettingsTranslations',
	[APP_NAMES.ORGANIZATION]: 'servicedeskOrganization',
	[APP_NAMES.ORGANIZATIONS]: 'servicedeskOrganizations',
	[APP_NAMES.ORGANIZATION_DETAILS]: 'servicedeskOrganizationDetails',
	[APP_NAMES.PORTAL_SETTINGS]: 'servicedeskPortalSettings',
	[APP_NAMES.QUEUES_AGENT_VIEW]: 'servicedeskQueuesAgentView',
	[APP_NAMES.QUEUES_EDIT_VIEW]: 'servicedeskQueuesEditView',
	[APP_NAMES.QUEUES_MAIN]: 'servicedeskQueuesMain',
	[APP_NAMES.REPORTS_CUSTOM]: 'servicedeskReportsCustom',
	[APP_NAMES.REPORTS_CUSTOM_CREATE]: 'servicedeskReportsCustomCreate',
	[APP_NAMES.REPORTS_CUSTOM_EDIT]: 'servicedeskReportsCustomEdit',
	[APP_NAMES.REPORTS_HOME_PAGE]: 'servicedeskReportsHomePage',
	[APP_NAMES.REPORTS_SATISFACTION]: 'servicedeskReportsSatisfaction',
	[APP_NAMES.REPORTS_WORKLOAD]: 'servicedeskReportsWorkload',
	[APP_NAMES.REQUEST_TYPES_CONFIG]: 'servicedeskRequestTypesConfig',
	[APP_NAMES.REQUEST_TYPE_FIELDS]: 'servicedeskRequestTypeFields',
	[APP_NAMES.SLA_SETTINGS]: 'servicedeskSlaSettings',
	[APP_NAMES.VIRTUAL_AGENT_BROWSE_CONVERSATIONS]: 'servicedeskVirtualAgentBrowseConversations',
	[APP_NAMES.VIRTUAL_AGENT_BROWSE_INTENTS]: 'servicedeskVirtualAgentBrowseIntents',
	[APP_NAMES.VIRTUAL_AGENT_BROWSE_STANDARD_FLOWS]: 'servicedeskVirtualAgentBrowseStandardFlows',
	[APP_NAMES.VIRTUAL_AGENT_LANDING]: 'servicedeskVirtualAgentLanding',
	[APP_NAMES.VIRTUAL_AGENT_SETTINGS]: 'servicedeskVirtualAgentSettings',
	[APP_NAMES.VIRTUAL_AGENT_VIEW_INTENT]: 'servicedeskVirtualAgentViewIntent',
	[APP_NAMES.VIRTUAL_AGENT_VIEW_STANDARD_FLOW]: 'servicedeskVirtualAgentViewStandardFlow',
	[APP_NAMES.PRACTICE_CONFIG_MODULE]: 'servicedeskPracticeConfigModule',
	[APP_NAMES.CREATE_SERVICE_MODAL]: 'servicedeskCreateServiceModal',
	[APP_NAMES.UPDATE_SERVICE_MODAL]: 'servicedeskUpdateServiceModal',
	[APP_NAMES.LINKED_ALERTS_MODAL]: 'servicedeskLinkAlertsModal',
	[APP_NAMES.INSIGHT_ASSETS_REPORTS]: 'servicedeskAssetsReports',
	[APP_NAMES.INSIGHT_ASSETS_REPORTS_DEFAULT_REDIRECT]: 'servicedeskAssetsReportsDefaultRedirect',
	[APP_NAMES.INSIGHT_ASSETS_REPORTS_UNSUBSCRIBE]: 'servicedeskAssetsReportsUnsubscribe',
	[APP_NAMES.INSIGHT_OBJECT_VIEW]: 'servicedeskObjectView',
	[APP_NAMES.INSIGHT_OBJECT_SCHEMA]: 'servicedeskObjectSchema',
	[APP_NAMES.INSIGHT_OBJECT_SCHEMA_CONFIGURATION]: 'servicedeskObjectSchemaConfiguration',
	[APP_NAMES.INSIGHT_OBJECT_TYPE_CONFIGURATION]: 'servicedeskObjectTypeConfiguration',
	[APP_NAMES.INSIGHT_GLOBAL_CONFIGURATION]: 'servicedeskInsightGlobalConfiguration',
	[APP_NAMES.INSIGHT_LANDING_PAGE]: 'servicedeskInsightLandingPage',
	[APP_NAMES.CUSTOMER_NOTIFICATIONS_MAIN_VIEW]: 'servicedeskCustomerNotificationsMainView',
	[APP_NAMES.CUSTOMER_NOTIFICATIONS_INVITE_CUSTOMER_VIEW]:
		'servicedeskCustomerNotificationsInviteCustomerView',
	[APP_NAMES.CUSTOMER_NOTIFICATIONS_RULE_VIEW]: 'servicedeskCustomerNotificationsRuleView',
	[APP_NAMES.CUSTOMER_NOTIFICATIONS_TEMPLATE_VIEW]: 'servicedeskCustomerNotificationsTemplateView',
	[APP_NAMES.CUSTOMER_NOTIFICATIONS_UNKNOWN_VIEW]: 'servicedeskCustomerNotificationsUnknownView',
	[APP_NAMES.GETTING_STARTED_PAGE]: 'servicedeskGettingStartedPage',
	[APP_NAMES.SERVICES_WITHOUT_PROJECT_VIEW]: 'servicedeskServicesWithoutProjectView',
	[APP_NAMES.SERVICE_DETAIL_WITHOUT_PROJECT_VIEW]: 'servicedeskServiceDetailWithoutProjectView',
	[APP_NAMES.WIDGET_SETTINGS]: 'servicedeskWidgetSettings',
} as const;

export type PackageName =
	| 'servicedeskAccounts'
	| 'serviceDeskAppDiscovery'
	| 'serviceDeskAtlassianAnalytics'
	| 'servicedeskAutomationEditRule'
	| 'servicedeskAutomationEditView'
	| 'servicedeskAutomationViewLog'
	| 'servicedeskBoard'
	| 'servicedeskBoardSettings'
	| 'servicedeskLanguageSettingsTranslations'
	| 'servicedeskCalendar'
	| 'servicedeskChangeCalendar'
	| 'servicedeskChangeManagementSettings'
	| 'servicedeskContacts'
	| 'servicedeskCreateProject'
	| 'servicedeskCsatConfig'
	| 'servicedeskCustomerPermissions'
	| 'servicedeskCustomers'
	| 'servicedeskCustomerDetails'
	| 'servicedeskCustomerServiceEscalations'
	| 'servicedeskCustomerServiceProducts'
	| 'servicedeskInviteTeamDialog'
	| 'servicedeskIssueScheduleChange'
	| 'servicedeskItsmFeaturesSettings'
	| 'servicedeskItsmSampleSpace'
	| 'servicedeskJourneys'
	| 'servicedeskKbRequestsReports'
	| 'servicedeskKnowledgeBaseBrowseArticles'
	| 'servicedeskKnowledgeBaseBrowseCategories'
	| 'servicedeskKnowledgeBaseCategory'
	| 'servicedeskKnowledgeBaseDrafts'
	| 'servicedeskKnowledgeBaseSuggestedArticles'
	| 'servicedeskKnowledgeBaseEmbedEditor'
	| 'servicedeskKnowledgeBaseLanding'
	| 'servicedeskKnowledgeBaseSearch'
	| 'servicedeskKnowledgeBaseSetup'
	| 'servicedeskKnowledgeBaseViewArticle'
	| 'servicedeskKnowledgeSettings'
	| 'servicedeskOrganization'
	| 'servicedeskOrganizations'
	| 'servicedeskOrganizationDetails'
	| 'servicedeskPortalSettings'
	| 'servicedeskQueuesAgentView'
	| 'servicedeskQueuesEditView'
	| 'servicedeskQueuesMain'
	| 'servicedeskReportsCustom'
	| 'servicedeskReportsCustomCreate'
	| 'servicedeskReportsCustomEdit'
	| 'servicedeskReportsHomePage'
	| 'servicedeskReportsSatisfaction'
	| 'servicedeskReportsWorkload'
	| 'servicedeskRequestTypesConfig'
	| 'servicedeskRequestTypeFields'
	| 'servicedeskSlaSettings'
	| 'servicedeskVirtualAgentBrowseConversations'
	| 'servicedeskVirtualAgentBrowseIntents'
	| 'servicedeskVirtualAgentBrowseStandardFlows'
	| 'servicedeskVirtualAgentLanding'
	| 'servicedeskVirtualAgentSettings'
	| 'servicedeskVirtualAgentViewIntent'
	| 'servicedeskVirtualAgentViewStandardFlow'
	| 'servicedeskPracticeConfigModule'
	| 'servicedeskCreateServiceModal'
	| 'servicedeskUpdateServiceModal'
	| 'servicedeskLinkAlertsModal'
	| 'servicedeskAssetsReports'
	| 'servicedeskAssetsReportsDefaultRedirect'
	| 'servicedeskAssetsReportsUnsubscribe'
	| 'servicedeskManageObjectSchemas'
	| 'servicedeskObjectTypeConfiguration'
	| 'servicedeskObjectView'
	| 'servicedeskObjectSchema'
	| 'servicedeskObjectSchemaConfiguration'
	| 'servicedeskInsightGlobalConfiguration'
	| 'servicedeskInsightLandingPage'
	| 'servicedeskCustomerNotificationsMainView'
	| 'servicedeskCustomerNotificationsInviteCustomerView'
	| 'servicedeskCustomerNotificationsRuleView'
	| 'servicedeskCustomerNotificationsTemplateView'
	| 'servicedeskCustomerNotificationsUnknownView'
	| 'servicedeskGettingStartedPage'
	| 'servicedeskServicesWithoutProjectView'
	| 'servicedeskServiceDetailWithoutProjectView'
	| 'servicedeskWidgetSettings';

export const toPackageName = (appName: AppName): PackageName => ANALYTICS_PACKAGE_NAMES[appName];
