import React, { memo } from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { borderRadius } from '@atlassian/jira-common-styles/src/main.tsx';
import type { NavigationProject } from '@atlassian/jira-router-resources-business-navigation/src/types.tsx';
import { PROJECT_ICON_SIZE } from '../../constants';
import FavoriteButton from '../favorite-button';
import SidebarLink from '../sidebar-link';

type Props = {
	project: NavigationProject;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onClick: () => any;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onFavoriteToggle: (favorite: boolean) => any;
	to: string;
	isActive?: boolean;
	className?: string;
	testId?: string;
	prefetch?: 'hover';
};

const ProjectItem = ({
	project,
	onClick,
	onFavoriteToggle,
	to,
	isActive,
	className,
	testId,
	prefetch,
}: Props) => (
	<SidebarLink
		to={to}
		onClick={onClick}
		isActive={isActive}
		elemAfter={
			project.permissions.viewIssues && (
				<FavoriteButton project={project} onToggle={onFavoriteToggle} />
			)
		}
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
		className={className}
		testId={testId}
		prefetch={prefetch}
	>
		<Avatar src={project.avatarUrl} alt="" />

		<ProjectName>{project.name}</ProjectName>
	</SidebarLink>
);

export default memo<Props>(ProjectItem);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ProjectName = styled.span({
	flex: 1,
	overflow: 'hidden',
	textAlign: 'left',
	display: 'block',
	whiteSpace: 'nowrap',
	textOverflow: 'ellipsis',
	fontWeight: token('font.weight.medium'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Avatar = styled.img({
	flex: 'none',
	boxSizing: 'border-box',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: `${PROJECT_ICON_SIZE}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	width: `${PROJECT_ICON_SIZE}px`,
	marginRight: token('space.150', '12px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	borderRadius: `${borderRadius}px`,
});
