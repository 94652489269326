import {
	ROUTE_GROUPS_SOFTWARE,
	ROUTE_GROUPS_CLASSIC_SOFTWARE,
	ROUTE_NAMES_SOFTWARE_PERISCOPE,
	ROUTE_NAMES_SOFTWARE_PERISCOPE_CLASSIC,
} from '@atlassian/jira-common-constants/src/spa-routes';

export const softwarePeriscopeRoute = {
	group: ROUTE_GROUPS_SOFTWARE,
	name: ROUTE_NAMES_SOFTWARE_PERISCOPE,
	path: '/jira/software/projects/:projectKey/development',
};

export const softwarePeriscopeClassicRoute = {
	group: ROUTE_GROUPS_CLASSIC_SOFTWARE,
	name: ROUTE_NAMES_SOFTWARE_PERISCOPE_CLASSIC,
	path: '/jira/software/c/projects/:projectKey/development',
};
