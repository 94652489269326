import { ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK } from '@atlassian/jira-common-constants/src/spa-routes';

export const SERVICE_DESK_PRODUCT = 'servicedesk';
const projectSettingsUrl = (product: string) => `/jira/${product}/projects/:projectKey/settings`;
export const landingPageRoute = {
	group: ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK,
	// We name this virtualagentonboarding without the "-" as otherwise it would trigger
	// the nesting stack, which we don't want for onboarding
	name: `${ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK}-virtualagentonboarding`,
	exact: true,
	path: `${projectSettingsUrl(SERVICE_DESK_PRODUCT)}/virtual-agent`,
};

export const browseIntentsRoute = {
	group: ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK,
	name: `${ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK}-virtualagent-browse-intents`,
	exact: true,
	path: `${projectSettingsUrl(SERVICE_DESK_PRODUCT)}/virtual-agent/intents`,
};

export const viewIntentRoute = {
	group: ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK,
	name: `${ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK}-virtualagent-view-intent`,
	exact: true,
	path: `${projectSettingsUrl(
		SERVICE_DESK_PRODUCT,
	)}/virtual-agent/intents/:intentId/:tab(flow|training|settings)?`,
};

export const browseStandardFlowsRoute = {
	group: ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK,
	name: `${ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK}-virtualagent-standard-flows`,
	exact: true,
	path: `${projectSettingsUrl(SERVICE_DESK_PRODUCT)}/virtual-agent/standard-flows`,
};

export const viewStandardFlowRoute = {
	group: ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK,
	name: `${ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK}-virtualagent-view-standard-flow`,
	exact: true,
	path: `${projectSettingsUrl(
		SERVICE_DESK_PRODUCT,
	)}/virtual-agent/standard-flows/:flowId/:tab(flow|settings)?`,
};

export const browseConversationsRoute = {
	group: ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK,
	name: `${ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK}-virtualagent-browseconversations`,
	exact: true,
	path: `${projectSettingsUrl(SERVICE_DESK_PRODUCT)}/virtual-agent/conversations`,
};

export const settingsRoute = {
	group: ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK,
	name: `${ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK}-virtualagent-settings`,
	exact: true,
	path: `${projectSettingsUrl(SERVICE_DESK_PRODUCT)}/virtual-agent/settings/:tab(basic|channels)?`,
};
