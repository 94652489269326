import {
	ROUTE_GROUPS_GLOBAL_SETTINGS,
	ROUTE_NAMES_PRODUCTS_JSM_GLOBAL_OPS_REPORTS,
} from '@atlassian/jira-common-constants/src/spa-routes';

export const productsJsmGlobalOpsReportsRoute = {
	group: ROUTE_GROUPS_GLOBAL_SETTINGS,
	name: ROUTE_NAMES_PRODUCTS_JSM_GLOBAL_OPS_REPORTS,
	path: '/jira/settings/products/ops/reports/:dashboardId',
	exact: true,
};

export const productsJsmGlobalOpsReports1Route = {
	group: ROUTE_GROUPS_GLOBAL_SETTINGS,
	name: ROUTE_NAMES_PRODUCTS_JSM_GLOBAL_OPS_REPORTS,
	path: '/jira/settings/products/ops/reports',
	exact: true,
};
