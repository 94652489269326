import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { ff } from '@atlassian/jira-feature-flagging';
import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import { createResource } from '@atlassian/jira-router';
import { resourceWithCondition2 } from '@atlassian/jira-router-resources-utils/src/utils/resource-with-condition/index.tsx';

type BoardDetails = {
	id: number;
	location?: {
		name: string;
		displayName: string;
		avatarURI: string;
		projectId?: number;
		projectKey?: string;
		projectName?: string;
		projectTypeKey?: string;
		userAccountId?: string;
		userId?: number;
	};
	name: string;
	self: string;
	type: string;
};

export const getBoardDetails = async (boardId: string): Promise<BoardDetails | null> => {
	try {
		return await fetchJson(`/rest/agile/1.0/board/${boardId}`);
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (error: any) {
		fireErrorAnalytics({
			error,
			meta: {
				id: 'getBoardDetailsRequest',
				packageName: 'jiraRouterResourcesClassicProjects',

				teamName: 'a4t-pandora',
			},
			sendToPrivacyUnsafeSplunk: true,
		});
		throw error;
	}
};

// Used for fetching during SSR
export const boardDetailsResource = createResource<BoardDetails | null>({
	type: 'CLASSIC_BOARD_SETTING_BOARD_DETAILS',
	getKey: (routerContext) =>
		routerContext.query?.config ? 'cmp-board-settings' : 'not-cmp-board-setting',
	getData: async (routerContext) => {
		const boardId = routerContext.match.params?.boardId ?? '';
		if (routerContext.query?.config) {
			return getBoardDetails(boardId);
		}
		return null;
	},
});

export const getBoardDetailsResource = () =>
	resourceWithCondition2(() => ff('simplified-boards-milestone-2_07hbk'), boardDetailsResource);
