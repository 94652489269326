import {
	ROUTE_GROUPS_SOFTWARE,
	ROUTE_GROUPS_CLASSIC_SOFTWARE,
	ROUTE_NAMES_SOFTWARE_INCIDENTS,
	ROUTE_NAMES_SOFTWARE_INCIDENTS_CLASSIC,
} from '@atlassian/jira-common-constants/src/spa-routes';
import type { Route } from '@atlassian/jira-router';

export const softwareIncidentsNextGenRoute: Route = {
	group: ROUTE_GROUPS_SOFTWARE,
	name: ROUTE_NAMES_SOFTWARE_INCIDENTS,
	path: '/jira/software/projects/:projectKey/incidents',
};

export const softwareIncidentsClassicRoute: Route = {
	group: ROUTE_GROUPS_CLASSIC_SOFTWARE,
	name: ROUTE_NAMES_SOFTWARE_INCIDENTS_CLASSIC,
	path: '/jira/software/c/projects/:projectKey/incidents',
};
