import log from '@atlassian/jira-common-util-logging/src/log';
import { performGetRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import { createResource } from '@atlassian/jira-router';
import { PLAN_SIDEBAR_NAVIGATION_OPTIN_STATUS } from '../../common/constants';

export type PlanSidebarNavigationOptInStatusResourceData = {
	planSidebarNavigationOptInStatus: {
		isEnabled: boolean;
	} | null;
};

export const planSidebarNavigationOptInStatusResources =
	createResource<PlanSidebarNavigationOptInStatusResourceData>({
		type: PLAN_SIDEBAR_NAVIGATION_OPTIN_STATUS,
		getKey: () => 'plan_sidebar_navigation_optin_status',
		getData: async (): Promise<PlanSidebarNavigationOptInStatusResourceData> => {
			let planSidebarNavigationOptInStatus = null;

			try {
				planSidebarNavigationOptInStatus = await performGetRequest(
					'/rest/api/3/mypreferences?key=arj-navigation-sidebar',
				);
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (e: any) {
				// Error 404 is acceptable as on the first call the key does not exist in the user profile
				if (e.statusCode === 404) {
					return {
						planSidebarNavigationOptInStatus: {
							isEnabled: false,
						},
					};
				}
				log.safeErrorWithoutCustomerData(
					'spa.router.resources.navigation.detectPlanSidebarNavigationVisibility',
					'getDetectPlanSidebarNavigationVisibility',
					e,
				);
			}
			return {
				planSidebarNavigationOptInStatus: planSidebarNavigationOptInStatus ?? {
					isEnabled: false,
				},
			};
		},
		maxAge: Number.MAX_VALUE,
	});
