import {
	ROUTE_GROUPS_CLASSIC_SOFTWARE,
	ROUTE_NAMES_RAPIDBOARD_BACKLOG,
	ROUTE_NAMES_RAPIDBOARD_BOARD,
} from '@atlassian/jira-common-constants/src/spa-routes';

export const rapidboardBacklogRoute = {
	group: ROUTE_GROUPS_CLASSIC_SOFTWARE,
	name: ROUTE_NAMES_RAPIDBOARD_BACKLOG,
	path: '/jira/software/c/projects/:projectKey/boards/:boardId/backlog',
	query: ['config=insightsConfig'],
};

export const rapidboardBoardRoute = {
	group: ROUTE_GROUPS_CLASSIC_SOFTWARE,
	name: ROUTE_NAMES_RAPIDBOARD_BOARD,
	path: '/jira/software/c/projects/:projectKey/boards/:boardId',
	query: ['config=insightsConfig'],
};
