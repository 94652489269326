import {
	ROUTE_GROUPS_SOFTWARE,
	ROUTE_NAMES_SOFTWARE_CALENDAR,
	ROUTE_NAMES_SOFTWARE_CALENDAR_EMBED,
} from '@atlassian/jira-common-constants/src/spa-routes';

export const softwareCalendarRoute = {
	group: ROUTE_GROUPS_SOFTWARE,
	name: ROUTE_NAMES_SOFTWARE_CALENDAR,
	path: '/jira/software/projects/:projectKey/boards/:boardId/calendar',
	queries: ['date?'],
};

export const softwareCalendarEmbedRoute = {
	group: ROUTE_GROUPS_SOFTWARE,
	name: ROUTE_NAMES_SOFTWARE_CALENDAR_EMBED,
	path: '/jira/software/projects/:projectKey/boards/:boardId/calendar/embed',
	queries: ['date?'],
};
