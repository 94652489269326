import {
	ROUTE_NAMES_PROFORMA_EXPORT_CLASSIC_BUSINESS,
	ROUTE_NAMES_PROFORMA_EXPORT_SERVICEDESK,
	ROUTE_NAMES_PROFORMA_EXPORT_SOFTWARE,
	ROUTE_NAMES_PROFORMA_ISSUE_FORMS_DIRECT_CLASSIC_BUSINESS,
	ROUTE_NAMES_PROFORMA_ISSUE_FORMS_DIRECT_SERVICEDESK,
	ROUTE_NAMES_PROFORMA_ISSUE_FORMS_DIRECT_SOFTWARE,
	ROUTE_NAMES_PROFORMA_ISSUE_FORMS_DIRECT_EMBED_CLASSIC_BUSINESS,
	ROUTE_NAMES_PROFORMA_ISSUE_FORMS_DIRECT_EMBED_SERVICEDESK,
	ROUTE_NAMES_PROFORMA_ISSUE_FORMS_DIRECT_EMBED_SOFTWARE,
	ROUTE_NAMES_PROFORMA_ISSUE_FORMS_GENERAL_CLASSIC_BUSINESS,
	ROUTE_NAMES_PROFORMA_ISSUE_FORMS_GENERAL_SERVICEDESK,
	ROUTE_NAMES_PROFORMA_ISSUE_FORMS_GENERAL_SOFTWARE,
	ROUTE_GROUPS_PROFORMA,
	ROUTE_GROUPS_SERVICEDESK,
	ROUTE_GROUPS_CLASSIC_BUSINESS,
	ROUTE_GROUPS_SOFTWARE,
} from '@atlassian/jira-common-constants/src/spa-routes';

const CORE_PRODUCT = 'core';
const SOFTWARE_PRODUCT = 'software';
const SERVICE_DESK_PRODUCT = 'servicedesk';
const jiraIssueFormCreateEndpoint =
	'projects/:projectKey/forms/form/direct/:templateFormId/:issueTypeId?'; // the 1 query param is "requestTypeId"
const EXPORT_URL = '/jira/forms/export';
const ISSUE_FORMS_GENERAL_URL = '/jira/forms/create';

export const routeGroupsServicedeskRouteNamesProformaExportRoute = {
	group: ROUTE_GROUPS_PROFORMA,
	name: ROUTE_NAMES_PROFORMA_EXPORT_SERVICEDESK,
	path: EXPORT_URL,
	exact: true,
};

export const routeGroupsServicedeskRouteNamesProformaIssueFormsGeneralRoute = {
	group: ROUTE_GROUPS_PROFORMA,
	name: ROUTE_NAMES_PROFORMA_ISSUE_FORMS_GENERAL_SERVICEDESK,
	path: ISSUE_FORMS_GENERAL_URL,
	exact: true,
};

export const routeGroupsServicedeskRouteNamesProformaIssueFormsDirectRoute = {
	group: ROUTE_GROUPS_SERVICEDESK,
	name: ROUTE_NAMES_PROFORMA_ISSUE_FORMS_DIRECT_SERVICEDESK,
	path: `/jira/${SERVICE_DESK_PRODUCT}/${jiraIssueFormCreateEndpoint}`,
	exact: true,
};

export const routeGroupsServicedeskRouteNamesProformaIssueFormsDirectEmbedRoute = {
	group: ROUTE_GROUPS_SERVICEDESK,
	name: ROUTE_NAMES_PROFORMA_ISSUE_FORMS_DIRECT_EMBED_SERVICEDESK,
	path: `/jira/${SERVICE_DESK_PRODUCT}/${jiraIssueFormCreateEndpoint}/embed`,
	exact: true,
};

export const routeGroupsClassicBusinessRouteNamesProformaExportRoute = {
	group: ROUTE_GROUPS_PROFORMA,
	name: ROUTE_NAMES_PROFORMA_EXPORT_CLASSIC_BUSINESS,
	path: EXPORT_URL,
	exact: true,
};

export const routeGroupsClassicBusinessRouteNamesProformaIssueFormsGeneralRoute = {
	group: ROUTE_GROUPS_PROFORMA,
	name: ROUTE_NAMES_PROFORMA_ISSUE_FORMS_GENERAL_CLASSIC_BUSINESS,
	path: ISSUE_FORMS_GENERAL_URL,
	exact: true,
};

export const routeGroupsClassicBusinessRouteNamesProformaIssueFormsDirectRoute = {
	group: ROUTE_GROUPS_CLASSIC_BUSINESS,
	name: ROUTE_NAMES_PROFORMA_ISSUE_FORMS_DIRECT_CLASSIC_BUSINESS,
	path: `/jira/${CORE_PRODUCT}/${jiraIssueFormCreateEndpoint}`,
	exact: true,
};

export const routeGroupsClassicBusinessRouteNamesProformaIssueFormsDirectEmbedRoute = {
	group: ROUTE_GROUPS_CLASSIC_BUSINESS,
	name: ROUTE_NAMES_PROFORMA_ISSUE_FORMS_DIRECT_EMBED_CLASSIC_BUSINESS,
	path: `/jira/${CORE_PRODUCT}/${jiraIssueFormCreateEndpoint}/embed`,
	exact: true,
};

export const routeGroupsSoftwareRouteNamesProformaExportRoute = {
	group: ROUTE_GROUPS_PROFORMA,
	name: ROUTE_NAMES_PROFORMA_EXPORT_SOFTWARE,
	path: EXPORT_URL,
	exact: true,
};

export const routeGroupsSoftwareRouteNamesProformaIssueFormsGeneralRoute = {
	group: ROUTE_GROUPS_PROFORMA,
	name: ROUTE_NAMES_PROFORMA_ISSUE_FORMS_GENERAL_SOFTWARE,
	path: ISSUE_FORMS_GENERAL_URL,
	exact: true,
};

export const routeGroupsSoftwareRouteNamesProformaIssueFormsDirectRoute = {
	group: ROUTE_GROUPS_SOFTWARE,
	name: ROUTE_NAMES_PROFORMA_ISSUE_FORMS_DIRECT_SOFTWARE,
	path: `/jira/${SOFTWARE_PRODUCT}/${jiraIssueFormCreateEndpoint}`,
	exact: true,
};

export const routeGroupsSoftwareRouteNamesProformaIssueFormsDirectEmbedRoute = {
	group: ROUTE_GROUPS_SOFTWARE,
	name: ROUTE_NAMES_PROFORMA_ISSUE_FORMS_DIRECT_EMBED_SOFTWARE,
	path: `/jira/${SOFTWARE_PRODUCT}/${jiraIssueFormCreateEndpoint}/embed`,
	exact: true,
};
