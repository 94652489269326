import {
	ROUTE_GROUPS_ARCHIVE_ISSUES,
	ROUTE_NAMES_ARCHIVED_ISSUES_DIRECTORY,
} from '@atlassian/jira-common-constants/src/spa-routes';

export const archivedIssuesRedirectRoute = {
	group: ROUTE_GROUPS_ARCHIVE_ISSUES,
	name: ROUTE_NAMES_ARCHIVED_ISSUES_DIRECTORY,
	path: '/jira/issues/archive',
	exact: true,
};

export const archivedIssuesJswRoute = {
	group: ROUTE_GROUPS_ARCHIVE_ISSUES,
	name: ROUTE_NAMES_ARCHIVED_ISSUES_DIRECTORY,
	path: '/jira/software/projects/:projectKey/issues/archive',
	exact: true,
};

export const archivedIssuesJswCompanyRoute = {
	group: ROUTE_GROUPS_ARCHIVE_ISSUES,
	name: ROUTE_NAMES_ARCHIVED_ISSUES_DIRECTORY,
	path: '/jira/software/c/projects/:projectKey/issues/archive',
	exact: true,
};

export const archivedIssuesJsmRoute = {
	group: ROUTE_GROUPS_ARCHIVE_ISSUES,
	name: ROUTE_NAMES_ARCHIVED_ISSUES_DIRECTORY,
	path: '/jira/servicedesk/projects/:projectKey/issues/archive',
	exact: true,
};

export const archivedIssuesJwmRoute = {
	group: ROUTE_GROUPS_ARCHIVE_ISSUES,
	name: ROUTE_NAMES_ARCHIVED_ISSUES_DIRECTORY,
	path: '/jira/core/projects/:projectKey/issues/archive',
	exact: true,
};
