import {
	ROUTE_GROUPS_EOC,
	ROUTE_NAMES_EOC_TEAM_CUSTOMER_LOGS,
	ROUTE_NAMES_EOC_TEAM_CUSTOMER_LOGS_DOWNLOAD,
} from '@atlassian/jira-common-constants/src/spa-routes';

export const eocTeamCustomerLogsRoute = {
	group: ROUTE_GROUPS_EOC,
	name: ROUTE_NAMES_EOC_TEAM_CUSTOMER_LOGS,
	path: '/jira/settings/products/ops/jira-service-management-audit-logs',
	exact: true,
};

export const eocTeamCustomerLogsDownloadRoute = {
	group: ROUTE_GROUPS_EOC,
	name: ROUTE_NAMES_EOC_TEAM_CUSTOMER_LOGS_DOWNLOAD,
	path: '/jira/settings/products/ops/jira-service-management-audit-logs/download/:exportUniqueKey',
	exact: true,
};
