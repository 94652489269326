import {
	ROUTE_NAMES_YOUR_WORK,
	ROUTE_NAMES_PEOPLE_INDEX,
} from '@atlassian/jira-common-constants/src/spa-routes';
import type { RouterContext } from '@atlassian/jira-router';
import { MENU_ID_TEAMS, MENU_ID_YOUR_WORK } from '../../../../constants';
import type { SelectedPath } from '../../../../types';

const routes = new Set<string>([
	ROUTE_NAMES_YOUR_WORK,
	// ROUTE_NAMES_HOME_ONBOARDING_REDIRECT,
	// ROUTE_NAMES_HOME_REDIRECT
	ROUTE_NAMES_PEOPLE_INDEX,
]);

function convert(routeContext: RouterContext): SelectedPath {
	const routeName = routeContext.route.name;
	const path: string[] = [];

	switch (routeName) {
		case ROUTE_NAMES_YOUR_WORK:
			path.push(MENU_ID_YOUR_WORK);
			break;

		case ROUTE_NAMES_PEOPLE_INDEX:
			path.push(MENU_ID_TEAMS);
			break;
		default:
	}

	return path;
}

export const topLevelConverter = { routes, convert };
