import {
	ROUTE_GROUPS_GLOBAL_SETTINGS,
	ROUTE_NAMES_PRODUCTS_JSM_EMAIL_REQUESTS,
} from '@atlassian/jira-common-constants/src/spa-routes';

export const productsJsmEmailRequestsRoute = {
	group: ROUTE_GROUPS_GLOBAL_SETTINGS,
	name: ROUTE_NAMES_PRODUCTS_JSM_EMAIL_REQUESTS,
	path: '/jira/settings/products/jira-service-management-email-requests',
	exact: true,
};
