import React from 'react';
import {
	BusinessWrapper,
	useBusinessLookAndFeel,
	ThemedContentLayout,
} from '@atlassian/jira-business-theme-page-container';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries';
import { ff } from '@atlassian/jira-feature-flagging';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { MainHeightFixCss } from '@atlassian/jira-main-height-fix';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout';
import { Main } from '@atlassian/jira-navigation-system';
import { RenderTracerMark } from '@atlassian/jira-render-tracer-analytics';
import SpaEntry, { type Props } from '@atlassian/jira-spa/src';
import { MainContentSkipLink } from '../../../common/ui/page-container/page-container-skip-links';
import { LayoutMainWrapper } from './styled';

export type { Props };

/* IMPORTANT:
 * When making any changes to the OLD component, please ensure that you also make the same changes to the
 * NEW component. The feature flag used is part of the Navigation Refresh work and we need to ensure that
 * we do not introduce tech debt for the roll-out by only updating the current component that is due to be
 * removed.
 *
 * If you have any concerns or questions please reach out to the #jira-navigation channel. Thanks!
 */

const SPA_TRACE_KEY = 'spa';

const NewContent = (props: Props) => {
	const { isInset, hasTheming } = useBusinessLookAndFeel();

	const renderContent = (
		<>
			<JSErrorBoundary id="mainContentSkipLink" packageName="jiraPageContainer" fallback="unmount">
				<MainContentSkipLink />
			</JSErrorBoundary>
			<RenderTracerMark traceKey={SPA_TRACE_KEY} type="start" />
			<SpaEntry {...props} />
			<RenderTracerMark traceKey={SPA_TRACE_KEY} type="end" />
		</>
	);

	if (hasTheming) {
		return (
			<ThemedContentLayout appearance={isInset ? 'inset' : 'default'}>
				{renderContent}
			</ThemedContentLayout>
		);
	}

	return renderContent;
};

const OldContent = (props: Props) => {
	const { isInset, hasProjectViewNavigation } = useBusinessLookAndFeel();

	const renderContent = (
		<>
			<MainHeightFixCss />
			<JSErrorBoundary id="mainContentSkipLink" packageName="jiraPageContainer" fallback="unmount">
				<MainContentSkipLink />
			</JSErrorBoundary>
			<Main id="ak-main-content">
				<RenderTracerMark traceKey={SPA_TRACE_KEY} type="start" />
				<SpaEntry {...props} />
				<RenderTracerMark traceKey={SPA_TRACE_KEY} type="end" />
			</Main>
		</>
	);

	if (hasProjectViewNavigation) {
		return <BusinessWrapper withWhiteBackground={isInset}>{renderContent}</BusinessWrapper>;
	}

	return ff('iph.enabled') ? <LayoutMainWrapper>{renderContent}</LayoutMainWrapper> : renderContent;
};

export const Content = componentWithCondition(getWillShowNav4, NewContent, OldContent);
