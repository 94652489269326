import { loadEntryPoint, type Environment } from 'react-relay';
import type { TenantContext } from '@atlassian/jira-shared-types/src/tenant-context.tsx';
import type { Plugin } from '@atlassian/react-resource-router';
import { isExpired, getExpiresAt, isSameRouteMatch } from '../../common/utils';
import { getEntryPointsStore, getPrefetchingRoute, getLastLoadedRoute } from '../store';
import { waitForEntryPoint } from '../wait-for-entry-point';

interface EntryPointsPlugin extends Plugin {
	waitForEntryPoint: () => Promise<unknown>;
}

type EntryPointParams = {
	tenantContext: TenantContext;
};

export const createEntryPointsPlugin = ({
	environment,
	entryPointParams,
}: {
	environment: Environment;
	entryPointParams: EntryPointParams;
}): EntryPointsPlugin => ({
	id: 'entry-points-plugin',
	routeLoad: ({ context }) => {
		const entryPoint = context.route.entryPoint?.();
		const prefetchingRoute = getPrefetchingRoute();

		let entryPointReference = null;

		if (entryPoint) {
			// check if route EntryPoint is already prefethed right before being loaded
			if (
				prefetchingRoute &&
				isSameRouteMatch({
					prevContextMatch: prefetchingRoute.routerContext.match,
					nextContextMatch: context.match,
				}) &&
				!isExpired(prefetchingRoute.expiresAt)
			) {
				entryPointReference = prefetchingRoute.entryPointReference;
			} else {
				entryPointReference = loadEntryPoint({ getEnvironment: () => environment }, entryPoint, {
					context,
					...entryPointParams,
				});
			}
		}

		// saving entryPointReference helps to prevent cases when same EntryPoint is fetched multiple times
		// important when fetchPolicy=network-only is used
		getEntryPointsStore().actions.setLastLoadedRoute({
			route: context.route,
			entryPointReference,
		});

		return {
			entryPoint: entryPointReference,
		};
	},
	routePrefetch: ({ nextContext }) => {
		const entryPoint = nextContext.route.entryPoint?.();

		if (entryPoint) {
			const prevPrefetchingRoute = getPrefetchingRoute();

			// check if EntryPoint is already prefetched and not expired
			if (
				prevPrefetchingRoute &&
				isSameRouteMatch({
					prevContextMatch: prevPrefetchingRoute.routerContext.match,
					nextContextMatch: nextContext.match,
				}) &&
				!isExpired(prevPrefetchingRoute.expiresAt)
			) {
				return;
			}

			const entryPointReference = loadEntryPoint(
				{ getEnvironment: () => environment },
				entryPoint,
				{
					context: nextContext,
					...entryPointParams,
				},
			);

			getEntryPointsStore().actions.setPrefetchingRoute({
				routerContext: nextContext,
				expiresAt: getExpiresAt(),
				entryPointReference,
			});
		} else {
			getEntryPointsStore().actions.setPrefetchingRoute({
				routerContext: nextContext,
				expiresAt: 0,
				entryPointReference: null,
			});
		}
	},
	waitForEntryPoint: () => {
		const lastLoadedRoute = getLastLoadedRoute();
		const entryPointReference = lastLoadedRoute?.entryPointReference ?? null;

		if (entryPointReference) {
			return waitForEntryPoint(entryPointReference);
		}

		return Promise.resolve();
	},
});
