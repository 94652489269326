import {
	ROUTE_GROUPS_GLOBAL_SETTINGS,
	ROUTE_NAMES_EXTERNAL_IMPORT,
} from '@atlassian/jira-common-constants/src/spa-routes';

export const externalImportRoute = {
	group: ROUTE_GROUPS_GLOBAL_SETTINGS,
	name: ROUTE_NAMES_EXTERNAL_IMPORT,
	path: '/jira/settings/system/external-import',
};
