import {
	ROUTE_GROUPS_GLOBAL_SETTINGS,
	ROUTE_NAMES_GLOBAL_SETTINGS_FORM_DATA_CONNECTIONS,
} from '@atlassian/jira-common-constants/src/spa-routes';

export const globalSettingsFormDataConnectionsRoute = {
	group: ROUTE_GROUPS_GLOBAL_SETTINGS,
	name: ROUTE_NAMES_GLOBAL_SETTINGS_FORM_DATA_CONNECTIONS,
	path: '/jira/settings/forms/data-connections',
};
