import {
	ROUTE_GROUPS_SOFTWARE,
	ROUTE_NAMES_SOFTWARE_TIMELINE,
} from '@atlassian/jira-common-constants/src/spa-routes';

export const softwareTimelineRoute = {
	group: ROUTE_GROUPS_SOFTWARE,
	name: ROUTE_NAMES_SOFTWARE_TIMELINE,
	path: '/jira/software/projects/:projectKey/boards/:boardId/timeline',
};
