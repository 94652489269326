import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import type DevopsCreateProjectType from '@atlassian/jira-spa-apps-devops-connected-project/src/ui/index.tsx';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';

export const LazyDevopsConnectedProject = lazyForPaint<typeof DevopsCreateProjectType>(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-devops-connected-project", jiraSpaEntry: "async-devops-connected-project" */ '@atlassian/jira-spa-apps-devops-connected-project'
		),
	),
);

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default () => (
	<LazyPage Page={LazyDevopsConnectedProject} pageId="devops-connected-project" shouldShowSpinner />
);
