import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async';
import { globalLayout } from '@atlassian/jira-global-layout';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { getNavigationSidebarGlobalSettingsResources } from '@atlassian/jira-navigation-apps-resources/src/controllers/forge/index.tsx';
import AsyncSidebarGlobalSettingsApps from '@atlassian/jira-navigation-apps-sidebar-global-settings-apps/src/async';
import { createEntry } from '@atlassian/jira-route-entry';
import type { Route } from '@atlassian/jira-router';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation';
import { navigationSidebarGlobalResource } from '@atlassian/jira-router-resources-navigation-sidebar-global';
import {
	findAppsRoute,
	appRequestRoute,
	promotionsRoute,
} from '@atlassian/jira-router-routes-marketplace-routes';
import type MarketplaceType from '@atlassian/jira-spa-apps-marketplace';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const LazyMarketplace = lazyForPaint<typeof MarketplaceType>(
	() =>
		markAsProductBundleReady(
			import(
				/* webpackChunkName: "async-marketplace", jiraSpaEntry: "async-marketplace" */ '@atlassian/jira-spa-apps-marketplace'
			),
		),
	{ ssr: false },
);
const MarketplaceComponent = () => (
	<LazyPage Page={LazyMarketplace} pageId="marketplace" shouldShowSpinner />
);

const findAppsRouteEntry = createEntry(findAppsRoute, {
	component: MarketplaceComponent,
	navigation: {
		sidebarId: SIDEBAR_ID.GLOBAL_APPS_SETTINGS,
		menuId: MENU_ID.ADDONS,
		sidebar: AsyncSidebarGlobalSettingsApps,
	},
	layout: globalLayout,
	resources: [
		...getNavigationResources(),
		...getNavigationSidebarGlobalSettingsResources(),
		navigationSidebarGlobalResource,
	],
	forPaint: [LazyAtlassianNavigation, LazyMarketplace],
	ufoName: 'embedded-marketplace',
});

export const appRequestsRouteEntry = createEntry(appRequestRoute, {
	component: MarketplaceComponent,
	navigation: {
		sidebarId: SIDEBAR_ID.GLOBAL_APPS_SETTINGS,
		menuId: MENU_ID.ADDONS,
		sidebar: AsyncSidebarGlobalSettingsApps,
	},
	layout: globalLayout,
	resources: [
		...getNavigationResources(),
		...getNavigationSidebarGlobalSettingsResources(),
		navigationSidebarGlobalResource,
	],
	forPaint: [LazyAtlassianNavigation, LazyMarketplace],
});

export const promotionsRouteEntry = createEntry(promotionsRoute, {
	component: MarketplaceComponent,
	navigation: {
		sidebarId: SIDEBAR_ID.GLOBAL_APPS_SETTINGS,
		menuId: MENU_ID.ADDONS,
		sidebar: AsyncSidebarGlobalSettingsApps,
	},
	layout: globalLayout,
	resources: [
		...getNavigationResources(),
		...getNavigationSidebarGlobalSettingsResources(),
		navigationSidebarGlobalResource,
	],
	forPaint: [LazyAtlassianNavigation, LazyMarketplace],
});

export const getMarketplaceRoutes = (): Route[] => [
	findAppsRouteEntry,
	appRequestsRouteEntry,
	promotionsRouteEntry,
];
