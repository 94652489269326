import { useIntl } from '@atlassian/jira-intl';
import { useProject } from '@atlassian/jira-navigation-apps-sidebar-common/src/controllers/project/index.tsx';
import type { RouteResourceError } from '@atlassian/jira-router';
import type { ReportGroup } from '../../common/types';
import { createReportUrlFunc } from '../../common/utils';
import messages from './messages';

type UseDefaultItemsReturn = {
	data: ReportGroup | null;
	loading: boolean;
	error: RouteResourceError | null;
};

export const useDefaultReportItems = (): UseDefaultItemsReturn => {
	const baseUrl = '';
	const { formatMessage } = useIntl();
	const { data: project, loading, error } = useProject();

	let defaultIssueAnalysisSection = null;

	if (project) {
		const createReportUrl = createReportUrlFunc({ baseUrl, project });
		defaultIssueAnalysisSection = {
			name: formatMessage(messages.issueAnalysis),
			id: 'issue-analysis-sidebar-links',
			items: [
				{
					id: 'averageage-report',
					url: createReportUrl('com.atlassian.jira.plugin.system.reports:averageage-report'),
					name: formatMessage(messages.averageAgeReport),
				},
				{
					id: 'createdvsresolved-report',
					url: createReportUrl('com.atlassian.jira.plugin.system.reports:createdvsresolved-report'),
					name: formatMessage(messages.createdResolvedIssuesReport),
				},
				{
					id: 'pie-report',
					url: createReportUrl('com.atlassian.jira.plugin.system.reports:pie-report'),
					name: formatMessage(messages.pieChart),
				},
				{
					id: 'recentlycreated-report',
					url: createReportUrl('com.atlassian.jira.plugin.system.reports:recentlycreated-report'),
					name: formatMessage(messages.recentlyCreatedIssues),
				},
				{
					id: 'resolutiontime-report',
					url: createReportUrl('com.atlassian.jira.plugin.system.reports:resolutiontime-report'),
					name: formatMessage(messages.resolutionTime),
				},
				{
					id: 'singlelevelgroupby',
					url: createReportUrl('com.atlassian.jira.plugin.system.reports:singlelevelgroupby'),
					name: formatMessage(messages.singleLevelGroup),
				},
				{
					id: 'timesince-report',
					url: createReportUrl('com.atlassian.jira.plugin.system.reports:timesince-report'),
					name: formatMessage(messages.timeSinceIssues),
				},
			],
		};
	}

	return { data: defaultIssueAnalysisSection, loading, error };
};
