import type { EntryPointRouteParams } from '@atlassian/jira-entry-points-plugin/src/common/types.tsx';
import { ff } from '@atlassian/jira-feature-flagging';
import { fg } from '@atlassian/jira-feature-gating';
import srcVirtualAgentSettingsQuery from '@atlassian/jira-relay/src/__generated__/srcVirtualAgentSettingsQuery$parameters';
import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

export const settingsEntryPoint = createEntryPoint({
	root: JSResourceForInteraction(
		() => import(/* webpackChunkName: "async-virtual-agent-settings" */ './src'),
	),
	getPreloadProps: ({ context, tenantContext }: EntryPointRouteParams) => ({
		queries: {
			page: {
				parameters: srcVirtualAgentSettingsQuery,
				variables: {
					cloudId: tenantContext.cloudId,
					projectKey: context.match.params.projectKey ?? '',
					checkForAiEnabledFF: ff('check-for-ai-enabling-in-get-premium-banner_oxh6r'),
					isNewVAChannelConfigEnabled: fg('is_new_va_channel_config_settings_enabled'),
				},
			},
		},
	}),
});
