import React, { memo, useCallback } from 'react';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import type { NavigationProject } from '@atlassian/jira-router-resources-business-navigation/src/types.tsx';
import ActiveItem from '../../../common/ui/active-item';
import ProjectItem from '../../../common/ui/project-item';
import ProjectMenu from './project-menu';

type Props = {
	project: NavigationProject;
	to: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onFavoriteToggle: (favourite: boolean) => any;
};

const TEST_ID = 'navigation-apps-sidebar-core.ui.multi-project-menu.current-project';

const ActiveProject = ({ project, to, onFavoriteToggle }: Props) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const onClick = useCallback(() => {
		fireUIAnalytics(createAnalyticsEvent({}), 'link clicked', 'jwmProjectLink', {
			projectId: project.id,
			starred: project.isFavorite,
			current: true,
		});
	}, [createAnalyticsEvent, project]);

	return (
		<ActiveItem testId={TEST_ID}>
			<ProjectItem
				project={project}
				to={to}
				isActive
				onClick={onClick}
				onFavoriteToggle={onFavoriteToggle}
			/>

			<ProjectMenu />
		</ActiveItem>
	);
};

export default memo<Props>(ActiveProject);
