/**
 * @generated SignedSource<<59c30d748fb0646c0ada66d3340f38ee>>
 * @relayHash 0da70eaea724c62b48f757515fce5371
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 1d98f2900b6c0cfe570866b292f8096f56cd3969a6a941a1a8269d0e4956467e

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { mainIssueAggQuery } from './mainIssueAggQuery.graphql';

import issueViewRelayAssigneeFieldFlag_provider from '@atlassian/jira-relay-provider/src/relay-migration-issue-fields-assignee.relayprovider';
import issueViewRelayParentFieldFlag_provider from '@atlassian/jira-relay-provider/src/relay-migration-issue-fields-parent';
import issueViewRelayReporterFieldFlag_provider from '@atlassian/jira-relay-provider/src/relay-migration-issue-fields-reporter.relayprovider';
import issueViewRelayUserFieldFlag_provider from '@atlassian/jira-relay-provider/src/relay-migration-issue-fields-user.relayprovider';

const node: PreloadableConcreteRequest<mainIssueAggQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "1d98f2900b6c0cfe570866b292f8096f56cd3969a6a941a1a8269d0e4956467e",
    "metadata": {
      "connection": [
        {
          "count": null,
          "cursor": null,
          "direction": "forward",
          "path": [
            "jira",
            "issueByKey",
            "attachments"
          ]
        }
      ]
    },
    "name": "mainIssueAggQuery",
    "operationKind": "query",
    "text": null,
    "providedVariables": {
      "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsparent": issueViewRelayParentFieldFlag_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsassigneerelayprovider": issueViewRelayAssigneeFieldFlag_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsreporterrelayprovider": issueViewRelayReporterFieldFlag_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsuserrelayprovider": issueViewRelayUserFieldFlag_provider
    }
  }
};

export default node;
