import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async';
import { ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK } from '@atlassian/jira-common-constants/src/spa-routes';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarServicedesk from '@atlassian/jira-navigation-apps-sidebar-servicedesk/src/async';
import { createEntry } from '@atlassian/jira-route-entry';
import type { Route } from '@atlassian/jira-router';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/index.tsx';
import { servicedeskPracticesResource } from '@atlassian/jira-router-resources-service-desk-practices/src/controllers/index.tsx';
import {
	routeGroupsProjectSettingsServicedeskUnassignedTicketTypeFieldsRoute,
	routeGroupsProjectSettingsServicedeskItsmTicketTypeFieldsRoute,
	routeGroupsProjectSettingsServicedeskTicketTypeFieldsRoute,
} from '@atlassian/jira-router-routes-project-settings-service-desk-ticket-type-fields-routes';
import { serviceProjectLayout } from '@atlassian/jira-service-project-layout';
import { APP_NAMES, toPackageName } from '@atlassian/jira-servicedesk-common/src/utils/app-names';
import { getSettingsNavigationResources, SERVICE_DESK_PRODUCT } from './ui/product-routes/common';
import ServiceDeskTicketTypeFields, {
	LazyProjectSettingsServiceDeskTicketTypeFields,
} from './ui/service-desk-ticket-type-fields';

const getTicketTypeFieldsRoutesCommon = () => ({
	exact: true,
	group: ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK,
	component: ServiceDeskTicketTypeFields,
	layout: serviceProjectLayout,
	navigation: {
		menuId: MENU_ID.PROJECTS,
		sidebar: AsyncSidebarServicedesk,
		sidebarId: SIDEBAR_ID.PROJECT_SETTINGS,
	},
	resources: [
		...getSettingsNavigationResources(SERVICE_DESK_PRODUCT),
		servicedeskPracticesResource,
		getConsolidationStateResource(),
	],
	forPaint: [LazyAtlassianNavigation, LazyProjectSettingsServiceDeskTicketTypeFields],
	meta: {
		reporting: {
			id: APP_NAMES.REQUEST_TYPE_FIELDS,
			packageName: toPackageName(APP_NAMES.REQUEST_TYPE_FIELDS),
			teamName: 'jsd-shield',
		},
	},
	ufoName: `${ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK}-ticket-type-fields`,
});

export const ticketTypeFieldsRouteEntries: Route[] = [
	createEntry(routeGroupsProjectSettingsServicedeskUnassignedTicketTypeFieldsRoute, {
		...getTicketTypeFieldsRoutesCommon(),
		component: ServiceDeskTicketTypeFields,
	}),
	createEntry(routeGroupsProjectSettingsServicedeskItsmTicketTypeFieldsRoute, {
		...getTicketTypeFieldsRoutesCommon(),
	}),
	createEntry(routeGroupsProjectSettingsServicedeskTicketTypeFieldsRoute, {
		...getTicketTypeFieldsRoutesCommon(),
	}),
];
