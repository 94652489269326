import {
	ROUTE_GROUPS_SOFTWARE,
	ROUTE_NAMES_SOFTWARE_PRESENTATION_TOOLKIT_REPORT,
} from '@atlassian/jira-common-constants/src/spa-routes';

export const softwarePresentationToolkitReportRoute = {
	group: ROUTE_GROUPS_SOFTWARE,
	name: ROUTE_NAMES_SOFTWARE_PRESENTATION_TOOLKIT_REPORT,
	path: '/jira/software/presentation-toolkit-report',
	exact: true,
};
