import {
	ROUTE_GROUPS_SERVICEDESK,
	ROUTE_NAMES_SERVICEDESK_CREATE_PROJECT,
} from '@atlassian/jira-common-constants/src/spa-routes';

export const servicedeskCreateProjectRoute = {
	group: ROUTE_GROUPS_SERVICEDESK,
	name: ROUTE_NAMES_SERVICEDESK_CREATE_PROJECT,
	path: '/jira/servicedesk/create-project',
	exact: true,
};
