import {
	ROUTE_GROUPS_SERVICEDESK,
	ROUTE_NAMES_SERVICEDESK_ITSM_SAMPLE_SPACE,
} from '@atlassian/jira-common-constants/src/spa-routes';

export const servicedeskItsmSampleSpaceRoute = {
	group: ROUTE_GROUPS_SERVICEDESK,
	name: ROUTE_NAMES_SERVICEDESK_ITSM_SAMPLE_SPACE,
	path: '/jira/servicedesk/itsm-sample-space',
	exact: true,
};
