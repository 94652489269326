import { issueHierarchyPageResource } from '@atlassian/jira-admin-pages-issue-hierarchy-directory/src/ui/query';
import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async';
import { insightsBoardSettingsResource } from '@atlassian/jira-board-settings-insights-route';
import { CLASSIC_BOARD, CLASSIC_BACKLOG } from '@atlassian/jira-capabilities';
import { AGGRESSIVE_EARLY_ASSET_STRATEGY } from '@atlassian/jira-common-constants/src/ssr-early-assets';
import { componentWithFF } from '@atlassian/jira-feature-flagging-utils';
import { componentWithFG } from '@atlassian/jira-feature-gate-component';
import { genericProjectLayout } from '@atlassian/jira-generic-project-layout';
import { embeddedIssuePreloadLayoutBuilder } from '@atlassian/jira-issue-preload-layout';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common';
import { classicBoardSidebars } from '@atlassian/jira-navigation-apps-sidebar-generic-sidebars';
import { createEntry } from '@atlassian/jira-route-entry';
import { composeLayouts } from '@atlassian/jira-route-layout';
import type { Route } from '@atlassian/jira-router';
import {
	getRapidboardResources,
	getRapidBoardDataResource,
	getBoardDetailsResource,
} from '@atlassian/jira-router-resources-classic-projects';
import {
	getNavigationResources,
	getNavigationSidebarProjectResource,
} from '@atlassian/jira-router-resources-navigation';
import { LazyIssueApp } from '@atlassian/jira-router-routes-common';
import {
	rapidboardBacklogRoute,
	rapidboardBoardRoute,
} from '@atlassian/jira-router-routes-software-classic-board-settings-insights-routes';
import {
	LazyRapidBoard,
	LazyRapidBoardApps,
	LazyRapidBoardResource,
	LazyRapidBoardConfigApiResource,
	LazyRapidBoardDataApiResource,
	LazyRapidBoardWrmBundleResource,
	RapidBoardSpaPage,
} from '@atlassian/jira-router-routes-user-board-entries/src/async';
import { ClassicBoardSkeleton, NextGenBacklogSkeleton } from '@atlassian/jira-skeletons';
import { AsyncHorizontalSoftwareProjectNav } from '@atlassian/jira-software-horizontal-nav';
import InsightsSettingsSpaPage from './async';
import { InsightsSettingsRedirect } from './common';

export const classicSoftwareBoardSettingsInsightsRouteEntries: Route[] = [
	createEntry(rapidboardBacklogRoute, {
		ufoName: 'rapidboard-backlog-insights',
		component: componentWithFG(
			'remove_jsw_insights_settings_ui',
			InsightsSettingsRedirect,
			componentWithFF(
				'simplified-boards-milestone-2_07hbk',
				InsightsSettingsSpaPage,
				RapidBoardSpaPage,
			),
		),
		skeleton: NextGenBacklogSkeleton,
		layout: composeLayouts(
			genericProjectLayout,
			embeddedIssuePreloadLayoutBuilder([{ query: ['selectedIssue', 'view=planning'] }]),
		),

		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: classicBoardSidebars,
			horizontal: AsyncHorizontalSoftwareProjectNav,
			horizontalFeatureFlag: 'sea-2744-jsw-tabs-nav',
		},

		resources: [
			...getNavigationResources(),
			...getNavigationSidebarProjectResource(),
			...getRapidboardResources(),
			getRapidBoardDataResource(),
			insightsBoardSettingsResource,
			issueHierarchyPageResource,
			getBoardDetailsResource(),
		],

		afterPaint: [LazyIssueApp],

		forPaint: [
			LazyAtlassianNavigation,
			LazyRapidBoard,
			LazyRapidBoardApps,
			LazyRapidBoardDataApiResource,
			LazyRapidBoardConfigApiResource,
			LazyRapidBoardWrmBundleResource,
			LazyRapidBoardResource,
		],

		preloadOptions: {
			earlyChunks: ['rapidboard-early-entry'],
		},

		meta: {
			capability: CLASSIC_BACKLOG,
		},

		earlyAssets: AGGRESSIVE_EARLY_ASSET_STRATEGY,
	}),
	createEntry(rapidboardBoardRoute, {
		ufoName: 'rapidboard-board-insights',
		component: componentWithFG(
			'remove_jsw_insights_settings_ui',
			InsightsSettingsRedirect,
			componentWithFF(
				'simplified-boards-milestone-2_07hbk',
				InsightsSettingsSpaPage,
				RapidBoardSpaPage,
			),
		),
		skeleton: ClassicBoardSkeleton,

		layout: composeLayouts(
			genericProjectLayout,
			embeddedIssuePreloadLayoutBuilder([
				{ query: ['selectedIssue', 'view=detail'] },
				{ query: ['selectedIssue', 'modal=detail'] },
			]),
		),

		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: classicBoardSidebars,
			horizontal: AsyncHorizontalSoftwareProjectNav,
			horizontalFeatureFlag: 'sea-2744-jsw-tabs-nav',
		},

		resources: [
			...getNavigationResources(),
			...getNavigationSidebarProjectResource(),
			...getRapidboardResources(),
			getRapidBoardDataResource(),
			insightsBoardSettingsResource,
			issueHierarchyPageResource,
			getBoardDetailsResource(),
		],

		afterPaint: [LazyIssueApp],

		forPaint: [
			LazyAtlassianNavigation,
			LazyRapidBoard,
			LazyRapidBoardApps,
			LazyRapidBoardDataApiResource,
			LazyRapidBoardConfigApiResource,
			LazyRapidBoardWrmBundleResource,
			LazyRapidBoardResource,
		],

		preloadOptions: {
			earlyChunks: ['rapidboard-early-entry'],
		},

		meta: {
			capability: CLASSIC_BOARD,
		},

		earlyAssets: AGGRESSIVE_EARLY_ASSET_STRATEGY,
	}),
];
