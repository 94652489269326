import {
	ROUTE_GROUPS_CLASSIC_SOFTWARE,
	ROUTE_NAMES_RAPIDBOARD_BOARD,
	ROUTE_NAMES_RAPIDBOARD_BOARD_EMBED,
} from '@atlassian/jira-common-constants/src/spa-routes';

export const classicSoftwareBoardRoute = {
	group: ROUTE_GROUPS_CLASSIC_SOFTWARE,
	name: ROUTE_NAMES_RAPIDBOARD_BOARD,
	path: '/jira/software/c/projects/:projectKey/boards/:boardId',
	ufoName: 'classic-board',
};

export const classicSoftwareBoardEmbedRoute = {
	group: ROUTE_GROUPS_CLASSIC_SOFTWARE,
	name: ROUTE_NAMES_RAPIDBOARD_BOARD_EMBED,
	path: '/jira/software/c/projects/:projectKey/boards/:boardId/embed',
	ufoName: 'classic-board-embed',
};
