import React, { type ReactNode } from 'react';
import type { TenantContext } from '@atlassian/jira-shared-types/src/tenant-context.tsx';
import { TenantContextSubscriber } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import { RedirectToLoginPage } from '../../redirect/login';

export type Props = {
	children: ReactNode;
};

/**
 * Imperative Authorisation Check
 */
export const checkAuthentication = (tenantContext: TenantContext): boolean =>
	Boolean(tenantContext.atlassianAccountId);

export const AuthenticationCheck = ({ children }: Props) => (
	<TenantContextSubscriber>
		{({ tenantContext }) => {
			if (checkAuthentication(tenantContext)) {
				return children;
			}
			return <RedirectToLoginPage />;
		}}
	</TenantContextSubscriber>
);
