import type { JiraProject } from '@atlassian/jira-business-entity-common/src/types.tsx';
import type { OverviewFragmentResponse, ProjectEdge } from './types';

const SIMPLIFIED_PROJECT_STYLE = 'TEAM_MANAGED_PROJECT';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const mapProjectsNode = (edges: any): JiraProject[] =>
	edges
		.map((edge: ProjectEdge): JiraProject | null => {
			const { node } = edge || {};
			if (node == null) {
				return null;
			}
			return {
				key: node.key,
				id: Number(node.projectId),
				avatarUrl: node.avatar?.large ?? '',
				name: node.name,
				type: node.projectType.toLowerCase(),
				isSimplified: node.projectStyle === SIMPLIFIED_PROJECT_STYLE,
				isFavorite: node.isFavourite === true,
				permissions: {
					administerProject: node.administerProject.canPerform,
					createIssues: node.createIssues.canPerform,
					deleteIssues: node.deleteIssues.canPerform,
					editIssues: node.editIssues.canPerform,
					linkIssues: node.linkIssues.canPerform,
					scheduleIssues: node.scheduleIssues.canPerform,
					// remove optional chaining when jwm-use-updated-graphql-querymutation-for-overview is cleaned up
					transitionIssues: node.transitionIssues?.canPerform === true,
					viewIssues: node.viewIssues.canPerform,
				},
			};
		})
		.filter((project: JiraProject | null) => project);

export const mapOverviewFragment = (overview: OverviewFragmentResponse) => {
	const { id, name, theme, projects: projectsData } = overview;

	const projects = mapProjectsNode(projectsData.edges);

	const unreachableProjectsCount = projectsData.edges.length - projects.length;

	return {
		id,
		name,
		theme,
		projects,
		unreachableProjectsCount,
	};
};
