import type {
	CalendarIssueEventFields,
	CalendarScopeBoard,
	CalendarViewMode,
	CalendarWeekStart,
} from '@atlassian/jira-calendar';
import type { EntryPointRouteParams } from '@atlassian/jira-entry-points-plugin';
import { ff } from '@atlassian/jira-feature-flagging';
import { fg } from '@atlassian/jira-feature-gating';
import parameters from '@atlassian/jira-relay/src/__generated__/ui_jiraCalendarQuery$parameters';
import type {
	JiraViewScopeInput,
	JiraCalendarViewConfigurationInput,
	JiraCalendarIssuesInput,
	JiraCalendarVersionsInput,
	JiraCalendarSprintsInput,
	JiraCalendarMode,
	JiraCalendarWeekStart,
} from '@atlassian/jira-relay/src/__generated__/ui_jiraCalendarQuery.graphql';
import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

// This is the key used to store the calendar filters in the local storage
const ISSUE_SEARCH_INPUT_STORAGE_KEY = 'calendar-issue-search-input';
// This is the key used to store the calendar view settings in the local storage
const VIEW_SETTINGS_STORAGE_KEY = 'calendar-view-settings';

function toBoardAri({ siteId, boardId }: { siteId: string; boardId: string }) {
	return `ari:cloud:jira-software:${siteId}:board/${boardId}`;
}

function toMidnightUTCString(date: Date) {
	const utcMilliseconds = Date.UTC(date.getFullYear(), date.getMonth(), date.getDate());
	return new Date(utcMilliseconds).toISOString();
}

function toDate(date: string | null | undefined): Date {
	if (!date) {
		return new Date();
	}
	try {
		const _date = new Date(date);
		return new Date(_date.getFullYear(), _date.getMonth(), _date.getDate());
	} catch (e) {
		return new Date();
	}
}

function createScope(
	{ context, tenantContext }: EntryPointRouteParams,
	viewId: string,
): CalendarScopeBoard {
	const { cloudId } = tenantContext;
	if (ff('update-calendar-query_n6opw')) {
		return {
			type: 'board',
			boardId: toBoardAri({
				siteId: cloudId,
				boardId: String(context.match.params.boardId),
			}),
			projectKeys: [String(context.match.params.projectKey)],
			viewId,
		};
	}
	return {
		type: 'board',
		boardId: toBoardAri({
			siteId: cloudId,
			boardId: String(context.match.params.boardId),
		}),
		projectKeys: [String(context.match.params.projectKey)],
	};
}

function buildScopeInput(
	{ tenantContext }: EntryPointRouteParams,
	scope: CalendarScopeBoard,
): JiraViewScopeInput {
	const { cloudId } = tenantContext;
	return {
		ids: [scope.boardId],
		projectKeys: {
			cloudId,
			keys: scope.projectKeys,
		},
	};
}

function toViewMode(mode: string): JiraCalendarMode {
	switch (mode) {
		case 'week':
			return 'WEEK';
		case 'day':
			return 'DAY';
		default:
			return 'MONTH';
	}
}

function toWeekStart(weekStart: string): JiraCalendarWeekStart {
	switch (weekStart) {
		case 'monday':
			return 'MONDAY';
		case 'saturday':
			return 'SATURDAY';
		default:
			return 'SUNDAY';
	}
}

function buildConfigurationInput({
	selectedDate,
	viewMode,
	weekStartsOn,
	startDateField,
	endDateField,
	viewId,
}: {
	selectedDate: Date;
	viewMode: CalendarViewMode;
	weekStartsOn: CalendarWeekStart;
	startDateField: string;
	endDateField: string;
	viewId: string | null;
}): JiraCalendarViewConfigurationInput {
	if (ff('update-calendar-query_n6opw')) {
		return {
			date: toMidnightUTCString(selectedDate),
			mode: toViewMode(viewMode),
			weekStart: toWeekStart(weekStartsOn),
			viewId,
		};
	}
	return {
		date: toMidnightUTCString(selectedDate),
		mode: toViewMode(viewMode),
		weekStart: toWeekStart(weekStartsOn),
		startDateField,
		endDateField,
	};
}

function buildStorageKey({
	cloudId,
	activationId,
	boardId,
	featureKey,
}: {
	cloudId: string;
	activationId: string;
	boardId: string;
	featureKey: string;
}): string {
	const viewId = buildViewId({ cloudId, activationId, boardId });
	const LOCALSTORAGE_KEY_PREFIX = 'persist:calendar';
	return `${LOCALSTORAGE_KEY_PREFIX}/view/${viewId}/${featureKey}`;
}

function getStorageValue(storageKey: string): string {
	// window check is needed because this code is also executed on the SSR
	if (typeof window !== 'undefined') {
		return localStorage?.getItem(storageKey) || '';
	}
	return '';
}

function buildIssuesSearchInputNew(storageKey: string): JiraCalendarIssuesInput {
	return { additionalFilterQuery: getStorageValue(storageKey) || '' };
}

function buildIssuesSearchInput(): JiraCalendarIssuesInput {
	return { additionalFilterQuery: '' };
}

function buildUnscheduledIssuesSearchInput(): JiraCalendarIssuesInput {
	return { additionalFilterQuery: '' };
}

function buildVersionsSearchInput(): JiraCalendarVersionsInput {
	return { versionStatuses: ['RELEASED', 'UNRELEASED'] };
}

function buildSprintsSearchInput(): JiraCalendarSprintsInput {
	return { sprintStates: ['ACTIVE', 'FUTURE', 'CLOSED'] };
}

function buildViewId({
	cloudId,
	activationId,
	boardId,
}: {
	cloudId: string;
	activationId: string;
	boardId: string;
}): string {
	// https://developer.atlassian.com/platform/atlassian-resource-identifier/resource-owners/registry/#ati%3Acloud%3Ajira%3Aview
	return `ari:cloud:jira:${cloudId}:view/activation/${activationId}/board/${boardId}`;
}

export const calendarEntryPoint = createEntryPoint({
	root: JSResourceForInteraction(
		() =>
			import(
				/* webpackChunkName: "async-servicedesk-calendar", jiraSpaEntry: "async-servicedesk-calendar" */ './src'
			),
	),
	getPreloadProps: (entryPointRouteParams: EntryPointRouteParams) => {
		const { context, tenantContext } = entryPointRouteParams;

		const { cloudId, activationId } = tenantContext;
		const { date } = context.query;
		const selectedDate = toDate(date);

		const { boardId, projectKey } = context.match.params;

		if (!boardId || !projectKey) {
			throw new Error('boardId and projectKey must be provided.');
		}

		const viewId = ff('jsm-board-settings-date-fields-tab_d75f4')
			? buildViewId({ cloudId, activationId, boardId })
			: null;

		const scope = createScope(entryPointRouteParams, viewId || '');
		const storageKeyForIssueSearchInput = ff('jsm-calendar-config-persist_orut6')
			? buildStorageKey({
					cloudId,
					activationId,
					boardId,
					featureKey: ISSUE_SEARCH_INPUT_STORAGE_KEY,
				})
			: '';
		const storageKeyForViewSettings = ff('jsm-calendar-config-persist_orut6')
			? buildStorageKey({ cloudId, activationId, boardId, featureKey: VIEW_SETTINGS_STORAGE_KEY })
			: '';

		let viewSettingsConfig = { weekStartsOn: null, showWeekends: false, showIssueKey: true };

		if (ff('jsm-calendar-config-persist_orut6')) {
			const storedConfig = getStorageValue(storageKeyForViewSettings);
			viewSettingsConfig = storedConfig ? JSON.parse(storedConfig) : {};
		}

		const viewMode: CalendarViewMode = 'month';
		const weekStartsOn: CalendarWeekStart = ff('jsm-calendar-config-persist_orut6')
			? viewSettingsConfig.weekStartsOn ?? 'sunday'
			: 'sunday';
		const startDateField = 'startdate';
		const endDateField = 'duedate';
		const issueEventFields: CalendarIssueEventFields = {
			startDateField,
			endDateField,
		};

		const issuesSearchInput = ff('jsm-calendar-config-persist_orut6')
			? buildIssuesSearchInputNew(storageKeyForIssueSearchInput)
			: buildIssuesSearchInput();

		return {
			queries: {
				calendarData: {
					options: {
						fetchPolicy: 'network-only' as const,
					},
					parameters,
					variables: {
						cloudId,
						scopeInput: buildScopeInput(entryPointRouteParams, scope),
						configurationInput: buildConfigurationInput({
							selectedDate,
							viewMode,
							weekStartsOn,
							startDateField,
							endDateField,
							viewId,
						}),
						issuesSearchInput,
						unscheduledIssuesSearchInput: buildUnscheduledIssuesSearchInput(),
						versionsSearchInput: buildVersionsSearchInput(),
						sprintsSearchInput: buildSprintsSearchInput(),
						skipSprintSearch: false,
						skipVersionSearch: false,
						viewId,
						queryV2Enabled: ff('update-calendar-query_n6opw'),
						filterProjectContextEnabled: fg('calendar_set_filter_context'),
					},
				},
			},
			entryPoints: {},
			extraProps: {
				scope,
				selectedDate,
				viewMode,
				weekStartsOn,
				issueEventFields,
				projectKey,
				storageKeyForViewSettings,
				storageKeyForIssueSearchInput,
				showWeekends: viewSettingsConfig.showWeekends,
				showIssueKey: viewSettingsConfig.showIssueKey,
			},
		};
	},
});
