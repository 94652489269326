import pickBy from 'lodash/pickBy';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { OPERATIONAL_EVENT_TYPE } from './events';
import mapAnalyticsDataPayloadToContext from './map-data-payload-to-context-payload';
import type { FireOperationalAnalyticsPayload } from './types';

// @ts-expect-error - TS7006 - Parameter 'a' implicitly has an 'any' type.
const isNotUndefined = (a) => a !== undefined;

const mapPayload = (payload: FireOperationalAnalyticsPayload) => {
	const context = mapAnalyticsDataPayloadToContext(payload);
	const { name } = payload;
	return pickBy(
		{
			...context,
			...context.attributes,
			attributes: undefined,
			name,
		},
		isNotUndefined,
	);
};

export const fireOperationalAnalytics = (
	event: UIAnalyticsEvent,
	payload: FireOperationalAnalyticsPayload,
) => {
	// Causes issues with mounting the src/packages/confluence/project-pages/src/view/examples.js component in storybook tests.
	// This is due to node_modules/@atlassiansox/analytics-web-client/dist/analytics-web-client.with-deps.js producing a reference error with the normalize function
	// This is possibly due to some scope hoisting issue with jest that is not present in the browser
	try {
		event.update({ ...mapPayload(payload), analyticsType: OPERATIONAL_EVENT_TYPE }).fire();
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (e: any) {
		fireErrorAnalytics({
			event,
			error: e,
			meta: {
				id: 'fireOperationalAnalytics',
				packageName: 'jiraAnalyticsWebReact',
				teamName: 'legacy-jfp-team-do-not-use',
			},
		});
	}
};
