import {
	ROUTE_GROUPS_GLOBAL_SETTINGS,
	ROUTE_NAMES_PRODUCTS_JSM_CHATOPS_AND_VIDEOS_TOOLS,
} from '@atlassian/jira-common-constants/src/spa-routes';

export const productsJsmChatopsAndVideosToolsRoute = {
	group: ROUTE_GROUPS_GLOBAL_SETTINGS,
	name: ROUTE_NAMES_PRODUCTS_JSM_CHATOPS_AND_VIDEOS_TOOLS,
	path: '/jira/settings/products/ops/jira-service-management-chatops-and-video-tools',
	exact: true,
};
