import {
	ROUTE_GROUPS_GLOBAL_SETTINGS,
	ROUTE_NAMES_PRODUCTS_JSM_AUTHENTICATION_SETTINGS,
} from '@atlassian/jira-common-constants/src/spa-routes';

export const productsJsmAuthenticationSettingsRoute = {
	group: ROUTE_GROUPS_GLOBAL_SETTINGS,
	name: ROUTE_NAMES_PRODUCTS_JSM_AUTHENTICATION_SETTINGS,
	path: '/jira/settings/products/servicedesk/authentication',
	exact: true,
};
