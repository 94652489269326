import { fireErrorAnalytics } from '@atlassian/jira-errors-handling';
import { performGetRequest } from '@atlassian/jira-fetch';
import type { Action } from '@atlassian/react-sweet-state';
import type { CloudId } from '../../common/types';
import { COHORT_DETAILS_DEFAULT_STATE } from './constants';
import type { CohortDetailsState, MigrationUIResponse } from './types';
import { getCohortStatusUrl, getOptInModalStatus, setOptInModalStatus } from './utils';

export const getCohortDetailsRest = async (cloudId: CloudId): Promise<MigrationUIResponse> => {
	const fetchUrl = getCohortStatusUrl(cloudId);

	return performGetRequest(fetchUrl).catch((error) => {
		fireErrorAnalytics({
			meta: {
				id: 'fetchCohortDetails',
				packageName: 'jiraC2CMigrationsCommonUtils',
				teamName: 'jira-c2c-migration',
			},
			error,
			sendToPrivacyUnsafeSplunk: true,
		});
		throw error;
	});
};

export const fetchCohortDetails = (cloudId: string): Action<CohortDetailsState> => {
	// @ts-expect-error - TS2322 - Type '({ setState }: StoreActionApi<MigrationDetailsState>) => Promise<{ data: MigrationAPIData; siteContainerSelections: readonly ["USERS"] | readonly [...]; } | undefined>' is not assignable to type 'Action<MigrationDetailsState, void, void | Promise<void>>'.
	return async ({ setState, getState }) => {
		const showOptInButton = getOptInModalStatus();
		setState({ showOptInButton });

		if (getState().data || getState().loading) {
			return undefined;
		}

		try {
			setState({ loading: true });

			const data = await getCohortDetailsRest(cloudId);
			const derivedOptInButtonStatus =
				(!data.hasOptedIn && data.status !== 'NEVER') || showOptInButton;
			setOptInModalStatus(derivedOptInButtonStatus);

			setState({
				data,
				loading: false,
				showOptInButton: derivedOptInButtonStatus,
			});

			return { data };
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			setState({
				error,
				loading: false,
			});
			return undefined;
		}
	};
};

export const updateShowOptInButton =
	(showOptInButton: boolean): Action<CohortDetailsState> =>
	({ setState }) => {
		setOptInModalStatus(showOptInButton);
		setState({
			showOptInButton,
		});
	};

export const reset =
	(): Action<CohortDetailsState> =>
	({ setState }) => {
		setState({ ...COHORT_DETAILS_DEFAULT_STATE, data: undefined, error: undefined });
	};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default {
	fetchCohortDetails,
	updateShowOptInButton,
	reset,
};
