import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async';
import { TEAM_MANAGED_BACKLOG } from '@atlassian/jira-capabilities/src/constants.tsx';
import { componentWithFG } from '@atlassian/jira-feature-gate-component';
import { embeddedIssuePreloadLayoutBuilder } from '@atlassian/jira-issue-preload-layout';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarNextGen from '@atlassian/jira-navigation-apps-sidebar-next-gen/src/async';
import { createEntry } from '@atlassian/jira-route-entry';
import { composeLayouts, createLayout } from '@atlassian/jira-route-layout';
import type { RouteContext, Route } from '@atlassian/jira-router';
import {
	getNavigationResources,
	getNavigationSidebarProjectResource,
} from '@atlassian/jira-router-resources-navigation';
import { conditionalBacklogRelayResource } from '@atlassian/jira-router-resources-next-gen-backlog/src/services/backlog-relay/index.tsx';
import { conditionalBacklogResource } from '@atlassian/jira-router-resources-next-gen-backlog/src/services/backlog/index.tsx';
import { customFiltersResource } from '@atlassian/jira-router-resources-next-gen-backlog/src/services/custom-filters/index.tsx';
import { LazyIssueApp } from '@atlassian/jira-router-routes-common/src/async.tsx';
import ErrorPagesNotFound from '@atlassian/jira-router-routes-common/src/ui/error-pages/not-found/index.tsx';
import {
	softwareBacklogEmbedRoute,
	softwareBacklogRoute,
} from '@atlassian/jira-router-routes-software-backlog-routes';
import { NextGenBacklogSkeleton } from '@atlassian/jira-skeletons/src/ui/next-gen-backlog/index.tsx';
import { AsyncHorizontalSoftwareProjectNav } from '@atlassian/jira-software-horizontal-nav/src/async.tsx';
import { softwareProjectLayout } from '@atlassian/jira-software-project-layout';
import type NextGenBacklogType from '@atlassian/jira-spa-apps-next-gen-backlog/src/ui/backlog/index.tsx';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';
import { getBoardId } from './ui/spa/software/common';

export const LazyBacklogPage = lazyForPaint<typeof NextGenBacklogType>(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-next-gen-backlog", jiraSpaEntry: "next-gen-backlog" */ '@atlassian/jira-spa-apps-next-gen-backlog'
		),
	),
);

type Props = RouteContext;

const BacklogPage = ({ location, match }: Props) => (
	<LazyPage
		Page={LazyBacklogPage}
		pageId={`next-gen-backlog-${getBoardId({ location, match })}`}
		shouldShowSpinner={false}
	/>
);

export const softwareBacklogRouteEntry: Route = createEntry(softwareBacklogRoute, {
	component: BacklogPage,
	skeleton: NextGenBacklogSkeleton,

	layout: composeLayouts(
		softwareProjectLayout,
		embeddedIssuePreloadLayoutBuilder([{ query: ['selectedIssue'] }]),
	),

	navigation: {
		menuId: MENU_ID.PROJECTS,
		sidebar: AsyncSidebarNextGen,
		horizontal: AsyncHorizontalSoftwareProjectNav,
		horizontalFeatureFlag: 'sea-2744-jsw-tabs-nav',
	},

	resources: [
		...getNavigationResources(),
		...getNavigationSidebarProjectResource(),
		conditionalBacklogResource,
		conditionalBacklogRelayResource,
		customFiltersResource,
	],

	preloadOptions: {
		earlyChunks: ['software-backlog-early-entry'],
	},

	afterPaint: [LazyIssueApp],
	forPaint: [LazyAtlassianNavigation, LazyBacklogPage],
	ufoName: 'next-gen-backlog',

	meta: {
		capability: TEAM_MANAGED_BACKLOG,
	},
});

export const softwareBacklogEmbedRouteEntry: Route = createEntry(softwareBacklogEmbedRoute, {
	component: componentWithFG('enable_backlog_smartlink_route', BacklogPage, ErrorPagesNotFound),

	layout: createLayout({
		isChromeless: true,
	}),

	resources: [conditionalBacklogResource, conditionalBacklogRelayResource, customFiltersResource],

	afterPaint: [LazyIssueApp],
	forPaint: [LazyBacklogPage],
	ufoName: 'next-gen-backlog-embed',

	meta: {
		capability: TEAM_MANAGED_BACKLOG,
	},
});

export default BacklogPage;
