import {
	ROUTE_GROUPS_GLOBAL_SETTINGS,
	ROUTE_NAMES_GLOBAL_SETTINGS_SYSTEM_BULK_MAIL,
} from '@atlassian/jira-common-constants/src/spa-routes';

export const globalSettingsSystemBulkMailRoute = {
	group: ROUTE_GROUPS_GLOBAL_SETTINGS,
	name: ROUTE_NAMES_GLOBAL_SETTINGS_SYSTEM_BULK_MAIL,
	path: '/jira/settings/system/send-email',
};
