/**
 * @generated SignedSource<<aa884f0b533f3c4a64929a5c153ce645>>
 * @relayHash e17b7ff5b99ba8e7ef87798cf91193f8
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID f9475a5cbb2c2a91e9a95e09ca15de293206233971da6fad298597e82907f0c5

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { uiCustomerDetailsPageQuery } from './uiCustomerDetailsPageQuery.graphql';

const node: PreloadableConcreteRequest<uiCustomerDetailsPageQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "f9475a5cbb2c2a91e9a95e09ca15de293206233971da6fad298597e82907f0c5",
    "metadata": {},
    "name": "uiCustomerDetailsPageQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;
