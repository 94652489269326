/**
 * @generated SignedSource<<8fa61a520640832ea30e2978c7ae96f6>>
 * @relayHash de027b5001b25b9c2ac320d0088c7ada
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID e5ea55eec56572b6b3f2dd959dfc88ecf3458c6f54b70478e0df5f99b6035b38

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { srcVirtualAgentSettingsQuery } from './srcVirtualAgentSettingsQuery.graphql';

const node: PreloadableConcreteRequest<srcVirtualAgentSettingsQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "e5ea55eec56572b6b3f2dd959dfc88ecf3458c6f54b70478e0df5f99b6035b38",
    "metadata": {},
    "name": "srcVirtualAgentSettingsQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;
