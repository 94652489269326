/**
 * @generated SignedSource<<83e8e4df38f92d5552639f18a2cae67f>>
 * @relayHash ffe0c87144d6246dfae6aabd62a9da9d
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 9e153a5ee899743765cd861e5d838569213fa8e0406f4baacc05ad8f2d7b71c5

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type uiOrganizationDetailsPageQuery$variables = {
  cloudId: string;
  includePlatformValue: boolean;
  organizationId: string;
};
export type uiOrganizationDetailsPageQuery$data = {
  readonly customerService: {
    readonly organizationByOrganizationId: {
      readonly __typename: "CustomerServiceOrganization";
      readonly details: {
        readonly " $fragmentSpreads": FragmentRefs<"customDetailsSidebar_servicedeskCustomerServiceCustomDetailsSidebar_Inner">;
      };
    } | {
      readonly __typename: "QueryError";
      readonly extensions: ReadonlyArray<{
        readonly statusCode: number | null | undefined;
      }> | null | undefined;
      readonly " $fragmentSpreads": FragmentRefs<"throwOnQueryError_servicedeskCustomerServiceCommon">;
    } | {
      // This will never be '%other', but we need some
      // value in case none of the concrete values match.
      readonly __typename: "%other";
    };
  };
};
export type uiOrganizationDetailsPageQuery = {
  response: uiOrganizationDetailsPageQuery$data;
  variables: uiOrganizationDetailsPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cloudId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "includePlatformValue"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "organizationId"
},
v3 = [
  {
    "kind": "Variable",
    "name": "cloudId",
    "variableName": "cloudId"
  }
],
v4 = [
  {
    "kind": "Variable",
    "name": "organizationId",
    "variableName": "organizationId"
  }
],
v5 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v6 = {
  "kind": "ScalarField",
  "name": "statusCode"
},
v7 = {
  "kind": "ScalarField",
  "name": "id"
},
v8 = {
  "kind": "ScalarField",
  "name": "name"
},
v9 = {
  "kind": "ScalarField",
  "name": "identifier"
},
v10 = {
  "kind": "ScalarField",
  "name": "message"
},
v11 = {
  "kind": "LinkedField",
  "name": "extensions",
  "plural": true,
  "selections": [
    (v5/*: any*/),
    (v6/*: any*/),
    {
      "kind": "ScalarField",
      "name": "errorType"
    }
  ]
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "name": "uiOrganizationDetailsPageQuery",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "args": (v3/*: any*/),
          "concreteType": "CustomerServiceQueryApi",
          "kind": "LinkedField",
          "name": "customerService",
          "plural": false,
          "selections": [
            {
              "kind": "RequiredField",
              "field": {
                "args": (v4/*: any*/),
                "kind": "LinkedField",
                "name": "organizationByOrganizationId",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "name": "details",
                        "plural": false,
                        "selections": [
                          {
                            "args": [
                              {
                                "kind": "Variable",
                                "name": "includePlatformValue",
                                "variableName": "includePlatformValue"
                              }
                            ],
                            "kind": "FragmentSpread",
                            "name": "customDetailsSidebar_servicedeskCustomerServiceCustomDetailsSidebar_Inner"
                          }
                        ]
                      }
                    ],
                    "type": "CustomerServiceOrganization"
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "name": "extensions",
                        "plural": true,
                        "selections": [
                          (v6/*: any*/)
                        ]
                      },
                      {
                        "kind": "FragmentSpread",
                        "name": "throwOnQueryError_servicedeskCustomerServiceCommon"
                      }
                    ],
                    "type": "QueryError"
                  }
                ]
              },
              "action": "THROW",
              "path": "customerService.organizationByOrganizationId"
            }
          ]
        },
        "action": "THROW",
        "path": "customerService"
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "uiOrganizationDetailsPageQuery",
    "selections": [
      {
        "args": (v3/*: any*/),
        "concreteType": "CustomerServiceQueryApi",
        "kind": "LinkedField",
        "name": "customerService",
        "plural": false,
        "selections": [
          {
            "args": (v4/*: any*/),
            "kind": "LinkedField",
            "name": "organizationByOrganizationId",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "kind": "LinkedField",
                    "name": "details",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      {
                        "kind": "TypeDiscriminator",
                        "abstractKey": "__isCustomerServiceCustomDetailValuesQueryResult"
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "concreteType": "CustomerServiceCustomDetailValue",
                            "kind": "LinkedField",
                            "name": "results",
                            "plural": true,
                            "selections": [
                              (v7/*: any*/),
                              {
                                "kind": "ScalarField",
                                "name": "canEdit"
                              },
                              (v8/*: any*/),
                              {
                                "kind": "ScalarField",
                                "name": "value"
                              },
                              {
                                "kind": "ScalarField",
                                "name": "values"
                              },
                              {
                                "concreteType": "CustomerServiceCustomDetailType",
                                "kind": "LinkedField",
                                "name": "type",
                                "plural": false,
                                "selections": [
                                  (v8/*: any*/),
                                  {
                                    "kind": "ScalarField",
                                    "name": "options"
                                  }
                                ]
                              },
                              {
                                "concreteType": "CustomerServiceCustomDetailConfigMetadata",
                                "kind": "LinkedField",
                                "name": "config",
                                "plural": false,
                                "selections": [
                                  {
                                    "concreteType": "CustomerServiceCustomAttributeStyleConfiguration",
                                    "kind": "LinkedField",
                                    "name": "styleConfiguration",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "concreteType": "CustomerServiceCustomAttributeOptionsStyleConfiguration",
                                        "kind": "LinkedField",
                                        "name": "options",
                                        "plural": true,
                                        "selections": [
                                          {
                                            "kind": "ScalarField",
                                            "name": "optionValue"
                                          },
                                          {
                                            "concreteType": "CustomerServiceCustomAttributeOptionStyle",
                                            "kind": "LinkedField",
                                            "name": "style",
                                            "plural": false,
                                            "selections": [
                                              {
                                                "kind": "ScalarField",
                                                "name": "backgroundColour"
                                              }
                                            ]
                                          }
                                        ]
                                      }
                                    ]
                                  }
                                ]
                              },
                              {
                                "condition": "includePlatformValue",
                                "kind": "Condition",
                                "passingValue": true,
                                "selections": [
                                  {
                                    "kind": "LinkedField",
                                    "name": "platformValue",
                                    "plural": false,
                                    "selections": [
                                      (v5/*: any*/),
                                      {
                                        "kind": "InlineFragment",
                                        "selections": [
                                          {
                                            "kind": "ScalarField",
                                            "name": "accountId"
                                          },
                                          (v8/*: any*/),
                                          {
                                            "kind": "ScalarField",
                                            "name": "avatarUrl"
                                          }
                                        ],
                                        "type": "CustomerServiceUserDetailValue"
                                      }
                                    ]
                                  }
                                ]
                              }
                            ]
                          }
                        ],
                        "type": "CustomerServiceCustomDetailValues"
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v9/*: any*/),
                          (v10/*: any*/),
                          (v11/*: any*/)
                        ],
                        "type": "QueryError"
                      }
                    ]
                  }
                ],
                "type": "CustomerServiceOrganization"
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  (v11/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/)
                ],
                "type": "QueryError"
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  (v7/*: any*/)
                ],
                "type": "Node",
                "abstractKey": "__isNode"
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "9e153a5ee899743765cd861e5d838569213fa8e0406f4baacc05ad8f2d7b71c5",
    "metadata": {},
    "name": "uiOrganizationDetailsPageQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "b72988d0a882ddae5a9ff7743d40e88e";

export default node;
