import {
	ROUTE_GROUPS_GLOBAL_SETTINGS,
	ROUTE_NAMES_PRODUCTS_JSM_CONFIGURATION,
} from '@atlassian/jira-common-constants/src/spa-routes';

export const productsJsmConfigurationRoute = {
	group: ROUTE_GROUPS_GLOBAL_SETTINGS,
	name: ROUTE_NAMES_PRODUCTS_JSM_CONFIGURATION,
	path: '/jira/settings/products/jira-service-management-configuration',
	exact: true,
};
