import {
	ROUTE_GROUPS_EOC,
	ROUTE_NAMES_EOC_API_KEY_MANAGEMENT,
} from '@atlassian/jira-common-constants/src/spa-routes';

export const eocApiKeyManagementRoute = {
	group: ROUTE_GROUPS_EOC,
	name: ROUTE_NAMES_EOC_API_KEY_MANAGEMENT,
	path: '/jira/settings/products/ops/api-key-management',
	exact: true,
};
