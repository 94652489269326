import {
	ROUTE_GROUPS_GLOBAL_SETTINGS,
	ROUTE_NAMES_ARCHIVED_PROJECTS_DIRECTORY,
} from '@atlassian/jira-common-constants/src/spa-routes';

export const archivedProjectsDirectoryRoute = {
	group: ROUTE_GROUPS_GLOBAL_SETTINGS,
	name: ROUTE_NAMES_ARCHIVED_PROJECTS_DIRECTORY,
	path: '/jira/settings/projects/archive',
	exact: true,
};
