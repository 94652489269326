import React, { memo } from 'react';
import { styled } from '@compiled/react';
import OverviewIcon from '@atlassian/jira-business-entity-overview/src/common/ui/overview-icon/index.tsx';
import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import type { NavigationOverview } from '@atlassian/jira-router-resources-business-navigation/src/types.tsx';
import ActiveItem from '../../../../common/ui/active-item';
import LinkLabel from '../../../../common/ui/link-label';
import SidebarLink from '../../../../common/ui/sidebar-link';

type Props = {
	overview: NavigationOverview;
	to: string;
	isActive?: boolean;
};

const OverviewLink = ({ overview, to, isActive = false }: Props) => {
	const { name, theme } = overview;
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const content = (
		<SidebarLink
			to={to}
			isActive={isActive}
			prefetch="hover"
			onClick={() => fireUIAnalytics(createAnalyticsEvent({}), 'link clicked', 'overviewLink')}
		>
			<SidebarOverviewIcon size="small" themeName={theme} />

			<LinkLabel>{name}</LinkLabel>
		</SidebarLink>
	);

	return isActive ? <ActiveItem>{content}</ActiveItem> : content;
};

export default memo<Props>(OverviewLink);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const SidebarOverviewIcon = styled(OverviewIcon)({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	marginRight: `${gridSize * 1.5}px`,
});
