import {
	ROUTE_GROUPS_GLOBAL_SETTINGS,
	ROUTE_NAMES_PRODUCTS_JSM_ORGANIZATIONS,
} from '@atlassian/jira-common-constants/src/spa-routes';

export const productsJsmOrganizationsRoute = {
	group: ROUTE_GROUPS_GLOBAL_SETTINGS,
	name: ROUTE_NAMES_PRODUCTS_JSM_ORGANIZATIONS,
	path: '/jira/settings/products/servicedesk/organizations',
	exact: true,
};

export const productsJsmOrganizations1Route = {
	group: ROUTE_GROUPS_GLOBAL_SETTINGS,
	name: ROUTE_NAMES_PRODUCTS_JSM_ORGANIZATIONS,
	path: '/jira/settings/products/servicedesk/organizations/:organizationId',
	exact: true,
};
