import {
	ROUTE_NAMES_EOC_ALERTS,
	ROUTE_NAMES_EOC_ALERT_DETAIL,
	ROUTE_NAMES_EOC_ALERT_ISSUE_SYNCING,
	ROUTE_NAMES_EOC_ALERT_ISSUE_SYNCING_CREATE,
	ROUTE_NAMES_EOC_ALERT_ISSUE_SYNCING_DETAIL,
	ROUTE_NAMES_EOC_API_KEY_MANAGEMENT,
	ROUTE_NAMES_EOC_ASSIGN_CUSTOM_ROLES,
	ROUTE_NAMES_EOC_AUTOMATIONS,
	ROUTE_NAMES_EOC_CUSTOM_ROLES,
	ROUTE_NAMES_EOC_GLOBAL_ALERTS,
	ROUTE_NAMES_EOC_GLOBAL_ALERT_DETAIL,
	ROUTE_NAMES_EOC_GLOBAL_GROUP_DETAIL,
	ROUTE_NAMES_EOC_GLOBAL_MAINTENANCE,
	ROUTE_NAMES_EOC_GLOBAL_REPORTS,
	ROUTE_NAMES_EOC_HEARTBEATS,
	ROUTE_NAMES_EOC_INCOMING_CALL,
	ROUTE_NAMES_EOC_INCOMING_CALL_DETAIL,
	ROUTE_NAMES_EOC_INTEGRATIONS,
	ROUTE_NAMES_EOC_INTEGRATION_CREATE,
	ROUTE_NAMES_EOC_INTEGRATION_DETAIL,
	ROUTE_NAMES_EOC_INTEGRATION_LIST,
	ROUTE_NAMES_EOC_INTEGRATION_LOG,
	ROUTE_NAMES_EOC_MAINTENANCE,
	ROUTE_NAMES_EOC_ON_CALL,
	ROUTE_NAMES_EOC_OVERVIEW,
	ROUTE_NAMES_EOC_PERMISSIONS,
	ROUTE_NAMES_EOC_POLICIES,
	ROUTE_NAMES_EOC_REPORTS,
	ROUTE_NAMES_EOC_REPORTS_TEAM,
	ROUTE_NAMES_EOC_REPORTS_UNSUBSCRIBE,
	ROUTE_NAMES_EOC_ROLE_BASED_NOTIFICATIONS,
	ROUTE_NAMES_EOC_ROLE_BASED_NOTIFICATIONS_DETAIL,
	ROUTE_NAMES_EOC_SETTINGS,
	ROUTE_NAMES_EOC_TEAM_ALERTS,
	ROUTE_NAMES_EOC_TEAM_ALERT_DETAILS,
	ROUTE_NAMES_EOC_TEAM_ALERT_ISSUE_SYNCING,
	ROUTE_NAMES_EOC_TEAM_ALERT_ISSUE_SYNCING_CREATE,
	ROUTE_NAMES_EOC_TEAM_ALERT_ISSUE_SYNCING_DETAIL,
	ROUTE_NAMES_EOC_TEAM_CUSTOMER_LOGS,
	ROUTE_NAMES_EOC_TEAM_CUSTOMER_LOGS_DOWNLOAD,
	ROUTE_NAMES_EOC_TEAM_GROUP_DETAIL,
	ROUTE_NAMES_EOC_TEAM_INTEGRATIONS,
	ROUTE_NAMES_EOC_TEAM_INTEGRATION_CREATE,
	ROUTE_NAMES_EOC_TEAM_INTEGRATION_DETAIL,
	ROUTE_NAMES_EOC_TEAM_INTEGRATION_LIST,
	ROUTE_NAMES_EOC_TEAM_INTEGRATION_LOG,
	ROUTE_NAMES_EOC_TEAM_POLICIES,
	ROUTE_NAMES_EOC_UNLOCK_OPERATIONS,
	ROUTE_NAMES_EOC_WHO_IS_ON_CALL,
	ROUTE_NAMES_EOC_OVERVIEW_ALL_TEAMS,
} from '@atlassian/jira-common-constants/src/spa-routes';
import type { RouterContext } from '@atlassian/jira-router';
import { MENU_ID_OPERATIONS } from '../../../../constants';
import type { SelectedPath } from '../../../../types';

const routes = new Set<string>([
	// TODO: Confirm all of these routes are relevant:
	ROUTE_NAMES_EOC_ALERTS,
	ROUTE_NAMES_EOC_ALERT_DETAIL,
	ROUTE_NAMES_EOC_ALERT_ISSUE_SYNCING,
	ROUTE_NAMES_EOC_ALERT_ISSUE_SYNCING_CREATE,
	ROUTE_NAMES_EOC_ALERT_ISSUE_SYNCING_DETAIL,
	ROUTE_NAMES_EOC_API_KEY_MANAGEMENT,
	ROUTE_NAMES_EOC_ASSIGN_CUSTOM_ROLES,
	ROUTE_NAMES_EOC_AUTOMATIONS,
	ROUTE_NAMES_EOC_CUSTOM_ROLES,
	ROUTE_NAMES_EOC_GLOBAL_ALERTS,
	ROUTE_NAMES_EOC_GLOBAL_ALERT_DETAIL,
	ROUTE_NAMES_EOC_GLOBAL_GROUP_DETAIL,
	ROUTE_NAMES_EOC_GLOBAL_MAINTENANCE,
	ROUTE_NAMES_EOC_GLOBAL_REPORTS,
	ROUTE_NAMES_EOC_HEARTBEATS,
	ROUTE_NAMES_EOC_INCOMING_CALL,
	ROUTE_NAMES_EOC_INCOMING_CALL_DETAIL,
	ROUTE_NAMES_EOC_INTEGRATIONS,
	ROUTE_NAMES_EOC_INTEGRATION_CREATE,
	ROUTE_NAMES_EOC_INTEGRATION_DETAIL,
	ROUTE_NAMES_EOC_INTEGRATION_LIST,
	ROUTE_NAMES_EOC_INTEGRATION_LOG,
	ROUTE_NAMES_EOC_MAINTENANCE,
	ROUTE_NAMES_EOC_ON_CALL,
	ROUTE_NAMES_EOC_OVERVIEW,
	ROUTE_NAMES_EOC_OVERVIEW_ALL_TEAMS,
	ROUTE_NAMES_EOC_PERMISSIONS,
	ROUTE_NAMES_EOC_POLICIES,
	ROUTE_NAMES_EOC_REPORTS,
	ROUTE_NAMES_EOC_REPORTS_TEAM,
	ROUTE_NAMES_EOC_REPORTS_UNSUBSCRIBE,
	ROUTE_NAMES_EOC_ROLE_BASED_NOTIFICATIONS,
	ROUTE_NAMES_EOC_ROLE_BASED_NOTIFICATIONS_DETAIL,
	ROUTE_NAMES_EOC_SETTINGS,
	ROUTE_NAMES_EOC_TEAM_ALERTS,
	ROUTE_NAMES_EOC_TEAM_ALERT_DETAILS,
	ROUTE_NAMES_EOC_TEAM_ALERT_ISSUE_SYNCING,
	ROUTE_NAMES_EOC_TEAM_ALERT_ISSUE_SYNCING_CREATE,
	ROUTE_NAMES_EOC_TEAM_ALERT_ISSUE_SYNCING_DETAIL,
	ROUTE_NAMES_EOC_TEAM_CUSTOMER_LOGS,
	ROUTE_NAMES_EOC_TEAM_CUSTOMER_LOGS_DOWNLOAD,
	ROUTE_NAMES_EOC_TEAM_GROUP_DETAIL,
	ROUTE_NAMES_EOC_TEAM_INTEGRATIONS,
	ROUTE_NAMES_EOC_TEAM_INTEGRATION_CREATE,
	ROUTE_NAMES_EOC_TEAM_INTEGRATION_DETAIL,
	ROUTE_NAMES_EOC_TEAM_INTEGRATION_LIST,
	ROUTE_NAMES_EOC_TEAM_INTEGRATION_LOG,
	ROUTE_NAMES_EOC_TEAM_POLICIES,
	ROUTE_NAMES_EOC_UNLOCK_OPERATIONS,
	ROUTE_NAMES_EOC_WHO_IS_ON_CALL,
]);

function convert(routeContext: RouterContext): SelectedPath {
	const routeName = routeContext.route.name;
	const path: string[] = [MENU_ID_OPERATIONS];

	switch (routeName) {
		default: {
			break;
		}
	}

	return path;
}

export const operationsConverter = { routes, convert };
