/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@compiled/react';
// eslint-disable-next-line jira/import-whitelist
import { componentWithFG } from '@atlassian/jira-feature-gate-component';
import { AppSkeletonImage } from '../../common/ui';
import insightsImg from './assets/insights.tpl.svg';
import periscopePageWithInsights from './assets/periscope-page-with-insights.tpl.svg';
import periscopePage from './assets/periscope-page.tpl.svg';
import suggestionsImg from './assets/suggestions.tpl.svg';

const PeriscopePageSkeletonStyles = css({
	width: '100%',
	height: '100%',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	object: {
		border: 'none',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	img: {
		border: 'none',
	},
});

export const PeriscopePageSkeletonWithInsights = () => (
	<div
		css={PeriscopePageSkeletonStyles}
		data-testid="skeletons.ui.periscope-page.app-skeleton-image-periscope-page"
	>
		<AppSkeletonImage src={periscopePageWithInsights} />
	</div>
);

export const PeriscopePageSkeletonOrig = () => (
	<div
		css={PeriscopePageSkeletonStyles}
		data-testid="skeletons.ui.periscope-page.app-skeleton-image-periscope-page"
	>
		<AppSkeletonImage src={periscopePage} />
	</div>
);

export const PeriscopeSuggestionsSkeleton = () => (
	<div
		css={PeriscopePageSkeletonStyles}
		data-testid="skeletons.ui.periscope-page.app-suggestions-skeleton"
	>
		<AppSkeletonImage src={suggestionsImg} />
	</div>
);

export const PeriscopeInsightsSkeleton = () => (
	<div
		css={PeriscopePageSkeletonStyles}
		data-testid="skeletons.ui.periscope-page.app-insights-skeleton"
	>
		<AppSkeletonImage src={insightsImg} />
	</div>
);

// We are using componentWithFG to work around eslint import restrictions, on FF cleanup, delete old svg
export const PeriscopePageSkeleton = componentWithFG(
	'jira_periscope_insights_panel',
	PeriscopePageSkeletonWithInsights,
	PeriscopePageSkeletonOrig,
);
