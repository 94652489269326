import {
	ROUTE_GROUPS_EOC,
	ROUTE_NAMES_EOC_AUTOMATIONS,
} from '@atlassian/jira-common-constants/src/spa-routes';

export const eocAutomationsRoute = {
	group: ROUTE_GROUPS_EOC,
	name: ROUTE_NAMES_EOC_AUTOMATIONS,
	path: '/jira/ops/teams/:teamId/automations',
	exact: true,
};
