import { type RouterContext, createResource } from '@atlassian/jira-router';
import { RESOURCE_TYPE_PRODUCT_JSM_ORGANIZATION_LIST } from './constants';

const TEN_SECONDS_IN_MILLIS = 1000 * 10;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const productsJsmOrganizationListResource = createResource<any>({
	type: RESOURCE_TYPE_PRODUCT_JSM_ORGANIZATION_LIST,
	getKey: (routerContext: RouterContext) => routerContext.query.page,
	getDataLoader: () =>
		import(
			/* webpackChunkName: "async-resource-products-jsm-organizations-list", jiraSpaEntry: "async-resource-products-jsm-organizations-list" */ './services/get-data'
		),
	maxAge: TEN_SECONDS_IN_MILLIS,
});
