import {
	ROUTE_GROUPS_MARKETPLACE,
	ROUTE_NAMES_MARKETPLACE_INDEX,
	ROUTE_NAMES_MARKETPLACE_APP_REQUESTS,
	ROUTE_NAMES_MARKETPLACE_PROMOTIONS,
} from '@atlassian/jira-common-constants/src/spa-routes';

export const findAppsRoute = {
	group: ROUTE_GROUPS_MARKETPLACE,
	name: ROUTE_NAMES_MARKETPLACE_INDEX,
	path: '/jira/marketplace',
};

export const appRequestRoute = {
	group: ROUTE_GROUPS_MARKETPLACE,
	name: ROUTE_NAMES_MARKETPLACE_APP_REQUESTS,
	path: '/jira/marketplace/manage/app-requests',
};

export const promotionsRoute = {
	group: ROUTE_GROUPS_MARKETPLACE,
	name: ROUTE_NAMES_MARKETPLACE_PROMOTIONS,
	path: '/jira/marketplace/manage/promotions',
};
