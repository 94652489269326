import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import type { ServiceUpdateModalWithoutProjectContext as ServiceUpdateModalWithProjectContextType } from '@atlassian/jira-spa-apps-servicedesk-service-update-modal-without-project-context/src/ui/index.tsx';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';

export const LazyServiceUpdateModalWithProjectContext = lazyForPaint<
	typeof ServiceUpdateModalWithProjectContextType
>(
	() =>
		import(
			/* webpackChunkName: "async-servicedesk-service-update-modal-without-project-context", jiraSpaEntry: "async-servicedesk-service-update-modal-without-project-context" */ '@atlassian/jira-spa-apps-servicedesk-service-update-modal-without-project-context'
		),
);

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default () => (
	<LazyPage
		Page={LazyServiceUpdateModalWithProjectContext}
		pageId="spa-apps/servicedesk/service-update-modal"
		shouldShowSpinner
	/>
);
