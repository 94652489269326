import {
	ROUTE_GROUPS_DIRECTORIES,
	ROUTE_NAMES_DASHBOARDS_DIRECTORY,
	ROUTE_NAMES_DASHBOARDS_DIRECTORY_SECURE,
} from '@atlassian/jira-common-constants/src/spa-routes';

export const dashboardsDirectoryRoute = {
	group: ROUTE_GROUPS_DIRECTORIES,
	name: ROUTE_NAMES_DASHBOARDS_DIRECTORY,
	path: '/jira/dashboards',
};

export const dashboardsDirectoryRedirectRoute = {
	group: ROUTE_GROUPS_DIRECTORIES,
	name: ROUTE_NAMES_DASHBOARDS_DIRECTORY_SECURE,
	path: '/secure/ConfigurePortalPages(!default.jspa|.jspa)',
};
