import {
	ROUTE_GROUPS_CLASSIC_BUSINESS,
	ROUTE_NAMES_CLASSIC_BUSINESS_REDIRECT,
	ROUTE_NAMES_CLASSIC_BUSINESS_OVERVIEW_REDIRECT,
} from '@atlassian/jira-common-constants/src/spa-routes';

export const jiraBusinessProjectRedirectRoute = {
	group: ROUTE_GROUPS_CLASSIC_BUSINESS,
	name: ROUTE_NAMES_CLASSIC_BUSINESS_REDIRECT,
	path: '/jira/core/projects/:projectKey',
	exact: true,
	isRedirect: true,
};

export const jiraBusinessOverviewRedirectRoute = {
	group: ROUTE_GROUPS_CLASSIC_BUSINESS,
	name: ROUTE_NAMES_CLASSIC_BUSINESS_OVERVIEW_REDIRECT,
	path: '/jira/core/overviews/:overviewId',
	exact: true,
	isRedirect: true,
};
