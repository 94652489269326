import {
	ROUTE_NAMES_RAPIDBOARD_BOARD,
	ROUTE_NAMES_RAPIDBOARD_BOARD_EMBED,
	ROUTE_NAMES_RAPIDBOARD_USER_BOARD,
	ROUTE_NAMES_SERVICEDESK_BOARDS,
	ROUTE_NAMES_RAPIDBOARD_BACKLOG,
	ROUTE_NAMES_RAPIDBOARD_USER_BACKLOG,
	ROUTE_NAMES_RAPIDBOARD_BACKLOG_EMBED,
} from '@atlassian/jira-common-constants/src/spa-routes';

export const isBoardRoute = (routeName: string): boolean =>
	routeName === ROUTE_NAMES_RAPIDBOARD_BOARD ||
	routeName === ROUTE_NAMES_RAPIDBOARD_BOARD_EMBED ||
	routeName === ROUTE_NAMES_RAPIDBOARD_USER_BOARD ||
	routeName === ROUTE_NAMES_SERVICEDESK_BOARDS;

export const isBacklogRoute = (routeName: string): boolean =>
	routeName === ROUTE_NAMES_RAPIDBOARD_BACKLOG ||
	routeName === ROUTE_NAMES_RAPIDBOARD_USER_BACKLOG ||
	routeName === ROUTE_NAMES_RAPIDBOARD_BACKLOG_EMBED;
