import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { ff } from '@atlassian/jira-feature-flagging';
import { performPostRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import { createResource, useResource } from '@atlassian/jira-router';
import { resourceWithCondition2 } from '@atlassian/jira-router-resources-utils/src/utils/resource-with-condition/index.tsx';
import { QUERY } from './gql';
import type { RovoEntitlementGraphQLResponse } from './types';

const RESOURCE_TYPE_ROVO_ENTITLEMENT = 'ROVO_ENTITLEMENT';

type RovoEntitlementData = {
	isRovoEnabled: boolean | null;
};

const fetchRovoEntitlement = async (cloudId: string): Promise<RovoEntitlementData> => {
	try {
		const response = await performPostRequest<RovoEntitlementGraphQLResponse>(
			'/gateway/api/graphql',
			{
				body: JSON.stringify({
					query: QUERY,
					variables: {
						cloudId,
					},
				}),
				headers: {
					'content-type': 'application/json',
				},
			},
		);

		// handle GraphQL errors
		if (response.errors) {
			fireErrorAnalytics({
				meta: {
					id: 'navigationResource',
					packageName: 'jiraRouterResourcesRovoEntitlement',
					teamName: 'ai-mate',
				},
				error: new Error(
					`RovoEntitlement Error. ${response.errors.map((error) => error.message).join('; ')}; IsDataEmpty=${response.data?.jira === null || response.data?.jira === undefined}`,
				),
				sendToPrivacyUnsafeSplunk: true,
			});
		}

		return {
			isRovoEnabled: response.data?.jira?.isRovoEnabled ?? null,
		};
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (error: any) {
		fireErrorAnalytics({
			meta: {
				id: 'navigationResource',
				packageName: 'jiraRouterResourcesRovoEntitlement',
				teamName: 'ai-mate',
			},
			error,
			sendToPrivacyUnsafeSplunk: true,
		});
	}

	return {
		isRovoEnabled: null,
	};
};

export const rovoEntitlementResource = resourceWithCondition2(
	() => ff('jira.frontend.enable-rovo-entitlement-resource-router'),
	createResource<RovoEntitlementData>({
		type: RESOURCE_TYPE_ROVO_ENTITLEMENT,
		getKey: () => RESOURCE_TYPE_ROVO_ENTITLEMENT,
		getData: (_, { tenantContext: { cloudId } }) => fetchRovoEntitlement(cloudId),
		maxAge: Infinity,
	}),
);

export const useRovoEntitlementResource = () => useResource(rovoEntitlementResource);
