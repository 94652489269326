import { createResource } from '@atlassian/jira-router';
import { getIssueKey } from '@atlassian/jira-router-resources-utils/src/utils/get-issue-key/index.tsx';
import { withResourceEarlyData } from '../../utils';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const issueMediaUploadPermissionResource = createResource<any>(
	withResourceEarlyData({
		type: 'ISSUE_MEDIA_UPLOAD_PERM',
		getKey: ({ match }) => {
			const issueKey = getIssueKey(match);
			return issueKey || '';
		},
		getData: () => null,
		isBrowserOnly: true,
	}),
);
