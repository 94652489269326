import type { ProjectKey } from '@atlassian/jira-shared-types';
import type { StoreActionApi } from '@atlassian/react-sweet-state';
import type { ProjectContext, State } from './types';

export const setProjectContext =
	(key: ProjectKey, projectContext: ProjectContext) =>
	({ setState }: StoreActionApi<State>) => {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		setState({ [key as string]: projectContext });
	};

export const mergeProjectProperty =
	(key: ProjectKey, projectContext: Partial<ProjectContext>) =>
	({ setState, getState }: StoreActionApi<State>) => {
		const currentState = getState();
		const currentProjectContext = currentState[key];
		const newProjectContext = { ...currentProjectContext, ...projectContext };
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		setState({ [key as string]: newProjectContext });
	};
