import { GraphQLErrors } from '@atlassian/jira-business-graphql-errors';
import { createAri } from '@atlassian/jira-platform-ari';
import { createResource, useResource } from '@atlassian/jira-router';
import { getProjectKeyId } from '@atlassian/jira-router-resources-utils/src/utils/get-project-key-id/index.tsx';
import { fetchFields } from './services/fields';
import { PROJECT_QUERY, OVERVIEW_QUERY } from './services/fields/gql';
import { transformData } from './services/fields/utils';
import type { FieldsProjectQueryResponse, Field, FieldsOverviewQueryResponse } from './types';

export type { Field };

const RESOURCE_TYPE_KEY = 'BUSINESS_FIELDS';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Handler = (promise: Promise<any>) => Promise<Field[]>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const handleProjectResponse: Handler = async (promise: Promise<any>) => {
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	const response = (await promise) as FieldsProjectQueryResponse;

	if (response.data?.jira?.jqlBuilder?.fields?.edges == null) {
		throw new GraphQLErrors(response.errors);
	}

	return transformData(response.data?.jira?.jqlBuilder?.fields?.edges);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const handleOverviewResponse: Handler = async (promise: Promise<any>) => {
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	const response = (await promise) as FieldsOverviewQueryResponse;

	if (response.data?.jira?.jwmOverview?.fields?.edges == null) {
		throw new GraphQLErrors(response.errors);
	}

	return transformData(response.data?.jira?.jwmOverview?.fields?.edges);
};

export const fieldsResource = createResource<Field[] | null>({
	type: RESOURCE_TYPE_KEY,
	getKey: (matchedRoute) => {
		if (matchedRoute.match.params.overviewId) {
			return `${RESOURCE_TYPE_KEY}_overview_${matchedRoute.match.params.overviewId}`;
		}

		const { projectKey, projectId } = getProjectKeyId(matchedRoute.match, matchedRoute.query);
		const projectIdOrKey = projectKey || projectId;
		return `${RESOURCE_TYPE_KEY}_project_${projectIdOrKey}`;
	},
	getData: async ({ match, query }, { tenantContext: { cloudId, activationId } }) => {
		if (match.params.overviewId) {
			return fetchFields(OVERVIEW_QUERY, handleOverviewResponse, {
				overviewAri: createAri({
					resourceOwner: 'jira',
					cloudId,
					resourceType: 'project-overview',
					resourceId: match.params.overviewId,
					activationId,
				}),
			});
		}

		const { projectKey, projectId } = getProjectKeyId(match, query);
		const projectIdOrKey = projectKey || projectId;

		return fetchFields(PROJECT_QUERY, handleProjectResponse, {
			cloudId,
			jqlContext: `project = '${projectIdOrKey}'`,
		});
	},
	maxAge: 60 * 60 * 1000, // 1 hour,
});

export const useFieldsResource = () => useResource<Field[] | null>(fieldsResource);
