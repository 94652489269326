/** @jsx jsx */
import React, { type CSSProperties } from 'react';
import { styled, css, jsx } from '@compiled/react';
import { isChrome } from '@atlassian/jira-common-util-browser';
import { useIntl } from '@atlassian/jira-intl';
// eslint-disable-next-line jira/import-whitelist
import UFOLoadHold from '@atlassian/react-ufo/load-hold';
import messages from './messages';

const skeletonImageStyles = css({
	display: 'block',
	boxSizing: 'border-box',
	width: '100%',
	borderStyle: 'solid',
	borderColor: 'transparent',
	borderLeftWidth: '40px',
	borderRightWidth: '40px',

	/* Fix FF 2x scale bug on high res devices https://bugzilla.mozilla.org/show_bug.cgi?id=986403 */
	'@media (min--moz-device-pixel-ratio: 2)': {
		borderLeftWidth: '20px',
		borderRightWidth: '20px',
	},
});

type Props = {
	src: string;
	// css?: string | string[];
	className?: string;
	/**
	 * Inline styles to apply to the image container, for example, to set a fixed container height for component
	 * skeletons.
	 */
	containerStyles?: CSSProperties;
};

export const AppSkeletonImage = ({ src, className, containerStyles }: Props) => {
	const { formatMessage } = useIntl();
	return (
		<UFOLoadHold name="app-skeleton">
			<SkeletonWrapper
				// eslint-disable-next-line jira/react/no-style-attribute, @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
				style={containerStyles}
			>
				{process.env.NODE_ENV !== 'production' && isChrome() ? (
					/* during dev use <object> instead of <img> to easily debug */
					<object
						data={src}
						type="image/svg+xml"
						css={skeletonImageStyles}
						// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
						className={className}
						aria-label={`${formatMessage(messages.loading)}...`}
					>
						{formatMessage(messages.loading)}...
					</object>
				) : (
					<img
						src={src}
						alt={`${formatMessage(messages.loading)}...`}
						css={skeletonImageStyles}
						// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
						className={className}
					/>
				)}
			</SkeletonWrapper>
		</UFOLoadHold>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SkeletonWrapper = styled.div({
	overflow: 'hidden',
	width: '100%',
	height: '100%',

	/* FF bug with high res devices https://bugzilla.mozilla.org/show_bug.cgi?id=986403 */
	'@media (min--moz-device-pixel-ratio: 2)': {
		width: '50%',
		height: '50%',
		transform: 'scale(2)',
		transformOrigin: '0 0',
	},
});
