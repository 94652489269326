import type { Action } from '@atlassian/react-sweet-state';
import type { State } from '../../model/types';

export const setNudgeStep =
	(nudge: string): Action<State> =>
	({ getState, setState }) => {
		const { nudgeStep } = getState();

		if (nudge === nudgeStep) {
			return;
		}

		setState({ nudgeStep: nudge });
	};

export default setNudgeStep;
