import React, { memo } from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import { ContextualAnalyticsData } from '@atlassian/jira-navigation-apps-sidebar-common/src/ui/contextual-analytics-data/index.tsx';
import { SidebarBoundary } from '@atlassian/jira-navigation-apps-sidebar-nav4-common/src/ui/sidebar-boundary';
import { Skeleton } from '@atlassian/jira-navigation-apps-sidebar-nav4-project-settings-common/src/common/ui/skeleton';
import { MENU_ID_JSM_SETTINGS } from '@atlassian/jira-navigation-apps-sidebar-nav4-project-settings-common/src/constants';
import Placeholder from '@atlassian/jira-placeholder';
import type { Props } from './main';

const LazySidebarContent = lazyForPaint(() =>
	import(
		/* webpackChunkName: "async-jira-navigation-apps-sidebar-nav4-sidebar-jsm-tmp-project-settings" */ './main'
	).then(({ JSMTmpProjectSettingsSidebar }) => JSMTmpProjectSettingsSidebar),
);

const analyticsAttributes = { navVersion: '4', projectType: 'tmp' };

export const AsyncJsmTmpProjectSettingsSidebar = memo(({ project }: Props) => (
	<SidebarBoundary
		id="nav4.sidebar.jsm-tmp-project-settings"
		packageName="jiraNavigationAppsSidebarNav4JsmTmpProjectSettings"
	>
		<ContextualAnalyticsData menuId={MENU_ID_JSM_SETTINGS} attributes={analyticsAttributes}>
			<Placeholder
				name="nav4.sidebar.jsm-tmp-project-settings.loading-lazy"
				fallback={<Skeleton />}
			>
				<LazySidebarContent project={project} />
			</Placeholder>
		</ContextualAnalyticsData>
	</SidebarBoundary>
));
