import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import type { ChangeCalendarLayout as ChangeCalendarType } from '@atlassian/jira-spa-apps-servicedesk-change-calendar/src/ui/index.tsx';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';

export const LazyChangeCalendar = lazyForPaint<typeof ChangeCalendarType>(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-servicedesk-change-calendar", jiraSpaEntry: "async-servicedesk-change-calendar" */ '@atlassian/jira-spa-apps-servicedesk-change-calendar'
		),
	),
);

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default () => (
	<LazyPage
		Page={LazyChangeCalendar}
		pageId="spa-apps/servicedesk/change-calendar"
		shouldShowSpinner
	/>
);
