import {
	ROUTE_GROUPS_GLOBAL_SETTINGS,
	ROUTE_NAMES_GLOBAL_SETTINGS_ISSUES_PRIORITY_SCHEMES,
	ROUTE_NAMES_GLOBAL_SETTINGS_ISSUES_PRIORITIES,
} from '@atlassian/jira-common-constants/src/spa-routes';

export const globalSettingsIssuesPrioritySchemesRoute = {
	group: ROUTE_GROUPS_GLOBAL_SETTINGS,
	name: ROUTE_NAMES_GLOBAL_SETTINGS_ISSUES_PRIORITY_SCHEMES,
	path: '/jira/settings/issues/priority-schemes',
};

export const globalSettingsIssuesPrioritiesRoute = {
	group: ROUTE_GROUPS_GLOBAL_SETTINGS,
	name: ROUTE_NAMES_GLOBAL_SETTINGS_ISSUES_PRIORITIES,
	path: '/jira/settings/issues/priorities',
};
