import log from '@atlassian/jira-common-util-logging/src/log';
import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import type {
	SidebarConfigData,
	SidebarConfigQuery,
} from '@atlassian/jira-navigation-types/src/types.tsx';

const LOGGER_LOCATION = 'navigation-next.baskets.navigation.utils';
const fetchUsingProtobuf = async (url: string) => {
	try {
		const fetchProtobuf = await import(
			/* webpackChunkName: "fetch-protobuf" */ '@atlassian/jira-proto/src/fetch/as-protobuf'
		);
		if (!fetchProtobuf) {
			log.safeErrorWithoutCustomerData(
				LOGGER_LOCATION,
				'Error dynamically loading protobuf fetch function',
			);
		}
		return fetchProtobuf.default(url);
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (err: any) {
		log.safeErrorWithoutCustomerData(
			LOGGER_LOCATION,
			'Error fetching Sidebar Configuration protocol buffer message',
			err,
		);
		throw err;
	}
};
/**
 * Generates a URL tailored for retrieving sidebar configurations, allowing for dynamic
 * customization based on user-defined criteria. This facilitates the flexible integration
 * of sidebar elements in applications.
 */
export const formSidebarConfigRequest = (
	baseUrl: string,
	requestQuery: SidebarConfigQuery,
	version = '2',
	queryParams?: string,
) => {
	const { pageType, identifierValue, identifierType, exclude } = requestQuery || {};
	const params: Record<string, string> = {
		exclude: ['webItems.reports', 'webItems.home', 'webItems.gin', exclude]
			.filter(Boolean)
			.join(','),
	};
	let path = '';
	if (pageType && identifierValue != null) {
		path = `${pageType}/${identifierValue}`;
	} else if (identifierType === 'boardId' && identifierValue != null) {
		path = `board/${identifierValue}`;
	} else if (identifierValue != null) {
		path = `project/${identifierValue}`;
	} else {
		path = 'product';
		const { projectId, projectKey } = requestQuery || {};
		// Connect apps rely on project context even for the global/product scoped webItems
		const projectIdOrKey = projectId || projectKey;
		if (projectIdOrKey) {
			params.projectIdOrKey = projectIdOrKey;
		}
	}
	const queryString = [new URLSearchParams(params).toString(), queryParams]
		.filter(Boolean)
		.join('&');
	return `${baseUrl}/rest/navigation/${version}/${path}?${queryString}`;
};
export const fetchSidebarConfig = async (
	baseUrl: string,
	requestQuery: SidebarConfigQuery,
	isServer: boolean,
	queryParams?: string,
): Promise<SidebarConfigData> => {
	if (isServer) {
		const protoUrl = formSidebarConfigRequest(baseUrl, requestQuery, '2.1', queryParams);
		return fetchUsingProtobuf(protoUrl);
	}
	const sidebarUrl = formSidebarConfigRequest(baseUrl, requestQuery, undefined, queryParams);
	return fetchJson(sidebarUrl);
};
