import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import { HelpCenterSettingsRedirect } from '@atlassian/jira-servicedesk-customer-service-common/src/ui/help-center-settings-redirect';
import type ProjectSettingsServiceDeskTicketTypesField from '@atlassian/jira-spa-apps-project-settings-service-desk-ticket-type-fields/src/ui/index.tsx';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';

export const LazyProjectSettingsServiceDeskTicketTypeFields = lazyForPaint<
	typeof ProjectSettingsServiceDeskTicketTypesField
>(
	() =>
		import(
			/* webpackChunkName: "async-project-settings-service-desk-ticket-type-fields", jiraSpaEntry: "async-project-settings-service-desk-ticket-type-fields" */ '@atlassian/jira-spa-apps-project-settings-service-desk-ticket-type-fields'
		),
);

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default () => (
	<HelpCenterSettingsRedirect page="ticket-type-fields">
		<LazyPage
			Page={LazyProjectSettingsServiceDeskTicketTypeFields}
			pageId="project-settings-ticket-type-fields"
			shouldShowSpinner
		/>
	</HelpCenterSettingsRedirect>
);
