import {
	ROUTE_GROUPS_GLOBAL_SETTINGS,
	ROUTE_NAMES_GLOBAL_SETTINGS_SYSTEM_UI_ANNOUNCEMENT_BANNER,
} from '@atlassian/jira-common-constants/src/spa-routes';

export const globalSettingsSystemUiAnnouncementBannerRoute = {
	group: ROUTE_GROUPS_GLOBAL_SETTINGS,
	name: ROUTE_NAMES_GLOBAL_SETTINGS_SYSTEM_UI_ANNOUNCEMENT_BANNER,
	path: '/jira/settings/system/ui/announcement-banner',
};
