import { ff } from '@atlassian/jira-feature-flagging';
import { createResource } from '@atlassian/jira-router';
import {
	browserOnlyResourceWithCondition,
	resourceWithCondition2,
} from '@atlassian/jira-router-resources-utils/src/utils/resource-with-condition/index.tsx';
import type { PrefetchedDataGql } from '@atlassian/jira-software-backlog-load-agility-graphql-data/src/types';
import { RESOURCE_TYPE_NEXTGEN_BACKLOG } from '../../constants';

export const backlogResource = browserOnlyResourceWithCondition(
	() => __SERVER__,
	createResource<PrefetchedDataGql | null>({
		type: RESOURCE_TYPE_NEXTGEN_BACKLOG,
		getKey: ({ match }) => match.params.boardId || '',
		getDataLoader: () =>
			import(
				/* webpackChunkName: "async-nextgen-common", jiraSpaEntry: "async-nextgen-common" */ '../get-data'
			),
		// SSR doesn't care about this and we can't check FFs here because its not in the render path
		maxAge: 1000 * 60 * 60, // 1 hour max age
	}),
);

export const conditionalBacklogResource = resourceWithCondition2(
	() => !ff('jsw.perf.critical-backlog-with-relay'),
	backlogResource,
);
