import {
	ROUTE_GROUPS_DIRECTORIES,
	ROUTE_NAMES_BOARDS_DIRECTORY,
} from '@atlassian/jira-common-constants/src/spa-routes';

export const boardsDirectoryRoute = {
	group: ROUTE_GROUPS_DIRECTORIES,
	name: ROUTE_NAMES_BOARDS_DIRECTORY,
	path: '/jira/boards',
};

export const boardsDirectoryRedirectRoute = {
	group: ROUTE_GROUPS_DIRECTORIES,
	name: `${ROUTE_NAMES_BOARDS_DIRECTORY}-redirect`,
	path: '/secure/ManageRapidViews.jspa',
};
