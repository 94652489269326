import {
	ROUTE_GROUPS_EOC,
	ROUTE_NAMES_EOC_PERMISSIONS,
} from '@atlassian/jira-common-constants/src/spa-routes';

export const eocPermissionsRoute = {
	group: ROUTE_GROUPS_EOC,
	name: ROUTE_NAMES_EOC_PERMISSIONS,
	path: '/jira/ops/teams/:teamId/settings',
	exact: true,
};
