import {
	ROUTE_NAMES_FORGE_ADMIN_SETTINGS,
	ROUTE_NAMES_FORGE_ADMIN,
	ROUTE_NAMES_FORGE_PROJECT,
	ROUTE_NAMES_FORGE_SERVICEDESK_QUEUE,
	ROUTE_NAMES_FORGE_FIELD_CONTEXTUAL_CONFIGURATION,
	ROUTE_NAMES_FORGE_GLOBAL,
} from '@atlassian/jira-common-constants/src/spa-routes';

export const forgeAdminSettingsRoute = {
	name: ROUTE_NAMES_FORGE_ADMIN_SETTINGS,
	path: '/jira/settings/apps/:settingsType(configure|get-started)/:appId/:envId/:route?',
};

export const forgeAdminRoute = {
	name: ROUTE_NAMES_FORGE_ADMIN,
	path: '/jira/settings/apps/:appId/:envId/:route?',
};

export const forgeProjectRoute = {
	name: ROUTE_NAMES_FORGE_PROJECT,
	path: '/jira/:projectType(software|software/c|servicedesk|core)/projects/:projectKey/apps/:appId/:envId/:route?',
};

export const forgeServicedeskQueueRoute = {
	name: ROUTE_NAMES_FORGE_SERVICEDESK_QUEUE,
	path: '/jira/servicedesk/projects/:projectKey/queues/apps/:appId/:envId/:route?',
};

export const forgeFieldContextualConfigurationRoute = {
	name: ROUTE_NAMES_FORGE_FIELD_CONTEXTUAL_CONFIGURATION,
	path: '/jira/settings/issues/field/:fieldId/context/:contextId/configure/app',
};

export const forgeGlobalRoute = {
	name: ROUTE_NAMES_FORGE_GLOBAL,
	path: '/jira/apps/:appId/:envId/:route?',
};
