import React, { memo, useState, useEffect, useCallback, type ReactElement } from 'react';
import Popup from '@atlaskit/popup';
import { getJwmCreateProjectDrawerOptions } from '@atlassian/jira-business-common/src/common/utils/create-jwm-project-drawer/index.tsx';
import { useLeftSidebarState } from '@atlassian/jira-layout-controller/src/controllers/layout-controller/consumers/left-sidebar-controller/index.tsx';
import type { OpenDrawerMethod } from '@atlassian/jira-project-create-drawer/src/types.tsx';
import { LOCATION } from '../../../../common/constants';
import { useProjectTemplates } from '../../../../services/project-templates';
import type { ProjectTemplatesDataType } from '../../../../services/project-templates/types';
import CreateProjectButton from '../create-project-button';

type Props = {
	renderContent: (data: ProjectTemplatesDataType, loading: boolean) => ReactElement;
	openProjectCreateDrawer: OpenDrawerMethod;
};

const OneClickProjectButton = ({ renderContent, openProjectCreateDrawer }: Props) => {
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const { data, loading, error, fetch: fetchJwmTemplates } = useProjectTemplates();
	const { isCollapsed: isLeftSidebarCollapsed } = useLeftSidebarState();

	const { templates, recommendationSessionId } = data;

	const wasFetched = templates != null || loading || error != null;

	const onClose = useCallback(() => setIsOpen(false), []);

	useEffect(() => {
		if (isOpen && !wasFetched) {
			fetchJwmTemplates();
		}
	}, [isOpen, wasFetched, fetchJwmTemplates]);

	useEffect(() => {
		if (isLeftSidebarCollapsed && isOpen) {
			onClose();
		}
	}, [isLeftSidebarCollapsed, isOpen, onClose]);

	useEffect(() => {
		if (error && isOpen) {
			openProjectCreateDrawer(getJwmCreateProjectDrawerOptions(LOCATION, recommendationSessionId));
			setIsOpen(false);
		}
	}, [error, isOpen, openProjectCreateDrawer, recommendationSessionId]);

	const content = useCallback(() => renderContent(data, loading), [renderContent, data, loading]);

	const trigger = useCallback(
		// @ts-expect-error - TS7006 - Parameter 'triggerProps' implicitly has an 'any' type.
		(triggerProps) => (
			<div {...triggerProps}>
				<CreateProjectButton onClick={() => setIsOpen(!isOpen)} />
			</div>
		),
		[isOpen],
	);

	return (
		<Popup
			isOpen={isOpen}
			placement="right-start"
			onClose={onClose}
			content={content}
			trigger={trigger}
		/>
	);
};

export default memo<Props>(OneClickProjectButton);
