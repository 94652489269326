import {
	ROUTE_NAMES_SOFTWARE_TIMELINE,
	ROUTE_NAMES_SOFTWARE_TIMELINE_CLASSIC,
} from '@atlassian/jira-common-constants/src/spa-routes';

export const isRoadmapRoute = (routeName: string | undefined): boolean => {
	if (routeName === undefined) {
		return false;
	}

	return (
		routeName === ROUTE_NAMES_SOFTWARE_TIMELINE ||
		routeName === ROUTE_NAMES_SOFTWARE_TIMELINE_CLASSIC
	);
};
