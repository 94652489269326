import React, { useEffect, useRef, useState } from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/index.tsx';
import { useEmbeddedPanelEntryPoint } from '@atlassian/jira-issue-create-confluence-content/src/controllers/use-embedded-panel-entry-point';
import { useActiveRightSidebarState } from '@atlassian/jira-layout-controller/src/controllers/layout-controller/consumers/right-sidebar-controller/index.tsx';

// TODO: get from the store
export const EMBEDDED_CONFLUENCE_CONTAINER_ID = 'embedded-confluence-panel-container';
const DELAY = 310;

export const PanelInLayout = () => {
	const rightSidebarCurrentState = useActiveRightSidebarState();

	// TODO: Get current Confluence Data & embeddedConfluenceSource from react-sweet-state
	const state = {
		panel: {
			initialWidth: 0.35,
			isOpen: true,
			wasLeftNavOpen: false,
		},
	};

	const [localPanelId, setLocalPanelId] = useState<unknown>();
	const prevPanelIdRef = useRef<unknown>();
	const delayRef = useRef<unknown>(null);

	const panelId = rightSidebarCurrentState?.panelId;
	const { entryPointActions, entryPoint } = useEmbeddedPanelEntryPoint();

	useEffect(() => {
		if (state.panel.isOpen) {
			entryPointActions?.load();
		}
	}, [state.panel.isOpen, entryPointActions, localPanelId]);
	/**
	 * This use effect is used to add (when is necessary) a delay
	 * in the panelId => localPanelId syncing
	 */
	useEffect(() => {
		// @ts-expect-error - TS2769 - No overload matches this call.
		clearTimeout(delayRef.current);

		if (prevPanelIdRef.current === EMBEDDED_CONFLUENCE_CONTAINER_ID) {
			/**
			 * The right-sidebar is showing the embedded confluence content...
			 */
			if (
				rightSidebarCurrentState &&
				panelId !== undefined &&
				!rightSidebarCurrentState.isCollapsed &&
				!rightSidebarCurrentState.isMinimised
			) {
				/**
				 * and is open, not collapsed and we want to show a panel
				 * with an ID !== EMBEDDED_CONFLUENCE_CONTAINER_ID
				 */
				setLocalPanelId(panelId);
			} else {
				/**
				 * and we want to close it
				 */
				delayRef.current = setTimeout(() => {
					setLocalPanelId(panelId);

					// TODO: trigger close analytics event
				}, DELAY);
			}
		} else {
			/**
			 * If the right sidebar is not showing the embedded confluence content,
			 * we just keep localPanelId in sync with panelId
			 */
			setLocalPanelId(panelId);
		}

		prevPanelIdRef.current = panelId;

		// @ts-expect-error - TS2769 - No overload matches this call.
		return () => clearTimeout(delayRef.current);
	}, [panelId, rightSidebarCurrentState]);

	if (localPanelId !== EMBEDDED_CONFLUENCE_CONTAINER_ID) {
		return null;
	}

	return <Box xcss={panelStyles}>{entryPoint}</Box>;
};

export const SafeEmbeddedConfluencePanel = () => (
	<JSErrorBoundary
		fallback="unmount"
		id="right-side-embedded-confluence-panel"
		packageName="jiraIssueCreateConfluenceContent"
	>
		<PanelInLayout />
	</JSErrorBoundary>
);

const panelStyles = xcss({
	padding: 'space.100',
	height: 'calc(100vh - (var(--topNavigationHeight, 0px) + var(--bannerHeight, 0px)))',
});
