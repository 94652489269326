import {
	ROUTE_GROUPS_CLASSIC_BUSINESS,
	ROUTE_NAMES_CLASSIC_BUSINESS_PROJECT_SUMMARY,
	ROUTE_NAMES_CLASSIC_BUSINESS_PROJECT_CALENDAR,
	ROUTE_NAMES_CLASSIC_BUSINESS_PROJECT_SHORTCUT,
	ROUTE_NAMES_CLASSIC_BUSINESS_PROJECT_LIST,
	ROUTE_NAMES_CLASSIC_BUSINESS_PROJECT_FORM,
	ROUTE_NAMES_CLASSIC_BUSINESS_PROJECT_FORM_SUBMIT,
	ROUTE_NAMES_CLASSIC_BUSINESS_PROJECT_FORM_BUILDER,
	ROUTE_NAMES_CLASSIC_BUSINESS_PROJECT_TIMELINE,
	ROUTE_NAMES_CLASSIC_BUSINESS_PROJECT_APPROVALS,
	ROUTE_NAMES_PROJECT_PAGES,
	ROUTE_NAMES_CLASSIC_BUSINESS_PROJECT_ATTACHMENTS,
	ROUTE_NAMES_CLASSIC_BUSINESS_PROJECT_TIMELINE_EMBED,
	ROUTE_NAMES_CLASSIC_BUSINESS_PROJECT_CALENDAR_EMBED,
	ROUTE_NAMES_CLASSIC_BUSINESS_PROJECT_LIST_EMBED,
	ROUTE_NAMES_CLASSIC_BUSINESS_PROJECT_BOARD_EMBED,
	ROUTE_NAMES_CLASSIC_BUSINESS_PROJECT_FORM_SUBMIT_EMBED,
	ROUTE_NAMES_CLASSIC_BUSINESS_PROJECT_SUMMARY_EMBED,
	ROUTE_NAMES_CLASSIC_BUSINESS_PROJECT_SAMPLE_ONBOARDING,
	ROUTE_NAMES_CLASSIC_BUSINESS_OVERVIEW_TIMELINE,
	ROUTE_NAMES_CLASSIC_BUSINESS_OVERVIEW_CALENDAR,
	ROUTE_NAMES_CLASSIC_BUSINESS_OVERVIEW_SUMMARY,
} from '@atlassian/jira-common-constants/src/spa-routes';

export const classicBusinessProjectSummaryRoute = {
	group: ROUTE_GROUPS_CLASSIC_BUSINESS,
	name: ROUTE_NAMES_CLASSIC_BUSINESS_PROJECT_SUMMARY,
	path: '/jira/core/projects/:projectKey/summary',
	exact: true,
};

export const classicBusinessProjectCalendarRoute = {
	group: ROUTE_GROUPS_CLASSIC_BUSINESS,
	name: ROUTE_NAMES_CLASSIC_BUSINESS_PROJECT_CALENDAR,
	path: '/jira/core/projects/:projectKey/calendar',
	exact: true,
};

export const classicBusinessProjectShortcutRoute = {
	group: ROUTE_GROUPS_CLASSIC_BUSINESS,
	name: ROUTE_NAMES_CLASSIC_BUSINESS_PROJECT_SHORTCUT,
	path: '/jira/core/projects/:projectKey/shortcuts',
	exact: true,
};

export const classicBusinessProjectListRoute = {
	group: ROUTE_GROUPS_CLASSIC_BUSINESS,
	name: ROUTE_NAMES_CLASSIC_BUSINESS_PROJECT_LIST,
	path: '/jira/core/projects/:projectKey/list',
	exact: true,
};

export const classicBusinessProjectFormRoute = {
	group: ROUTE_GROUPS_CLASSIC_BUSINESS,
	name: ROUTE_NAMES_CLASSIC_BUSINESS_PROJECT_FORM,
	path: '/jira/core/projects/:projectKey/form',
	exact: true,
};

export const classicBusinessProjectFormSubmitRoute = {
	group: ROUTE_GROUPS_CLASSIC_BUSINESS,
	name: ROUTE_NAMES_CLASSIC_BUSINESS_PROJECT_FORM_SUBMIT,
	path: '/jira/core/projects/:projectKey/form/:formId',
	exact: true,
};

export const classicBusinessProjectFormBuilderRoute = {
	group: ROUTE_GROUPS_CLASSIC_BUSINESS,
	name: ROUTE_NAMES_CLASSIC_BUSINESS_PROJECT_FORM_BUILDER,
	path: '/jira/core/projects/:projectKey/form/:formId/builder',
	exact: true,
};

export const classicBusinessProjectTimelineRoute = {
	group: ROUTE_GROUPS_CLASSIC_BUSINESS,
	name: ROUTE_NAMES_CLASSIC_BUSINESS_PROJECT_TIMELINE,
	path: '/jira/core/projects/:projectKey/timeline',
	exact: true,
};

export const classicBusinessProjectApprovalsRoute = {
	group: ROUTE_GROUPS_CLASSIC_BUSINESS,
	name: ROUTE_NAMES_CLASSIC_BUSINESS_PROJECT_APPROVALS,
	path: '/jira/core/projects/:projectKey/approvals',
	exact: true,
};

export const projectPagesRoute = {
	group: ROUTE_GROUPS_CLASSIC_BUSINESS,
	name: ROUTE_NAMES_PROJECT_PAGES,
	path: '/jira/core/projects/:projectKey/pages',
};
export const classicBusinessProjectAttachmentsRoute = {
	group: ROUTE_GROUPS_CLASSIC_BUSINESS,
	name: ROUTE_NAMES_CLASSIC_BUSINESS_PROJECT_ATTACHMENTS,
	path: '/jira/core/projects/:projectKey/attachments',
};

export const classicBusinessProjectTimelineEmbedRoute = {
	group: ROUTE_GROUPS_CLASSIC_BUSINESS,
	name: ROUTE_NAMES_CLASSIC_BUSINESS_PROJECT_TIMELINE_EMBED,
	path: '/jira/core/projects/:projectKey/timeline/embed',
	exact: true,
};

export const classicBusinessProjectCalendarEmbedRoute = {
	group: ROUTE_GROUPS_CLASSIC_BUSINESS,
	name: ROUTE_NAMES_CLASSIC_BUSINESS_PROJECT_CALENDAR_EMBED,
	path: '/jira/core/projects/:projectKey/calendar/embed',
	exact: true,
};

export const classicBusinessProjectListEmbedRoute = {
	group: ROUTE_GROUPS_CLASSIC_BUSINESS,
	name: ROUTE_NAMES_CLASSIC_BUSINESS_PROJECT_LIST_EMBED,
	path: '/jira/core/projects/:projectKey/list/embed',
	exact: true,
};

export const classicBusinessProjectBoardEmbedRoute = {
	group: ROUTE_GROUPS_CLASSIC_BUSINESS,
	name: ROUTE_NAMES_CLASSIC_BUSINESS_PROJECT_BOARD_EMBED,
	path: '/jira/core/projects/:projectKey/board/embed',
	exact: true,
};

export const classicBusinessProjectFormSubmitEmbedRoute = {
	group: ROUTE_GROUPS_CLASSIC_BUSINESS,
	name: ROUTE_NAMES_CLASSIC_BUSINESS_PROJECT_FORM_SUBMIT_EMBED,
	path: '/jira/core/projects/:projectKey/form/:formId/embed',
	exact: true,
};

export const classicBusinessProjectSummaryEmbedRoute = {
	group: ROUTE_GROUPS_CLASSIC_BUSINESS,
	name: ROUTE_NAMES_CLASSIC_BUSINESS_PROJECT_SUMMARY_EMBED,
	path: '/jira/core/projects/:projectKey/summary/embed',
	exact: true,
};

export const classicBusinessProjectSampleOnboardingRoute = {
	group: ROUTE_GROUPS_CLASSIC_BUSINESS,
	name: ROUTE_NAMES_CLASSIC_BUSINESS_PROJECT_SAMPLE_ONBOARDING,
	path: '/jira/core/sample/onboarding/:taskId',
	exact: true,
};

export const classicBusinessOverviewTimelineRoute = {
	group: ROUTE_GROUPS_CLASSIC_BUSINESS,
	name: ROUTE_NAMES_CLASSIC_BUSINESS_OVERVIEW_TIMELINE,
	path: '/jira/core/overviews/:overviewId/timeline',
	exact: true,
};

export const classicBusinessOverviewCalendarRoute = {
	group: ROUTE_GROUPS_CLASSIC_BUSINESS,
	name: ROUTE_NAMES_CLASSIC_BUSINESS_OVERVIEW_CALENDAR,
	path: '/jira/core/overviews/:overviewId/calendar',
	exact: true,
};

export const classicBusinessOverviewSummaryRoute = {
	group: ROUTE_GROUPS_CLASSIC_BUSINESS,
	name: ROUTE_NAMES_CLASSIC_BUSINESS_OVERVIEW_SUMMARY,
	path: '/jira/core/overviews/:overviewId/summary',
	exact: true,
};
