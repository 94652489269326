import React, { useMemo } from 'react';
import { ForgeScreenEvent } from '@atlassian/jira-forge-ui-analytics';
import { ForgeAnalyticsWrapper } from '@atlassian/jira-forge-ui-analytics/src/common/ui';
import { MODULE_TYPE_TRIGGER } from '@atlassian/jira-forge-ui-analytics/src/constants';
import type { ExtensionPointModule } from '@atlassian/jira-forge-ui-types';
import type { NestingMenuItemProps } from '@atlassian/jira-navigation-apps-sidebar-common';
import { useFilteredItems } from '@atlassian/jira-navigation-apps-sidebar-common/src/controllers/use-filtered-connect-items';
import { ConnectItem, type ConnectDataItem } from '@atlassian/jira-navigation-apps-sidebar-connect';
import type { ForgeItemProps } from '@atlassian/jira-navigation-apps-sidebar-forge/src/common/types';
import { ForgeItem } from '@atlassian/jira-navigation-apps-sidebar-forge/src/common/ui/forge-item';
import type { ForgeProjectSettingsItem } from '@atlassian/jira-navigation-apps-sidebar-forge/src/controllers/project-settings';

export type EcosystemMenuProps = {
	hasSeparator?: boolean;
	shouldFilter?: boolean;
	forgeModule: ExtensionPointModule;
	items: {
		connect: ConnectDataItem[] | null;
		forge: ForgeItemProps[] | null;
	};
	overrides: NestingMenuItemProps['overrides'];
	showIcons?: boolean;
	submenuId?: string;
	nestedMenuActive?: boolean;
};

type InnerEcosystemMenuProps = Flow.Diff<
	EcosystemMenuProps,
	{
		forgeModule: ExtensionPointModule;
	}
>;
export const EcosystemMenu: Flow.AbstractComponent<InnerEcosystemMenuProps> = ({
	items,
	overrides,
	showIcons = false,
	shouldFilter = true,
	submenuId = '',
	nestedMenuActive = true,
}: InnerEcosystemMenuProps) => {
	const forgeItems = useMemo(() => items.forge || [], [items.forge]);
	const connectItems = useFilteredItems(items.connect, shouldFilter);

	const sortedItems = useMemo(
		() =>
			[
				...forgeItems.map((item) => ({ type: 'forge', item })),
				...connectItems.map((item) => ({ type: 'connect', item })),
			].sort((a, b) => a.item.name.localeCompare(b.item.name)),
		[connectItems, forgeItems],
	);

	if (!sortedItems.length) {
		return null;
	}

	return (
		<>
			{sortedItems.map(({ type, item }, index) => {
				if (type === 'forge') {
					// @ts-expect-error - TS2322 - Type 'ConnectDataItem | ForgeItemProps' is not assignable to type 'ForgeProjectSettingsItem'.
					const forgeItem: ForgeProjectSettingsItem = item;
					return (
						<ForgeItem
							key={forgeItem.id}
							{...forgeItem}
							overrides={overrides}
							showIcon={showIcons}
							nestedMenuActive={nestedMenuActive}
						/>
					);
				}
				const connectItem: ConnectDataItem = item;
				return (
					<ConnectItem
						item={connectItem}
						key={connectItem.id}
						index={index}
						overrides={overrides}
						showIcons={showIcons}
						submenuId={submenuId}
					/>
				);
			})}
			{forgeItems.length ? <ForgeScreenEvent /> : null}
		</>
	);
};
// eslint-disable-next-line jira/import/no-anonymous-default-export
export default ({ forgeModule, ...restProps }: EcosystemMenuProps) => (
	<ForgeAnalyticsWrapper module={forgeModule} moduleType={MODULE_TYPE_TRIGGER}>
		<EcosystemMenu {...restProps} />
	</ForgeAnalyticsWrapper>
);
