import {
	ROUTE_GROUPS_PROJECT_SETTINGS_CLASSIC,
	ROUTE_NAMES_CLASSIC_PROJECT_SETTINGS_ISSUE_LAYOUT,
} from '@atlassian/jira-common-constants/src/spa-routes';

export const classicProjectSettingsIssueLayoutRoute = {
	group: ROUTE_GROUPS_PROJECT_SETTINGS_CLASSIC,
	name: ROUTE_NAMES_CLASSIC_PROJECT_SETTINGS_ISSUE_LAYOUT,
	path: '/plugins/servlet/project-config/:projectKey/issuelayout',
	query: ['screenId'],
	exact: true,
};
