import {
	ROUTE_GROUPS_COMPONENTS,
	ROUTE_NAMES_CREATE_PROJECT,
} from '@atlassian/jira-common-constants/src/spa-routes';

export const createProjectRoute = {
	group: ROUTE_GROUPS_COMPONENTS,
	name: ROUTE_NAMES_CREATE_PROJECT,
	path: '/jira/create-project',
	exact: true,
};
