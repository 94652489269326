import React from 'react';
import { useIssueKey } from '@atlassian/jira-issue-context-service';
import {
	useLeftSidebarController,
	useLeftSidebarState,
	useRightSidebarController,
} from '@atlassian/jira-layout-controller';
import { createStore, createHook, createContainer } from '@atlassian/react-sweet-state';
import { EMBEDDED_CONFLUENCE_SIDEPANEL_CONTAINER_ID } from '../../constants';
import { actions, initialState } from './actions';
import {
	DisplayTypes,
	type OpenSidePanelCoreParams,
	type EmbeddedConfluenceState,
	type UseEmbeddedConfluenceSidePanelType,
} from './types';
import { getInitialEmbeddedConfluencePanelWidth } from './utils';

export type Actions = typeof actions;

/**
 * Container for {@link useEmbeddedConfluenceSidePanel}. Any component that uses this hook
 * must be wrapped in this container.
 */
const Container = createContainer();

const Store = createStore<EmbeddedConfluenceState, Actions>({
	actions,
	containedBy: Container,
	initialState,
	name: EMBEDDED_CONFLUENCE_SIDEPANEL_CONTAINER_ID,
});

export const EmbeddedConfluenceSidepanelContainer = ({
	children,
}: {
	children: React.ReactNode;
}) => {
	const issueKey = useIssueKey();

	/*
	In order for each usage of the store to manage the same state irregardless of
	their location in the component tree, we need to wrap components in a container
	with the same scope value. This is why we use the issueKey in the scope value.
	see https://atlassian.github.io/react-sweet-state/#/advanced/container?id=scoped-data-sharing
	for more information about scoped data sharing.

	*/
	return <Container scope={`embedded-confluence-sidepanel-${issueKey}`}>{children}</Container>;
};

const storeHook = createHook(Store);

export const useEmbeddedConfluenceSidePanel: UseEmbeddedConfluenceSidePanelType = (
	embeddedConfluenceSource?: string,
) => {
	const [state, storeActions] = storeHook();
	const { isCollapsed } = useLeftSidebarState();
	const { collapseLeftSidebar, expandLeftSidebar } = useLeftSidebarController();
	const { closeRightSidebar, openRightSidebar } = useRightSidebarController();

	const openSidePanel = ({
		contentType,
		data,
		mode,
		isLive,
		isFailedRemoteLink,
		isDraft,
		url,
	}: OpenSidePanelCoreParams) => {
		const initialWidth = getInitialEmbeddedConfluencePanelWidth();
		const shouldOpenModal = initialWidth === 0;
		const displayType = shouldOpenModal ? DisplayTypes.MODAL : DisplayTypes.PANEL;

		if (!isCollapsed && displayType === DisplayTypes.PANEL) {
			collapseLeftSidebar();
		}
		storeActions.openSidePanel({
			contentType,
			embeddedConfluenceSource,
			data,
			displayType,
			initialWidth,
			isDraft,
			isFailedRemoteLink,
			isLive,
			mode,
			shouldOpenModal,
			url,
			wasLeftNavOpen: !shouldOpenModal && !isCollapsed,
		});

		openRightSidebar(EMBEDDED_CONFLUENCE_SIDEPANEL_CONTAINER_ID, initialWidth, false, true);
	};

	const closeSidePanel = () => {
		const {
			panel: { wasLeftNavOpen },
		} = state;
		if (wasLeftNavOpen) {
			expandLeftSidebar();
		}
		closeRightSidebar(EMBEDDED_CONFLUENCE_SIDEPANEL_CONTAINER_ID);

		storeActions.closeSidePanel();
	};
	return [state, { openSidePanel, closeSidePanel }];
};
