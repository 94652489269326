import type {
	AriGraphFilter,
	AriGraphFilterShape,
} from '@atlassian/jira-devops-ari-graph-filters/src/types.tsx';
import type {
	QUERY_PARAM_SEARCH,
	QUERY_PARAM_CONTAINER,
	QUERY_PARAM_SEVERITY,
	QUERY_PARAM_VULN_STATUS,
	QUERY_PARAM_ISSUE_STATUS,
	QUERY_PARAM_SORT_KEY,
	QUERY_PARAM_SORT_ORDER,
} from './constants';

export type QueryParams = {
	[QUERY_PARAM_SEARCH]: string | null | undefined;
	[QUERY_PARAM_CONTAINER]: string | null | undefined;
	[QUERY_PARAM_SEVERITY]: string | null | undefined;
	[QUERY_PARAM_VULN_STATUS]: string | null | undefined;
	[QUERY_PARAM_ISSUE_STATUS]: string | null | undefined;
	[QUERY_PARAM_SORT_KEY]: string | null | undefined;
	[QUERY_PARAM_SORT_ORDER]: string | null | undefined;
};

// This is returned type from useQueryParams hook
export type SearchQueryParams = {
	[QUERY_PARAM_SEARCH]?: string;
	[QUERY_PARAM_CONTAINER]?: string;
	[QUERY_PARAM_SEVERITY]?: string;
	[QUERY_PARAM_VULN_STATUS]?: string;
	[QUERY_PARAM_ISSUE_STATUS]?: string;
	[QUERY_PARAM_SORT_KEY]?: string;
	[QUERY_PARAM_SORT_ORDER]?: string;
};

export const VulnerabilitySeverityEnums = {
	CRITICAL: 'CRITICAL',
	HIGH: 'HIGH',
	LOW: 'LOW',
	MEDIUM: 'MEDIUM',
	UNKNOWN: 'UNKNOWN',
} as const;

export type VulnerabilitySeverityEnums =
	(typeof VulnerabilitySeverityEnums)[keyof typeof VulnerabilitySeverityEnums];

export const VulnerabilityStatusEnums = {
	CLOSED: 'CLOSED',
	IGNORED: 'IGNORED',
	OPEN: 'OPEN',
	UNKNOWN: 'UNKNOWN',
} as const;

export type VulnerabilityStatusEnums =
	(typeof VulnerabilityStatusEnums)[keyof typeof VulnerabilityStatusEnums];

export const VulnerabilityTypeEnums = {
	DAST: 'DAST',
	SAST: 'SAST',
	SCA: 'SCA',
	UNKNOWN: 'UNKNOWN',
} as const;

export type VulnerabilityTypeEnums =
	(typeof VulnerabilityTypeEnums)[keyof typeof VulnerabilityTypeEnums];

export type VulnerabilityFilters = {
	to_severity?: AriGraphFilter<VulnerabilitySeverityEnums>;
	to_status?: AriGraphFilter<VulnerabilityStatusEnums>;
	to_type?: AriGraphFilter<VulnerabilityTypeEnums>;
	to_container?: {
		containerAri: {
			value: AriGraphFilter<string>;
		};
	};
};

export type AriGraphVulnerabilitiesFilter = AriGraphFilterShape<VulnerabilityFilters>;
