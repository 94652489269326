import {
	ROUTE_GROUPS_SOFTWARE,
	ROUTE_GROUPS_CLASSIC_SOFTWARE,
	ROUTE_NAMES_SOFTWARE_DEPLOYMENTS,
	ROUTE_NAMES_SOFTWARE_DEPLOYMENTS_CLASSIC,
} from '@atlassian/jira-common-constants/src/spa-routes';

export const softwareDeploymentsRoute = {
	group: ROUTE_GROUPS_SOFTWARE,
	name: ROUTE_NAMES_SOFTWARE_DEPLOYMENTS,
	path: '/jira/software/projects/:projectKey/deployments',
};

export const softwareDeploymentsClassicRoute = {
	group: ROUTE_GROUPS_CLASSIC_SOFTWARE,
	name: ROUTE_NAMES_SOFTWARE_DEPLOYMENTS_CLASSIC,
	path: '/jira/software/c/projects/:projectKey/deployments',
};
