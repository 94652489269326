import { createResource, useResource } from '@atlassian/jira-router';
import { getProjectKeyOrId } from '@atlassian/jira-router-resources-utils/src/utils/get-project-key-or-id/index.tsx';

const getDataLoader = () =>
	import(
		/* webpackChunkName: "escalations/component-filter-resource", jiraSpaEntry: "escalations/component-filter-resource" */ './get-data'
	);

export type ComponentFilterData = {
	self: string;
	id: string;
	name: string;
	description: string;
	lead: {
		self: string;
		accountId: string;
		avatarUrls: {
			'48x48': string;
			'24x24': string;
			'16x16': string;
			'32x32': string;
		};
		displayName: string;
		active: boolean;
	};
	assigneeType: string;
	realAssigneeType: string;
	isAssigneeTypeValid: boolean;
	project: string;
	projectId: number;
}[];

export const componentFilterResource = createResource<ComponentFilterData>({
	type: 'ESCALATIONS_COMPONENT_FILTER_RESOURCE',
	getKey: ({ match }) => getProjectKeyOrId(match),
	getDataLoader,
});

export const useComponentFilterResource = () =>
	useResource<ComponentFilterData>(componentFilterResource);
