import { ff } from '@atlassian/jira-feature-flagging';
import { createRelayResource } from '@atlassian/jira-relay-utils/src/services/resources/index.tsx';
// eslint-disable-next-line jira/import-whitelist -- Unclear how to change the config whilst we transition to flat config
import UI_CUSTOMER_DETAILS_PAGE_QUERY from '@atlassian/jira-relay/src/__generated__/uiCustomerDetailsPageQuery$parameters';
import type { uiCustomerDetailsPageQuery } from '@atlassian/jira-relay/src/__generated__/uiCustomerDetailsPageQuery.graphql';

export const uiCustomerDetailsPageQueryResource = createRelayResource<uiCustomerDetailsPageQuery>({
	type: 'UI_CUSTOMER_DETAILS_PAGE_QUERY',
	getQuery: ({ match }, { tenantContext }) => ({
		parameters: UI_CUSTOMER_DETAILS_PAGE_QUERY,
		variables: {
			accountId: String(match.params.customerId),
			cloudId: tenantContext.cloudId,
			includePlatformValue: ff('csm-shipit-58-user-custom-detail-type_be1zw'),
		},
		options: { fetchPolicy: 'store-and-network' },
	}),
});
