import { isFedRamp } from '@atlassian/atl-context';
import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { globalSettingsLayout } from '@atlassian/jira-global-settings-layout';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarGlobalSettingsProducts from '@atlassian/jira-navigation-apps-sidebar-global-settings-products/src/async';
import {
	AsyncHorizontalOperationsConfigurationNav,
	LazyHorizontalOperationsConfigurationNav,
} from '@atlassian/jira-operations-configurations-horizontal-nav/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry';
import {
	getNavigationResources,
	getNavigationSidebarProductsSettingResource,
} from '@atlassian/jira-router-resources-navigation';
import ErrorPagesNotFound from '@atlassian/jira-router-routes-common/src/ui/error-pages/not-found/index.tsx';
import { productsJsmChatopsAndVideosToolsRoute } from '@atlassian/jira-router-routes-products-jsm-chatops-and-videos-tools-routes';
import ProductsJsmChatopsAndVideoTools, { LazyProductsJsmChatopsAndVideoTools } from './ui';

export const productsJsmChatopsAndVideosToolsRouteEntry = createEntry(
	productsJsmChatopsAndVideosToolsRoute,
	{
		component: componentWithCondition(
			isFedRamp,
			ErrorPagesNotFound,
			ProductsJsmChatopsAndVideoTools,
		),
		layout: globalSettingsLayout,

		navigation: {
			menuId: MENU_ID.SETTINGS,
			sidebar: AsyncSidebarGlobalSettingsProducts,
			horizontal: AsyncHorizontalOperationsConfigurationNav,
			horizontalFeatureFlag: 'platform.alx-8775-operations-horizontal-nav-platform_42yfm',
		},

		resources: [...getNavigationResources(), ...getNavigationSidebarProductsSettingResource()],
		forPaint: [
			LazyHorizontalOperationsConfigurationNav,
			LazyAtlassianNavigation,
			LazyProductsJsmChatopsAndVideoTools,
		],
	},
);
