import {
	ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK,
	ROUTE_NAMES_PROJECT_SETTINGS_SERVICEDESK_CUSTOMER_NOTIFICATIONS,
} from '@atlassian/jira-common-constants/src/spa-routes';

export const SERVICE_DESK_PRODUCT = 'servicedesk';
const projectSettingsUrl = (product: string) => `/jira/${product}/projects/:projectKey/settings`;
export const routeGroupsProjectSettingsServicedeskCustomerNotificationsRoute = {
	group: ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK,
	name: ROUTE_NAMES_PROJECT_SETTINGS_SERVICEDESK_CUSTOMER_NOTIFICATIONS,
	path: `${projectSettingsUrl(SERVICE_DESK_PRODUCT)}/customer-notifications`,
};

export const routeGroupsProjectSettingsServicedeskNotificationsCustomerNotificationsRoute = {
	group: ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK,
	name: `${ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK}-notifications-customer-notifications`,
	path: `${projectSettingsUrl(SERVICE_DESK_PRODUCT)}/notifications/customer-notifications`,
};
