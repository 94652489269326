import {
	type IssueId,
	toAccountId,
	toIssueId,
	type AccountId,
} from '@atlassian/jira-shared-types/src/general.tsx';
import {
	QUERY_PARAM_TEXT,
	QUERY_PARAM_EPIC,
	QUERY_PARAM_STATUS_CATEGORY,
	QUERY_PARAM_WARNINGS,
	QUERY_PARAM_ASSIGNEE,
	QUERY_PARAM_SORT_BY,
	QUERY_PARAM_SORT_ORDER,
} from './constants';
import {
	isVersionStatusCategory,
	toWarningsFilterValue,
	isSortBy,
	isSortOrder,
	type WarningsCategory,
	type ParsedQueryParams,
	type SearchQueryParams,
	type VersionStatusCategory,
} from './types';

export const isAnyFilterApplied = (parsedQueryParams: ParsedQueryParams): boolean =>
	(parsedQueryParams[QUERY_PARAM_TEXT] != null && parsedQueryParams[QUERY_PARAM_TEXT].length > 0) ||
	(parsedQueryParams[QUERY_PARAM_EPIC] != null && parsedQueryParams[QUERY_PARAM_EPIC].length > 0) ||
	(parsedQueryParams[QUERY_PARAM_STATUS_CATEGORY] != null &&
		parsedQueryParams[QUERY_PARAM_STATUS_CATEGORY].length > 0) ||
	(parsedQueryParams[QUERY_PARAM_WARNINGS] != null &&
		parsedQueryParams[QUERY_PARAM_WARNINGS].length > 0) ||
	(parsedQueryParams[QUERY_PARAM_ASSIGNEE] != null &&
		parsedQueryParams[QUERY_PARAM_ASSIGNEE].length > 0);

export const parseEpicFilterParam = (paramValue: string | undefined): IssueId[] | undefined =>
	paramValue == null
		? undefined
		: paramValue
				.split(',')
				.map((str) => str.trim())
				.map(toIssueId);

export const parseStatusCategoryParam = (
	paramValue: string | undefined,
): VersionStatusCategory[] | undefined =>
	paramValue == null
		? undefined
		: paramValue
				.split(',')
				.map((str) => str.trim())
				.filter(isVersionStatusCategory);

export const parseWarningCategoryParam = (
	paramValue: string | undefined,
): WarningsCategory[] | undefined =>
	paramValue == null
		? undefined
		: toWarningsFilterValue(paramValue.split(',').map((str) => str.trim()));

export const parseAssigneeParam = (paramValue: string | undefined): AccountId[] | undefined =>
	paramValue == null ? undefined : paramValue.split(',').map((str) => toAccountId(str.trim()));

export const parseQueryParams = (paramsRaw: SearchQueryParams): ParsedQueryParams => {
	const result: ParsedQueryParams = {};

	result[QUERY_PARAM_TEXT] = paramsRaw[QUERY_PARAM_TEXT];
	result[QUERY_PARAM_EPIC] = parseEpicFilterParam(paramsRaw[QUERY_PARAM_EPIC]);
	result[QUERY_PARAM_STATUS_CATEGORY] = parseStatusCategoryParam(
		paramsRaw[QUERY_PARAM_STATUS_CATEGORY],
	);
	result[QUERY_PARAM_WARNINGS] = parseWarningCategoryParam(paramsRaw[QUERY_PARAM_WARNINGS]);
	result[QUERY_PARAM_ASSIGNEE] = parseAssigneeParam(paramsRaw[QUERY_PARAM_ASSIGNEE]);
	result[QUERY_PARAM_SORT_BY] =
		paramsRaw[QUERY_PARAM_SORT_BY] && isSortBy(paramsRaw[QUERY_PARAM_SORT_BY])
			? paramsRaw[QUERY_PARAM_SORT_BY]
			: 'CREATED';

	result[QUERY_PARAM_SORT_ORDER] =
		paramsRaw[QUERY_PARAM_SORT_ORDER] && isSortOrder(paramsRaw[QUERY_PARAM_SORT_ORDER])
			? paramsRaw[QUERY_PARAM_SORT_ORDER]
			: 'ASC';

	return result;
};
