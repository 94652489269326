import {
	ROUTE_GROUPS_POLARIS,
	ROUTE_GROUPS_GLOBAL_SETTINGS,
	ROUTE_NAMES_POLARIS_IDEAS,
	ROUTE_NAMES_POLARIS_COLLECTIONS,
	ROUTE_NAMES_POLARIS_ONBOARDING,
	ROUTE_NAMES_POLARIS_SETTINGS_ACCESS,
	ROUTE_NAMES_POLARIS_SETTINGS_GLOBAL_FIELDS,
	ROUTE_NAMES_POLARIS_UNLICENSED,
} from '@atlassian/jira-common-constants/src/spa-routes';

export const polarisIdeasRoute = {
	group: ROUTE_GROUPS_POLARIS,
	name: ROUTE_NAMES_POLARIS_IDEAS,
	path: '/jira/polaris/projects/:projectKey/ideas/:section?/:resource?',
	exact: true,
};

export const polarisCollectionsRoute = {
	group: ROUTE_GROUPS_POLARIS,
	name: ROUTE_NAMES_POLARIS_COLLECTIONS,
	path: '/jira/polaris/roadmaps/:collectionUUID?/:section?/:resource?',
	exact: true,
};

export const polarisOnboardingRoute = {
	group: ROUTE_GROUPS_POLARIS,
	name: ROUTE_NAMES_POLARIS_ONBOARDING,
	path: '/welcome/discovery',
	exact: true,
};

export const polarisSettingsAccessRoute = {
	group: ROUTE_GROUPS_GLOBAL_SETTINGS,
	name: ROUTE_NAMES_POLARIS_SETTINGS_ACCESS,
	path: '/jira/settings/products/jira-product-discovery/access',
	exact: true,
};

export const polarisSettingsGlobalFieldsRoute = {
	group: ROUTE_GROUPS_GLOBAL_SETTINGS,
	name: ROUTE_NAMES_POLARIS_SETTINGS_GLOBAL_FIELDS,
	path: '/jira/settings/products/jira-product-discovery/global-fields',
	exact: true,
};

export const polarisUnlicensedRoute = {
	group: ROUTE_GROUPS_POLARIS,
	name: ROUTE_NAMES_POLARIS_UNLICENSED,
	path: '/jira/polaris/unlicensed',
	exact: true,
};
