import {
	ROUTE_GROUPS_REDIRECTS,
	ROUTE_NAMES_PROJECTS_REDIRECT,
} from '@atlassian/jira-common-constants/src/spa-routes';

export const projectsRedirectRoute = {
	group: ROUTE_GROUPS_REDIRECTS,
	name: ROUTE_NAMES_PROJECTS_REDIRECT,
	path: '/projects/:projectKey',
	query: ['selectedItem!=(.+)'],
	exact: true,
};
