import {
	ROUTE_GROUPS_SOFTWARE,
	ROUTE_NAMES_SOFTWARE_REQUESTS,
	ROUTE_NAMES_SOFTWARE_REQUESTS_CLASSIC,
} from '@atlassian/jira-common-constants/src/spa-routes';

export const softwareRequestsRoute = {
	group: ROUTE_GROUPS_SOFTWARE,
	name: ROUTE_NAMES_SOFTWARE_REQUESTS,
	path: '/jira/software/projects/:projectKey/requests',
};

export const softwareRequestsClassicRoute = {
	group: ROUTE_GROUPS_SOFTWARE,
	name: ROUTE_NAMES_SOFTWARE_REQUESTS_CLASSIC,
	path: '/jira/software/c/projects/:projectKey/requests',
};
