// eslint-disable-next-line jira/import-whitelist
import { isGadgetConfigured } from '@atlassian/jira-dashboard-common/src/utils/is-gadget-configured.tsx';
// eslint-disable-next-line jira/import-whitelist
import GADGETS_DEFINITION, {
	type DashboardReactGadgetsResourceData,
	type ReactGadgetsResourceSharedValue,
} from '@atlassian/jira-react-gadgets-definition/src/main.tsx';
import type { RouterDataContext, ResourceStoreContext } from '@atlassian/jira-router';
import { SharedValueAggregateError, type DataOrErrorOrNull } from '../common/dependent-resources';
import { loadReactGadgets } from '../common/load-react-gadgets';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (
		maybePrevData: DataOrErrorOrNull<DashboardReactGadgetsResourceData>,
		sharedValue: DataOrErrorOrNull<ReactGadgetsResourceSharedValue>,
	) =>
	(
		{ isPrefetch }: RouterDataContext,
		spaContext: ResourceStoreContext,
	): DashboardReactGadgetsResourceData | Promise<DashboardReactGadgetsResourceData> => {
		if (!sharedValue || sharedValue instanceof Error) {
			throw new SharedValueAggregateError({
				sharedValue,
				isPrefetch,
				name: 'AMD modules resource',
			});
		}

		return (async () => {
			const { reactGadgets: nextGadgets } = sharedValue;

			const prev =
				(maybePrevData != null &&
					!(maybePrevData instanceof Error) &&
					maybePrevData.reactGadgetsState) ||
				{};

			const persistedGadgets = nextGadgets.filter(({ id }) => !!prev[id]);
			const gadgetsToLoad = nextGadgets.filter((gadget) => {
				// componentEdit will be optional in the future, although currently types say that it's required
				const isConfigurable = !!GADGETS_DEFINITION[gadget.reactKey].componentEdit;
				const isUnconfigured = isConfigurable && !isGadgetConfigured(gadget.userPrefs);

				return !prev[gadget.id] && !isUnconfigured;
			});
			const skippedGadgets = nextGadgets.filter(
				(gadget) => !prev[gadget.id] && !gadgetsToLoad.some(({ id }) => id === gadget.id),
			);

			const reactGadgetsState = {
				...Object.fromEntries(persistedGadgets.map(({ id }) => [id, prev[id]])),
				...Object.fromEntries(skippedGadgets.map(({ id }) => [id, {}])),
				...(await loadReactGadgets(gadgetsToLoad, spaContext)),
			};

			return { sharedValue, reactGadgetsState };
		})();
	};
