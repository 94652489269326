import {
	ROUTE_GROUPS_SOFTWARE,
	ROUTE_NAMES_SOFTWARE_ONBOARDING,
} from '@atlassian/jira-common-constants/src/spa-routes';

export const softwareOnboardingRoute = {
	group: ROUTE_GROUPS_SOFTWARE,
	name: ROUTE_NAMES_SOFTWARE_ONBOARDING,
	path: '/welcome/software',
	exact: true,
};
