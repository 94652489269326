import {
	ROUTE_GROUPS_PROJECT_SETTINGS_CLASSIC,
	ROUTE_NAMES_CLASSIC_PROJECT_SETTINGS_DATA_CLASSIFICATIONS,
} from '@atlassian/jira-common-constants/src/spa-routes';

export const classicProjectSettingsDataClassificationsRoute = {
	group: ROUTE_GROUPS_PROJECT_SETTINGS_CLASSIC,
	name: ROUTE_NAMES_CLASSIC_PROJECT_SETTINGS_DATA_CLASSIFICATIONS,
	path: '/plugins/servlet/project-config/:projectKey/data-classifications',
	exact: true,
};
