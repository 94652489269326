import queryString from 'query-string';
import { fetchJson } from '@atlassian/jira-fetch';
import {
	createResource,
	type RouterDataContext,
	type ResourceStoreContext,
} from '@atlassian/jira-router';

export const RESOURCE_TYPE_JIRA_SEARCH = 'RESOURCE_TYPE_JIRA_SEARCH';
export type JiraSearchResponse = {
	url: string;
};

export const jiraSearchResource = createResource<JiraSearchResponse | null>({
	type: RESOURCE_TYPE_JIRA_SEARCH,
	getKey: () => 'search',
	getData: ({ query }: RouterDataContext, { tenantContext }: ResourceStoreContext) => {
		const { atlassianAccountId } = tenantContext;

		if (!atlassianAccountId || !query?.searchString) {
			return null;
		}

		const queryStr = queryString.stringify({ searchString: query.searchString });

		return fetchJson(`/rest/navigation/4/quicksearch?${queryStr}`);
	},
	isBrowserOnly: true,
});
