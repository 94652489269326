import {
	loadQuery,
	type LoadQueryOptions,
	type PreloadableConcreteRequest,
	type PreloadedQuery,
} from 'react-relay';
import {
	getRequest,
	type GraphQLTaggedNode,
	type OperationType,
	type VariablesOf,
} from 'relay-runtime';
import { setMark, measureFunc, setMeasure } from '@atlassian/jira-common-performance/src/marks.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import getRelayEnvironment from '@atlassian/jira-relay-environment';
import { startCaptureGraphQlErrors } from '@atlassian/jira-relay-errors';
import { QueryPromisesMap } from '@atlassian/jira-relay-query-promises';
import {
	createResource,
	type ResourceStoreContext,
	type RouterContext,
	type RouterDataContext,
	type RouteResource,
	useResource,
} from '@atlassian/jira-router';
import { startCapturingTraceIds } from '@atlassian/relay-traceid';

/**
 * @deprecated Use Relay EntryPoint instead https://hello.atlassian.net/wiki/spaces/UAF/pages/2754170095
 */
export const RELAY_RESOURCE_TYPE = 'RELAY_RESOURCE_TYPE';

type Config<TQuery extends OperationType> = {
	parameters: GraphQLTaggedNode | PreloadableConcreteRequest<TQuery>;
	variables?: VariablesOf<TQuery>;
	options?: LoadQueryOptions;
};

type CreateRelayResourceConfig<TQuery extends OperationType> = {
	type: string;
	isBrowserOnly?: boolean;
	getQuery: (arg1: RouterContext | RouterDataContext, arg2: ResourceStoreContext) => Config<TQuery>;
	loadParameters?: () => Promise<GraphQLTaggedNode | PreloadableConcreteRequest<TQuery>>;
	captureErrors?: boolean;
	captureTraceIds?: boolean;
};

type GetDataConfig<TQuery extends OperationType> = {
	routerContext: RouterContext;
	customContext: ResourceStoreContext;
	type: string;
	loadParameters?: () => Promise<GraphQLTaggedNode | PreloadableConcreteRequest<TQuery>>;
	getQuery: (arg1: RouterContext | RouterDataContext, arg2: ResourceStoreContext) => Config<TQuery>;
	captureErrors?: boolean;
	captureTraceIds?: boolean;
};

const getDataOld = async <TQuery extends OperationType>({
	routerContext,
	customContext,
	type,
	getQuery,
	loadParameters,
	captureErrors,
	captureTraceIds,
}: GetDataConfig<TQuery>) => {
	const measureName = `relay_resource_get_data:${type}`;
	const startMark = `relay_resource_get_data:${type}:start`;
	const endMark = `relay_resource_get_data:${type}:end`;

	setMark(startMark);

	const { parameters, variables, options } = getQuery(routerContext, customContext);

	// We either have a sync query or an async one from loadParameters. Never neither
	// This is enforced by 2 aliases of this method having different types, but Typescript cannot infer that neatly, so we cast away the nullable case.
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	const query = (parameters ?? (await loadParameters?.())) as
		| GraphQLTaggedNode
		| PreloadableConcreteRequest<TQuery>;

	// loadQuery returns not a Promise, but Observable.
	// Resource waits until query Observable is completed and returns a queryReference
	// later queryReference could be used by usePreloadedQuery
	const queryReference = measureFunc(`relay_resource_load_query:${type}`, () =>
		loadQuery(getRelayEnvironment(), query, variables || {}, options),
	);

	if (__SERVER__ && queryReference.id != null) {
		await QueryPromisesMap.get(queryReference.id);
	}

	if (queryReference.id != null) {
		QueryPromisesMap.get(queryReference.id)?.then(() => {
			setMark(endMark);
			setMeasure(measureName, startMark, endMark);
		});
	}

	/**
	 * We want to store HTTP codes from fetch response so that we can use it to report SLA correctly.
	 * (Unfortunately errors thrown by relay client validation doesn't include HTTP codes at the moment, so we have to store it ourselves)
	 * Starting to capture errors here ensures we capture errors for the early fetch of the query on initial page load,
	 * as well as subsequent fetches made by route transitions between spa-apps.
	 */
	if (captureErrors) {
		startCaptureGraphQlErrors(queryReference.fetchKey.toString());
	}

	if (captureTraceIds) {
		startCapturingTraceIds(queryReference.name);
	}

	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	return queryReference as PreloadedQuery<TQuery>;
};

const getDataNew = <TQuery extends OperationType>({
	routerContext,
	customContext,
	type,
	getQuery,
	captureErrors,
	captureTraceIds,
}: GetDataConfig<TQuery>) => {
	const measureName = `relay_resource_get_data:${type}`;
	const startMark = `relay_resource_get_data:${type}:start`;
	const endMark = `relay_resource_get_data:${type}:end`;

	setMark(startMark);

	const { parameters, variables, options } = getQuery(routerContext, customContext);

	// loadQuery returns not a Promise, but Observable.
	// Resource waits until query Observable is completed and returns a queryReference
	// later queryReference could be used by usePreloadedQuery
	const queryReference = measureFunc(`relay_resource_load_query:${type}`, () =>
		loadQuery(getRelayEnvironment(), parameters, variables || {}, options),
	);

	if (__SERVER__ && queryReference.id != null) {
		const promise = QueryPromisesMap.get(queryReference.id);
		if (promise) {
			return promise.then(() => {
				setMark(endMark);
				setMeasure(measureName, startMark, endMark);

				/**
				 * We want to store HTTP codes from fetch response so that we can use it to report SLA correctly.
				 * (Unfortunately errors thrown by relay client validation doesn't include HTTP codes at the moment, so we have to store it ourselves)
				 * Starting to capture errors here ensures we capture errors for the early fetch of the query on initial page load,
				 * as well as subsequent fetches made by route transitions between spa-apps.
				 */
				if (captureErrors) {
					startCaptureGraphQlErrors(queryReference.fetchKey.toString());
				}

				if (captureTraceIds) {
					startCapturingTraceIds(queryReference.name);
				}

				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				return queryReference as PreloadedQuery<TQuery>;
			});
		}
	}

	if (queryReference.id != null) {
		QueryPromisesMap.get(queryReference.id)?.then(() => {
			setMark(endMark);
			setMeasure(measureName, startMark, endMark);
		});
	}

	/**
	 * We want to store HTTP codes from fetch response so that we can use it to report SLA correctly.
	 * (Unfortunately errors thrown by relay client validation doesn't include HTTP codes at the moment, so we have to store it ourselves)
	 * Starting to capture errors here ensures we capture errors for the early fetch of the query on initial page load,
	 * as well as subsequent fetches made by route transitions between spa-apps.
	 */
	if (captureErrors) {
		startCaptureGraphQlErrors(queryReference.fetchKey.toString());
	}

	if (captureTraceIds) {
		startCapturingTraceIds(queryReference.name);
	}

	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	return queryReference as PreloadedQuery<TQuery>;
};

const createRelayResourceImpl = <TQuery extends OperationType>({
	type,
	isBrowserOnly,
	getQuery,
	loadParameters,
	captureErrors = false,
	captureTraceIds = false,
}: CreateRelayResourceConfig<TQuery>): RouteResource<PreloadedQuery<TQuery>> =>
	createResource({
		type: `${RELAY_RESOURCE_TYPE}_${type}`,
		getKey: (routerContext: RouterContext, customContext: ResourceStoreContext) => {
			const { parameters: preloadableRequest, variables } = getQuery(routerContext, customContext);
			// When providing an async query via `loadParameters` instead of getQuery's config, the query ID is not known upfront and so cannot be contributed to the resource key
			if (!preloadableRequest) {
				return JSON.stringify(variables || {});
			}

			let queryId;
			let params;

			// sources: https://github.com/facebook/relay/blob/4b78c7dd27b286f9ee8f5b993ccb160163956999/packages/react-relay/relay-hooks/loadQuery.js#L273
			// @ts-expect-error - TS2339 - Property 'kind' does not exist on type 'GraphQLTaggedNode | PreloadableConcreteRequest<TQuery>'.
			if (preloadableRequest.kind === 'PreloadableConcreteRequest') {
				const preloadableConcreteRequest: PreloadableConcreteRequest<TQuery> =
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
					preloadableRequest as any;
				({ params } = preloadableConcreteRequest);
				({ id: queryId } = params);
			} else {
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
				const graphQlTaggedNode: GraphQLTaggedNode = preloadableRequest as any;
				const request = getRequest(graphQlTaggedNode);
				params = request.params;
				queryId = 'cacheID' in params && params.cacheID != null ? params.cacheID : params.id;
			}

			return `${String(queryId)}${JSON.stringify(variables || {})}`;
		},
		getData: (routerContext: RouterDataContext, customContext: ResourceStoreContext) => {
			if (fg('blu-3813-improve-ssr-hydration-of-relay')) {
				return getDataNew({
					routerContext,
					customContext,
					type,
					getQuery,
					captureErrors,
					captureTraceIds,
				});
			}
			return getDataOld({
				routerContext,
				customContext,
				type,
				loadParameters,
				getQuery,
				captureErrors,
				captureTraceIds,
			});
		},
		maxAge: 0,
		isBrowserOnly,
	});

// A public version of the internal `Config` type but with a mandatory `parameters`
type SyncConfig<TQuery extends OperationType> = {
	parameters: GraphQLTaggedNode | PreloadableConcreteRequest<TQuery>;
	variables?: VariablesOf<TQuery>;
	options?: LoadQueryOptions;
};
// A public version of the internal `CreateRelayResourceConfig` type but with no `loadParameters`, since `SyncConfig` has a mandatory `parameters`
type CreateRelayResourceConfigSync<TQuery extends OperationType> = {
	type: string;
	isBrowserOnly?: boolean;
	getQuery: (
		arg1: RouterContext | RouterDataContext,
		arg2: ResourceStoreContext,
	) => SyncConfig<TQuery>;
	captureErrors?: boolean;
	captureTraceIds?: boolean;
};
/**
 * @deprecated Use Relay EntryPoint instead https://hello.atlassian.net/wiki/spaces/UAF/pages/2754170095
 *
 * Creates a Relay powered route resource. For use when the graphql query is in the same chunk, otherwise use {@link createAsyncRelayResource}
 */
// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const createRelayResource = createRelayResourceImpl as <TQuery extends OperationType>(
	param: CreateRelayResourceConfigSync<TQuery>,
) => RouteResource<PreloadedQuery<TQuery>>;

// A public version of the internal `Config` type but with no `parameters`, since `CreateRelayResourceConfigAsync` contains a `loadParameters`
type AsyncConfig<TQuery extends OperationType> = {
	variables?: VariablesOf<TQuery>;
	options?: LoadQueryOptions;
};
// A public version of the internal `CreateRelayResourceConfig` type but with a `loadParameters` type and the `getQuery` is not expected to return a `parameters` property.
type CreateRelayResourceConfigAsync<TQuery extends OperationType> = {
	type: string;
	isBrowserOnly?: boolean;
	loadParameters: () => Promise<GraphQLTaggedNode | PreloadableConcreteRequest<TQuery>>;
	getQuery: (
		arg1: RouterContext | RouterDataContext,
		arg2: ResourceStoreContext,
	) => AsyncConfig<TQuery>;
	captureErrors?: boolean;
	captureTraceIds?: boolean;
};

/**
 * @deprecated Use Relay EntryPoint instead https://hello.atlassian.net/wiki/spaces/UAF/pages/2754170095
 *
 * Creates a Relay powered route resource which loads a graphql query asyncronously before loading its data.
 */
// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const createAsyncRelayResource = createRelayResourceImpl as <TQuery extends OperationType>(
	param: CreateRelayResourceConfigAsync<TQuery>,
) => RouteResource<PreloadedQuery<TQuery>>;

/**
 * @deprecated Use Relay EntryPoint instead https://hello.atlassian.net/wiki/spaces/UAF/pages/2754170095
 *
 * Access a relay resource
 */
export const useRelayResource = <TQuery extends OperationType>(
	resource: RouteResource<PreloadedQuery<TQuery>>,
	options?: { suspendWhenLoading?: boolean },
): {
	queryReference: PreloadedQuery<TQuery> | null;
} => {
	const { data: queryReference, loading, promise } = useResource<PreloadedQuery<TQuery>>(resource);

	if (options?.suspendWhenLoading && loading) {
		throw promise;
	}

	return {
		queryReference,
	};
};
