import React from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import { useIntl } from '@atlassian/jira-intl';
import {
	PeriscopePageSkeleton,
	PeriscopeInsightsSkeleton,
	PeriscopeSuggestionsSkeleton,
} from '@atlassian/jira-skeletons';
import messages from './messages';

// Extend this for loading screen
export const Skeleton = () => {
	const { formatMessage } = useIntl();
	return (
		<Box
			xcss={SpinnerContainerStyles}
			data-testId="software-periscope-periscope-page.ui.loading"
			aria-label={formatMessage(messages.labelNonFinal)}
			padding="space.500"
		>
			<PeriscopePageSkeleton />
		</Box>
	);
};

export const InsightsSkeleton = () => {
	const { formatMessage } = useIntl();
	return (
		<Box
			xcss={SpinnerContainerStyles}
			data-testId="software-periscope-periscope-page.insights-ui.loading"
			aria-label={formatMessage(messages.insightsLabelNonFinal)}
			paddingBlockStart="space.500"
			paddingInlineStart="space.500"
			paddingInlineEnd="space.500"
		>
			<PeriscopeInsightsSkeleton />
		</Box>
	);
};

export const SuggestionsSkeleton = () => {
	const { formatMessage } = useIntl();
	return (
		<Box
			xcss={SpinnerContainerStyles}
			data-testId="software-periscope-periscope-page.suggestions-ui.loading"
			aria-label={formatMessage(messages.suggestionsLabelNonFinal)}
			paddingBlockStart="space.400"
			paddingBlockEnd="space.500"
			paddingInlineStart="space.500"
			paddingInlineEnd="space.500"
		>
			<PeriscopeSuggestionsSkeleton />
		</Box>
	);
};

const SpinnerContainerStyles = xcss({
	width: '100%',
	height: '100%',
	boxSizing: 'border-box',
});
