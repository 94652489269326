import {
	BURNDOWN_BEACON_ID,
	BURNDOWN_SETTINGS_DEFAULTS,
} from '@atlassian/jira-insights-common-constants/src/common/constants/burndown.tsx';
import {
	EPIC_PROGRESS_BEACON_ID,
	EPIC_PROGRESS_SETTINGS_DEFAULTS,
} from '@atlassian/jira-insights-common-constants/src/common/constants/epic-progress.tsx';
import {
	ISSUE_TYPE_WORKLOAD_BEACON_ID,
	ISSUE_TYPE_WORKLOAD_SETTINGS_DEFAULTS,
} from '@atlassian/jira-insights-common-constants/src/common/constants/issue-type-workload.tsx';
import {
	ISSUES_FOR_ATTENTION_BEACON_ID,
	ISSUES_FOR_ATTENTION_SETTINGS_DEFAULTS,
} from '@atlassian/jira-insights-common-constants/src/common/constants/issues-for-attention.tsx';
import { INSIGHTS_PACKAGE } from '@atlassian/jira-insights-common-constants/src/common/constants/package';
import {
	SPRINT_COMMITMENT_BEACON_ID,
	SPRINT_COMMITMENT_SETTINGS_DEFAULTS,
} from '@atlassian/jira-insights-common-constants/src/common/constants/sprint-commitment.tsx';
import {
	SPRINT_PROGRESS_BEACON_ID,
	SPRINT_PROGRESS_SETTINGS_DEFAULTS,
} from '@atlassian/jira-insights-common-constants/src/common/constants/sprint-progress.tsx';
import type { BoardInsightsSettings } from './types';

export const INSIGHTS_SETTINGS_ANALYTICS = `${INSIGHTS_PACKAGE}.settings`;
export const INSIGHTS_SETTINGS_UPDATE_SUCCESS_FLAG_ID = `${INSIGHTS_PACKAGE}.settings.update-flag.success`;
export const INSIGHTS_SETTINGS_UPDATE_ERROR_FLAG_ID = `${INSIGHTS_PACKAGE}.settings.update-flag.error`;

export const insightsSettingsLoaderAnalytics = {
	ACTION_SUBJECT: INSIGHTS_SETTINGS_ANALYTICS,
	ACTION_SUBJECT_ID: 'get-settings',
} as const;

export const insightsSettingsDefaults: BoardInsightsSettings = {
	[SPRINT_COMMITMENT_BEACON_ID]: SPRINT_COMMITMENT_SETTINGS_DEFAULTS,
	[ISSUE_TYPE_WORKLOAD_BEACON_ID]: ISSUE_TYPE_WORKLOAD_SETTINGS_DEFAULTS,
	[BURNDOWN_BEACON_ID]: BURNDOWN_SETTINGS_DEFAULTS,
	[SPRINT_PROGRESS_BEACON_ID]: SPRINT_PROGRESS_SETTINGS_DEFAULTS,
	[EPIC_PROGRESS_BEACON_ID]: EPIC_PROGRESS_SETTINGS_DEFAULTS,
	[ISSUES_FOR_ATTENTION_BEACON_ID]: ISSUES_FOR_ATTENTION_SETTINGS_DEFAULTS,
};

export const BACKLOG_LOCATION = 'backlog';
export const BOARD_LOCATION = 'board';

export const hasBoardAdminPermissionAnalytics = {
	ACTION_SUBJECT: INSIGHTS_SETTINGS_ANALYTICS,
	ACTION_SUBJECT_ID: 'board-admin',
} as const;

// 10s
export const GET_SETTINGS_ENDPOINT_TIMEOUT_WHEN_INSIGHTS_LOADING = 10 * 1000;
