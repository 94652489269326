import {
	ROUTE_GROUPS_SERVICEDESK,
	ROUTE_NAMES_NOTIFICATION_LOGS,
} from '@atlassian/jira-common-constants/src/spa-routes';

export const notificationLogsRoute = {
	group: ROUTE_GROUPS_SERVICEDESK,
	name: ROUTE_NAMES_NOTIFICATION_LOGS,
	path: '/jira/servicedesk/projects/:projectKey/notification-logs',
	exact: true,
};
