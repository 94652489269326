import { useCallback, useState } from 'react';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { UNSAFE_noExposureExp } from '@atlassian/jira-feature-experiments';
import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import { PACKAGE_NAME } from '../../common/constants';
import type { ProjectTemplatesResponse, TemplatesV2Response, ProjectTemplatesState } from './types';
import { getFilteredTemplates, getFilteredTemplatesNew } from './utils';

const initialState = {
	data: {
		templates: null,
	},
	loading: false,
	error: null,
};

export const useProjectTemplates = (): ProjectTemplatesResponse => {
	const [{ loading, data, error }, setState] = useState<ProjectTemplatesState>(initialState);

	const fetch = useCallback(async () => {
		setState({
			data: {
				templates: null,
			},
			loading: true,
			error: null,
		});

		try {
			const response = await fetchJson<TemplatesV2Response>(
				'/rest/simplified/2.0/project-templates?recommendations',
			);

			// * The exposure will be fired at the backend
			// eslint-disable-next-line jira/ff/unsafe-no-exposure
			const [expConfig] = UNSAFE_noExposureExp('cb_jira_template_recs_with_for_all_products');
			const shouldUseNewRecommendations = expConfig.get('useNewRecommendations', false);

			const jwmTemplates = shouldUseNewRecommendations
				? getFilteredTemplatesNew(response)
				: getFilteredTemplates(response);

			setState({
				data: {
					templates: jwmTemplates,
					recommendationSessionId: response.recommendationSessionId,
				},
				loading: false,
				error: null,
			});
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (err: any) {
			fireErrorAnalytics({
				meta: {
					id: 'useProjectTemplates',
					packageName: PACKAGE_NAME,
					teamName: 'jira-warepil',
				},
				error: err,
				sendToPrivacyUnsafeSplunk: true,
			});

			setState({
				data: {
					templates: null,
				},
				loading: false,
				error: err,
			});
		}
	}, []);

	return { data, loading, error, fetch };
};
