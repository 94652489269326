import camelCase from 'lodash/camelCase';
import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async';
import { ROUTE_NAMES_OPSGENIE_SCHEDULE } from '@atlassian/jira-common-constants/src/spa-routes';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { genericProjectLayout } from '@atlassian/jira-generic-project-layout';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { GENERIC_PROJECT_HORIZONTAL_NAV } from '@atlassian/jira-navigation-apps-horizontal-nav-selector/src/constants.tsx';
import { genericProjectSidebars } from '@atlassian/jira-navigation-apps-sidebar-generic-sidebars/src/ui/index.tsx';
import AsyncSidebarNextGen from '@atlassian/jira-navigation-apps-sidebar-next-gen/src/async';
import AsyncSidebarSoftwareClassic from '@atlassian/jira-navigation-apps-sidebar-software-classic/src/async';
import { createEntry } from '@atlassian/jira-route-entry';
import { getBusinessProjectResources } from '@atlassian/jira-router-resources-business-common';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/index.tsx';
import {
	getNavigationResources,
	getNavigationSidebarProjectResource,
} from '@atlassian/jira-router-resources-navigation';
import { projectContextResource } from '@atlassian/jira-router-resources-project-context/src/services/project-context/index.tsx';
import ErrorPagesNotFound from '@atlassian/jira-router-routes-common/src/ui/error-pages/not-found/index.tsx';
import {
	routeNamesOpsgenieScheduleRedirectRoute,
	opsgenieScheduleRoute,
} from '@atlassian/jira-router-routes-opsgenie-schedule-routes';
import { isDevopsFeatureDisabledInFedRamp } from '@atlassian/jira-software-devops-fedramp-utils';
import { AsyncHorizontalSoftwareProjectNav } from '@atlassian/jira-software-horizontal-nav/src/async.tsx';
import OpsgenieSchedule, { LazyOpsGenieSchedule, OpsgenieScheduleRedirect } from './ui';

export const opsgenieScheduleRouteEntry = createEntry(routeNamesOpsgenieScheduleRedirectRoute, {
	component: !__SERVER__ ? OpsgenieScheduleRedirect : OpsgenieSchedule,
	layout: genericProjectLayout,

	navigation: {
		menuId: MENU_ID.PROJECTS,
		sidebar: genericProjectSidebars,
		horizontal: GENERIC_PROJECT_HORIZONTAL_NAV,
	},

	resources: [
		...getBusinessProjectResources(),
		...getNavigationResources(),
		...getNavigationSidebarProjectResource(),
		projectContextResource,
		getConsolidationStateResource(),
	],

	forPaint: [LazyAtlassianNavigation, LazyOpsGenieSchedule],
	isRedirect: true,
});

export const opsgenieSchedule1RouteEntry = createEntry(opsgenieScheduleRoute, {
	ufoName: 'project-opsgenie-schedule',

	component: componentWithCondition(
		isDevopsFeatureDisabledInFedRamp,
		ErrorPagesNotFound,
		OpsgenieSchedule,
	),

	layout: genericProjectLayout,

	navigation: {
		menuId: MENU_ID.PROJECTS,
		sidebar: [AsyncSidebarNextGen, AsyncSidebarSoftwareClassic],
		horizontal: AsyncHorizontalSoftwareProjectNav,
		horizontalFeatureFlag: 'sea-2744-jsw-tabs-nav',
	},

	resources: [
		...getNavigationResources(),
		...getNavigationSidebarProjectResource(),
		projectContextResource,
	],

	forPaint: [LazyAtlassianNavigation, LazyOpsGenieSchedule],

	meta: {
		reporting: {
			id: ROUTE_NAMES_OPSGENIE_SCHEDULE,
			packageName: camelCase(ROUTE_NAMES_OPSGENIE_SCHEDULE),
			teamName: 'fusion-solaris',
		},
	},
});
