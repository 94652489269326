import { useCallback, useEffect, useState } from 'react';
import has from 'lodash/has';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { performGetRequest, performPutRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import { createResource, useResource } from '@atlassian/jira-router';
import {
	RESOURCE_TYPE_ISSUE_TRANSITION_CHANGEBOARDING_PREFERENCES,
	ISSUE_TRANSITION_CHANGEBOARDING_PREFERENCES_STATUS_RESOURCE_KEY,
	ISSUE_TRANSITION_CHANGEBOARDING_PREFERENCES_VISIBILITY_API,
	USER_SEEN_CARDINALITY_UNKNOWN,
} from '../common/constants';
import type {
	IssueTransitionChangeboardingPreferencesUpdateRequest,
	IssueTransitionChangeboardingPreferencesGetResponse,
	IssueTransitionChangeboardingPreferencesStatusResourceData,
	UseIssueTransitionChangeboardingPreferences,
	ResponseStatus,
} from '../types';

export const issueTransitionChangeboardingPreferencesResource =
	createResource<IssueTransitionChangeboardingPreferencesStatusResourceData>({
		type: RESOURCE_TYPE_ISSUE_TRANSITION_CHANGEBOARDING_PREFERENCES,
		getKey: () => ISSUE_TRANSITION_CHANGEBOARDING_PREFERENCES_STATUS_RESOURCE_KEY,
		getData: async (): Promise<IssueTransitionChangeboardingPreferencesStatusResourceData> => {
			let modalViewCount = USER_SEEN_CARDINALITY_UNKNOWN;

			try {
				const apiResult = await performGetRequest(
					ISSUE_TRANSITION_CHANGEBOARDING_PREFERENCES_VISIBILITY_API,
				);
				if (typeof apiResult !== 'object' || !has(apiResult, 'value')) {
					const error = new Error('Invalid preference value');
					fireErrorAnalytics({
						meta: {
							id: 'issueTransitionChangeboardingPreferencesResource',
							packageName: 'jiraRouterResourcesIssueTransitionChangeboardingPreferences',
							teamName: 'gryffindor',
						},
						error,
						sendToPrivacyUnsafeSplunk: true,
					});
				} else {
					modalViewCount = Number(apiResult.value);
					return {
						numberOfTimesUserHasSeenChangeboarding: modalViewCount,
					};
				}
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (error: any) {
				if (error.statusCode === 404)
					return {
						numberOfTimesUserHasSeenChangeboarding: 0,
					};
				fireErrorAnalytics({
					meta: {
						id: 'issueTransitionChangeboardingPreferencesResource',
						packageName: 'jiraRouterResourcesIssueTransitionChangeboardingPreferences',
						teamName: 'gryffindor',
					},
					error,
					sendToPrivacyUnsafeSplunk: true,
				});
			}
			return {
				numberOfTimesUserHasSeenChangeboarding: modalViewCount,
			};
		},
	});

export const useIssueTransitionChangeboardingPreferences: UseIssueTransitionChangeboardingPreferences =
	() => {
		const {
			data,
			loading: loadingGetResource,
			error: errorGetResource,
			update,
		} = useResource(issueTransitionChangeboardingPreferencesResource);

		const [issueTransitionChangeboardingPreference, setIssueTransitionChangeboardingPreference] =
			useState<IssueTransitionChangeboardingPreferencesGetResponse>(() => {
				if (typeof data?.numberOfTimesUserHasSeenChangeboarding === 'number') {
					return data.numberOfTimesUserHasSeenChangeboarding;
				}
				return USER_SEEN_CARDINALITY_UNKNOWN;
			});

		const [responseStatusGet, setResponseStatusGet] = useState<ResponseStatus>('not started');
		const [responseStatusPut, setResponseStatusPut] = useState<ResponseStatus>('not started');

		const updateIssueTranstitionChangeboardingPreferences = useCallback(
			async (
				updates: IssueTransitionChangeboardingPreferencesUpdateRequest,
			): Promise<ResponseStatus> => {
				let response: ResponseStatus = 'loading';
				setResponseStatusPut('loading');
				try {
					await performPutRequest(ISSUE_TRANSITION_CHANGEBOARDING_PREFERENCES_VISIBILITY_API, {
						body: JSON.stringify({ value: updates }),
					});
					setResponseStatusPut('success');
					update(() => ({
						numberOfTimesUserHasSeenChangeboarding: updates,
					}));
					response = 'success';
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
				} catch (error: any) {
					fireErrorAnalytics({
						meta: {
							id: 'issueTransitionChangeboardingPreferencesUpdate',
							packageName: 'jiraRouterResourcesIssueTransitionChangeboardingPreferences',
							teamName: 'gryffindor',
						},
						error,
						sendToPrivacyUnsafeSplunk: true,
					});
					setResponseStatusPut('error');
					response = 'error';
				}
				return response;
			},
			[update],
		);

		useEffect(() => {
			if (loadingGetResource) {
				setResponseStatusGet('loading');
				return;
			}

			if (errorGetResource) {
				setResponseStatusGet('error');
				return;
			}

			if (typeof data?.numberOfTimesUserHasSeenChangeboarding === 'number') {
				setResponseStatusGet('success');
				setIssueTransitionChangeboardingPreference(data?.numberOfTimesUserHasSeenChangeboarding);
			}
		}, [loadingGetResource, errorGetResource, data?.numberOfTimesUserHasSeenChangeboarding]);

		return {
			stateGet: {
				responseStatus: responseStatusGet,
				state: issueTransitionChangeboardingPreference,
			},
			stateUpdate: {
				responseStatus: responseStatusPut,
				setState: updateIssueTranstitionChangeboardingPreferences,
			},
		};
	};
