/**
 * @generated SignedSource<<024aaa53509c63df5d0f7d4ba220b24a>>
 * @relayHash f6105eb37e8f0ee0dc9af3030e229de3
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID ae5d4ec89b8904f489a246c6f90962dbc6569bbb7322b6503be3308def2541bd

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type JiraAtlassianIntelligenceFeatureEnum = "AI_MATE" | "NATURAL_LANGUAGE_TO_JQL" | "%future added value";
export type JiraSoftwareIssueSearchCustomInputContext = "BACKLOG" | "BOARD" | "NONE" | "%future added value";
export type JiraIssueSearchInput = {
  customInput?: JiraIssueSearchCustomInput | null | undefined;
  filterId?: string | null | undefined;
  jql?: string | null | undefined;
};
export type JiraIssueSearchCustomInput = {
  jiraSoftwareInput?: JiraSoftwareIssueSearchCustomInput | null | undefined;
};
export type JiraSoftwareIssueSearchCustomInput = {
  additionalJql?: string | null | undefined;
  context?: JiraSoftwareIssueSearchCustomInputContext | null | undefined;
  jiraEntityId: string;
};
export type JiraIssueSearchOptions = {
  issueKey?: string | null | undefined;
};
export type main_IssueNavigatorQuery$variables = {
  after?: string | null | undefined;
  amountOfColumns: number;
  atlassianIntelligenceProductFeature: JiraAtlassianIntelligenceFeatureEnum;
  before?: string | null | undefined;
  cloudId: string;
  fieldSetIds: ReadonlyArray<string>;
  filterId?: string | null | undefined;
  first?: number | null | undefined;
  issueSearchInput: JiraIssueSearchInput;
  last?: number | null | undefined;
  namespace?: string | null | undefined;
  options?: JiraIssueSearchOptions | null | undefined;
  queryStatusForIssuekeyStrikethrough: boolean;
  shouldQueryFieldSetsById: boolean;
  viewId?: string | null | undefined;
};
export type main_IssueNavigatorQuery$data = {
  readonly jira: {
    readonly userPreferences: {
      readonly " $fragmentSpreads": FragmentRefs<"main_issueNavigator_IssueNavigator_userPreferences">;
    } | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"main_issueNavigator_IssueNavigator_jira">;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"main_issueNavigator_IssueNavigator_issueQuery">;
};
export type main_IssueNavigatorQuery = {
  response: main_IssueNavigatorQuery$data;
  variables: main_IssueNavigatorQuery$variables;
};
({
  "__relay_internal__pv__atlassianjirarelayprovidersrcissuesearchviewresultrelayprovider": isIssueSearchViewResultEnabled_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrcischildissuestotalcountenabledrelayprovider": isChildIssueTotalCountEnabled_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrcjscm1apiupdatesrelayprovider": isJscM1ApiUpdatesEnabled_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrcstatusinlineeditrelayprovider": isSpreadsheetUIEnabled_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrcparentfromissueviewrelayprovider": isIssueViewParentComponentEnabled_provider
} as {
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcischildissuestotalcountenabledrelayprovider: {
    readonly get: () => boolean;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcissuesearchviewresultrelayprovider: {
    readonly get: () => boolean;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcjscm1apiupdatesrelayprovider: {
    readonly get: () => boolean;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcparentfromissueviewrelayprovider: {
    readonly get: () => boolean;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcstatusinlineeditrelayprovider: {
    readonly get: () => boolean;
  };
});

import isChildIssueTotalCountEnabled_provider from '@atlassian/jira-relay-provider/src/is-child-issues-total-count-enabled.relayprovider';
import isIssueSearchViewResultEnabled_provider from '@atlassian/jira-relay-provider/src/issue-search-view-result.relayprovider';
import isJscM1ApiUpdatesEnabled_provider from '@atlassian/jira-relay-provider/src/jsc-m1-api-updates.relayprovider';
import isIssueViewParentComponentEnabled_provider from '@atlassian/jira-relay-provider/src/parent-from-issue-view.relayprovider';
import isSpreadsheetUIEnabled_provider from '@atlassian/jira-relay-provider/src/status-inline-edit.relayprovider';

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "amountOfColumns"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "atlassianIntelligenceProductFeature"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "before"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cloudId"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "fieldSetIds"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filterId"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v8 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "issueSearchInput"
},
v9 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "last"
},
v10 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "namespace"
},
v11 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "options"
},
v12 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "queryStatusForIssuekeyStrikethrough"
},
v13 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "shouldQueryFieldSetsById"
},
v14 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "viewId"
},
v15 = {
  "kind": "Variable",
  "name": "cloudId",
  "variableName": "cloudId"
},
v16 = [
  (v15/*: any*/)
],
v17 = {
  "kind": "ScalarField",
  "name": "key"
},
v18 = {
  "kind": "ScalarField",
  "name": "id"
},
v19 = {
  "kind": "Variable",
  "name": "filterId",
  "variableName": "filterId"
},
v20 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "amountOfColumns"
},
v21 = {
  "kind": "Variable",
  "name": "namespace",
  "variableName": "namespace"
},
v22 = {
  "kind": "Variable",
  "name": "viewId",
  "variableName": "viewId"
},
v23 = [
  (v19/*: any*/),
  (v20/*: any*/),
  (v21/*: any*/),
  (v22/*: any*/)
],
v24 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v25 = {
  "kind": "ScalarField",
  "name": "fieldId"
},
v26 = {
  "kind": "ScalarField",
  "name": "name"
},
v27 = {
  "kind": "ScalarField",
  "name": "text"
},
v28 = {
  "kind": "ScalarField",
  "name": "picture"
},
v29 = {
  "concreteType": "JiraAvatar",
  "kind": "LinkedField",
  "name": "avatar",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "small"
    }
  ]
},
v30 = {
  "kind": "ScalarField",
  "name": "statusCategoryId"
},
v31 = {
  "concreteType": "JiraStatusCategory",
  "kind": "LinkedField",
  "name": "statusCategory",
  "plural": false,
  "selections": [
    (v30/*: any*/),
    (v18/*: any*/)
  ]
},
v32 = [
  {
    "kind": "Variable",
    "name": "fieldSetIds",
    "variableName": "fieldSetIds"
  },
  (v20/*: any*/)
],
v33 = {
  "kind": "ScalarField",
  "name": "type"
},
v34 = {
  "kind": "ScalarField",
  "name": "fieldSetId"
},
v35 = {
  "kind": "TypeDiscriminator",
  "abstractKey": "__isJiraIssueField"
},
v36 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "concreteType": "JiraAffectedServiceConnection",
      "kind": "LinkedField",
      "name": "selectedAffectedServicesConnection",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraAffectedServiceEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "concreteType": "JiraAffectedService",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "name": "serviceId"
                },
                (v26/*: any*/)
              ]
            }
          ]
        }
      ]
    }
  ],
  "type": "JiraAffectedServicesField"
},
v37 = {
  "kind": "ScalarField",
  "name": "message"
},
v38 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "concreteType": "JiraCmdbObjectConnection",
      "kind": "LinkedField",
      "name": "selectedCmdbObjectsConnection",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraCmdbObjectEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "concreteType": "JiraCmdbObject",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "name": "objectId"
                },
                {
                  "kind": "ScalarField",
                  "name": "label"
                },
                {
                  "concreteType": "JiraCmdbAvatar",
                  "kind": "LinkedField",
                  "name": "avatar",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "ScalarField",
                      "name": "url48"
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          "concreteType": "QueryError",
          "kind": "LinkedField",
          "name": "errors",
          "plural": true,
          "selections": [
            (v37/*: any*/)
          ]
        }
      ]
    }
  ],
  "type": "JiraCMDBField"
},
v39 = [
  (v26/*: any*/),
  (v18/*: any*/)
],
v40 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "concreteType": "JiraComponentConnection",
      "kind": "LinkedField",
      "name": "selectedComponentsConnection",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraComponentEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "concreteType": "JiraComponent",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": (v39/*: any*/)
            }
          ]
        }
      ]
    }
  ],
  "type": "JiraComponentsField"
},
v41 = {
  "concreteType": "JiraFieldConfig",
  "kind": "LinkedField",
  "name": "fieldConfig",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "isRequired"
    }
  ]
},
v42 = {
  "kind": "ClientExtension",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "lazyIsEditableInIssueView"
    }
  ]
},
v43 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "date"
    },
    (v26/*: any*/),
    (v41/*: any*/),
    (v42/*: any*/)
  ],
  "type": "JiraDatePickerField"
},
v44 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "dateTime"
    }
  ],
  "type": "JiraDateTimePickerField"
},
v45 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "concreteType": "JiraEpic",
      "kind": "LinkedField",
      "name": "epic",
      "plural": false,
      "selections": [
        (v17/*: any*/),
        {
          "kind": "ScalarField",
          "name": "summary"
        },
        {
          "kind": "ScalarField",
          "name": "color"
        },
        (v18/*: any*/)
      ]
    }
  ],
  "type": "JiraEpicLinkField"
},
v46 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "renderedFieldHtml"
    }
  ],
  "type": "JiraFallbackField"
},
v47 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "concreteType": "JiraStatus",
      "kind": "LinkedField",
      "name": "status",
      "plural": false,
      "selections": [
        (v26/*: any*/),
        {
          "kind": "ScalarField",
          "name": "statusId"
        },
        (v31/*: any*/),
        (v18/*: any*/)
      ]
    }
  ],
  "type": "JiraStatusField"
},
v48 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 1000
  }
],
v49 = [
  (v26/*: any*/)
],
v50 = {
  "kind": "ScalarField",
  "name": "totalCount"
},
v51 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "args": (v48/*: any*/),
      "concreteType": "JiraLabelConnection",
      "kind": "LinkedField",
      "name": "selectedLabelsConnection",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraLabelEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "concreteType": "JiraLabel",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": (v49/*: any*/)
            }
          ]
        },
        (v50/*: any*/)
      ],
      "storageKey": "selectedLabelsConnection(first:1000)"
    },
    (v26/*: any*/),
    (v42/*: any*/)
  ],
  "type": "JiraLabelsField"
},
v52 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "concreteType": "JiraIssueType",
      "kind": "LinkedField",
      "name": "issueType",
      "plural": false,
      "selections": [
        (v29/*: any*/),
        (v26/*: any*/),
        (v18/*: any*/)
      ]
    }
  ],
  "type": "JiraIssueTypeField"
},
v53 = {
  "concreteType": "JiraSingleLineTextField",
  "kind": "LinkedField",
  "name": "summaryField",
  "plural": false,
  "selections": [
    (v27/*: any*/),
    (v18/*: any*/)
  ]
},
v54 = {
  "concreteType": "JiraAvatar",
  "kind": "LinkedField",
  "name": "avatar",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "xsmall"
    }
  ]
},
v55 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "concreteType": "JiraIssue",
      "kind": "LinkedField",
      "name": "parentIssue",
      "plural": false,
      "selections": [
        (v17/*: any*/),
        {
          "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcparentfromissueviewrelayprovider",
          "kind": "Condition",
          "passingValue": true,
          "selections": [
            (v53/*: any*/)
          ]
        },
        {
          "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcparentfromissueviewrelayprovider",
          "kind": "Condition",
          "passingValue": false,
          "selections": [
            {
              "args": [
                {
                  "kind": "Literal",
                  "name": "ids",
                  "value": [
                    "issuetype",
                    "summary"
                  ]
                }
              ],
              "concreteType": "JiraIssueFieldConnection",
              "kind": "LinkedField",
              "name": "fieldsById",
              "plural": false,
              "selections": [
                {
                  "concreteType": "JiraIssueFieldEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        (v24/*: any*/),
                        (v25/*: any*/),
                        (v52/*: any*/),
                        {
                          "kind": "InlineFragment",
                          "selections": [
                            (v27/*: any*/)
                          ],
                          "type": "JiraSingleLineTextField"
                        },
                        (v18/*: any*/)
                      ]
                    }
                  ]
                }
              ],
              "storageKey": "fieldsById(ids:[\"issuetype\",\"summary\"])"
            }
          ]
        },
        (v18/*: any*/)
      ]
    },
    {
      "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcparentfromissueviewrelayprovider",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "concreteType": "JiraIssue",
          "kind": "LinkedField",
          "name": "parentIssue",
          "plural": false,
          "selections": [
            {
              "concreteType": "JiraColorField",
              "kind": "LinkedField",
              "name": "issueColorField",
              "plural": false,
              "selections": [
                {
                  "concreteType": "JiraColor",
                  "kind": "LinkedField",
                  "name": "color",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "ScalarField",
                      "name": "colorKey"
                    },
                    (v18/*: any*/)
                  ]
                },
                (v18/*: any*/)
              ]
            },
            {
              "concreteType": "JiraIssueTypeField",
              "kind": "LinkedField",
              "name": "issueTypeField",
              "plural": false,
              "selections": [
                {
                  "concreteType": "JiraIssueType",
                  "kind": "LinkedField",
                  "name": "issueType",
                  "plural": false,
                  "selections": [
                    (v54/*: any*/),
                    (v26/*: any*/),
                    (v18/*: any*/)
                  ]
                },
                (v18/*: any*/)
              ]
            },
            (v53/*: any*/)
          ]
        }
      ]
    }
  ],
  "type": "JiraParentIssueField"
},
v56 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "concreteType": "JiraPriority",
      "kind": "LinkedField",
      "name": "priority",
      "plural": false,
      "selections": [
        (v26/*: any*/),
        {
          "kind": "ScalarField",
          "name": "iconUrl"
        },
        (v18/*: any*/)
      ]
    },
    (v26/*: any*/),
    (v42/*: any*/)
  ],
  "type": "JiraPriorityField"
},
v57 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "concreteType": "JiraProject",
      "kind": "LinkedField",
      "name": "project",
      "plural": false,
      "selections": [
        (v26/*: any*/),
        (v17/*: any*/),
        {
          "concreteType": "JiraAvatar",
          "kind": "LinkedField",
          "name": "avatar",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "name": "large"
            }
          ]
        },
        (v18/*: any*/)
      ]
    }
  ],
  "type": "JiraProjectField"
},
v58 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "concreteType": "JiraResolution",
      "kind": "LinkedField",
      "name": "resolution",
      "plural": false,
      "selections": (v39/*: any*/)
    }
  ],
  "type": "JiraResolutionField"
},
v59 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "concreteType": "JiraSprintConnection",
      "kind": "LinkedField",
      "name": "selectedSprintsConnection",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraSprintEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "concreteType": "JiraSprint",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v18/*: any*/),
                {
                  "kind": "ScalarField",
                  "name": "sprintId"
                },
                (v26/*: any*/),
                {
                  "kind": "ScalarField",
                  "name": "state"
                },
                {
                  "kind": "ScalarField",
                  "name": "endDate"
                }
              ]
            }
          ]
        }
      ]
    },
    (v26/*: any*/),
    (v42/*: any*/)
  ],
  "type": "JiraSprintField"
},
v60 = {
  "kind": "ScalarField",
  "name": "accountId"
},
v61 = {
  "kind": "ScalarField",
  "name": "accountStatus"
},
v62 = {
  "kind": "InlineFragment",
  "selections": [
    (v26/*: any*/),
    {
      "concreteType": "JiraVersionConnection",
      "kind": "LinkedField",
      "name": "selectedVersionsConnection",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraVersionEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "concreteType": "JiraVersion",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": (v39/*: any*/)
            }
          ]
        }
      ]
    }
  ],
  "type": "JiraMultipleVersionPickerField"
},
v63 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "concreteType": "JiraRichText",
      "kind": "LinkedField",
      "name": "richText",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraADF",
          "kind": "LinkedField",
          "name": "adfValue",
          "plural": false,
          "selections": [
            {
              "concreteType": "JiraAdfToConvertedPlainText",
              "kind": "LinkedField",
              "name": "convertedPlainText",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "name": "plainText"
                }
              ]
            }
          ]
        }
      ]
    }
  ],
  "type": "JiraRichTextField"
},
v64 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "concreteType": "JiraServiceManagementRequestType",
      "kind": "LinkedField",
      "name": "requestType",
      "plural": false,
      "selections": [
        (v26/*: any*/),
        (v54/*: any*/),
        (v18/*: any*/)
      ]
    }
  ],
  "type": "JiraServiceManagementRequestTypeField"
},
v65 = {
  "kind": "ScalarField",
  "name": "hasNextPage"
},
v66 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "args": (v48/*: any*/),
      "concreteType": "JiraIssueLinkConnection",
      "kind": "LinkedField",
      "name": "issueLinkConnection",
      "plural": false,
      "selections": [
        {
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            (v65/*: any*/)
          ]
        },
        {
          "concreteType": "JiraIssueLinkEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "concreteType": "JiraIssueLink",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "concreteType": "JiraIssue",
                  "kind": "LinkedField",
                  "name": "issue",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "ScalarField",
                      "name": "webUrl"
                    },
                    (v17/*: any*/),
                    {
                      "args": [
                        {
                          "kind": "Literal",
                          "name": "ids",
                          "value": [
                            "statusCategory"
                          ]
                        }
                      ],
                      "concreteType": "JiraIssueFieldConnection",
                      "kind": "LinkedField",
                      "name": "fieldsById",
                      "plural": false,
                      "selections": [
                        {
                          "concreteType": "JiraIssueFieldEdge",
                          "kind": "LinkedField",
                          "name": "edges",
                          "plural": true,
                          "selections": [
                            {
                              "kind": "LinkedField",
                              "name": "node",
                              "plural": false,
                              "selections": [
                                (v24/*: any*/),
                                {
                                  "kind": "InlineFragment",
                                  "selections": [
                                    (v31/*: any*/)
                                  ],
                                  "type": "JiraStatusCategoryField"
                                },
                                (v18/*: any*/)
                              ]
                            }
                          ]
                        }
                      ],
                      "storageKey": "fieldsById(ids:[\"statusCategory\"])"
                    },
                    (v18/*: any*/)
                  ]
                },
                {
                  "kind": "ScalarField",
                  "name": "relationName"
                },
                (v18/*: any*/)
              ]
            }
          ]
        }
      ],
      "storageKey": "issueLinkConnection(first:1000)"
    }
  ],
  "type": "JiraIssueLinkField"
},
v67 = {
  "concreteType": "JiraIssueFieldConnection",
  "kind": "LinkedField",
  "name": "fields",
  "plural": false,
  "selections": [
    {
      "concreteType": "JiraIssueFieldEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v24/*: any*/),
            (v25/*: any*/),
            (v33/*: any*/),
            (v35/*: any*/),
            (v18/*: any*/),
            (v36/*: any*/),
            (v38/*: any*/),
            (v40/*: any*/),
            (v43/*: any*/),
            (v44/*: any*/),
            (v45/*: any*/),
            (v46/*: any*/),
            {
              "kind": "InlineFragment",
              "selections": [
                (v27/*: any*/),
                (v26/*: any*/),
                (v42/*: any*/)
              ],
              "type": "JiraSingleLineTextField"
            },
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "concreteType": "JiraStatusCategory",
                  "kind": "LinkedField",
                  "name": "statusCategory",
                  "plural": false,
                  "selections": [
                    (v26/*: any*/),
                    (v30/*: any*/),
                    (v18/*: any*/)
                  ]
                }
              ],
              "type": "JiraStatusCategoryField"
            },
            (v47/*: any*/),
            (v51/*: any*/),
            (v52/*: any*/),
            (v55/*: any*/),
            (v56/*: any*/),
            (v57/*: any*/),
            (v58/*: any*/),
            (v59/*: any*/),
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "kind": "LinkedField",
                  "name": "user",
                  "plural": false,
                  "selections": [
                    (v24/*: any*/),
                    (v60/*: any*/),
                    (v28/*: any*/),
                    (v26/*: any*/),
                    (v18/*: any*/),
                    (v61/*: any*/)
                  ]
                },
                (v26/*: any*/),
                (v41/*: any*/),
                (v42/*: any*/)
              ],
              "type": "JiraSingleSelectUserPickerField"
            },
            (v62/*: any*/),
            (v63/*: any*/),
            (v64/*: any*/),
            (v66/*: any*/)
          ]
        }
      ]
    }
  ]
},
v68 = {
  "concreteType": "JiraIssueFieldConnection",
  "kind": "LinkedField",
  "name": "fields",
  "plural": false,
  "selections": [
    {
      "concreteType": "JiraIssueFieldEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v25/*: any*/),
            (v33/*: any*/),
            (v35/*: any*/),
            (v36/*: any*/),
            (v38/*: any*/),
            (v40/*: any*/),
            (v43/*: any*/),
            (v44/*: any*/),
            (v45/*: any*/),
            (v46/*: any*/),
            {
              "kind": "InlineFragment",
              "selections": [
                (v33/*: any*/),
                (v42/*: any*/)
              ],
              "type": "JiraSingleLineTextField"
            },
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "concreteType": "JiraStatusCategory",
                  "kind": "LinkedField",
                  "name": "statusCategory",
                  "plural": false,
                  "selections": (v49/*: any*/)
                }
              ],
              "type": "JiraStatusCategoryField"
            },
            (v47/*: any*/),
            (v51/*: any*/),
            (v55/*: any*/),
            (v56/*: any*/),
            (v57/*: any*/),
            (v58/*: any*/),
            (v59/*: any*/),
            {
              "kind": "InlineFragment",
              "selections": [
                (v33/*: any*/),
                {
                  "kind": "LinkedField",
                  "name": "user",
                  "plural": false,
                  "selections": [
                    (v60/*: any*/),
                    (v61/*: any*/)
                  ]
                },
                (v41/*: any*/),
                (v42/*: any*/)
              ],
              "type": "JiraSingleSelectUserPickerField"
            },
            (v62/*: any*/),
            (v63/*: any*/),
            (v64/*: any*/),
            (v66/*: any*/)
          ]
        }
      ]
    }
  ]
},
v69 = {
  "kind": "ScalarField",
  "name": "cursor"
},
v70 = {
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "endCursor"
    },
    (v65/*: any*/)
  ]
},
v71 = {
  "kind": "ClientExtension",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "__id"
    }
  ]
},
v72 = {
  "kind": "ScalarField",
  "name": "isCurrent"
},
v73 = {
  "kind": "ScalarField",
  "name": "pageNumber"
},
v74 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "feature",
        "variableName": "atlassianIntelligenceProductFeature"
      }
    ],
    "kind": "ObjectValue",
    "name": "atlassianIntelligenceProductFeatureInput"
  },
  (v15/*: any*/)
],
v75 = [
  (v15/*: any*/),
  (v19/*: any*/),
  (v21/*: any*/),
  (v22/*: any*/)
],
v76 = {
  "kind": "ScalarField",
  "name": "displayName"
},
v77 = [
  {
    "kind": "ScalarField",
    "name": "viewId"
  },
  {
    "args": [
      {
        "kind": "Literal",
        "name": "filter",
        "value": {
          "fieldSetSelectedState": "SELECTED"
        }
      },
      (v20/*: any*/)
    ],
    "concreteType": "JiraIssueSearchFieldSetConnection",
    "kind": "LinkedField",
    "name": "fieldSets",
    "plural": false,
    "selections": [
      {
        "concreteType": "JiraIssueSearchFieldSetEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "concreteType": "JiraIssueSearchFieldSet",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v34/*: any*/),
              (v33/*: any*/),
              (v76/*: any*/),
              {
                "kind": "ScalarField",
                "name": "jqlTerm"
              },
              {
                "kind": "ScalarField",
                "name": "isSortable"
              },
              {
                "concreteType": "JiraFieldSetPreferences",
                "kind": "LinkedField",
                "name": "fieldSetPreferences",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "name": "width"
                  }
                ]
              },
              {
                "concreteType": "JiraFieldType",
                "kind": "LinkedField",
                "name": "fieldType",
                "plural": false,
                "selections": [
                  (v76/*: any*/)
                ]
              }
            ]
          }
        ]
      },
      (v50/*: any*/),
      (v71/*: any*/)
    ]
  },
  (v18/*: any*/),
  {
    "kind": "ScalarField",
    "name": "hasDefaultFieldSets"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/),
      (v9/*: any*/),
      (v10/*: any*/),
      (v11/*: any*/),
      (v12/*: any*/),
      (v13/*: any*/),
      (v14/*: any*/)
    ],
    "kind": "Fragment",
    "name": "main_IssueNavigatorQuery",
    "selections": [
      {
        "kind": "FragmentSpread",
        "name": "main_issueNavigator_IssueNavigator_issueQuery"
      },
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v16/*: any*/),
            "concreteType": "JiraUserPreferences",
            "kind": "LinkedField",
            "name": "userPreferences",
            "plural": false,
            "selections": [
              {
                "kind": "FragmentSpread",
                "name": "main_issueNavigator_IssueNavigator_userPreferences"
              }
            ]
          },
          {
            "kind": "FragmentSpread",
            "name": "main_issueNavigator_IssueNavigator_jira"
          }
        ]
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v4/*: any*/),
      (v8/*: any*/),
      (v7/*: any*/),
      (v9/*: any*/),
      (v3/*: any*/),
      (v0/*: any*/),
      (v10/*: any*/),
      (v14/*: any*/),
      (v11/*: any*/),
      (v6/*: any*/),
      (v5/*: any*/),
      (v13/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v12/*: any*/),
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcissuesearchviewresultrelayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcischildissuestotalcountenabledrelayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcjscm1apiupdatesrelayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcstatusinlineeditrelayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcparentfromissueviewrelayprovider"
      }
    ],
    "kind": "Operation",
    "name": "main_IssueNavigatorQuery",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": [
              {
                "kind": "Variable",
                "name": "after",
                "variableName": "after"
              },
              {
                "kind": "Variable",
                "name": "before",
                "variableName": "before"
              },
              (v15/*: any*/),
              {
                "kind": "Variable",
                "name": "first",
                "variableName": "first"
              },
              {
                "kind": "Variable",
                "name": "issueSearchInput",
                "variableName": "issueSearchInput"
              },
              {
                "kind": "Variable",
                "name": "last",
                "variableName": "last"
              },
              {
                "kind": "Variable",
                "name": "options",
                "variableName": "options"
              }
            ],
            "concreteType": "JiraIssueConnection",
            "kind": "LinkedField",
            "name": "issueSearchStable",
            "plural": false,
            "selections": [
              {
                "concreteType": "JiraIssueEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "concreteType": "JiraIssue",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v17/*: any*/),
                      (v18/*: any*/),
                      {
                        "kind": "ScalarField",
                        "name": "issueId"
                      },
                      {
                        "args": (v23/*: any*/),
                        "concreteType": "JiraIssueFieldSetConnection",
                        "kind": "LinkedField",
                        "name": "fieldSetsForIssueSearchView",
                        "plural": false,
                        "selections": [
                          {
                            "concreteType": "JiraIssueFieldSetEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "concreteType": "JiraIssueFieldSet",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  {
                                    "concreteType": "JiraIssueFieldConnection",
                                    "kind": "LinkedField",
                                    "name": "fields",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "concreteType": "JiraIssueFieldEdge",
                                        "kind": "LinkedField",
                                        "name": "edges",
                                        "plural": true,
                                        "selections": [
                                          {
                                            "kind": "LinkedField",
                                            "name": "node",
                                            "plural": false,
                                            "selections": [
                                              (v24/*: any*/),
                                              {
                                                "kind": "InlineFragment",
                                                "selections": [
                                                  (v25/*: any*/),
                                                  (v26/*: any*/),
                                                  (v27/*: any*/)
                                                ],
                                                "type": "JiraSingleLineTextField"
                                              },
                                              {
                                                "kind": "InlineFragment",
                                                "selections": [
                                                  (v25/*: any*/),
                                                  (v26/*: any*/),
                                                  {
                                                    "kind": "LinkedField",
                                                    "name": "user",
                                                    "plural": false,
                                                    "selections": [
                                                      (v24/*: any*/),
                                                      (v26/*: any*/),
                                                      (v28/*: any*/),
                                                      (v18/*: any*/)
                                                    ]
                                                  }
                                                ],
                                                "type": "JiraSingleSelectUserPickerField"
                                              },
                                              {
                                                "kind": "InlineFragment",
                                                "selections": [
                                                  (v25/*: any*/),
                                                  {
                                                    "concreteType": "JiraIssueType",
                                                    "kind": "LinkedField",
                                                    "name": "issueType",
                                                    "plural": false,
                                                    "selections": [
                                                      (v26/*: any*/),
                                                      (v29/*: any*/),
                                                      (v18/*: any*/)
                                                    ]
                                                  }
                                                ],
                                                "type": "JiraIssueTypeField"
                                              },
                                              {
                                                "kind": "InlineFragment",
                                                "selections": [
                                                  (v25/*: any*/),
                                                  (v31/*: any*/)
                                                ],
                                                "type": "JiraStatusCategoryField"
                                              },
                                              (v18/*: any*/)
                                            ]
                                          }
                                        ]
                                      }
                                    ]
                                  }
                                ]
                              }
                            ]
                          }
                        ]
                      },
                      {
                        "condition": "queryStatusForIssuekeyStrikethrough",
                        "kind": "Condition",
                        "passingValue": true,
                        "selections": [
                          {
                            "args": [
                              {
                                "kind": "Literal",
                                "name": "ids",
                                "value": [
                                  "status"
                                ]
                              }
                            ],
                            "concreteType": "JiraIssueFieldConnection",
                            "kind": "LinkedField",
                            "name": "fieldsById",
                            "plural": false,
                            "selections": [
                              {
                                "concreteType": "JiraIssueFieldEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                  {
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                      (v24/*: any*/),
                                      {
                                        "kind": "InlineFragment",
                                        "selections": [
                                          (v25/*: any*/),
                                          {
                                            "concreteType": "JiraStatus",
                                            "kind": "LinkedField",
                                            "name": "status",
                                            "plural": false,
                                            "selections": [
                                              (v31/*: any*/),
                                              (v18/*: any*/)
                                            ]
                                          }
                                        ],
                                        "type": "JiraStatusField"
                                      },
                                      (v18/*: any*/)
                                    ]
                                  }
                                ]
                              }
                            ],
                            "storageKey": "fieldsById(ids:[\"status\"])"
                          }
                        ]
                      },
                      {
                        "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcjscm1apiupdatesrelayprovider",
                        "kind": "Condition",
                        "passingValue": false,
                        "selections": [
                          {
                            "condition": "shouldQueryFieldSetsById",
                            "kind": "Condition",
                            "passingValue": true,
                            "selections": [
                              {
                                "args": (v32/*: any*/),
                                "concreteType": "JiraIssueFieldSetConnection",
                                "kind": "LinkedField",
                                "name": "fieldSetsById",
                                "plural": false,
                                "selections": [
                                  {
                                    "concreteType": "JiraIssueFieldSetEdge",
                                    "kind": "LinkedField",
                                    "name": "edges",
                                    "plural": true,
                                    "selections": [
                                      {
                                        "concreteType": "JiraIssueFieldSet",
                                        "kind": "LinkedField",
                                        "name": "node",
                                        "plural": false,
                                        "selections": [
                                          (v33/*: any*/),
                                          (v34/*: any*/),
                                          (v67/*: any*/)
                                        ]
                                      }
                                    ]
                                  }
                                ]
                              }
                            ]
                          },
                          {
                            "condition": "shouldQueryFieldSetsById",
                            "kind": "Condition",
                            "passingValue": false,
                            "selections": [
                              {
                                "args": (v23/*: any*/),
                                "concreteType": "JiraIssueFieldSetConnection",
                                "kind": "LinkedField",
                                "name": "fieldSetsForIssueSearchView",
                                "plural": false,
                                "selections": [
                                  {
                                    "concreteType": "JiraIssueFieldSetEdge",
                                    "kind": "LinkedField",
                                    "name": "edges",
                                    "plural": true,
                                    "selections": [
                                      {
                                        "concreteType": "JiraIssueFieldSet",
                                        "kind": "LinkedField",
                                        "name": "node",
                                        "plural": false,
                                        "selections": [
                                          (v33/*: any*/),
                                          (v34/*: any*/),
                                          (v68/*: any*/)
                                        ]
                                      }
                                    ]
                                  }
                                ]
                              }
                            ]
                          }
                        ]
                      },
                      {
                        "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcjscm1apiupdatesrelayprovider",
                        "kind": "Condition",
                        "passingValue": true,
                        "selections": [
                          {
                            "condition": "shouldQueryFieldSetsById",
                            "kind": "Condition",
                            "passingValue": true,
                            "selections": [
                              {
                                "args": (v32/*: any*/),
                                "concreteType": "JiraIssueFieldSetConnection",
                                "kind": "LinkedField",
                                "name": "fieldSetsById",
                                "plural": false,
                                "selections": [
                                  {
                                    "concreteType": "JiraIssueFieldSetEdge",
                                    "kind": "LinkedField",
                                    "name": "edges",
                                    "plural": true,
                                    "selections": [
                                      (v24/*: any*/),
                                      (v69/*: any*/),
                                      {
                                        "concreteType": "JiraIssueFieldSet",
                                        "kind": "LinkedField",
                                        "name": "node",
                                        "plural": false,
                                        "selections": [
                                          (v24/*: any*/),
                                          (v33/*: any*/),
                                          (v34/*: any*/),
                                          (v67/*: any*/)
                                        ]
                                      }
                                    ]
                                  },
                                  (v70/*: any*/)
                                ]
                              },
                              {
                                "args": (v32/*: any*/),
                                "filters": [],
                                "handle": "connection",
                                "key": "IssueTable_IssueRow__fieldSetsById",
                                "kind": "LinkedHandle",
                                "name": "fieldSetsById"
                              }
                            ]
                          },
                          {
                            "condition": "shouldQueryFieldSetsById",
                            "kind": "Condition",
                            "passingValue": false,
                            "selections": [
                              {
                                "args": (v23/*: any*/),
                                "concreteType": "JiraIssueFieldSetConnection",
                                "kind": "LinkedField",
                                "name": "fieldSetsForIssueSearchView",
                                "plural": false,
                                "selections": [
                                  {
                                    "concreteType": "JiraIssueFieldSetEdge",
                                    "kind": "LinkedField",
                                    "name": "edges",
                                    "plural": true,
                                    "selections": [
                                      (v24/*: any*/),
                                      (v69/*: any*/),
                                      {
                                        "concreteType": "JiraIssueFieldSet",
                                        "kind": "LinkedField",
                                        "name": "node",
                                        "plural": false,
                                        "selections": [
                                          (v24/*: any*/),
                                          (v33/*: any*/),
                                          (v34/*: any*/),
                                          (v68/*: any*/)
                                        ]
                                      }
                                    ]
                                  },
                                  (v70/*: any*/)
                                ]
                              },
                              {
                                "args": (v23/*: any*/),
                                "filters": [],
                                "handle": "connection",
                                "key": "IssueTable_IssueRow__fieldSetsForIssueSearchView",
                                "kind": "LinkedHandle",
                                "name": "fieldSetsForIssueSearchView"
                              }
                            ]
                          }
                        ]
                      },
                      {
                        "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcischildissuestotalcountenabledrelayprovider",
                        "kind": "Condition",
                        "passingValue": true,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "name": "childIssues",
                            "plural": false,
                            "selections": [
                              (v24/*: any*/),
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  {
                                    "args": [
                                      {
                                        "kind": "Literal",
                                        "name": "activeProjectsOnly",
                                        "value": true
                                      },
                                      {
                                        "kind": "Literal",
                                        "name": "first",
                                        "value": 10
                                      }
                                    ],
                                    "concreteType": "JiraIssueConnection",
                                    "kind": "LinkedField",
                                    "name": "issues",
                                    "plural": false,
                                    "selections": [
                                      (v50/*: any*/)
                                    ],
                                    "storageKey": "issues(activeProjectsOnly:true,first:10)"
                                  }
                                ],
                                "type": "JiraChildIssuesWithinLimit"
                              }
                            ]
                          }
                        ]
                      },
                      {
                        "kind": "ClientExtension",
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "name": "isHighlightedIssueRow"
                          }
                        ]
                      }
                    ]
                  },
                  (v24/*: any*/),
                  (v71/*: any*/)
                ]
              },
              {
                "kind": "ScalarField",
                "name": "totalIssueSearchResultCount"
              },
              {
                "args": [
                  {
                    "kind": "Literal",
                    "name": "maxCursors",
                    "value": 7
                  }
                ],
                "concreteType": "JiraPageCursors",
                "kind": "LinkedField",
                "name": "pageCursors",
                "plural": false,
                "selections": [
                  {
                    "concreteType": "JiraPageCursor",
                    "kind": "LinkedField",
                    "name": "around",
                    "plural": true,
                    "selections": [
                      (v69/*: any*/),
                      (v72/*: any*/),
                      (v73/*: any*/)
                    ]
                  },
                  {
                    "concreteType": "JiraPageCursor",
                    "kind": "LinkedField",
                    "name": "last",
                    "plural": false,
                    "selections": [
                      (v73/*: any*/),
                      (v69/*: any*/),
                      (v72/*: any*/)
                    ]
                  },
                  {
                    "concreteType": "JiraPageCursor",
                    "kind": "LinkedField",
                    "name": "first",
                    "plural": false,
                    "selections": [
                      (v69/*: any*/),
                      (v73/*: any*/),
                      (v72/*: any*/)
                    ]
                  }
                ],
                "storageKey": "pageCursors(maxCursors:7)"
              },
              {
                "concreteType": "JiraIssueNavigatorPageInfo",
                "kind": "LinkedField",
                "name": "issueNavigatorPageInfo",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "name": "firstIssuePosition"
                  },
                  {
                    "kind": "ScalarField",
                    "name": "lastIssuePosition"
                  },
                  {
                    "kind": "ScalarField",
                    "name": "firstIssueKeyFromNextPage"
                  },
                  {
                    "kind": "ScalarField",
                    "name": "lastIssueKeyFromPreviousPage"
                  }
                ]
              },
              {
                "kind": "LinkedField",
                "name": "issueSearchError",
                "plural": false,
                "selections": [
                  (v24/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "name": "messages"
                      }
                    ],
                    "type": "JiraInvalidJqlError"
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v37/*: any*/),
                      {
                        "kind": "ScalarField",
                        "name": "errorType"
                      }
                    ],
                    "type": "JiraInvalidSyntaxError"
                  }
                ]
              },
              {
                "kind": "ScalarField",
                "name": "isCappingIssueSearchResult"
              },
              (v71/*: any*/)
            ]
          },
          {
            "args": (v16/*: any*/),
            "concreteType": "JiraUserPreferences",
            "kind": "LinkedField",
            "name": "userPreferences",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "name": "jqlBuilderSearchMode"
              },
              {
                "kind": "ScalarField",
                "name": "isNaturalLanguageSpotlightTourEnabled"
              },
              (v71/*: any*/)
            ]
          },
          {
            "args": (v74/*: any*/),
            "kind": "ScalarField",
            "name": "shouldShowAtlassianIntelligence"
          },
          {
            "args": (v74/*: any*/),
            "kind": "LinkedField",
            "name": "atlassianIntelligenceAction",
            "plural": false,
            "selections": [
              (v24/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "kind": "ScalarField",
                    "name": "isAccessible"
                  }
                ],
                "type": "JiraAccessAtlassianIntelligenceFeature"
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "kind": "ScalarField",
                    "name": "link"
                  }
                ],
                "type": "JiraEnableAtlassianIntelligenceDeepLink"
              }
            ]
          },
          {
            "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcissuesearchviewresultrelayprovider",
            "kind": "Condition",
            "passingValue": false,
            "selections": [
              {
                "args": (v75/*: any*/),
                "concreteType": "JiraIssueSearchView",
                "kind": "LinkedField",
                "name": "issueSearchViewByNamespaceAndViewId",
                "plural": false,
                "selections": (v77/*: any*/)
              }
            ]
          },
          {
            "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcissuesearchviewresultrelayprovider",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "args": (v75/*: any*/),
                "kind": "LinkedField",
                "name": "issueSearchViewResult",
                "plural": false,
                "selections": [
                  (v24/*: any*/),
                  {
                    "kind": "TypeDiscriminator",
                    "abstractKey": "__isJiraIssueSearchViewResult"
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v77/*: any*/),
                    "type": "JiraIssueSearchView"
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v37/*: any*/),
                      {
                        "kind": "LinkedField",
                        "name": "extensions",
                        "plural": true,
                        "selections": [
                          (v24/*: any*/),
                          {
                            "kind": "ScalarField",
                            "name": "statusCode"
                          }
                        ]
                      }
                    ],
                    "type": "QueryError"
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v18/*: any*/)
                    ],
                    "type": "Node",
                    "abstractKey": "__isNode"
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "ae5d4ec89b8904f489a246c6f90962dbc6569bbb7322b6503be3308def2541bd",
    "metadata": {},
    "name": "main_IssueNavigatorQuery",
    "operationKind": "query",
    "text": null,
    "providedVariables": {
      "__relay_internal__pv__atlassianjirarelayprovidersrcissuesearchviewresultrelayprovider": isIssueSearchViewResultEnabled_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcischildissuestotalcountenabledrelayprovider": isChildIssueTotalCountEnabled_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcjscm1apiupdatesrelayprovider": isJscM1ApiUpdatesEnabled_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcstatusinlineeditrelayprovider": isSpreadsheetUIEnabled_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcparentfromissueviewrelayprovider": isIssueViewParentComponentEnabled_provider
    }
  }
};
})();

(node as any).hash = "ac2645746e8a36ff02277b3aac6b42cd";

export default node;
