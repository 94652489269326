import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarServicedesk from '@atlassian/jira-navigation-apps-sidebar-servicedesk/src/async';
import { createEntry } from '@atlassian/jira-route-entry';
import type { Route } from '@atlassian/jira-router';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/index.tsx';
import { getServicedeskPageContextResources } from '@atlassian/jira-router-resources-service-desk-common';
import { routeGroupsProjectSettingsServicedeskKbRoute } from '@atlassian/jira-router-routes-project-settings-service-desk-knowledge-base-routes';
import { serviceProjectLayout } from '@atlassian/jira-service-project-layout';
import { getSettingsNavigationResources, SERVICE_DESK_PRODUCT } from './ui/product-routes/common';
import ServiceDeskKnowledgeBase, {
	ServiceDeskKnowledgeBaseV2,
	LazyProjectSettingsServiceDeskKnowledgeBaseV2,
} from './ui/service-desk-knowledge-base';

export { ServiceDeskKnowledgeBaseV2, ServiceDeskKnowledgeBase };

export const knowledgeSettingsRouteEntries: Route[] = [
	createEntry(routeGroupsProjectSettingsServicedeskKbRoute, {
		perfMetricKey: 'service-management.kb-settings',
		layout: serviceProjectLayout,
		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarServicedesk,
			sidebarId: SIDEBAR_ID.PROJECT_SETTINGS,
		},
		component: ServiceDeskKnowledgeBaseV2,
		forPaint: [LazyAtlassianNavigation, LazyProjectSettingsServiceDeskKnowledgeBaseV2],
		resources: [
			...getSettingsNavigationResources(SERVICE_DESK_PRODUCT),
			...getServicedeskPageContextResources(),
			getConsolidationStateResource(),
		],
	}),
];
