import {
	ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK,
	ROUTE_NAMES_SERVICEDESK_SLA_SETTINGS,
} from '@atlassian/jira-common-constants/src/spa-routes';

export const SERVICE_DESK_PRODUCT = 'servicedesk';

export const servicedeskSlaSettingsRoute = {
	group: ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK,
	name: ROUTE_NAMES_SERVICEDESK_SLA_SETTINGS,
	path: '/jira/servicedesk/projects/:projectKey/settings/sla/(custom|new)?/:id?/(edit)?',
};
