import type { EntryPointRouteParams } from '@atlassian/jira-entry-points-plugin/src/common/types.tsx';
import { ff } from '@atlassian/jira-feature-flagging';
import srcVirtualAgentViewStandardFlowQuery from '@atlassian/jira-relay/src/__generated__/srcVirtualAgentViewStandardFlowQuery$parameters';
import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

export const viewStandardFlowEntryPoint = createEntryPoint({
	root: JSResourceForInteraction(
		() => import(/* webpackChunkName: "async-virtual-agent-view-standard-flow" */ './src'),
	),
	getPreloadProps: ({ context, tenantContext }: EntryPointRouteParams) => ({
		queries: {
			page: {
				parameters: srcVirtualAgentViewStandardFlowQuery,
				variables: {
					cloudId: tenantContext.cloudId,
					projectKey: context.match.params.projectKey ?? '',
					flowId: context.match.params.flowId ?? '',
					checkForAiEnabledFF: ff('check-for-ai-enabling-in-get-premium-banner_oxh6r'),
				},
			},
		},
	}),
});
