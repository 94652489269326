import type { CreateUIAnalyticsEvent } from '@atlaskit/analytics-next';
import { CORE_PROJECT, type ProjectType } from '@atlassian/jira-common-constants';
import { UNSAFE_noExposureExp } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import { getJswTrackDeferSitenameSiteTrait } from '@atlassian/jira-onboarding-core';
import { ONBOARDING_SELECTED_PROJECT_TEMPLATE_KEY } from '@atlassian/jira-onboarding-core/src/constants';
import {
	fireOperationalAnalytics,
	fireTrackAnalytics,
} from '@atlassian/jira-product-analytics-bridge';
import type { AnalyticsAttributes } from '@atlassian/jira-product-analytics-bridge/src/types';
import { getOnboardingPremiumTrial } from '@atlassian/jira-reverse-trial-utils';
import { type TenantContext, PREMIUM_EDITION } from '@atlassian/jira-shared-types';
import type { Action } from '@atlassian/react-sweet-state';
import {
	KEY_OPEN,
	KEY_DISMISSED,
	KEY_OPEN_TASK,
	KEY_TASK_GROUP,
	PREMIUM_REVERSE_TRIAL_TASK,
	PREMIUM_EDITION_OVERVIEW_TASK,
	PREMIUM_EDITION_OVERVIEW_TASK_NON_ADMIN,
	STANDARD_EDITION_OVERVIEW_TASK,
	KEY_HAS_EVER_SEEN_ROUTE,
	convertToHaveSeenItTask,
	KEY_JSW_ONBOARDING,
	PACKAGE_NAME,
	PACKAGE_TEAM_NAME,
	KEY_PREMIUM_TRIAL_REOPENED,
	QUICKSTART_TYPE,
	getBackendVisibilityKey,
	QUICKSTART_UI_STATUS,
	JSM_BUSINESS_TASKS,
	allTasksExcludingJSMBusinessTasks,
} from '../../../common/constants';
import { pollBackend, pushToBackend } from '../../../common/utils';
import { fireAPIErrorAnalytics } from '../../../utils/analytics';
import { isUserEnrolledInTailoredOnboardingExperiment } from '../../../utils/tailored-onboarding';
import { getCrossProjectBoardTrait } from '../../cross-project-board-to-plan-upsell-personalization-trait';
import type { Attribute } from '../../cross-project-board-to-plan-upsell-personalization-trait/types';
import { getIsDevOpsProject } from '../../devops';
import type { State } from '../../model/types';
import { getOnboardingSkipApplicationProperty } from '../../skip-onboarding';
import { nextSetChecklistIsDismissed } from '../next-checklist-dismiss';
import { nextSetChecklistIsOpen } from '../next-checklist-open';
import { nextMarkAsCompletedTask } from '../next-complete-task';
import { nextChangeTaskGroupParent, nextGoBackToPreviousTaskGroup } from '../next-task-group';
import type { Props } from '../types';
import { getPersonaExperience } from './utils';

const allTasks = [...allTasksExcludingJSMBusinessTasks, ...JSM_BUSINESS_TASKS];

type RefreshAppStateProps = {
	canEditPlans: boolean;
	isAdvancedRoadmapsTrial: boolean;
	isTargetedForReverseTrials: boolean;
	createAnalyticsEvent: CreateUIAnalyticsEvent;
	projectType?: ProjectType;
} & Pick<TenantContext, 'cloudId' | 'atlassianAccountId' | 'appEditions' | 'isAdmin'>;

export const refreshAppState =
	({
		atlassianAccountId,
		cloudId,
		appEditions,
		isAdmin,
		canEditPlans,
		isAdvancedRoadmapsTrial,
		isTargetedForReverseTrials,
		createAnalyticsEvent,
		projectType,
	}: RefreshAppStateProps): Action<State, Props> =>
	async ({ getState, setState, dispatch }) => {
		// Need to check to see if there is anything in the backend to take that as source of truth
		// If there is nothing then use defaults
		// Check backend for flags that are marked as completed (true)
		// Carry over state from previous actions
		// Create Completed Actions

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const updatedState: Record<string, any> = {};

		const hasSeenTasks = [
			convertToHaveSeenItTask(PREMIUM_EDITION_OVERVIEW_TASK),
			convertToHaveSeenItTask(PREMIUM_EDITION_OVERVIEW_TASK_NON_ADMIN),
			convertToHaveSeenItTask(STANDARD_EDITION_OVERVIEW_TASK),
		];

		const { projectState = null } = getState();

		// Open QS Checklist as default state if state cannot be determined from the backend
		updatedState.isOpen = true;
		updatedState.isDismissed = false;
		updatedState.quickstartStateProjectType = projectType;
		let backendVisibilityKeys: string[] = [];
		let jsmTaskProjectKeys: string[] = [];
		// Open QS Checklist as default state if state cannot be determined from the backend
		updatedState.visibility = Object.values(QUICKSTART_TYPE).reduce<
			Record<QUICKSTART_TYPE, string>
		>(
			(acc, type) => {
				acc[type] = QUICKSTART_UI_STATUS.OPEN;
				return acc;
			},
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			{} as Record<QUICKSTART_TYPE, string>,
		);

		backendVisibilityKeys = Object.values(QUICKSTART_TYPE).map((type) =>
			getBackendVisibilityKey(type),
		);
		if (projectState?.projectId)
			jsmTaskProjectKeys = JSM_BUSINESS_TASKS.map((task) => `${task}.${projectState?.projectId}`);

		const backendState = await pollBackend({
			keys: [
				KEY_OPEN,
				KEY_DISMISSED,
				KEY_OPEN_TASK,
				KEY_TASK_GROUP,
				KEY_HAS_EVER_SEEN_ROUTE,
				KEY_JSW_ONBOARDING,
				KEY_PREMIUM_TRIAL_REOPENED,
				...allTasks,
				...hasSeenTasks,
				...backendVisibilityKeys,
				...jsmTaskProjectKeys,
			],
		});

		if (projectState && projectState.projectKey) {
			updatedState.isDevOpsProject = await getIsDevOpsProject(projectState.projectKey);
		}

		const backendStateExists = backendState !== undefined && Object.keys(backendState).length > 0;

		if (backendStateExists) {
			updatedState.completedTaskList = Object.keys(backendState).filter(
				(key) =>
					[...allTasksExcludingJSMBusinessTasks, ...jsmTaskProjectKeys].includes(key) &&
					backendState[key] === true,
			);

			updatedState.hasSeenTasks = hasSeenTasks.filter((key) => backendState[key] === true);

			Object.values(QUICKSTART_TYPE).forEach((type: QUICKSTART_TYPE) => {
				// use new state if possible
				if (getBackendVisibilityKey(type) in backendState) {
					updatedState.visibility[type] = backendState[getBackendVisibilityKey(type)];
					// else fallback to old state
				} else if (KEY_DISMISSED in backendState && backendState[KEY_DISMISSED]) {
					updatedState.visibility[type] = QUICKSTART_UI_STATUS.DISMISSED;
				} else if (KEY_OPEN in backendState) {
					updatedState.visibility[type] = backendState[KEY_OPEN]
						? QUICKSTART_UI_STATUS.OPEN
						: QUICKSTART_UI_STATUS.MINIMIZED;
				}
			});

			// Delete when cleaning up isDismissed in state
			if (KEY_DISMISSED in backendState) {
				updatedState.isDismissed = backendState[KEY_DISMISSED];
			}
			// Delete when cleaning up isOpen in state
			if (KEY_OPEN in backendState) {
				updatedState.isOpen = backendState[KEY_OPEN];
			}
			if (KEY_OPEN_TASK in backendState) {
				updatedState.openTask = backendState[KEY_OPEN_TASK];
			}
			if (KEY_HAS_EVER_SEEN_ROUTE in backendState) {
				updatedState.hasEverSeenRoute = backendState[KEY_HAS_EVER_SEEN_ROUTE];
			}

			if (KEY_TASK_GROUP in backendState) {
				updatedState.currentTaskGroupParent =
					// @ts-expect-error - TS2571 - Object is of type 'unknown'.
					backendState[KEY_TASK_GROUP].taskGroup.currentTaskGroupParent;
				updatedState.previousTaskGroupParents =
					// @ts-expect-error - TS2571 - Object is of type 'unknown'.
					backendState[KEY_TASK_GROUP].taskGroup.previousTaskGroupParents;
			}

			if (KEY_JSW_ONBOARDING in backendState) {
				if (
					typeof backendState[KEY_JSW_ONBOARDING] === 'object' &&
					backendState[KEY_JSW_ONBOARDING] !== null &&
					ONBOARDING_SELECTED_PROJECT_TEMPLATE_KEY in backendState[KEY_JSW_ONBOARDING]
				) {
					updatedState.selectedProjectTemplateKeyFromOnboarding =
						backendState[KEY_JSW_ONBOARDING][ONBOARDING_SELECTED_PROJECT_TEMPLATE_KEY];
				}
			}
		}

		if (
			atlassianAccountId &&
			projectType === CORE_PROJECT &&
			isUserEnrolledInTailoredOnboardingExperiment()
		) {
			try {
				const analyticsEvent = createAnalyticsEvent({});
				const personaExperience = await getPersonaExperience(
					cloudId,
					atlassianAccountId,
					(data: AnalyticsAttributes) =>
						fireOperationalAnalytics(
							analyticsEvent,
							'refreshAppState settingPersonaExperienceFromTraits',
							data,
						),
				);

				if (personaExperience) {
					updatedState.personaExperience = personaExperience;
				}
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (error: any) {
				fireAPIErrorAnalytics(error, 'fetchSiteUserPersonalization');
			}
		}
		// Important that this call is before the dismiss migration logic and the premium card permission migration, otherwise it would
		// override the dismiss and currentTaskGroupParent
		setState(updatedState);

		// Bit of a migration
		// Handles case where non-admin premium users have a premium card child open which they can no longer access as we added more permission checks
		// Or, if a user was on a premium trial and saw premium content but is now free,
		// We take them to the root instead
		if (
			((updatedState.currentTaskGroupParent === PREMIUM_EDITION_OVERVIEW_TASK_NON_ADMIN ||
				updatedState.currentTaskGroupParent === PREMIUM_REVERSE_TRIAL_TASK) &&
				!canEditPlans) ||
			((updatedState.currentTaskGroupParent === PREMIUM_EDITION_OVERVIEW_TASK ||
				updatedState.currentTaskGroupParent === PREMIUM_EDITION_OVERVIEW_TASK_NON_ADMIN ||
				updatedState.currentTaskGroupParent === PREMIUM_REVERSE_TRIAL_TASK) &&
				appEditions.software !== PREMIUM_EDITION)
		) {
			dispatch(nextGoBackToPreviousTaskGroup());
			const analyticsEvent = createAnalyticsEvent({});
			fireOperationalAnalytics(analyticsEvent, 'refreshAppState premiumCardPermissionsRevoked');
		}

		if (!(backendStateExists && KEY_OPEN in backendState)) {
			try {
				const skipApplicationProperty = await getOnboardingSkipApplicationProperty();
				if (skipApplicationProperty.quickstart) {
					dispatch(nextSetChecklistIsDismissed(true));
				}
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (error: any) {
				fireAPIErrorAnalytics(error, 'skipOnboarding');
			}
		}

		const jswTrackDeferSitenameSiteTrait = await getJswTrackDeferSitenameSiteTrait(cloudId, {
			packageName: PACKAGE_NAME,
			teamName: PACKAGE_TEAM_NAME,
		});
		setState({
			isEligibleToUpdateSitename:
				jswTrackDeferSitenameSiteTrait?.value === 'experiment-generated-site-name',
		});

		const setPremiumCardAudienceTrait = async (accountId: string | null) => {
			if (accountId === null) {
				return;
			}

			const trait: Attribute | undefined = await getCrossProjectBoardTrait(
				createAnalyticsEvent,
				cloudId,
				accountId,
			);

			setState({
				isCrossProjectBoardToPlanUpsell: trait?.value === true || false,
			});
		};
		// We only want to check if a non-admin user has the CPB trait if they are in a
		// premium evaluation of jira software (i.e. they have access to Advanced Roadmaps)
		if (appEditions.software === PREMIUM_EDITION && !isAdmin) {
			await setPremiumCardAudienceTrait(atlassianAccountId);
		}

		const { isCrossProjectBoardToPlanUpsell } = getState();

		// If the user is on a JSW Premium instance, perform a fetch to check that they signed up with a Premium Reverse Trial.
		let isReverseTrialForJSW;
		if (isTargetedForReverseTrials && fg('jsw_reverse_trials_feature_gate')) {
			const isOnboardingPremiumTrial = await getOnboardingPremiumTrial();
			isReverseTrialForJSW = isOnboardingPremiumTrial?.premiumTrialOnSignup;
		}

		/**
		 * Re-open QS for:
		 *  - admins on an AdvancedRoadmaps trial who haven't had it re-opened for them yet
		 *  - non-admins on an AdvancedRoadmaps trial with isCrossProjectBoardToPlanUpsell trait
		 */
		// eslint-disable-next-line jira/ff/unsafe-no-exposure
		const [simplifyKanbanQsExpConfig] = UNSAFE_noExposureExp('jsw_simplify_kanban_qs');

		const shouldSimplifyKanbanQs =
			simplifyKanbanQsExpConfig.get('cohort', 'not-enrolled') === 'variation';
		if (
			!shouldSimplifyKanbanQs &&
			!isReverseTrialForJSW &&
			appEditions.software === PREMIUM_EDITION &&
			isAdvancedRoadmapsTrial &&
			(isAdmin || isCrossProjectBoardToPlanUpsell) &&
			atlassianAccountId != null &&
			!backendState[KEY_PREMIUM_TRIAL_REOPENED]
		) {
			// Re-open QS if not already opened
			dispatch(nextSetChecklistIsOpen(true));
			dispatch(nextSetChecklistIsDismissed(false));

			const taskToDispatch = isAdmin
				? PREMIUM_EDITION_OVERVIEW_TASK
				: PREMIUM_EDITION_OVERVIEW_TASK_NON_ADMIN;

			// Go to advanced roadmaps task
			dispatch(nextMarkAsCompletedTask(taskToDispatch));
			dispatch(nextChangeTaskGroupParent(taskToDispatch));

			await pushToBackend({ key: KEY_PREMIUM_TRIAL_REOPENED, value: true });

			const analyticsEvent = createAnalyticsEvent({});
			fireTrackAnalytics(analyticsEvent, 'advancedRoadmapsTask reopened', {
				isAdmin,
			});
		}

		setState({ canEditPlans });
		setState({ isQuickstartLoaded: true });
	};
