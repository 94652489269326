import React from 'react';
import { useFilteredItems } from '@atlassian/jira-navigation-apps-sidebar-common/src/controllers/use-filtered-connect-items';
import { ConnectItem } from './connect-item';
import type { ConnectMenuProps } from './types';

export const ConnectMenu = ({
	items,
	overrides,
	showIcons = false,
	shouldFilter = true,
	submenuId = '',
}: ConnectMenuProps) => {
	const filteredItems = useFilteredItems(items || null, shouldFilter);

	if (filteredItems == null || !filteredItems.length) return null;

	return (
		<>
			{filteredItems.map((item, index) => (
				<ConnectItem
					item={item}
					key={item.id}
					index={index}
					overrides={overrides}
					showIcons={showIcons}
					submenuId={submenuId}
				/>
			))}
		</>
	);
};
