import { defineMessages } from '@atlassian/jira-intl';

// The following are the description for the translators for the different text that we need translating.
// Unfortunately, this needs to copied to individual files because the i18n text extraction tool needs
// to be able to resolve variables statically. This duplication is OK.
const NAME_DESC =
	'Name of the site optimizer metric that will be used as the title on the page and menu item.';
const DESCRIPTION_DESC = 'Description of the site optimizer metric.';
const WARNING_DESC = 'Warning message if metric are getting close to the threshold.';
const EXCEEDED_DESC = 'Message to show to the user if metric has exceeded the threshold.';
const CURRENT_VALUE_LABEL_DESC = 'Label to be shown next to the current value of the metric.';
const CLEANUP_VALUE_LABEL_DESC = 'Label to be shown next to the cleanup value of the metric.';
const GRAPH_HEADER_DESC = 'Text to be shown on the header of the metric graph.';

export default defineMessages({
	nameNonFinal: {
		id: 'admin-pages-performance.common.assets.project-cleanup.name-non-final',
		defaultMessage: 'Projects',
		description: NAME_DESC,
	},
	descriptionNonFinal: {
		id: 'admin-pages-performance.common.assets.project-cleanup.description-non-final',
		defaultMessage:
			'Below, we’ve identified company-managed and team-managed projects in your site that can be archived or trashed because they contain no issues. Removing these projects can improve site performance and stability.',
		description: DESCRIPTION_DESC,
	},
	analysisNonFinal: {
		id: 'admin-pages-performance.common.assets.project-cleanup.analysis-non-final',
		defaultMessage: 'Analysis',
		description: 'Heading for analysis tab',
	},
	activityLogNonFinal: {
		id: 'admin-pages-performance.common.assets.project-cleanup.activity-log-non-final',
		defaultMessage: 'Activity log',
		description: 'Heading for activity log',
	},
	graphHeader: {
		// eslint-disable-next-line jira/i18n/id-named-by-package -- PLEASE FIX THIS SUPPRESSION
		id: 'admin-pages-performance.common.assets.project-role-actor.graph-header',
		defaultMessage: 'Projects over time',
		description: GRAPH_HEADER_DESC,
	},
	// Since we don't have a threshold, these messages below will be unused - keeping them purely to satisfy the Metric type
	// We should make this type more flexible in the future
	warningNonFinal: {
		id: 'admin-pages-performance.common.assets.project-cleanup.warning-non-final',
		defaultMessage: 'The number of projects is nearing our recommended threshold.',
		description: WARNING_DESC,
	},
	exceededNonFinal: {
		id: 'admin-pages-performance.common.assets.project-cleanup.exceeded-non-final',
		defaultMessage: 'There are some project...',
		description: EXCEEDED_DESC,
	},
	currentValueLabel: {
		id: 'admin-pages-performance.common.assets.project-cleanup.current-value-label',
		defaultMessage: 'Projects in your site',
		description: CURRENT_VALUE_LABEL_DESC,
	},
	cleanupValueLabel: {
		id: 'admin-pages-performance.common.assets.project-cleanup.cleanup-value-label',
		defaultMessage: 'Recommended for cleanup',
		description: CLEANUP_VALUE_LABEL_DESC,
	},
});
