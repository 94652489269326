import {
	ROUTE_GROUPS_GLOBAL_SETTINGS,
	ROUTE_NAMES_GLOBAL_SETTINGS_ISSUE_WORKFLOW_EDIT,
	ROUTE_NAMES_GLOBAL_SETTINGS_ISSUE_WORKFLOW,
} from '@atlassian/jira-common-constants/src/spa-routes';

export const globalSettingsIssueWorkflowEditRoute = {
	group: ROUTE_GROUPS_GLOBAL_SETTINGS,
	name: ROUTE_NAMES_GLOBAL_SETTINGS_ISSUE_WORKFLOW_EDIT,
	path: '/jira/settings/issues/workflows/:workflowId',
};

export const globalSettingsIssueWorkflow1Route = {
	group: ROUTE_GROUPS_GLOBAL_SETTINGS,
	name: ROUTE_NAMES_GLOBAL_SETTINGS_ISSUE_WORKFLOW,
	path: '/jira/settings/issues/workflows',
};
