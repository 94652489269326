import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarServicedesk from '@atlassian/jira-navigation-apps-sidebar-servicedesk/src/async';
import { createEntry } from '@atlassian/jira-route-entry';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/index.tsx';
import { getServiceDeskNavigationResources } from '@atlassian/jira-router-resources-service-desk-common';
import { RedirectToLastVisitedProjectPage } from '@atlassian/jira-router-routes-common/src/ui/last-visited-project-page/index.tsx';
import { projectServiceDeskRoute } from '@atlassian/jira-router-routes-servicedesk-default-routes';
import { serviceProjectLayout } from '@atlassian/jira-service-project-layout';

export const projectServiceDeskRouteEntry = createEntry(projectServiceDeskRoute, {
	exact: true,
	isRedirect: true,
	component: RedirectToLastVisitedProjectPage,
	layout: serviceProjectLayout,
	navigation: {
		menuId: MENU_ID.PROJECTS,
		sidebar: AsyncSidebarServicedesk,
	},
	resources: [...getServiceDeskNavigationResources(), getConsolidationStateResource()],
	forPaint: [LazyAtlassianNavigation],
});
