import { CUSTOM_FIELDS } from '@atlassian/jira-admin-pages-performance/src/common/assets/custom-fields/index.tsx';
import {
	ISSUE,
	ISSUE_KEY,
} from '@atlassian/jira-admin-pages-performance/src/common/assets/issue/index.tsx';
import { PROJECT_CLEANUP } from '@atlassian/jira-admin-pages-performance/src/common/assets/project-cleanup/index.tsx';
import {
	PROJECT_ROLE_ACTOR,
	PROJECT_ROLE_ACTOR_KEY,
} from '@atlassian/jira-admin-pages-performance/src/common/assets/project-role-actor/index.tsx';
import type { Metric } from '@atlassian/jira-admin-pages-performance/src/common/assets/types.tsx';

/**
 * List of all metrics. This list should match the list in the server backend.
 * The order of the metrics here is the order that we show to the user.
 */
export const METRICS: ReadonlyArray<Metric> = [
	// Inclusion of issues in metrics is dependent on improving mitigation strategies for issues.
	// Inclusion of issue links in metrics is dependent on improving mitigation strategies for issue links.
	// ISSUE_LINKS,
	// CUSTOM_FIELD_OPTIONS,
	CUSTOM_FIELDS,
	ISSUE,
	PROJECT_ROLE_ACTOR,
	// Inclusion of file attachments in metrics is dependent on improving mitigation strategies for file attachments.
	// FILE_ATTACHMENTS,
	PROJECT_CLEANUP,
];

/** Analytics constants */
export const RUN_ANALYSIS = 'runAnalysis';
export const DELETE_RECOMMENDATIONS = 'deleteRecommendations';
export const DELETE_RECOMMENDATIONS_DROPDOWN = 'deleteRecommendationsDropdown';
export const DELETE_RECOMMENDATIONS_CONFIRM = 'deleteRecommendationsConfirm';
export const DELETE_RECOMMENDATIONS_CANCEL = 'deleteRecommendationsCancel';

export const TAB_CLICKED = 'psiTab';
export const RECOMMENDATIONS_LOADED = 'recommendations loaded';

export const METRIC_CARD_CLICKED = 'mitigationMetricCard';

export const PROJECT_ROLE_ACTOR_FILTER_BY_PROJECT = 'projectRoleActorFilterByProject';
export const projectRoleActorAttributes = { metric: PROJECT_ROLE_ACTOR_KEY };

export const ARCHIVE_ISSUES_CONFIRM = 'archiveIssuesConfirm';
export const RESTORE_ARCHIVE_ISSUES = 'restoreIssueArchival';
export const RESTORE_ARCHIVE_ISSUES_CANCEL = 'restoreIssueCancel';
export const RESTORE_ARCHIVE_ISSUES_TRY_AGAIN = 'restoreIssueTryAgain';
export const VIEW_ISSUES = 'viewIssues';
export const STOP_ISSUE_ARCHIVAL = 'stopIssueArchival';
export const ISSUE_UNARCHIVAL_COMPLETED = 'restoreIssueArchival completed';
export const JQL_SEARCH = 'jqlSearch';
export const DEFAULT_QUERY_SELECTED = 'defaultQuerySelected';
export const issueAnalyticsAttributes = { metric: ISSUE_KEY };

export const CUSTOM_FIELD_MOVE_TO_TRASH = 'customFieldMoveToTrash';
export const CUSTOM_FIELD_MOVE_TO_TRASH_CONFIRM = 'customFieldMoveToTrashConfirm';
export const CUSTOM_FIELD_MOVE_TO_TRASH_CANCEL = 'customFieldMoveToTrashCancel';
export const CUSTOM_FIELD_MOVE_TO_SUCCEEDED = 'customFieldMoveToTrash succeeded';
export const customFieldAttributes = { metric: CUSTOM_FIELDS.key };

export const METRICS_SPOTLIGHT_STARTED = 'metricPerformanceSpotlight started';
export const METRICS_SPOTLIGHT_DISMISSED = 'metricPerformanceSpotlightDismissed';
export const METRICS_SPOTLIGHT_COMPLETED = 'metricPerformanceSpotlight completed';

export const RESTORE_ACTIVITY_LOG = 'restoreActivityLog';
export const DOWNLOAD_CSV = 'downloadActivityLogCsv';

export const PROJECT_CLEANUP_FILTERS_CLEARED = 'projectCleanupFiltersCleared';
export const PROJECT_CLEANUP_FILTER_BY_PROJECT = 'projectCleanupFilterByProjectState';
export const PROJECT_CLEANUP_FILTER_BY_STALE = 'projectCleanupFilterByStale';
export const PROJECT_CLEAN_UP = 'projectCleanUp';
export const PROJECT_CLEANUP_COMPLETED = 'projectCleanup completed';
export const ACTION_TRASH = 'TRASH' as const;
export const ACTION_ARCHIVE = 'ARCHIVE' as const;
export const ACTION_UNARCHIVE = 'UNARCHIVE' as const;
