import {
	ROUTE_GROUPS_EOC,
	ROUTE_NAMES_EOC_INCOMING_CALL,
	ROUTE_NAMES_EOC_INCOMING_CALL_DETAIL,
} from '@atlassian/jira-common-constants/src/spa-routes';
import type { Route } from '@atlassian/jira-router';

// List route
// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const globalIncomingCallRoute = {
	group: ROUTE_GROUPS_EOC,
	name: ROUTE_NAMES_EOC_INCOMING_CALL,
	path: '/jira/settings/products/ops/incoming-call',
} as const as Route;

// Detail route
// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const globalIncomingCallDetailRoute = {
	group: ROUTE_GROUPS_EOC,
	name: ROUTE_NAMES_EOC_INCOMING_CALL_DETAIL,
	path: '/jira/settings/products/ops/incoming-call/:id',
} as const as Route;
