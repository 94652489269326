import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import { Redirect } from '@atlassian/jira-router';
import type PolarisIdeasPageType from '@atlassian/jira-spa-apps-polaris-ideas';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
export const LazyIdeasPage = lazyForPaint<typeof PolarisIdeasPageType>(
	() =>
		markAsProductBundleReady(
			import(
				/* webpackChunkName: "async-polaris-ideas", jiraSpaEntry: "async-polaris-ideas" */ '@atlassian/jira-spa-apps-polaris-ideas'
			),
		),
	{ ssr: false },
);

const useJpdLicensed = () => {
	const context = useTenantContext();
	return context.licensedProducts['jira-product-discovery'];
};

export const RedirectingIdeasPage = () => {
	const jpdLicensed = useJpdLicensed();
	if (!jpdLicensed) {
		return <Redirect to="/jira/polaris/unlicensed" />;
	}
	return <LazyPage Page={LazyIdeasPage} pageId="async-polaris-ideas" shouldShowSpinner={false} />;
};

export default RedirectingIdeasPage;
