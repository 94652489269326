import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import type { ServicedeskServicesWithoutProjectContextType } from '@atlassian/jira-spa-apps-servicedesk-services-without-project-context/src/ui/index.tsx';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';

export const LazyServicedeskServicesWithoutProjectContext = lazyForPaint<
	typeof ServicedeskServicesWithoutProjectContextType
>(
	() =>
		import(
			/* webpackChunkName: "async-servicedesk-services-without-project-context", jiraSpaEntry: "async-servicedesk-services-without-project-context" */ '@atlassian/jira-spa-apps-servicedesk-services-without-project-context'
		),
);

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default () => (
	<LazyPage
		Page={LazyServicedeskServicesWithoutProjectContext}
		pageId="spa-apps/servicedesk/services"
		shouldShowSpinner
	/>
);
