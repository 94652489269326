import {
	ROUTE_GROUPS_GLOBAL_SETTINGS,
	ROUTE_NAMES_SETTINGS_CMDB_FIELD_CONFIG,
} from '@atlassian/jira-common-constants/src/spa-routes';

export const settingsCmdbFieldConfigRoute = {
	group: ROUTE_GROUPS_GLOBAL_SETTINGS,
	name: ROUTE_NAMES_SETTINGS_CMDB_FIELD_CONFIG,
	path: '/secure/admin/CmdbObjectFieldConfiguration',
};
