import {
	FireUiAnalytics as FireUiAnalyticsOriginal,
	type AnalyticsProps as FireUiAnalyticsProps,
} from '@atlassian/jira-product-analytics-bridge';
import { withNestingOptimisation } from '../with-nesting-optimisation';

export const FireUiAnalytics =
	withNestingOptimisation<FireUiAnalyticsProps>(FireUiAnalyticsOriginal);

export type { FireUiAnalyticsProps };
