import {
	ROUTE_GROUPS_GLOBAL_SETTINGS,
	ROUTE_NAMES_GLOBAL_SETTINGS_ISSUE_HIERARCHY,
	ROUTE_NAMES_GLOBAL_SETTINGS_ISSUE_HIERARCHY_REDIRECT,
	ROUTE_NAMES_GLOBAL_SETTINGS_ISSUE_TYPES,
	ROUTE_NAMES_GLOBAL_SETTINGS_ISSUE_TYPES_SCHEMES,
} from '@atlassian/jira-common-constants/src/spa-routes';

export const globalSettingsIssueHierarchyRoute = {
	group: ROUTE_GROUPS_GLOBAL_SETTINGS,
	name: ROUTE_NAMES_GLOBAL_SETTINGS_ISSUE_HIERARCHY,
	path: '/jira/settings/issues/issue-hierarchy',
};

export const globalSettingsIssueHierarchyRedirectRoute = {
	group: ROUTE_GROUPS_GLOBAL_SETTINGS,
	name: ROUTE_NAMES_GLOBAL_SETTINGS_ISSUE_HIERARCHY_REDIRECT,
	path: '/secure/PortfolioHierarchy.jspa',
};

export const globalSettingsIssueTypesRoute = {
	group: ROUTE_GROUPS_GLOBAL_SETTINGS,
	name: ROUTE_NAMES_GLOBAL_SETTINGS_ISSUE_TYPES,
	path: '/jira/settings/issues/issue-types',
};

export const globalSettingsIssueTypesSchemesRoute = {
	group: ROUTE_GROUPS_GLOBAL_SETTINGS,
	name: ROUTE_NAMES_GLOBAL_SETTINGS_ISSUE_TYPES_SCHEMES,
	path: '/jira/settings/issues/issue-type-schemes',
};
