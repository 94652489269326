import React, { type ReactNode } from 'react';
import Heading from '@atlaskit/heading';
import { Box, Inline, Stack, xcss } from '@atlaskit/primitives';
import { useIntl } from '@atlassian/jira-intl';
import { BackButton } from '@atlassian/jira-navigation-apps-sidebar-nav4-back-button/src/ui/back-button/index.tsx';
import { MenuList } from '@atlassian/jira-navigation-system/src/v4';
import { AsyncPageSwitcher } from '../../common/ui/page-switcher';
import type { PageName } from '../../common/ui/page-switcher/types';
import messages from './messages';

type Props = {
	children: ReactNode;
	currentPageName: PageName;
};

export const SidebarContent = ({ children, currentPageName }: Props) => {
	const { formatMessage } = useIntl();

	return (
		<>
			<Stack space="space.200" xcss={settingsHeading}>
				<Inline
					alignBlock="center"
					grow="hug"
					space="space.100"
					testId="navigation-apps-sidebar-nav4-global-settings-common.ui.sidebar-content.header"
				>
					<BackButton />
					<Heading size="small" as="h2">
						{formatMessage(messages.heading)}
					</Heading>
				</Inline>

				<AsyncPageSwitcher currentPageName={currentPageName} />
			</Stack>

			<Box
				xcss={contentStyles}
				testId="navigation-apps-sidebar-nav4-global-settings-common.ui.sidebar-content"
			>
				<MenuList>{children}</MenuList>
			</Box>
		</>
	);
};

const settingsHeading = xcss({
	position: 'sticky',
	top: 'space.0',
	paddingInline: 'space.200',
	paddingBlockStart: 'space.200',
	paddingBlockEnd: 'space.100',
	backgroundColor: 'elevation.surface',
	// @ts-expect-error this is ridiculous I don't want a z-index of 100
	zIndex: 1,
});

const contentStyles = xcss({
	paddingBlockStart: 'space.100',
	paddingBlockEnd: 'space.200',
	paddingInline: 'space.200',
});
