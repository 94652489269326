import { get } from '@atlassian/jira-common-rest/src/internal/latest/frontendconfig/properties';
import type { BaseUrl, ProjectId } from '@atlassian/jira-shared-types/src/general.tsx';

type GetSuccess = {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	[key: string]: any;
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default async ({
	baseUrl,
	projectId,
	properties,
}: {
	baseUrl: BaseUrl;
	projectId: ProjectId;
	properties: string[];
}): Promise<GetSuccess> => {
	const { project } = await get(baseUrl, {
		projectId,
		projectProperties: properties,
		consumerMeta: {
			id: 'remoteGet',
			packageName: 'jiraProjectPropertiesServices',
			teamName: 'jsd-shield',
		},
	});
	return project;
};
