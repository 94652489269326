import { useEffect, useMemo } from 'react';
import getMeta from '@atlassian/jira-get-meta';
import { useProject } from '@atlassian/jira-navigation-apps-sidebar-common/src/controllers/project/index.tsx';

export const useProjectFavicon = __SERVER__
	? () => undefined
	: () => {
			const { data: project } = useProject();
			const iconEle = document.querySelector("link[rel='shortcut icon']");

			// Keep a copy of the default favicon
			const defaultFavicon = useMemo(
				() => (iconEle instanceof HTMLLinkElement ? iconEle.href : ''),
				[iconEle],
			);
			const enabled = useMemo(() => getMeta('ajs-favicon-use-projecticon') === 'true', []);
			useEffect(() => {
				const favicon = project ? project.avatarUrl : defaultFavicon;
				if (enabled && iconEle instanceof HTMLLinkElement) {
					iconEle.href = favicon;
				}

				// Switch back to default favicon
				return () => {
					if (enabled && iconEle instanceof HTMLLinkElement) {
						iconEle.href = defaultFavicon;
					}
				};
			}, [project, iconEle, defaultFavicon, enabled]);
		};
