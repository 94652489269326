import type { StoreActionApi } from '@atlassian/react-sweet-state';
import { ACCOUNT_ID, KEY_HAS_NAVIGATED_FROM_LINK } from '../../../common/constants';
import { localStorageProvider } from '../../../common/utils';
import type { State } from '../../model/types';
import { getNextNonCompleteTask } from '../../utils';
import { nextAutoOpenTask } from '../next-open-task';

export const nextAutoExpand =
	() =>
	({ getState, dispatch }: StoreActionApi<State>) => {
		const hasNudgeToShowAfterNavigation = localStorageProvider().get({
			accountId: ACCOUNT_ID,
			key: KEY_HAS_NAVIGATED_FROM_LINK,
		});
		if (hasNudgeToShowAfterNavigation) {
			return;
		}

		const { openTask, completedTaskList, tasks } = getState();

		if (!tasks) {
			return;
		}
		const { items } = tasks;

		if (
			// @ts-expect-error - TS2345 - Argument of type 'string | null' is not assignable to parameter of type 'string'.
			items.includes(openTask) &&
			// @ts-expect-error - TS2345 - Argument of type 'string | null' is not assignable to parameter of type 'string'.
			!completedTaskList.includes(openTask)
		) {
			return;
		}

		const taskIdToExpand = getNextNonCompleteTask(items, completedTaskList, openTask);
		if (taskIdToExpand === null) {
			return;
		}

		dispatch(nextAutoOpenTask(taskIdToExpand));
	};
