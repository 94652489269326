/* eslint-disable jira/import-whitelist */
import { loadQuery, type PreloadedQuery } from 'react-relay';
import {
	QUERY_PARAM_ORDER_DIRECTION,
	QUERY_PARAM_ORDER_FIELD,
	QUERY_PARAM_SEARCH,
	QUERY_PARAM_STATUS,
} from '@atlassian/jira-project-versions/src/common/constants.tsx';
import {
	transformSortFieldFromParam,
	transformSortOrderFromParam,
	transformStatusFromParam,
} from '@atlassian/jira-project-versions/src/common/utils.tsx';
import getRelayEnvironment from '@atlassian/jira-relay-environment';
import { QueryPromisesMap } from '@atlassian/jira-relay-query-promises';
import { RELAY_RESOURCE_TYPE } from '@atlassian/jira-relay-utils/src/services/resources/index.tsx';
import uiProjectVersionsQuery, {
	type uiProjectVersionsQuery as uiProjectVersionsQueryType,
} from '@atlassian/jira-relay/src/__generated__/uiProjectVersionsQuery.graphql';
import { createResource } from '@atlassian/jira-router';
import { RESOURCE_TYPE_PROJECT_VERSIONS } from '../constants';

export const projectVersionsRelayResource = createResource<
	PreloadedQuery<uiProjectVersionsQueryType>
>({
	type: `${RELAY_RESOURCE_TYPE}_${RESOURCE_TYPE_PROJECT_VERSIONS}`,

	getKey: ({ match }) => {
		const { projectKey } = match.params;
		return `project-version-query-relay-${projectKey}`;
	},

	// @ts-expect-error - might return null
	getData: async ({ match, query }, { tenantContext }) => {
		const variables = {
			initialLoadCount: 20,
			cloudId: tenantContext.cloudId,
			projectKey: match.params.projectKey,
			filter: transformStatusFromParam(query[QUERY_PARAM_STATUS]),
			searchString: query[QUERY_PARAM_SEARCH] || '',
			sortBy: {
				sortByField: transformSortFieldFromParam(query[QUERY_PARAM_ORDER_FIELD]),
				order: transformSortOrderFromParam(query[QUERY_PARAM_ORDER_DIRECTION]),
			},
		};

		const queryReference = loadQuery(getRelayEnvironment(), uiProjectVersionsQuery, variables, {
			fetchPolicy: 'store-or-network',
		});

		if (__SERVER__ && queryReference.id != null) {
			await QueryPromisesMap.get(queryReference.id);
		}

		return queryReference;
	},
	maxAge: 0,
});
