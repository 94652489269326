import { createResource } from '@atlassian/jira-router';
import type { InsightObjectDetailsResponse } from '@atlassian/jira-servicedesk-insight-object-types/src/common/types/object-details.tsx';
import getData from './get-data';

const RESOURCE_TYPE_SERVICEDESK_INSIGHT_OBJECT = 'SERVICEDESK_INSIGHT_OBJECT';
export const insightObjectResource = createResource<InsightObjectDetailsResponse | null>({
	type: RESOURCE_TYPE_SERVICEDESK_INSIGHT_OBJECT,
	getKey: ({ match }) => match.params.objectId || '',
	getData,
	isBrowserOnly: true,
});
