import React, { useEffect, useRef, useState } from 'react';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/index.tsx';
import {
	useActiveRightSidebarState,
	useRightSidebarController,
} from '@atlassian/jira-layout-controller/src/controllers/layout-controller/consumers/right-sidebar-controller/index.tsx';
import { AsyncRightPanel } from './async';
import { PANEL_ID } from './constants';

export { PANEL_ID, SIDEBAR_WIDTH } from './constants';
export { useSetProjectListRightPanelState } from '@atlassian/jira-growth-recommendations-in-project-list/src/services/set-right-panel-state/index.tsx';

const JiraProjectsTemplateListRightPanel = () => {
	const rightSidebarCurrentState = useActiveRightSidebarState();
	const { closeRightSidebar } = useRightSidebarController();
	const [localPanelId, setLocalPanelId] = useState<string | undefined>();
	const prevPanelIdRef = useRef<string | undefined>();
	const delayRef = useRef<NodeJS.Timeout | undefined>(undefined);
	const panelId = rightSidebarCurrentState?.panelId;

	/**
	 * This use effect is used to add (when is necessary) a delay
	 * in the panelId => localPanelId syncing
	 */
	useEffect(() => {
		clearTimeout(delayRef.current);

		if (prevPanelIdRef.current === PANEL_ID) {
			/**
			 * The right-sidebar is showing the templates panel...
			 */
			if (
				rightSidebarCurrentState &&
				panelId !== undefined &&
				!rightSidebarCurrentState.isCollapsed &&
				!rightSidebarCurrentState.isMinimised
			) {
				/**
				 * and is open, not collapsed and we want to show a panel
				 * with an ID !== PANEL_ID
				 */
				setLocalPanelId(panelId);
			} else {
				/**
				 * and we want to close it
				 */
				delayRef.current = setTimeout(() => {
					setLocalPanelId(panelId);
				}, 310);
			}
		} else {
			/**
			 * If the right sidebar is not showing the templates panel,
			 * we just keep localPanelId in sync with panelId
			 */
			setLocalPanelId(panelId);
		}

		prevPanelIdRef.current = panelId;

		return () => clearTimeout(delayRef.current);
	}, [panelId, rightSidebarCurrentState]);

	useEffect(
		() => () => {
			closeRightSidebar(PANEL_ID);
		},
		[closeRightSidebar],
	);

	if (localPanelId !== PANEL_ID) {
		return null;
	}

	return <AsyncRightPanel />;
};

const JiraProjectsTemplateListRightPanelContainer = () => (
	<JSErrorBoundary
		fallback="unmount"
		id="right-sidebar-layout-component"
		packageName="jiraGrowthRecommendationsInProjectList"
	>
		<JiraProjectsTemplateListRightPanel />
	</JSErrorBoundary>
);

export { JiraProjectsTemplateListRightPanelContainer as JiraProjectsTemplateListRightPanel };
