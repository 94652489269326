import {
	ROUTE_GROUPS_GLOBAL_SETTINGS,
	ROUTE_NAMES_SETTINGS_OAUTH_CREDENTIALS,
} from '@atlassian/jira-common-constants/src/spa-routes';

export const settingsOauthCredentialsRoute = {
	group: ROUTE_GROUPS_GLOBAL_SETTINGS,
	name: ROUTE_NAMES_SETTINGS_OAUTH_CREDENTIALS,
	path: '/secure/admin/oauth-credentials',
};
