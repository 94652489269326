import {
	ROUTE_GROUPS_HOME,
	ROUTE_NAMES_HOME_ONBOARDING_REDIRECT,
} from '@atlassian/jira-common-constants/src/spa-routes';

export const homeOnboardingRedirectRoute = {
	group: ROUTE_GROUPS_HOME,
	name: ROUTE_NAMES_HOME_ONBOARDING_REDIRECT,
	path: '/jira/your-work-onboarding',
	exact: true,
};
