import {
	ROUTE_GROUPS_GLOBAL_SETTINGS,
	ROUTE_NAMES_GLOBAL_SETTINGS_CONTACT_ADMINISTRATOR,
} from '@atlassian/jira-common-constants/src/spa-routes';

export const globalSettingsContactAdministratorRoute = {
	group: ROUTE_GROUPS_GLOBAL_SETTINGS,
	name: ROUTE_NAMES_GLOBAL_SETTINGS_CONTACT_ADMINISTRATOR,
	path: '/jira/contact-administrator',
};
