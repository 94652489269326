import {
	ROUTE_GROUPS_GLOBAL_SETTINGS,
	ROUTE_NAMES_PRODUCTS_JSM_FEATURE_USAGE,
} from '@atlassian/jira-common-constants/src/spa-routes';

export const productsJsmFeatureUsageRoute = {
	group: ROUTE_GROUPS_GLOBAL_SETTINGS,
	name: ROUTE_NAMES_PRODUCTS_JSM_FEATURE_USAGE,
	path: '/jira/settings/products/servicedesk/feature-usage',
	exact: true,
};
