import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async';
import { ff } from '@atlassian/jira-feature-flagging';
import { componentWithFF } from '@atlassian/jira-feature-flagging-utils';
import { globalLayout } from '@atlassian/jira-global-layout';
import { globalSettingsLayout } from '@atlassian/jira-global-settings-layout';
import { AsyncHorizontalNavPlans } from '@atlassian/jira-horizontal-nav-plans';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common';
import AsyncSidebarGlobalSettingsProducts from '@atlassian/jira-navigation-apps-sidebar-global-settings-products/src/async';
import AsyncSidebarPlan from '@atlassian/jira-navigation-apps-sidebar-plan/src/async';
import { planLayout } from '@atlassian/jira-plan-layout';
import { createEntry } from '@atlassian/jira-route-entry';
import { composeLayouts } from '@atlassian/jira-route-layout';
import {
	advancedRoadmapsPlanPageInitialChecksResource,
	advancedRoadmapsReduxStoreStateResource,
	advancedRoadmapsLastVisitedProgramBoardIdResource,
} from '@atlassian/jira-router-resources-advanced-roadmaps-plan';
import { arjSettingsFinancialYearResource } from '@atlassian/jira-router-resources-advanced-roadmaps-settings';
import {
	getNavigationResources,
	getNavigationSidebarPlanResources,
} from '@atlassian/jira-router-resources-navigation';
import { navigationSidebarGlobalResource } from '@atlassian/jira-router-resources-navigation-sidebar-global';
import { resourceWithCondition2 } from '@atlassian/jira-router-resources-utils';
import {
	archivedPlansRoute,
	trashedPlansRoute,
	trashedPlanDirectLinkRoute,
	planIncrementRoute,
	planIncrementsRoute,
	arjSettingsFinancialYearPageRoute,
} from '@atlassian/jira-router-routes-advanced-roadmaps-routes';
import { ArchivedPlansDirectory, LazyArchivedPlans } from './plan-archive';
import { PlanIncrement, LazyPlanIncrement } from './plan-increment';
import { PlanIncrements } from './plan-increments';
import { PlanProgram, LazyPlanProgram } from './plan-program';
import { TrashedPlansDirectory, LazyTrashedPlans } from './plan-trash';
import { ArjSettingsFinancialYear, LazyArjSettingsFinancialYear } from './settings';
import { TrashedPlanDirectLink, LazyTrashedPlanDirectLink } from './trashed-plan-direct-link';
import { withRedirection } from './utils';

// /program route - new: redirect or empty state/ old: "Increments" page
export const planIncrementsRouteEntry = createEntry(planIncrementsRoute, {
	// delete this redirection when removing the FF isIncrementPlanningEnabled i.e.
	component: withRedirection(
		componentWithFF(
			'com.atlassian.rm.jpo.jpo3cloud.increment-planning-board-m1',
			PlanProgram,
			PlanIncrements,
		),
		{
			to: '',
			redirectCondition: () => !ff('com.atlassian.rm.jpo.jpo3cloud.increment-planning-board-m1'),
		},
	),
	exact: true,
	layout: composeLayouts(globalLayout, planLayout),
	navigation: {
		menuId: MENU_ID.PLANS,
		sidebar: AsyncSidebarPlan,
		horizontal: AsyncHorizontalNavPlans,
		onlyShowHorziontalOnNav4: true,
	},
	preloadOptions: {
		earlyChunks: ['advanced-roadmaps-early-entry'],
	},
	resources: [
		...getNavigationResources(),
		...getNavigationSidebarPlanResources(),
		advancedRoadmapsPlanPageInitialChecksResource,
		advancedRoadmapsReduxStoreStateResource,
		resourceWithCondition2(
			() => ff('com.atlassian.rm.jpo.jpo3cloud.increment-planning-board-m1'),
			advancedRoadmapsLastVisitedProgramBoardIdResource,
		),
	],
	forPaint: [LazyAtlassianNavigation, LazyPlanProgram],
});

// "Increment" board page
export const planIncrementRouteEntry = createEntry(planIncrementRoute, {
	// delete this redirection when removing the FF isIncrementPlanningEnabled i.e.
	// component: PlanIncrement,
	component: withRedirection(PlanIncrement, {
		to: '',
		redirectCondition: () => !ff('com.atlassian.rm.jpo.jpo3cloud.increment-planning-board-m1'),
	}),
	exact: true,
	layout: composeLayouts(globalLayout, planLayout),
	navigation: {
		menuId: MENU_ID.PLANS,
		sidebar: AsyncSidebarPlan,
		horizontal: AsyncHorizontalNavPlans,
		onlyShowHorziontalOnNav4: true,
	},
	preloadOptions: {
		earlyChunks: ['advanced-roadmaps-early-entry'],
	},
	resources: [
		...getNavigationResources(),
		...getNavigationSidebarPlanResources(),
		advancedRoadmapsPlanPageInitialChecksResource,
		advancedRoadmapsReduxStoreStateResource,
	],
	forPaint: [LazyAtlassianNavigation, LazyPlanIncrement],
});

export const archivedPlansRouteEntry = createEntry(archivedPlansRoute, {
	exact: true,
	component: ArchivedPlansDirectory,
	layout: globalSettingsLayout,
	navigation: {
		sidebarId: SIDEBAR_ID.GLOBAL_PRODUCTS_SETTINGS,
		menuId: MENU_ID.SETTINGS,
		sidebar: AsyncSidebarGlobalSettingsProducts,
	},
	resources: [...getNavigationResources(), navigationSidebarGlobalResource],
	forPaint: [LazyAtlassianNavigation, LazyArchivedPlans],
});

export const trashPlansRouteEntry = createEntry(trashedPlansRoute, {
	exact: true,
	component: TrashedPlansDirectory,
	layout: globalSettingsLayout,
	navigation: {
		sidebarId: SIDEBAR_ID.GLOBAL_PRODUCTS_SETTINGS,
		menuId: MENU_ID.SETTINGS,
		sidebar: AsyncSidebarGlobalSettingsProducts,
	},

	resources: [...getNavigationResources(), navigationSidebarGlobalResource],
	forPaint: [LazyAtlassianNavigation, LazyTrashedPlans],
});

export const trashedPlanDirectLinkRouteEntry = createEntry(trashedPlanDirectLinkRoute, {
	component: TrashedPlanDirectLink,
	layout: globalLayout,
	resources: [...getNavigationResources()],
	forPaint: [LazyAtlassianNavigation, LazyTrashedPlanDirectLink],
	ufoName: 'plan-trashed',
});

export const arjSettingsFinancialYearPageEntry = createEntry(arjSettingsFinancialYearPageRoute, {
	exact: true,
	component: ArjSettingsFinancialYear,
	layout: globalSettingsLayout,
	navigation: {
		sidebarId: SIDEBAR_ID.GLOBAL_PRODUCTS_SETTINGS,
		menuId: MENU_ID.SETTINGS,
		sidebar: AsyncSidebarGlobalSettingsProducts,
	},
	resources: [
		...getNavigationResources(),
		navigationSidebarGlobalResource,
		arjSettingsFinancialYearResource,
	],
	forPaint: [LazyAtlassianNavigation, LazyArjSettingsFinancialYear],
});
