import {
	ROUTE_GROUPS_EOC,
	ROUTE_NAMES_EOC_HEARTBEATS,
} from '@atlassian/jira-common-constants/src/spa-routes';
import type { Route } from '@atlassian/jira-router';

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const heartbeatsRoute = {
	group: ROUTE_GROUPS_EOC,
	name: ROUTE_NAMES_EOC_HEARTBEATS,
	path: '/jira/ops/teams/:teamId/heartbeats',
} as const as Route;
