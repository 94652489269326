export const SIDE_PANEL_WIDTH_SM = 0.35;
export const SIDE_PANEL_WIDTH_LG = 0.6;

export const getInitialEmbeddedConfluencePanelWidth = () => {
	/*
	We set the screen width to 60% of the screen width if the screen
	width is less than 1700px because we want the Embedded Confluence in the side panel to
	take up half the screen width AND force the centrally located Jira Issue Details panel
	into its mobile styles. Otherwise we'll set the panel width to 35% of the screen width.
	If the screen width is less than 1024px, we'll set the panel width to 0 because we will be
	opening the EmbeddedContentModal instead.
	This also assumes that we are currently on the full page version of a Jira Issue.

	*/
	const screenWidth = window.innerWidth;
	if (screenWidth >= 1700) {
		return screenWidth * SIDE_PANEL_WIDTH_SM;
	}
	if (screenWidth < 1700 && screenWidth >= 1024) {
		return screenWidth * SIDE_PANEL_WIDTH_LG;
	}
	return 0;
};
