import { UNSAFE_noExposureExp } from '@atlassian/jira-feature-experiments';
import type { Action } from '@atlassian/react-sweet-state';
import { convertToHaveSeenItTask } from '../../../common/constants';
import { pushToBackend } from '../../../common/utils';
import type { State } from '../../model/types';
import { nextOpenTask } from '../next-open-task';

export const nextSetChecklistItemSeen =
	(taskId: string): Action<State> =>
	({ getState, setState, dispatch }) => {
		const hasSeenTaskId = convertToHaveSeenItTask(taskId);
		const { hasSeenTasks } = getState();

		if (hasSeenTasks.includes(hasSeenTaskId)) {
			return;
		}

		setState({ hasSeenTasks: [...hasSeenTasks, hasSeenTaskId] });

		pushToBackend({
			key: convertToHaveSeenItTask(taskId),
			value: true,
		});

		// This will prioritise expanding this current taskId as the next card to expand.
		// For simplify kanban QS we don't want to expand hasSeen cards first
		// eslint-disable-next-line jira/ff/unsafe-no-exposure
		const [simplifyKanbanQsExpConfig] = UNSAFE_noExposureExp('jsw_simplify_kanban_qs');

		const shouldSimplifyKanbanQs =
			simplifyKanbanQsExpConfig.get('cohort', 'not-enrolled') === 'variation';

		if (shouldSimplifyKanbanQs === false) {
			dispatch(nextOpenTask(taskId));
		}
	};
