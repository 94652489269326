import {
	ROUTE_GROUPS_PROJECT_SETTINGS_CORE,
	ROUTE_NAMES_PROJECT_SETTINGS_CORE_DEFAULT_REDIRECT,
	ROUTE_NAMES_PROJECT_SETTINGS_CORE_ISSUE_TYPES,
	ROUTE_NAMES_PROJECT_SETTINGS_CORE_FIELDS,
	ROUTE_NAMES_PROJECT_SETTINGS_CORE_ISSUE_TYPES_WORKFLOW,
	ROUTE_NAMES_PROJECT_SETTINGS_CORE_APPS,
	ROUTE_NAMES_PROJECT_SETTINGS_CORE_APPS_FIELDS,
	ROUTE_NAMES_PROJECT_SETTINGS_CORE_DETAILS,
	ROUTE_NAMES_PROJECT_SETTINGS_CORE_ACCESS,
	ROUTE_NAMES_PROJECT_SETTINGS_CORE_ACCESS_REDIRECT,
	ROUTE_NAMES_PROJECT_SETTINGS_CORE_PROFORMA_PROJECT_FORMS,
	ROUTE_NAMES_PROJECT_SETTINGS_CORE_PROFORMA_JIRA_FORM_BUILDER,
	ROUTE_NAMES_PROJECT_SETTINGS_CORE_AUTOMATION,
	ROUTE_NAMES_PROJECT_SETTINGS_CORE_DATA_CLASSIFICATIONS,
	ROUTE_NAMES_PROJECT_SETTINGS_CORE_NOTIFICATIONS,
	ROUTE_NAMES_PROJECT_SETTINGS_CORE_NOTIFICATIONS_ISSUE_LOGS,
	ROUTE_NAMES_PROJECT_SETTINGS_CORE_NOTIFICATION_EMAIL,
	ROUTE_NAMES_PROJECT_SETTINGS_CORE_FORGE_APPS,
	ROUTE_NAMES_PROJECT_SETTINGS_CORE_APPROVALS,
} from '@atlassian/jira-common-constants/src/spa-routes';
import type { Route } from '@atlassian/jira-router';

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const projectSettingsCoreDefaultRedirectRoute = {
	group: ROUTE_GROUPS_PROJECT_SETTINGS_CORE,
	name: ROUTE_NAMES_PROJECT_SETTINGS_CORE_DEFAULT_REDIRECT,
	path: '/jira/core/projects/:projectKey/settings',
} as const as Route;

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const projectSettingsCoreIssueTypesRoute = {
	group: ROUTE_GROUPS_PROJECT_SETTINGS_CORE,
	name: ROUTE_NAMES_PROJECT_SETTINGS_CORE_ISSUE_TYPES,
	path: '/jira/core/projects/:projectKey/settings/issuetypes/:issueTypeId?',
} as const as Route;

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const projectSettingsCoreFieldsRoute = {
	group: ROUTE_GROUPS_PROJECT_SETTINGS_CORE,
	name: ROUTE_NAMES_PROJECT_SETTINGS_CORE_FIELDS,
	path: '/jira/core/projects/:projectKey/settings/fields',
} as const as Route;

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const projectSettingsCoreIssueTypesWorkflowRoute = {
	group: ROUTE_GROUPS_PROJECT_SETTINGS_CORE,
	name: ROUTE_NAMES_PROJECT_SETTINGS_CORE_ISSUE_TYPES_WORKFLOW,
	path: '/jira/core/projects/:projectKey/settings/issuetypes/:issueTypeId/:initialSection(workflow)',
} as const as Route;

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const projectSettingsCoreAppsRoute = {
	group: ROUTE_GROUPS_PROJECT_SETTINGS_CORE,
	name: ROUTE_NAMES_PROJECT_SETTINGS_CORE_APPS,
	path: '/jira/core/projects/:projectKey/settings/apps/:appId?',
} as const as Route;

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const projectSettingsCoreAppsFieldsRoute = {
	group: ROUTE_GROUPS_PROJECT_SETTINGS_CORE,
	name: ROUTE_NAMES_PROJECT_SETTINGS_CORE_APPS_FIELDS,
	path: '/jira/core/projects/:projectKey/settings/apps/app-fields',
} as const as Route;

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const projectSettingsCoreDetailsRoute = {
	group: ROUTE_GROUPS_PROJECT_SETTINGS_CORE,
	name: ROUTE_NAMES_PROJECT_SETTINGS_CORE_DETAILS,
	path: '/jira/core/projects/:projectKey/settings/details',
} as const as Route;

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const projectSettingsCoreAccessRoute = {
	group: ROUTE_GROUPS_PROJECT_SETTINGS_CORE,
	name: ROUTE_NAMES_PROJECT_SETTINGS_CORE_ACCESS,
	path: '/jira/core/projects/:projectKey/settings/access',
} as const as Route;

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const projectSettingsCoreAccessRedirectRoute = {
	group: ROUTE_GROUPS_PROJECT_SETTINGS_CORE,
	name: ROUTE_NAMES_PROJECT_SETTINGS_CORE_ACCESS_REDIRECT,
	path: '/jira/core/projects/:projectKey/settings/people',
} as const as Route;

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const projectSettingsCoreProformaProjectFormsRoute = {
	group: ROUTE_GROUPS_PROJECT_SETTINGS_CORE,
	name: ROUTE_NAMES_PROJECT_SETTINGS_CORE_PROFORMA_PROJECT_FORMS,
	path: '/jira/core/projects/:projectKey/settings/forms',
} as const as Route;

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const projectSettingsCoreProformaJiraFormBuilderRoute = {
	group: ROUTE_GROUPS_PROJECT_SETTINGS_CORE,
	name: ROUTE_NAMES_PROJECT_SETTINGS_CORE_PROFORMA_JIRA_FORM_BUILDER,
	path: '/jira/core/projects/:projectKey/settings/forms/form/:templateFormId/edit',
} as const as Route;

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const projectSettingsCoreAutomationRoute = {
	group: ROUTE_GROUPS_PROJECT_SETTINGS_CORE,
	name: ROUTE_NAMES_PROJECT_SETTINGS_CORE_AUTOMATION,
	path: '/jira/core/projects/:projectKey/settings/automation',
} as const as Route;

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const projectSettingsCoreAutomationV2Route = {
	group: ROUTE_GROUPS_PROJECT_SETTINGS_CORE,
	name: ROUTE_NAMES_PROJECT_SETTINGS_CORE_AUTOMATION,
	path: '/jira/core/projects/:projectKey/settings/automate',
} as const as Route;

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const projectSettingsCoreDataClassificationsRoute = {
	group: ROUTE_GROUPS_PROJECT_SETTINGS_CORE,
	name: ROUTE_NAMES_PROJECT_SETTINGS_CORE_DATA_CLASSIFICATIONS,
	path: '/jira/core/projects/:projectKey/settings/data-classifications',
} as const as Route;

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const projectSettingsCoreNotificationsRoute = {
	group: ROUTE_GROUPS_PROJECT_SETTINGS_CORE,
	name: ROUTE_NAMES_PROJECT_SETTINGS_CORE_NOTIFICATIONS,
	path: '/jira/core/projects/:projectKey/settings/notifications',
} as const as Route;

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const projectSettingsCoreNotificationsIssueLogsRoute = {
	group: ROUTE_GROUPS_PROJECT_SETTINGS_CORE,
	name: ROUTE_NAMES_PROJECT_SETTINGS_CORE_NOTIFICATIONS_ISSUE_LOGS,
	path: '/jira/core/projects/:projectKey/settings/notifications/issue-logs',
} as const as Route;

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const projectSettingsCoreNotificationsEmailRoute = {
	group: ROUTE_GROUPS_PROJECT_SETTINGS_CORE,
	name: ROUTE_NAMES_PROJECT_SETTINGS_CORE_NOTIFICATION_EMAIL,
	path: '/jira/core/projects/:projectKey/settings/notifications/notification-email',
} as const as Route;

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const projectSettingsCoreForgeAppsRoute = {
	group: ROUTE_GROUPS_PROJECT_SETTINGS_CORE,
	name: ROUTE_NAMES_PROJECT_SETTINGS_CORE_FORGE_APPS,
	path: '/jira/core/projects/:projectKey/settings/apps/:appId/:envId/:route?',
} as const as Route;

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const projectSettingsCoreApprovalsRoute = {
	group: ROUTE_GROUPS_PROJECT_SETTINGS_CORE,
	name: ROUTE_NAMES_PROJECT_SETTINGS_CORE_APPROVALS,
	path: '/jira/core/projects/:projectKey/settings/approvals',
} as const as Route;
