import React from 'react';
import Breadcrumb from '@atlassian/jira-breadcrumbs/src/breadcrumb/component.tsx';
import type { BreadcrumbData } from '../../types';
import { createProjectUrl } from '../common/create-url';
import messages from './messages';
import type { ProjectBreadcrumbPropsWithIntl } from './types';

const ProjectBreadcrumb = ({
	isSimplifiedProject,
	children,
	projectKey,
	projectName,
	projectId,
	intl: { formatMessage },
}: ProjectBreadcrumbPropsWithIntl) => {
	const breadcrumb: BreadcrumbData = {
		href: createProjectUrl(projectKey),
		text: projectName,
		type: 'projectName',
		analyticsAttributes: {
			nextGenProject: isSimplifiedProject,
			projectConfig:
				isSimplifiedProject !== undefined && isSimplifiedProject === true ? 'next-gen' : 'classic',
		},
		analyticsFields: {
			name: 'navigateToProject',
			containerId: projectId,
			containerType: 'project',
		},
	};

	const ProjectsBreadcrumb: BreadcrumbData = {
		href: '/jira/projects',
		text: formatMessage(messages.projectsTitle),
		type: 'projectsDirectory',
		analyticsAttributes: {
			nextGenProject: isSimplifiedProject,
			projectConfig:
				isSimplifiedProject !== undefined && isSimplifiedProject === true ? 'next-gen' : 'classic',
		},
		analyticsFields: {
			name: 'navigateToProjectsList',
		},
	};

	const ProjectNameBreadcrumb = <Breadcrumb breadcrumb={breadcrumb}>{children}</Breadcrumb>;

	return <Breadcrumb breadcrumb={ProjectsBreadcrumb}>{ProjectNameBreadcrumb}</Breadcrumb>;
};

ProjectBreadcrumb.defaultProps = {
	children: null,
	isSimplifiedProject: false,
	projectId: undefined,
};

export default ProjectBreadcrumb;
