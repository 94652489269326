import React, { memo } from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import { SidebarBoundary } from '@atlassian/jira-navigation-apps-sidebar-common/src/async.tsx';
import {
	setPerformanceMarkLoadStart,
	setPerformanceMarkLoadEnd,
} from '@atlassian/jira-navigation-apps-sidebar-common/src/utils/performance-marks/index.tsx';
import type CoreProjectSidebarType from '@atlassian/jira-navigation-apps-sidebar-core/src/ui/index.tsx';

const LazyCoreProjectSidebar = lazyForPaint<typeof CoreProjectSidebarType>(() => {
	setPerformanceMarkLoadStart();
	return import(/* webpackChunkName: "async-sidebar-core" */ './index').then((bundle) => {
		setPerformanceMarkLoadEnd();
		return bundle;
	});
});

const CoreProjectSidebar = () => (
	<SidebarBoundary id="coreProject">
		<LazyCoreProjectSidebar />
	</SidebarBoundary>
);

export default memo(CoreProjectSidebar);
