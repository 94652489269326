import React, { createContext, useContext, useLayoutEffect, useMemo, useState } from 'react';
import { useRouter } from '@atlassian/jira-router';
import type { SidebarNav4ContextType } from '../../common/types';
import { getInitialSelectedPath } from '../../common/utils/selected-path/getInitialSelectedPath';
import { isSelectedPath } from '../../common/utils/selected-path/isSelectedPath';
import { routeToSelectedPath } from '../../common/utils/selected-path/routeToSelectedPath';

/**
 * This context is designed to allow the correct menu item to display during
 * SSR and when navigating.
 *
 * The context is used by the JiraExpandableMenuItem and JiraMenuLink components.
 */
const SidebarNav4Context = createContext<SidebarNav4ContextType | null>(null);

type Props = {
	children: React.ReactNode;
	// By passing a function, we have the ability to make ALL routes initially selected, or none, or just one.
	isInitialSelectedPath?(menuId: string): boolean;
};

export function SidebarNav4ContextProvider(props: Props) {
	const { children, isInitialSelectedPath } = props;
	const [routerContext] = useRouter();
	const initialSelectedPath = getInitialSelectedPath(routerContext);
	const [selectedPath, setSelectedPath] = useState(initialSelectedPath);

	// When the `routerContext` changes, we need to recalculate the selected path.
	// Use layout effect so this happens before render.
	useLayoutEffect(() => {
		setSelectedPath((currentPath) => {
			const newPath = routeToSelectedPath(routerContext);
			return currentPath.toString() === newPath.toString() ? currentPath : newPath;
		});
	}, [routerContext]);

	const sidebarNav4Controller = useMemo(
		() => ({
			getSelectedPath_UNSAFE: () => selectedPath,
			isInitialSelectedPath: isInitialSelectedPath ?? isSelectedPath(initialSelectedPath),
			isSelected: isSelectedPath(selectedPath),
		}),
		[initialSelectedPath, isInitialSelectedPath, selectedPath],
	);

	return (
		<SidebarNav4Context.Provider value={sidebarNav4Controller}>
			{children}
		</SidebarNav4Context.Provider>
	);
}

export function useSidebarNav4() {
	const context = useContext(SidebarNav4Context);

	if (context == null) {
		throw new Error('useSidebarNav4 must be used inside a SidebarNav4ContextProvider');
	}

	return context;
}
