import {
	ROUTE_NAMES_DASHBOARDS_DIRECTORY,
	ROUTE_NAMES_DASHBOARD_EDIT,
	ROUTE_NAMES_DASHBOARD_VIEW,
	ROUTE_NAMES_DASHBOARD_WALLBOARD,
} from '@atlassian/jira-common-constants/src/spa-routes';
import type { RouterContext } from '@atlassian/jira-router';
import { ENTITY_ID, MENU_ID_DASHBOARDS, MENU_ID_DASHBOARDS_VIEW_ALL } from '../../../../constants';
import type { SelectedPath } from '../../../../types';

const routes = new Set<string>([
	ROUTE_NAMES_DASHBOARDS_DIRECTORY,
	ROUTE_NAMES_DASHBOARD_EDIT,
	ROUTE_NAMES_DASHBOARD_VIEW,
	ROUTE_NAMES_DASHBOARD_WALLBOARD,
]);

function convert(routeContext: RouterContext): SelectedPath {
	const routeName = routeContext.route.name;
	const { match, query } = routeContext;
	const path: string[] = [MENU_ID_DASHBOARDS];

	switch (routeName) {
		case ROUTE_NAMES_DASHBOARDS_DIRECTORY:
			path.push(MENU_ID_DASHBOARDS_VIEW_ALL);
			break;

		case ROUTE_NAMES_DASHBOARD_WALLBOARD:
			query.dashboardId && path.push(ENTITY_ID.DASHBOARD(query.dashboardId));
			break;

		default: {
			const dashboardId = match.params.dashboardId;

			if (dashboardId === 'last-visited') {
				// This is not really correct. The problem is we cannot tell what the true dashboardId is in this case.
				path.push(MENU_ID_DASHBOARDS_VIEW_ALL);
			} else {
				dashboardId && path.push(ENTITY_ID.DASHBOARD(dashboardId));
			}

			break;
		}
	}

	return path;
}

export const dashboardsConverter = { routes, convert };
