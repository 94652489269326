import {
	ROUTE_GROUPS_COMPONENTS,
	ROUTE_NAMES_PROJECT_SETTINGS_COMPONENTS,
} from '@atlassian/jira-common-constants/src/spa-routes';

export const projectSettingsComponentsRoute = {
	group: ROUTE_GROUPS_COMPONENTS,
	name: ROUTE_NAMES_PROJECT_SETTINGS_COMPONENTS,
	path: '/plugins/servlet/project-config/:projectKey/administer-components',
};
