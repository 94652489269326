import {
	ROUTE_GROUPS_SERVICEDESK,
	ROUTE_NAMES_SERVICEDESK_CUSTOMER_SERVICE_PRODUCTS,
} from '@atlassian/jira-common-constants/src/spa-routes';
import type { Route } from '@atlassian/jira-router';

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const productsRoute = {
	group: ROUTE_GROUPS_SERVICEDESK,
	name: ROUTE_NAMES_SERVICEDESK_CUSTOMER_SERVICE_PRODUCTS,
	path: '/jira/servicedesk/projects/:projectKey/products',
	exact: true,
} as const as Route;
