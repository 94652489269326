import {
	ROUTE_GROUPS_GLOBAL_SETTINGS,
	ROUTE_NAMES_GLOBAL_SETTINGS_ISSUES_NEW_ISSUE_SEARCH,
} from '@atlassian/jira-common-constants/src/spa-routes';

export const globalSettingsIssuesNewIssueSearchRoute = {
	group: ROUTE_GROUPS_GLOBAL_SETTINGS,
	name: ROUTE_NAMES_GLOBAL_SETTINGS_ISSUES_NEW_ISSUE_SEARCH,
	path: '/jira/settings/issues/new-issue-search-transition',
};
