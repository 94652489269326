import React, { type Dispatch, memo, type SetStateAction, useCallback } from 'react';
import { NestableNavigationContent, Section } from '@atlaskit/side-navigation';
import { useIntl } from '@atlassian/jira-intl';
import type { NestingMenuItemProps } from '@atlassian/jira-navigation-apps-sidebar-common/src/ui/nesting-menu-item/types.tsx';
import { ConnectMenu } from '@atlassian/jira-navigation-apps-sidebar-connect/src/common/ui/connect-menu/main.tsx';
import { useProjectConnectItems } from '@atlassian/jira-navigation-apps-sidebar-connect/src/controllers/project/index.tsx';
import useAutoExpandSidebar from '@atlassian/jira-navigation-apps-sidebar-core/src/controllers/auto-expand-sidebar/index.tsx';
import type { Project } from '../../../../../common/types';
import { messages } from './messages';

type Props = {
	stack: string[];
	project: Project;
	onStackChange: Dispatch<SetStateAction<string[]>>;
};

const ConnectAppMenu = ({ stack, project, onStackChange }: Props) => {
	useAutoExpandSidebar();
	const {
		data: connectItems,
		stack: connectStack,
		selected: selectedConnectApp,
	} = useProjectConnectItems();

	const { formatMessage } = useIntl();

	const projectKey = String(project?.key);

	const getBackMenuItem = useCallback(
		(): NestingMenuItemProps['overrides']['BackMenuItem'] => ({
			href: `/jira/core/projects/${projectKey}`,
			title: formatMessage(messages.itemBack),
		}),
		[formatMessage, projectKey],
	);

	if (connectStack != null && connectStack.length > 0 && selectedConnectApp != null) {
		return (
			<NestableNavigationContent stack={stack} onChange={onStackChange}>
				<Section>
					<ConnectMenu
						items={connectItems}
						showIcons
						overrides={{
							BackMenuItem: getBackMenuItem(),
							navigateOnBackMenuItemClick: true,
						}}
					/>
				</Section>
			</NestableNavigationContent>
		);
	}

	return null;
};

export default memo(ConnectAppMenu);
