import {
	ROUTE_GROUPS_GLOBAL_SETTINGS,
	ROUTE_NAMES_GLOBAL_SETTINGS_GLOBAL_PERMISSIONS,
} from '@atlassian/jira-common-constants/src/spa-routes';

export const globalSettingsGlobalPermissionsRoute = {
	group: ROUTE_GROUPS_GLOBAL_SETTINGS,
	name: ROUTE_NAMES_GLOBAL_SETTINGS_GLOBAL_PERMISSIONS,
	path: '/secure/admin/global-permissions',
};
