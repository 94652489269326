import { PROJECTS_VERSIONS_QUERY_PARAMS } from '@atlassian/jira-common-constants/src/projects-versions-query-params';
import {
	ROUTE_GROUPS_COMPONENTS,
	ROUTE_NAMES_PROJECT_VERSIONS,
} from '@atlassian/jira-common-constants/src/spa-routes';

export const projectVersionsRoute = {
	group: ROUTE_GROUPS_COMPONENTS,
	name: ROUTE_NAMES_PROJECT_VERSIONS,
	path: '/projects/:projectKey',
	query: PROJECTS_VERSIONS_QUERY_PARAMS,
};
