import { createResource } from '@atlassian/jira-router';
import { MIN_MAX_AGE } from '@atlassian/jira-router-resources-utils/src/constants.tsx';
import { RESOURCE_TYPE_FILTERS_DIRECTORY } from './constants';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const filtersDirectoryResource = createResource<any>({
	type: RESOURCE_TYPE_FILTERS_DIRECTORY,
	getKey: () => 'state',
	getDataLoader: () =>
		import(
			/* webpackChunkName: "async-resource-filters-directory", jiraSpaEntry: "async-resource-filters-directory" */ './controllers/get-data'
		),
	maxAge: MIN_MAX_AGE,
});
