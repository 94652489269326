import { useCallback } from 'react';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { createResource, useResource } from '@atlassian/jira-router';
import { userPreferenceAPI } from '@atlassian/jira-user-preferences-services/src/utils/index.tsx';
import { STORE_KEY, RESOURCE_TYPE, RESOURCE_KEY, PACKAGE_NAME } from './constants';
import type {
	GICSmartFieldsFeatureResourceData,
	GICSmartFieldsMyPreferencesResponse,
} from './types';

export const gicSmartFieldsFeatureResource = createResource<GICSmartFieldsFeatureResourceData>({
	type: RESOURCE_TYPE,
	getKey: () => RESOURCE_KEY,
	getData: async (): Promise<GICSmartFieldsFeatureResourceData> => {
		try {
			const data = await userPreferenceAPI.get<GICSmartFieldsMyPreferencesResponse>(STORE_KEY);

			if (!data)
				return {
					hasUserOptedOut: false,
					hasUserDismissedReadMore: false,
				};

			return data;
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			// If value has never been set, we expect a 404
			if (error.statusCode === 404) {
				return {
					hasUserOptedOut: false,
					hasUserDismissedReadMore: false,
				};
			}

			fireErrorAnalytics({
				error,
				meta: {
					id: 'gicSmartFieldsFeatureResource',
					packageName: PACKAGE_NAME,
					teamName: 'deliveroo',
				},
				sendToPrivacyUnsafeSplunk: true,
			});
		}

		return {
			hasUserOptedOut: false,
			hasUserDismissedReadMore: false,
		};
	},
	maxAge: Number.MAX_VALUE,
});

export const useIsSmartFieldsOptIn = () => {
	const { data, update, loading } = useResource(gicSmartFieldsFeatureResource);

	const currentOptInStatus =
		typeof data?.hasUserOptedOut === 'boolean' ? !data.hasUserOptedOut : true;

	const currentDismissedReadMore =
		typeof data?.hasUserDismissedReadMore === 'boolean' ? data.hasUserDismissedReadMore : false;

	const dismissReadMore = useCallback(() => {
		update((previousData) => ({
			...(previousData?.hasUserOptedOut
				? { hasUserOptedOut: previousData.hasUserOptedOut }
				: { hasUserOptedOut: false }),
			hasUserDismissedReadMore: true,
		}));
	}, [update]);

	return {
		isSmartFieldsOptIn: loading ? null : currentOptInStatus,
		isSmartFieldDismissedReadMore: loading ? null : currentDismissedReadMore,
		dismissReadMore,
		loading,
	};
};
