import {
	ROUTE_GROUPS_GLOBAL_SETTINGS,
	ROUTE_NAMES_SETTINGS_AUTOMATION,
} from '@atlassian/jira-common-constants/src/spa-routes';

export const settingsAutomationRoute = {
	group: ROUTE_GROUPS_GLOBAL_SETTINGS,
	name: ROUTE_NAMES_SETTINGS_AUTOMATION,
	path: '/jira/settings/automation',
	exact: true,
};
