// THIS FILE WAS AUTO-GENERATED. MODIFY WITH CAUTION. See README.md
// brandfolder asset id = jx8k5z4r4cbqfnn3t56t46v9
// brandfolder url = https://brandfolder.com/atlassian-brand-creative-library/atlassian-public-asset-library/#!asset/jx8k5z4r4cbqfnn3t56t46v9
import { createIllustration, type SVGViewBox } from '@atlassian/jira-illustration';
import srcDark from '../../assets/generic-error-darkmode.svg';
import srcLight from '../../assets/generic-error.svg';

const Illustration = createIllustration({
	srcLight,
	srcDark,
});

export default Illustration;

// for advanced use only
const viewBox: SVGViewBox = [0, 0, 164, 212];
export { srcLight, srcDark, viewBox };
