import {
	ROUTE_GROUPS_EOC,
	ROUTE_NAMES_EOC_REPORTS,
	ROUTE_NAMES_EOC_GLOBAL_REPORTS,
	ROUTE_NAMES_EOC_REPORTS_UNSUBSCRIBE,
	ROUTE_NAMES_EOC_REPORTS_TEAM,
} from '@atlassian/jira-common-constants/src/spa-routes';

export const eocTeamReportsHomePageRoute = {
	group: ROUTE_GROUPS_EOC,
	name: ROUTE_NAMES_EOC_REPORTS,
	path: '/jira/ops/teams/:teamId/reports',
	exact: true,
};

export const eocGlobalReportsHomePageRouteNav4 = {
	group: ROUTE_GROUPS_EOC,
	name: ROUTE_NAMES_EOC_GLOBAL_REPORTS,
	path: '/jira/ops/reports',
	exact: true,
};

export const eocReportsUnsubscribeRoute = {
	group: ROUTE_GROUPS_EOC,
	name: ROUTE_NAMES_EOC_REPORTS_UNSUBSCRIBE,
	path: '/jira/ops/reports/unsubscribe/:templateId/:subscriptionId',
	exact: true,
};

export const eocGlobalReportsNav4Route = {
	group: ROUTE_GROUPS_EOC,
	name: ROUTE_NAMES_EOC_GLOBAL_REPORTS,
	path: '/jira/ops/reports/:dashboardId',
	exact: true,
};

export const eocReportsTeamRoute = {
	group: ROUTE_GROUPS_EOC,
	name: ROUTE_NAMES_EOC_REPORTS_TEAM,
	path: '/jira/ops/teams/:teamId/reports/:dashboardId',
	exact: true,
};
