import { stringify } from 'query-string';
import { performGetRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import type { Data, FetchArgs, QueryParamExpand } from './types';

export const fetchData = ({ params, header }: FetchArgs): Promise<Data> => {
	const queryParams = {
		...params,
		expand: Object.keys(params.expand || {}).filter(
			(key) =>
				params.expand &&
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				params.expand[key as keyof NonNullable<QueryParamExpand['expand']>],
		),
	};

	const query = stringify(queryParams, { arrayFormat: 'none' });

	const queryString = query ? `?${query}` : '';
	const requestUrl = `/rest/api/2/user${queryString}`;
	// @ts-expect-error - TS2345 - Argument of type '{ headers: HeaderParamForceAccountId; } | {}' is not assignable to parameter of type 'RequestInit | undefined'.
	return performGetRequest(requestUrl, header ? { headers: header } : {});
};
