import {
	ROUTE_GROUPS_EOC,
	ROUTE_NAMES_EOC_GLOBAL_GROUP_DETAIL,
	ROUTE_NAMES_EOC_TEAM_GROUP_DETAIL,
} from '@atlassian/jira-common-constants/src/spa-routes';

export const eocGlobalGroupDetailRoute = {
	group: ROUTE_GROUPS_EOC,
	name: ROUTE_NAMES_EOC_GLOBAL_GROUP_DETAIL,
	path: '/jira/ops/group-detail/:id',
	exact: true,
};

export const eocTeamGroupDetailRoute = {
	group: ROUTE_GROUPS_EOC,
	name: ROUTE_NAMES_EOC_TEAM_GROUP_DETAIL,
	path: '/jira/ops/teams/:teamId/group-detail/:id',
	exact: true,
};
