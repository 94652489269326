import { useCallback, useRef } from 'react';
import { isLoaderError } from 'react-loosely-lazy';
import { isClientFetchError } from '@atlassian/jira-fetch/src/utils/is-error.tsx';
import type { ExperienceAttributes } from '../../common/types';
import { useExperienceContext } from '../../common/ui/use-experience-context';

type Props = {
	experience: string;
	attributes?: ExperienceAttributes;
};
/**
 * Enhances the tracking of failed experiences by excluding certain client-side errors and
 * allowing for the addition of extra attributes to the failure information. This
 * enables more precise and enriched reporting of issues encountered.
 */
export const useExperienceFail = ({ experience, attributes }: Props) => {
	const experienceTracker = useExperienceContext();
	const self = useRef({
		name: experience,
		attributes,
		experienceTracker,
	});
	self.current.name = experience;
	self.current.attributes = attributes;
	self.current.experienceTracker = experienceTracker;
	return useCallback(
		(location: string, error: Error, extraAttributes: ExperienceAttributes = {}) => {
			const { current } = self;

			// ignore client side errors from impacting experience metrics
			if ((isLoaderError(error) && error.name === 'ChunkLoadError') || isClientFetchError(error)) {
				return;
			}
			current.experienceTracker.fail({
				name: current.name,
				error,
				attributes: {
					...current.attributes,
					...extraAttributes,
					location,
				},
			});
		},
		[self],
	);
};
