import {
	ROUTE_GROUPS_HOME,
	ROUTE_NAMES_YOUR_WORK,
} from '@atlassian/jira-common-constants/src/spa-routes';

export const jiraHomeRoute = {
	group: ROUTE_GROUPS_HOME,
	name: ROUTE_NAMES_YOUR_WORK,
	path: '/jira/your-work',
};
