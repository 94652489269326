import { ff } from '@atlassian/jira-feature-flagging';
import { fg } from '@atlassian/jira-feature-gating';
import {
	uifBacklogResource,
	uifBacklogResourceOld,
} from '@atlassian/jira-router-resources-uif-backlog';
import { resourceWithCondition2 } from '@atlassian/jira-router-resources-utils/src/utils/resource-with-condition/index.tsx';
import { boardConfigResource } from './board-config';
import { boardConfigApiResource } from './config-api';
import { boardDataResource } from './data-api';
import { initialAllDataResource } from './initial-all-data';
import { rapidboardResource } from './rapidboard-resource';
import { wrmBundleResource } from './wrm-bundle';

export const shouldLoadUIFBoardResources = () => !__SERVER__;
export const shouldLoadLegacyBoardResources = () => !shouldLoadUIFBoardResources();

export const getRapidboardResources = () => [
	wrmBundleResource,
	resourceWithCondition2(
		() => ff('jsw.classic.board.api-calls.modern-frontend'),
		boardConfigApiResource,
		boardConfigResource,
	),
	resourceWithCondition2(shouldLoadLegacyBoardResources, rapidboardResource),
];

export const getRapidBoardDataResource = () =>
	resourceWithCondition2(
		() => ff('jsw.classic.board.api-calls.modern-frontend'),
		boardDataResource,
		initialAllDataResource,
	);

export const getUIFBacklogDataResource = () => [
	resourceWithCondition2(
		() => shouldLoadUIFBoardResources() && !fg('indexeddb_cache_for_backlog'),
		uifBacklogResourceOld,
	),
	resourceWithCondition2(
		() => shouldLoadUIFBoardResources() && fg('indexeddb_cache_for_backlog'),
		uifBacklogResource,
	),
];
