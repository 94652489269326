import React, { Component } from 'react';
import get from 'lodash/get';
// @ts-expect-error - TS2614 - Module '"history/createBrowserHistory"' has no exported member 'BrowserHistory'. Did you mean to use 'import BrowserHistory from "history/createBrowserHistory"' instead?
import type { BrowserHistory } from 'history/createBrowserHistory';
import queryString from 'query-string';
import { SPA_SSR_RENDERED_MARK } from '@atlassian/jira-common-constants/src/analytics-marks';
import clearMarks from '@atlassian/jira-common-performance/src/clear-marks-with-prefix.tsx';
// eslint-disable-next-line jira/restricted/@atlassian+jira-common-legacy
import { getTenantContext_DEPRECATED_DO_NOT_USE } from '@atlassian/jira-common-util-get-tenant-context';
import log from '@atlassian/jira-common-util-logging/src/log';
import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import type { RouteContext, Query } from '@atlassian/jira-router';
import UFOLoadHold from '@atlassian/react-ufo/load-hold';
import { LOG_LOCATION, SEARCH_FILTER, SHOW_PROJECT_BUILDER_FILTER } from './constants';
import { getNow } from './helpers';

const buildEndpoint = (projectKey: string, initialRedirect: boolean) =>
	`/rest/internal/2/projectlandingpage/${projectKey}${initialRedirect ? '?initialRedirect=true' : ''}`;
type Props = RouteContext & {
	history: BrowserHistory;
};
type SearchParams = {
	[key: string]: string;
};
const allowListedSearchParams = [SEARCH_FILTER, SHOW_PROJECT_BUILDER_FILTER];
const redirectForAnonymousUser = (
	projectKey: string,
	history: BrowserHistory,
	startTime: number,
) => {
	const destination = `/projects/${projectKey}/issues/?filter=allissues`;
	history.replace(destination);
	// Log success
	log.safeInfoWithoutCustomerData(
		LOG_LOCATION,
		'History replace correctly triggered for anonymous user',
		{
			requestTime: getNow() - startTime,
			destination,
		},
	);
};
export const getFilteredSearchParams = (query: Query): SearchParams =>
	Object.fromEntries(
		Object.entries(query).filter(
			([key]) =>
				allowListedSearchParams.some((allowedParam) => key === allowedParam) ||
				key.includes(SEARCH_FILTER),
		),
	);
export const getURLSearchQueryParams = (
	searchQuery: SearchParams,
	queryInitializer: string,
): string => {
	const queryKeys = new URLSearchParams(searchQuery).toString();
	return queryKeys.length > 0 ? `${queryInitializer}${queryKeys}` : '';
};

/**
 * Handles the redirection of users to the most recently accessed project page. This class checks for the project key in the URL or route parameters, fetches the required project data, and redirects the user accordingly. It also manages error logging for failed redirections and provides a tailored experience for anonymous users.
 */
// eslint-disable-next-line jira/react/no-class-components
export class LastVisitedProjectPageRedirect extends Component<Props> {
	componentDidMount() {
		const {
			history,
			location,
			query,
			match: { params },
		} = this.props;

		// First time a project is created
		const { initial } = query;

		// Clear the SSR Ready Mark if present
		clearMarks(SPA_SSR_RENDERED_MARK);
		const startTime = getNow();
		const projectKey =
			// @ts-expect-error - TS2345 - Argument of type 'string | {}' is not assignable to parameter of type 'string'.
			params.projectKey || queryString.parse(get(location, 'search', {})).projectKey;
		if (!params.projectKey) {
			log.safeInfoWithoutCustomerData(LOG_LOCATION, 'queryString', {
				duration: getNow() - startTime,
			});
		}
		if (projectKey) {
			fetchJson(buildEndpoint(projectKey, !!initial)).then(
				(response) => {
					const endTime = getNow();
					let searchQueryParams = '';
					const searchQuery = getFilteredSearchParams(query);
					const queryInitializer = response.data.url.includes('?') ? '&' : '?';
					searchQueryParams = getURLSearchQueryParams(searchQuery, queryInitializer);
					const destination = response.data.url + searchQueryParams;
					history.replace(destination);
					// Log success
					log.safeInfoWithoutCustomerData(LOG_LOCATION, 'History replace correctly triggered', {
						requestTime: endTime - startTime,
						destination,
					});
				},
				(error: Error) => {
					const endTime = getNow();
					if (getTenantContext_DEPRECATED_DO_NOT_USE().isAnonymous) {
						redirectForAnonymousUser(projectKey, history, startTime);
					} else {
						// Log failures
						log.safeErrorWithoutCustomerData(
							LOG_LOCATION,
							`Error on request. Request time: ${endTime - startTime}`,
							error,
						);
					}
				},
			);
		}
	}

	render() {
		return <UFOLoadHold name="last-visited-project-page-redirect" />;
	}
}
export default LastVisitedProjectPageRedirect;
