import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import { createPageEntryPoint } from '@atlassian/jira-create-route-entry-point';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import type JiraBusinessListAppType from '@atlassian/jira-spa-apps-business-list';
import { listEntryPoint } from '@atlassian/jira-spa-apps-business-list/entrypoint';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';

export const LazyListView = lazyForPaint<typeof JiraBusinessListAppType>(
	() =>
		import(
			/* webpackChunkName: "async-business-list", jiraSpaEntry: "async-business-list" */ '@atlassian/jira-spa-apps-business-list'
		),
);

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default () => (
	<LazyPage Page={LazyListView} pageId="async-business-list" shouldShowSpinner />
);

export const listPageEntryPoint = createPageEntryPoint({
	main: listEntryPoint,
	topNavigationMenuId: MENU_ID.PROJECTS,
});
