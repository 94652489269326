import React, { type FC, useEffect, useMemo } from 'react';
import { styled } from '@compiled/react';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { JiraEntryPointContainer } from '@atlassian/jira-entry-point-container';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import { useActiveRightSidebarState } from '@atlassian/jira-layout-controller/src/controllers/layout-controller/consumers/right-sidebar-controller/index.tsx';
import { useProjectContext } from '@atlassian/jira-providers-project-context/src';
import { virtualAgentCreateIntentTemplateSidebarEntryPoint } from '@atlassian/jira-servicedesk-settings-virtual-agent-create-intent-templates-sidebar/entrypoint';
import { ErrorDisplay } from '@atlassian/jira-servicedesk-settings-virtual-agent-create-intent-templates-sidebar/src/ui/content/error-display';
import { VirtualAgentIntentTemplateSidebarSkeleton } from '@atlassian/jira-skeletons/src/ui/virtual-agent/intent-template-sidebar/index.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';

const runtimeProps = {};
export const IntentTemplatesSidebar: FC = () => {
	const rightSidebarCurrentState = useActiveRightSidebarState();
	const panelId = rightSidebarCurrentState?.panelId ?? '';
	const cloudId = useCloudId();
	const { data: projectContextData } = useProjectContext();

	const entryPointParams = useMemo(
		() => ({
			cloudId,
			key: projectContextData?.projectKey ?? '',
		}),
		[cloudId, projectContextData],
	);
	const { entryPointActions, entryPointReferenceSubject } = useEntryPoint(
		virtualAgentCreateIntentTemplateSidebarEntryPoint,
		entryPointParams,
	);

	// eslint-disable-next-line @atlassian/react-entrypoint/no-load-in-hooks
	useEffect(() => {
		if (panelId === 'intentTemplate') {
			entryPointActions.load();
		} else {
			entryPointActions.unload();
		}
	}, [entryPointActions, panelId]);

	return (
		<JiraEntryPointContainer
			id="createIntentTemplatesSidebar"
			packageName="jiraServicedeskSettingsVirtualAgentCreateIntentTemplates"
			entryPointReferenceSubject={entryPointReferenceSubject}
			fallback={<VirtualAgentIntentTemplateSidebarSkeleton />}
			errorFallback={() => (
				<SideBarWrapper>
					<ErrorDisplay refetch={() => window.location.reload()} />
				</SideBarWrapper>
			)}
			runtimeProps={runtimeProps}
		/>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- Ignored via go/DSP-18766
const SideBarWrapper = styled.div({
	display: 'flex',
	flexDirection: 'column',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	borderLeft: `2px solid ${token('color.border', colors.N30A)}`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	background: token('elevation.surface.overlay', colors.N0),
	boxSizing: 'border-box',
	rowGap: token('space.100', '8px'),
	height: '100%',
	overflowY: 'auto',
});

export default IntentTemplatesSidebar;
