import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async';
import { globalSettingsLayout } from '@atlassian/jira-global-settings-layout';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarGlobalSettingsIssues from '@atlassian/jira-navigation-apps-sidebar-global-settings-issues/src/async';
import { createEntry } from '@atlassian/jira-route-entry';
import type { Route } from '@atlassian/jira-router';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation';
import { navigationSidebarGlobalResource } from '@atlassian/jira-router-resources-navigation-sidebar-global';
import { globalSettingsFormDataConnectionsRoute } from '@atlassian/jira-router-routes-admin-pages-proforma-routes';
import ProformaJiraDataConnectionsPage, {
	LazyProformaJiraDataConnectionsPage,
} from './ui/spa/admin-pages/proforma/data-connections';

export const proformaRouteEntries: Route[] = [
	createEntry(globalSettingsFormDataConnectionsRoute, {
		component: ProformaJiraDataConnectionsPage,
		layout: globalSettingsLayout,

		navigation: {
			sidebarId: SIDEBAR_ID.GLOBAL_ISSUES_SETTINGS,
			menuId: MENU_ID.SETTINGS,
			sidebar: AsyncSidebarGlobalSettingsIssues,
		},

		resources: [...getNavigationResources(), navigationSidebarGlobalResource],
		forPaint: [LazyAtlassianNavigation, LazyProformaJiraDataConnectionsPage],
	}),
];
