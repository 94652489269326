import { GraphQLErrors } from '@atlassian/jira-business-graphql-errors';
import { performPostRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import {
	BOARD_ISSUES_JQL,
	CREATED_THIS_WEEK_JQL,
	DONE_THIS_WEEK_JQL,
	DUE_THIS_WEEK_JQL,
	OPEN_ISSUES_JQL,
	PAGED_DATA_PAGE_SIZE,
	UPDATED_THIS_WEEK_JQL,
} from '../constants';
import { SUMMARY_PAGE_DATA_QUERY } from './gql';
import type { Response, SummaryPageDataType } from './types';
import { transformData } from './utils';

const QUERY_NAME = 'SummaryPageData';

type Props = {
	cloudId: string;
	jqlContext: string;
};

export const fetchSummaryPageData = async ({
	cloudId,
	jqlContext,
}: Props): Promise<SummaryPageDataType> => {
	const response = await performPostRequest<Response>(`/rest/gira/1/?operation=${QUERY_NAME}`, {
		body: JSON.stringify({
			query: SUMMARY_PAGE_DATA_QUERY,
			variables: {
				cloudId,
				jqlContext,
				doneThisWeekJql: `${jqlContext} AND ${DONE_THIS_WEEK_JQL}`,
				updatedThisWeekJql: `${jqlContext} AND ${UPDATED_THIS_WEEK_JQL}`,
				createdThisWeekJql: `${jqlContext} AND ${CREATED_THIS_WEEK_JQL}`,
				dueThisWeekJql: `${jqlContext} AND ${DUE_THIS_WEEK_JQL}`,
				issuesByStatusJql: `${jqlContext} AND ${BOARD_ISSUES_JQL}`,
				issuesByPriorityJql: `${jqlContext} AND ${OPEN_ISSUES_JQL}`,
				issuesByAssigneeJql: `${jqlContext} AND ${OPEN_ISSUES_JQL}`,
				issuesByTypeJql: `${jqlContext} AND ${OPEN_ISSUES_JQL}`,
				pagedDataPageSize: PAGED_DATA_PAGE_SIZE,
			},
		}),
	});

	if (response.errors != null) {
		throw new GraphQLErrors(response.errors);
	}

	if (response.data == null) {
		throw new Error('GraphQL response has no data');
	}

	return transformData(response.data);
};
