import {
	ROUTE_GROUPS_DASHBOARD,
	ROUTE_NAMES_DASHBOARD,
	ROUTE_NAMES_DASHBOARD_SELECTED,
	ROUTE_NAMES_DASHBOARD_EDIT,
	ROUTE_NAMES_DASHBOARD_WALLBOARD,
	ROUTE_NAMES_DASHBOARD_VIEW,
	ROUTE_NAMES_DASHBOARD_VIEW_EMBED,
	ROUTE_NAMES_SYSTEM_DASHBOARD_EDIT,
} from '@atlassian/jira-common-constants/src/spa-routes';

export const dashboardRoute = {
	group: ROUTE_GROUPS_DASHBOARD,
	name: ROUTE_NAMES_DASHBOARD,
	path: '/secure/Dashboard.jspa',
};

export const selectedDashboardRoute = {
	group: ROUTE_GROUPS_DASHBOARD,
	name: ROUTE_NAMES_DASHBOARD_SELECTED,
	path: '/secure/Dashboard.jspa',
	query: ['selectPageId'],
};

// IMPORTANT - must precede the view route due to substring path match
export const dashboardEditRoute = {
	group: ROUTE_GROUPS_DASHBOARD,
	name: ROUTE_NAMES_DASHBOARD_EDIT,
	path: '/jira/dashboards/:dashboardId(\\d+)/edit',
	query: ['maximized?'],
	exact: true,
};

export const dashboardWallboardRoute = {
	group: ROUTE_GROUPS_DASHBOARD,
	name: ROUTE_NAMES_DASHBOARD_WALLBOARD,
	path: '/jira/dashboards/wallboard',
	query: ['dashboardId', 'cyclePeriod?', 'transitionFx?', 'random?'],
	exact: true,
};

export const dashboardViewRoute = {
	group: ROUTE_GROUPS_DASHBOARD,
	name: ROUTE_NAMES_DASHBOARD_VIEW,
	path: '/jira/dashboards/:dashboardId(\\d+|last-visited)',
	query: ['maximized?'],
	exact: true,
};

export const dashboardViewEmbedRoute = {
	group: ROUTE_GROUPS_DASHBOARD,
	name: ROUTE_NAMES_DASHBOARD_VIEW_EMBED,
	path: '/jira/dashboards/:dashboardId(\\d+)/embed',
	query: [],
	exact: true,
};

export const systemDashboardEditRoute = {
	group: ROUTE_GROUPS_DASHBOARD,
	name: ROUTE_NAMES_SYSTEM_DASHBOARD_EDIT,
	path: '/secure/admin/EditDefaultDashboard(!default)?.jspa',
	query: ['maximized?'],
};
