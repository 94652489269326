import { createAri } from '@atlassian/jira-platform-ari';
import { createRelayResource } from '@atlassian/jira-relay-utils/src/services/resources/index.tsx';
import QUERY, {
	type customFiltersQuery,
} from '@atlassian/jira-relay/src/__generated__/customFiltersQuery.graphql';
import { RESOURCE_TYPE_NEXTGEN_PROJECT_SETTINGS_CUSTOM_FILTERS } from '../constants';

export const customFiltersResource = createRelayResource<customFiltersQuery>({
	type: RESOURCE_TYPE_NEXTGEN_PROJECT_SETTINGS_CUSTOM_FILTERS,
	getQuery: ({ match }, { tenantContext }) => ({
		parameters: QUERY,
		variables: {
			boardId: createAri({
				resourceOwner: 'jira-software',
				cloudId: tenantContext.cloudId,
				resourceType: 'board',
				resourceId: String(match.params.boardId),
			}),
		},
		options: { fetchPolicy: 'store-and-network' },
	}),
});
