import {
	ROUTE_GROUPS_SOFTWARE,
	ROUTE_GROUPS_CLASSIC_SOFTWARE,
	ROUTE_NAMES_SOFTWARE_SECURITY,
	ROUTE_NAMES_SOFTWARE_SECURITY_CLASSIC,
} from '@atlassian/jira-common-constants/src/spa-routes';

export const softwareSecurityRoute = {
	group: ROUTE_GROUPS_SOFTWARE,
	name: ROUTE_NAMES_SOFTWARE_SECURITY,
	path: '/jira/software/projects/:projectKey/security',
};

export const softwareSecurityClassicRoute = {
	group: ROUTE_GROUPS_CLASSIC_SOFTWARE,
	name: ROUTE_NAMES_SOFTWARE_SECURITY_CLASSIC,
	path: '/jira/software/c/projects/:projectKey/security',
};
