import React from 'react';
import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarEocTeamDashboard, {
	LazyTeamDashboardSidebar,
} from '@atlassian/jira-navigation-apps-sidebar-eoc-team-dashboard/src/async';
import AsyncSidebarGlobalSettingsProducts from '@atlassian/jira-navigation-apps-sidebar-global-settings-products/src/async';
import {
	AsyncHorizontalOperationsConfigurationNav,
	LazyHorizontalOperationsConfigurationNav,
} from '@atlassian/jira-operations-configurations-horizontal-nav/src/async.tsx';
import {
	AsyncHorizontalOperationsNav,
	LazyHorizontalOperationsNav,
} from '@atlassian/jira-operations-horizontal-nav/src/async.tsx';
import { opsTeamLayout } from '@atlassian/jira-ops-team-layout';
import { createEntry } from '@atlassian/jira-route-entry';
import { resources as customerLogResources } from '@atlassian/jira-router-resources-eoc-customer-logs';
import { resources as integrationAutomationResources } from '@atlassian/jira-router-resources-eoc-integration-automation';
import { resources as teamDashboardResources } from '@atlassian/jira-router-resources-eoc-team-dashboard';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/index.tsx';
import {
	getNavigationResources,
	getNavigationSidebarProductsSettingResource,
} from '@atlassian/jira-router-resources-navigation';
import {
	globalIntegrationsRoute,
	globalIntegrationCreateRoute,
	globalIntegrationDetailRoute,
	globalIntegrationLogRoute,
	globalIntegrationListRoute,
	teamIntegrationLogRoute,
	teamIntegrationsRoute,
	teamIntegrationListRoute,
	teamIntegrationCreateRoute,
	teamIntegrationDetailRoute,
} from '@atlassian/jira-router-routes-eoc-integration-automation-routes';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';
import {
	LazyIntegrationAutomation,
	LazyIntegrationDetail,
	LazyIntegrationCreate,
	LazyIntegrationList,
	LazyIntegrationLog,
} from './async';

export const IntegrationAutomation = () => (
	<LazyPage
		Page={LazyIntegrationAutomation}
		pageId="async-jira-eoc-integration-automation"
		shouldShowSpinner
	/>
);

export const IntegrationDetail = () => (
	<LazyPage
		Page={LazyIntegrationDetail}
		pageId="async-jira-eoc-integration-automation"
		shouldShowSpinner
	/>
);

export const IntegrationCreate = () => (
	<LazyPage
		Page={LazyIntegrationCreate}
		pageId="async-jira-eoc-integration-automation"
		shouldShowSpinner
	/>
);

export const IntegrationList = () => (
	<LazyPage
		Page={LazyIntegrationList}
		pageId="async-jira-eoc-integration-automation"
		shouldShowSpinner
	/>
);

export const IntegrationLog = () => (
	<LazyPage
		Page={LazyIntegrationLog}
		pageId="async-jira-eoc-integration-automation"
		shouldShowSpinner
	/>
);

// Team dashboard routes
export const teamIntegrationsRouteEntry = createEntry(teamIntegrationsRoute, {
	// @ts-expect-error - 'slot' and 'basePath' do not exist in type Route
	basePath: '/jira/ops/teams',
	exact: true,
	component: IntegrationAutomation,
	layout: opsTeamLayout,
	slot: null,
	resources: [
		...getNavigationResources(),
		...teamDashboardResources,
		getConsolidationStateResource(),
		integrationAutomationResources.configuredIntegrationsResource,
		integrationAutomationResources.userAccessRightsResource,
	],
	navigation: {
		menuId: MENU_ID.PEOPLE,
		sidebar: AsyncSidebarEocTeamDashboard,
		horizontal: AsyncHorizontalOperationsNav,
		horizontalFeatureFlag: 'platform.alx-8775-operations-horizontal-nav-platform_42yfm',
	},
	forPaint: [
		LazyHorizontalOperationsNav,
		LazyAtlassianNavigation,
		LazyIntegrationAutomation,
		LazyTeamDashboardSidebar,
	],
});

export const teamIntegrationListRouteEntry = createEntry(teamIntegrationListRoute, {
	// @ts-expect-error - 'slot' and 'basePath' do not exist in type Route
	basePath: '/jira/ops/teams',
	exact: true,
	component: IntegrationAutomation,
	layout: opsTeamLayout,
	slot: IntegrationList,
	resources: [
		...getNavigationResources(),
		...teamDashboardResources,
		getConsolidationStateResource(),
		integrationAutomationResources.integrationListResource,
		integrationAutomationResources.userAccessRightsResource,
	],
	navigation: {
		menuId: MENU_ID.PEOPLE,
		sidebar: AsyncSidebarEocTeamDashboard,
		horizontal: AsyncHorizontalOperationsNav,
		horizontalFeatureFlag: 'platform.alx-8775-operations-horizontal-nav-platform_42yfm',
	},
	forPaint: [
		LazyHorizontalOperationsNav,
		LazyAtlassianNavigation,
		LazyIntegrationAutomation,
		LazyTeamDashboardSidebar,
	],
});

export const teamIntegrationCreateRouteEntry = createEntry(teamIntegrationCreateRoute, {
	// @ts-expect-error - 'slot' and 'basePath' do not exist in type Route
	basePath: '/jira/ops/teams',
	exact: true,
	component: IntegrationAutomation,
	layout: opsTeamLayout,
	slot: IntegrationCreate,
	resources: [
		...getNavigationResources(),
		...teamDashboardResources,
		getConsolidationStateResource(),
		integrationAutomationResources.configuredIntegrationsResource,
		integrationAutomationResources.integrationListResource,
		integrationAutomationResources.userAccessRightsResource,
	],
	navigation: {
		menuId: MENU_ID.PEOPLE,
		sidebar: AsyncSidebarEocTeamDashboard,
		horizontal: AsyncHorizontalOperationsNav,
		horizontalFeatureFlag: 'platform.alx-8775-operations-horizontal-nav-platform_42yfm',
	},
	forPaint: [
		LazyHorizontalOperationsNav,
		LazyAtlassianNavigation,
		LazyIntegrationAutomation,
		LazyTeamDashboardSidebar,
	],
});

export const teamIntegrationDetailRouteEntry = createEntry(teamIntegrationDetailRoute, {
	// @ts-expect-error - 'slot' and 'basePath' do not exist in type Route
	basePath: '/jira/ops/teams',
	exact: true,
	component: IntegrationAutomation,
	layout: opsTeamLayout,
	slot: IntegrationDetail,
	resources: [
		...getNavigationResources(),
		...teamDashboardResources,
		getConsolidationStateResource(),
		integrationAutomationResources.configuredIntegrationsResource,
		integrationAutomationResources.userAccessRightsResource,
	],
	navigation: {
		menuId: MENU_ID.PEOPLE,
		sidebar: AsyncSidebarEocTeamDashboard,
		horizontal: AsyncHorizontalOperationsNav,
		horizontalFeatureFlag: 'platform.alx-8775-operations-horizontal-nav-platform_42yfm',
	},
	forPaint: [
		LazyHorizontalOperationsNav,
		LazyAtlassianNavigation,
		LazyIntegrationAutomation,
		LazyTeamDashboardSidebar,
	],
});

export const teamIntegrationLogRouteEntry = createEntry(teamIntegrationLogRoute, {
	// @ts-expect-error - 'slot' and 'basePath' do not exist in type Route
	basePath: '/jira/ops/teams',
	component: IntegrationAutomation,
	slot: IntegrationLog,
	exact: true,
	layout: opsTeamLayout,
	resources: [
		...getNavigationResources(),
		...teamDashboardResources,
		...customerLogResources,
		getConsolidationStateResource(),
		integrationAutomationResources.configuredIntegrationsResource,
		integrationAutomationResources.userAccessRightsResource,
	],
	navigation: {
		menuId: MENU_ID.PEOPLE,
		sidebar: AsyncSidebarEocTeamDashboard,
		horizontal: AsyncHorizontalOperationsNav,
		horizontalFeatureFlag: 'platform.alx-8775-operations-horizontal-nav-platform_42yfm',
	},
	forPaint: [
		LazyHorizontalOperationsNav,
		LazyAtlassianNavigation,
		LazyIntegrationAutomation,
		LazyTeamDashboardSidebar,
	],
});

// Global routes
export const globalIntegrationsRouteEntry = createEntry(globalIntegrationsRoute, {
	// @ts-expect-error - 'slot' and 'basePath' do not exist in type Route
	basePath: '/jira/settings/products/ops',
	exact: true,
	component: IntegrationAutomation,
	slot: null,
	resources: [
		...getNavigationResources(),
		...getNavigationSidebarProductsSettingResource(),
		integrationAutomationResources.configuredIntegrationsResource,
		integrationAutomationResources.userAccessRightsResource,
	],
	navigation: {
		menuId: MENU_ID.SETTINGS,
		sidebar: AsyncSidebarGlobalSettingsProducts,
		horizontal: AsyncHorizontalOperationsConfigurationNav,
		horizontalFeatureFlag: 'platform.alx-8775-operations-horizontal-nav-platform_42yfm',
	},
	forPaint: [
		LazyHorizontalOperationsConfigurationNav,
		LazyAtlassianNavigation,
		LazyIntegrationAutomation,
	],
});

export const globalIntegrationListRouteEntry = createEntry(globalIntegrationListRoute, {
	// @ts-expect-error - 'slot' and 'basePath' do not exist in type Route
	basePath: '/jira/settings/products/ops',
	exact: true,
	component: IntegrationAutomation,
	slot: IntegrationList,
	resources: [
		...getNavigationResources(),
		...getNavigationSidebarProductsSettingResource(),
		integrationAutomationResources.integrationListResource,
		integrationAutomationResources.userAccessRightsResource,
	],
	navigation: {
		menuId: MENU_ID.SETTINGS,
		sidebar: AsyncSidebarGlobalSettingsProducts,
		horizontal: AsyncHorizontalOperationsConfigurationNav,
		horizontalFeatureFlag: 'platform.alx-8775-operations-horizontal-nav-platform_42yfm',
	},
	forPaint: [
		LazyHorizontalOperationsConfigurationNav,
		LazyAtlassianNavigation,
		LazyIntegrationAutomation,
	],
});

export const globalIntegrationCreateRouteEntry = createEntry(globalIntegrationCreateRoute, {
	// @ts-expect-error - 'slot' and 'basePath' do not exist in type Route
	basePath: '/jira/settings/products/ops',
	exact: true,
	component: IntegrationAutomation,
	slot: IntegrationCreate,
	resources: [
		...getNavigationResources(),
		...getNavigationSidebarProductsSettingResource(),
		integrationAutomationResources.configuredIntegrationsResource,
		integrationAutomationResources.integrationListResource,
		integrationAutomationResources.userAccessRightsResource,
	],
	navigation: {
		menuId: MENU_ID.SETTINGS,
		sidebar: AsyncSidebarGlobalSettingsProducts,
		horizontal: AsyncHorizontalOperationsConfigurationNav,
		horizontalFeatureFlag: 'platform.alx-8775-operations-horizontal-nav-platform_42yfm',
	},
	forPaint: [
		LazyHorizontalOperationsConfigurationNav,
		LazyAtlassianNavigation,
		LazyIntegrationAutomation,
	],
});

export const globalIntegrationDetailRouteEntry = createEntry(globalIntegrationDetailRoute, {
	// @ts-expect-error - 'slot' and 'basePath' do not exist in type Route
	basePath: '/jira/settings/products/ops',
	exact: true,
	component: IntegrationAutomation,
	slot: IntegrationDetail,
	resources: [
		...getNavigationResources(),
		...getNavigationSidebarProductsSettingResource(),
		integrationAutomationResources.configuredIntegrationsResource,
		integrationAutomationResources.userAccessRightsResource,
	],
	navigation: {
		menuId: MENU_ID.SETTINGS,
		sidebar: AsyncSidebarGlobalSettingsProducts,
		horizontal: AsyncHorizontalOperationsConfigurationNav,
		horizontalFeatureFlag: 'platform.alx-8775-operations-horizontal-nav-platform_42yfm',
	},
	forPaint: [
		LazyHorizontalOperationsConfigurationNav,
		LazyAtlassianNavigation,
		LazyIntegrationAutomation,
	],
});

export const globalIntegrationLogRouteEntry = createEntry(globalIntegrationLogRoute, {
	// @ts-expect-error - 'slot' and 'basePath' do not exist in type Route
	basePath: '/jira/settings/products/ops',
	component: IntegrationAutomation,
	slot: IntegrationLog,
	exact: true,
	resources: [
		...getNavigationResources(),
		...getNavigationSidebarProductsSettingResource(),
		...customerLogResources,
		integrationAutomationResources.configuredIntegrationsResource,
		integrationAutomationResources.userAccessRightsResource,
	],
	navigation: {
		menuId: MENU_ID.SETTINGS,
		sidebar: AsyncSidebarGlobalSettingsProducts,
		horizontal: AsyncHorizontalOperationsConfigurationNav,
		horizontalFeatureFlag: 'platform.alx-8775-operations-horizontal-nav-platform_42yfm',
	},
	forPaint: [
		LazyHorizontalOperationsConfigurationNav,
		LazyAtlassianNavigation,
		LazyIntegrationAutomation,
	],
});

export const eocIntegrationsRouteEntries = [
	teamIntegrationsRouteEntry,
	teamIntegrationListRouteEntry,
	teamIntegrationCreateRouteEntry,
	teamIntegrationDetailRouteEntry,
	teamIntegrationLogRouteEntry,
	globalIntegrationsRouteEntry,
	globalIntegrationListRouteEntry,
	globalIntegrationCreateRouteEntry,
	globalIntegrationDetailRouteEntry,
	globalIntegrationLogRouteEntry,
];
