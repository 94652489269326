import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import type { IrremovableFieldsConfig } from '@atlassian/jira-jql-builder-basic/src/utils/irremovable-fields/index.tsx';
import {
	DEFAULT_CALENDAR_FILTER_FIELDS,
	TEAM_NAME,
	RESOURCE_TYPE_NAME,
	PACKAGE_NAME,
} from '../../common/constants';
import type { FilterFieldResponse } from './types';

export const TARGET_FILTER_TYPE = 'com.atlassian.servicedesk:vp-origin';
const TARGET_FILTER_SEARCH_TEMPLATE = 'com.atlassian.servicedesk:vp-origin-searcher';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const reportError = (error: any) => {
	fireErrorAnalytics({
		error,
		sendToPrivacyUnsafeSplunk: true,
		meta: {
			id: RESOURCE_TYPE_NAME,
			packageName: PACKAGE_NAME,
			teamName: TEAM_NAME,
		},
	});
};

export const transformAndReport = (response: FilterFieldResponse): IrremovableFieldsConfig => {
	const edges = response.data?.jira?.jqlBuilder?.fields?.edges;

	// There may be ambigious custom fields with same name, so find the correct one and pass to JQLBuilder
	const requestType = edges?.find(
		(edge) =>
			edge?.node?.type === TARGET_FILTER_TYPE &&
			edge?.node?.searchTemplate?.key === TARGET_FILTER_SEARCH_TEMPLATE,
	)?.node;

	if (!requestType) {
		reportError(new Error('Unable to prefetch request type'));
	}

	// if requestType field found, add it to existing default calendar filter fields
	return requestType
		? [
				{
					...requestType,
					searchTemplate: requestType.searchTemplate.key,
					fieldType: requestType.type,
				},
				...DEFAULT_CALENDAR_FILTER_FIELDS,
			]
		: DEFAULT_CALENDAR_FILTER_FIELDS;
};
