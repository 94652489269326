import React, { useCallback, useMemo } from 'react';
import { useThemeObserver } from '@atlaskit/tokens';
import { parseUrl } from '@atlassian/embedded-confluence';
import { useEmbeddedConfluenceSidePanel } from '@atlassian/jira-confluence-integration-controls';
import { JiraEntryPointContainer } from '@atlassian/jira-entry-point-container';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
// eslint-disable-next-line jira/import/no-restricted-paths
import { embeddedPanelEntrypoint } from '../../ui/embedded-panel/entrypoint';
import { useEmbeddedPageTracking } from '../use-embedded-page-tracking';
import { useParentProduct } from '../use-parent-product';

const entryPointParams = {};
export const useEmbeddedPanelEntryPoint = () => {
	const [state, actions] = useEmbeddedConfluenceSidePanel();

	const {
		contentType,
		confluenceData: { contentUrl, isFailedRemoteLink, title, mode },
		panel: { isOpen },
		embeddedConfluenceSource,
	} = state;
	// todo remove hard coded url once we have working demo
	const href =
		contentUrl ??
		'https://corina4.jira-dev.com/wiki/spaces/~5efc53e4deb6ca0baa2a079e/pages/edit-v2/112099329?draftShareId=697697f8-2261-49d4-9dfb-e45d3dee5e7c';

	const parentProduct = useParentProduct();

	const { entryPointActions, entryPointReferenceSubject } = useEntryPoint(
		embeddedPanelEntrypoint,
		entryPointParams,
	);

	const fireAnalyticsEvent = useEmbeddedPageTracking();
	const themeState = useThemeObserver();

	const onContentAction = useCallback(
		(action: string, eventName: string) => {
			fireAnalyticsEvent({
				action,
				eventName,
				embeddedConfluenceSource,
			});
		},
		[fireAnalyticsEvent, embeddedConfluenceSource],
	);

	const onClose = useCallback(() => {
		fireAnalyticsEvent({
			action: 'clicked',
			eventName: 'closeEmbeddedConfluencePanel',
			embeddedConfluenceSource,
		});
		entryPointActions.unload();

		actions.closeSidePanel();
	}, [actions, fireAnalyticsEvent, entryPointActions, embeddedConfluenceSource]);

	const { locale } = useIntl();
	const shouldOpenPanel = isOpen && !isFailedRemoteLink && href;

	// TODO: set different runtime Props based on contentType
	const runtimeProps = useMemo(
		() => ({
			locale,
			hasFooterLogo: false,
			panelTitle: title,
			navigationPolicy: undefined,
			onClose,
			onContentAction,
			parentProduct,
			spaceKey: parseUrl(href)?.spaceKey,
			themeState,
			url: href,
			mode,
			embeddedConfluenceSource,
			contentId: parseUrl(href)?.contentId,
			contentType,
			allowedFeatures: {
				view: [
					'byline-contributors',
					'byline-extensions',
					'page-comments',
					'page-reactions',
					'inline-comments',
					'non-licensed-share',
				],
			},
			// TODO: figure out how to call dispatch redux store from here
			// eslint-disable-next-line @typescript-eslint/no-empty-function
			linkConfluencePage: () => {},
		}),
		[
			locale,
			title,
			onClose,
			onContentAction,
			parentProduct,
			href,
			contentType,
			themeState,
			embeddedConfluenceSource,
			mode,
		],
	);

	return shouldOpenPanel
		? {
				entryPointActions,
				entryPoint: (
					<JiraEntryPointContainer
						entryPointReferenceSubject={entryPointReferenceSubject}
						id="epPanelEntrypointContainer"
						packageName="@atlassian/jira-issue-create-confluence-content"
						teamName="confluence-better-together"
						runtimeProps={runtimeProps}
					/>
				),
			}
		: { ref: undefined, entryPoint: null };
};
