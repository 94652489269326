import { createStore, createContainer, createHook } from '@atlassian/react-sweet-state';
import type {
	State,
	Actions,
	UseValueType,
	CreateControllerReturnType,
	CreateControllerType,
} from './types';

export const createValueController: CreateControllerType = <T,>(
	name: string,
	defaultValue: T,
): CreateControllerReturnType<T> => {
	const actions: Actions<T> = {
		setValue:
			(value) =>
			({ setState }) =>
				setState({ value }),
	};

	const initialState: State<T> = { value: defaultValue };

	const store = createStore<State<T>, Actions<T>>({
		name,
		initialState,
		actions,
	});

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const ValueContainer = createContainer<State<T>, Actions<T>, Record<any, any>>(store);

	const selector = (state: State<T>) => state.value;

	const useValueInternal = createHook(store, {
		selector,
	});

	const useValue: UseValueType<T> = () => {
		const [value, { setValue }] = useValueInternal();
		return [value, setValue];
	};

	return { ValueContainer, useValue };
};
