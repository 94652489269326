import {
	ROUTE_GROUPS_SERVICEDESK,
	ROUTE_NAMES_SERVICEDESK_ONCALL_SCHEDULE,
} from '@atlassian/jira-common-constants/src/spa-routes';

export const SERVICE_DESK_ROUTE_PREFIX = '/jira/servicedesk/projects/:projectKey';

export const servicedeskOncallScheduleRoute = {
	group: ROUTE_GROUPS_SERVICEDESK,
	name: ROUTE_NAMES_SERVICEDESK_ONCALL_SCHEDULE,
	path: `${SERVICE_DESK_ROUTE_PREFIX}/on-call-schedule`,
	exact: true,
};
