import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import type LazyProformaIssueFormsGeneralType from '@atlassian/jira-spa-apps-proforma-issue-forms-general/src/ui/index.tsx';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';

export const LazyProformaIssueFormsGeneral = lazyForPaint<typeof LazyProformaIssueFormsGeneralType>(
	() =>
		import(
			/* webpackChunkName: "async-proforma-issue-forms-general", jiraSpaEntry: "async-proforma-issue-forms-general" */ '@atlassian/jira-spa-apps-proforma-issue-forms-general'
		),
);

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default () => (
	<LazyPage
		Page={LazyProformaIssueFormsGeneral}
		pageId="proforma-issue-forms-general"
		shouldShowSpinner
	/>
);
