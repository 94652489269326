import {
	ROUTE_GROUPS_SERVICEDESK,
	ROUTE_NAMES_SERVICEDESK_SERVICES,
	ROUTE_NAMES_SERVICEDESK_SERVICE_DETAILS,
	ROUTE_NAMES_SERVICEDESK_SERVICE_MODAL_NEW,
	ROUTE_NAMES_SERVICEDESK_SERVICE_MODAL_EDIT,
	ROUTE_NAMES_SERVICEDESK_SERVICES_WITHOUT_PROJECT,
	ROUTE_NAMES_SERVICEDESK_SERVICE_DETAILS_WITHOUT_PROJECT,
} from '@atlassian/jira-common-constants/src/spa-routes';

const SERVICE_DESK_ROUTE_PREFIX = '/jira/servicedesk/projects/:projectKey';
const SERVICE_DESK_ROUTE_PREFIX_WITHOUT_PROJECT = '/jira/servicedesk';
const SERVICE_HASHED_ARI = '/:serviceAri';
const SERVICES_ROUTE_SUFFIX = '/services';
const SERVICE_MODAL_ROUTE_SUFFIX = '/service-modal';

export const servicedeskServicesRoute = {
	group: ROUTE_GROUPS_SERVICEDESK,
	name: ROUTE_NAMES_SERVICEDESK_SERVICES,
	path: `${SERVICE_DESK_ROUTE_PREFIX}${SERVICES_ROUTE_SUFFIX}`,
	exact: true,
};

export const servicedeskServiceDetailsRoute = {
	group: ROUTE_GROUPS_SERVICEDESK,
	name: ROUTE_NAMES_SERVICEDESK_SERVICE_DETAILS,
	path: `${SERVICE_DESK_ROUTE_PREFIX}${SERVICES_ROUTE_SUFFIX}${SERVICE_HASHED_ARI}`,
};

export const servicedeskServiceModalNewRoute = {
	group: ROUTE_GROUPS_SERVICEDESK,
	name: ROUTE_NAMES_SERVICEDESK_SERVICE_MODAL_NEW,
	path: `${SERVICE_DESK_ROUTE_PREFIX_WITHOUT_PROJECT}${SERVICE_MODAL_ROUTE_SUFFIX}`,
	exact: true,
};

export const servicedeskServiceModalEditRoute = {
	group: ROUTE_GROUPS_SERVICEDESK,
	name: ROUTE_NAMES_SERVICEDESK_SERVICE_MODAL_EDIT,
	path: `${SERVICE_DESK_ROUTE_PREFIX_WITHOUT_PROJECT}${SERVICE_MODAL_ROUTE_SUFFIX}${SERVICE_HASHED_ARI}`,
};

export const serviceDeskServicesWithoutProjectRoute = {
	group: ROUTE_GROUPS_SERVICEDESK,
	name: ROUTE_NAMES_SERVICEDESK_SERVICES_WITHOUT_PROJECT,
	path: `${SERVICE_DESK_ROUTE_PREFIX_WITHOUT_PROJECT}${SERVICES_ROUTE_SUFFIX}`,
	exact: true,
	meta: {
		reporting: {
			id: ROUTE_NAMES_SERVICEDESK_SERVICES_WITHOUT_PROJECT,
			packageName: 'jiraSpaAppsServicedeskServicesWithoutProjectContext"',
			teamName: 'Falcons',
		},
	},
};

export const serviceDeskServiceDetailsWithoutProjectRoute = {
	group: ROUTE_GROUPS_SERVICEDESK,
	name: ROUTE_NAMES_SERVICEDESK_SERVICE_DETAILS_WITHOUT_PROJECT,
	path: `${SERVICE_DESK_ROUTE_PREFIX_WITHOUT_PROJECT}${SERVICES_ROUTE_SUFFIX}${SERVICE_HASHED_ARI}`,
	meta: {
		reporting: {
			id: ROUTE_NAMES_SERVICEDESK_SERVICE_DETAILS_WITHOUT_PROJECT,
			packageName: 'jiraSpaAppsServicedeskServiceDetailsWithoutProjectContext"',
			teamName: 'Falcons',
		},
	},
};
