import React, { useEffect } from 'react';
import { JiraEntryPointContainer } from '@atlassian/jira-entry-point-container';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import { ProjectListSidebarSkeleton } from '@atlassian/jira-skeletons/src/ui/project-list-sidebar/index.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { asyncSidebar } from './entrypoint';

const runtimeProps = {};
export const AsyncRightPanel = () => {
	const cloudId = useCloudId();

	const { entryPointActions, entryPointReferenceSubject } = useEntryPoint(asyncSidebar, {
		cloudId,
	});

	// eslint-disable-next-line @atlassian/react-entrypoint/no-load-in-hooks
	useEffect(() => {
		entryPointActions.load();
	}, [entryPointActions]);

	return (
		<JiraEntryPointContainer
			id="right-side-panel"
			packageName="jiraGrowthRecommendationsInProjectList"
			entryPointReferenceSubject={entryPointReferenceSubject}
			runtimeProps={runtimeProps}
			fallback={<ProjectListSidebarSkeleton />}
		/>
	);
};

export default AsyncRightPanel;
