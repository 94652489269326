/**
 * @generated SignedSource<<816e0138c00cd9aed31e974f17e8a5f4>>
 * @relayHash 8887753cc09cf6973ae62916fe0f5327
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID c7f83ca59b974d0332444c8eb9f985b67215e5a9662d299e2e5ec582236c21ce

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { ui_jiraCalendarQuery } from './ui_jiraCalendarQuery.graphql';

const node: PreloadableConcreteRequest<ui_jiraCalendarQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "c7f83ca59b974d0332444c8eb9f985b67215e5a9662d299e2e5ec582236c21ce",
    "metadata": {},
    "name": "ui_jiraCalendarQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;
