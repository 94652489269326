import { ff } from '@atlassian/jira-feature-flagging';
import { fg } from '@atlassian/jira-feature-gating';
import {
	ADMIN_PAGE_MODULE,
	PROJECT_PAGE_MODULE,
	PROJECT_SETTINGS_PAGE_MODULE,
	GLOBAL_PAGE_MODULE,
	CUSTOM_FIELD_TYPE_MODULE,
	SERVICEDESK_QUEUE_PAGE_MODULE,
	SOURCE_NAVIGATION,
} from '@atlassian/jira-forge-ui-constants';
import type {
	FullPageModule,
	FullPageModuleWithAccessNarrowing,
	AdminPageWithAccessNarrowing,
	ProjectPageWithAccessNarrowing,
	ProjectSettingsPageWithAccessNarrowing,
	GlobalPageWithAccessNarrowing,
	FullPage,
	CustomFieldType,
	ServiceDeskQueuePageWithAccessNarrowing,
} from '@atlassian/jira-forge-ui-types';
import type { DataClassificationProps } from '@atlassian/jira-forge-ui-utils/src/types';
import fetchForgeModules from '@atlassian/jira-forge-ui-utils/src/utils/fetch-modules';
import { fetchModules } from '@atlassian/jira-forge-ui-utils/src/utils/fetch-modules-v2';
import {
	createResource,
	useResource,
	type RouterDataContext,
	type RouteResource,
	type Match,
} from '@atlassian/jira-router';
import { getProjectKeyOrId } from '@atlassian/jira-router-resources-utils';
import type { TenantContext, ActivationId, CloudId } from '@atlassian/jira-shared-types';
import { fetchForgeModule as fetchForgeModulesWithFilter } from '../../services/forge';

export const validateDataClassification = (
	params: Match,
	moduleName: FullPageModule,
): DataClassificationProps => {
	const isModuleWithAccessNarrowing = [
		SERVICEDESK_QUEUE_PAGE_MODULE,
		PROJECT_PAGE_MODULE,
		PROJECT_SETTINGS_PAGE_MODULE,
	] // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		.includes(moduleName as FullPageModuleWithAccessNarrowing);

	const projectIdOrKey = getProjectKeyOrId(params);
	if (typeof projectIdOrKey === 'string' && projectIdOrKey !== '' && isModuleWithAccessNarrowing) {
		return { projectIdOrKey };
	}
	return null;
};

const createDataGetter =
	<TFullPage extends FullPage>(
		moduleName: FullPageModule,
		shouldReturnANExtensions = false,
		createContextIds?: ({
			cloudId,
			activationId,
		}: {
			cloudId: CloudId;
			activationId: ActivationId;
		}) => string[],
	) =>
	(
		route: RouterDataContext,
		{ tenantContext: { cloudId, activationId } }: { tenantContext: TenantContext },
	) => {
		const { match: params } = route;
		const dataClassification = validateDataClassification(params, moduleName);
		// isNonJSDModule variable to be removed with arken-1053-jira-forge-extension-changes-for-aegis_aws9x FF cleanup. It's been added to keep JSD and non-JSD modules
		// independent during rollout/rollback of the FFs
		const isNonJSDModule = moduleName !== SERVICEDESK_QUEUE_PAGE_MODULE;
		let contextIds;
		if (isNonJSDModule && ff('arken-1053-jira-forge-extension-changes-for-aegis_aws9x')) {
			contextIds =
				(typeof createContextIds === 'function' && createContextIds({ cloudId, activationId })) ||
				undefined;
		} else if (!isNonJSDModule) {
			contextIds =
				(typeof createContextIds === 'function' && createContextIds({ cloudId, activationId })) ||
				undefined;
		}

		return fetchForgeModulesWithFilter<TFullPage>({
			cloudId,
			moduleName,
			dataClassification,
			shouldReturnANExtensions,
			contextIds,
		});
	};

const createContextIds = ({
	cloudId,
	activationId,
}: {
	cloudId: CloudId;
	activationId: ActivationId;
}) => [`ari:cloud:jira:${cloudId}:workspace/${activationId}`];

export const forgeAdminModuleResource = createResource<AdminPageWithAccessNarrowing>({
	type: 'FORGE_ADMIN_MODULE',
	getKey: () => 'extensions',
	getData: createDataGetter(ADMIN_PAGE_MODULE, false, createContextIds),
	maxAge: Number.MAX_SAFE_INTEGER,
});

export const forgeProjectModuleResource: ReturnType<
	typeof createResource<ProjectPageWithAccessNarrowing>
> = createResource<ProjectPageWithAccessNarrowing>({
	type: 'FORGE_PROJECT_MODULE',
	getKey: (routeContext) => {
		const projectKeyOrId = getProjectKeyOrId(routeContext.match);
		return `extensions-${projectKeyOrId}`;
	},
	getData: createDataGetter(PROJECT_PAGE_MODULE, true, createContextIds),
	maxAge: Number.MAX_SAFE_INTEGER,
});

export const forgeProjectSettingsModuleResource: ReturnType<
	typeof createResource<ProjectSettingsPageWithAccessNarrowing>
> = createResource<ProjectSettingsPageWithAccessNarrowing>({
	type: 'FORGE_PROJECT_SETTINGS_MODULE',
	getKey: (routeContext) => {
		const projectKeyOrId = getProjectKeyOrId(routeContext.match);
		return `extensions-${projectKeyOrId}`;
	},
	getData: createDataGetter(PROJECT_SETTINGS_PAGE_MODULE, true, createContextIds),
	maxAge: Number.MAX_SAFE_INTEGER,
});

export const useForgeProjectSettingsModuleResource = () =>
	useResource(forgeProjectSettingsModuleResource);

export const forgeGlobalModuleResource = createResource<GlobalPageWithAccessNarrowing>({
	type: 'FORGE_GLOBAL_MODULE',
	getKey: () => 'extensions',
	getData: createDataGetter(GLOBAL_PAGE_MODULE, false, createContextIds),
	maxAge: Number.MAX_SAFE_INTEGER,
});

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const forgeCustomFieldTypeResource = createResource<CustomFieldType[]>({
	type: 'FORGE_CUSTOM_FIELD_TYPE_MODULE',
	getKey: () => 'extensions',
	getData: (_route, { tenantContext: { cloudId, activationId } }) =>
		fg('new_graphql_endpoint_for_fetching_forge_modules')
			? fetchModules({
					cloudId,
					types: [CUSTOM_FIELD_TYPE_MODULE],
					context: {},
					includeHidden: false,
					source: SOURCE_NAVIGATION,
				}).then((modules) => modules[CUSTOM_FIELD_TYPE_MODULE])
			: fetchForgeModules(
					cloudId,
					[CUSTOM_FIELD_TYPE_MODULE],
					null,
					undefined,
					SOURCE_NAVIGATION,
					(ff('arken-1053-jira-forge-extension-changes-for-aegis_aws9x') &&
						createContextIds({ cloudId, activationId })) ||
						undefined,
				) // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					.then((modules) => modules.extensions.jiraCustomFieldType as CustomFieldType[]),
	maxAge: Number.MAX_SAFE_INTEGER,
}) as RouteResource<CustomFieldType[]>;

export const forgeServiceDeskQueueModuleResource: ReturnType<
	typeof createResource<ServiceDeskQueuePageWithAccessNarrowing>
> = createResource<ServiceDeskQueuePageWithAccessNarrowing>({
	type: 'FORGE_SERVICEDESK_QUEUE_MODULE',
	getKey: (routeContext) => {
		const projectKeyOrId = getProjectKeyOrId(routeContext.match);
		return `extensions-${projectKeyOrId}`;
	},
	getData: createDataGetter(SERVICEDESK_QUEUE_PAGE_MODULE, true, createContextIds),
	maxAge: Number.MAX_SAFE_INTEGER,
});

export const getNavigationSidebarGlobalSettingsResources = () => [forgeAdminModuleResource];
