import React from 'react';
import { issueHierarchyPageResource } from '@atlassian/jira-admin-pages-issue-hierarchy-directory/src/ui/query';
import issueTypeSchemeResource from '@atlassian/jira-admin-pages-issue-type-schemes-directory/src/services/resource/issue-type-scheme/index.tsx';
import { issueTypesResource } from '@atlassian/jira-admin-pages-issue-types-resources/src/controllers/issue-types/utils.tsx';
import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async';
import { globalSettingsLayout } from '@atlassian/jira-global-settings-layout';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarGlobalSettingsIssues from '@atlassian/jira-navigation-apps-sidebar-global-settings-issues/src/async';
import { createEntry } from '@atlassian/jira-route-entry';
import type { Route } from '@atlassian/jira-router';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation';
import { navigationSidebarGlobalResource } from '@atlassian/jira-router-resources-navigation-sidebar-global';
import {
	globalSettingsIssueHierarchyRedirectRoute,
	globalSettingsIssueHierarchyRoute,
	globalSettingsIssueTypesRoute,
	globalSettingsIssueTypesSchemesRoute,
} from '@atlassian/jira-router-routes-admin-pages-issue-types-routes';
import { ScreenRedirect } from '@atlassian/jira-screen-redirect/src/ui/index.tsx';
import IssueHierarchyDirectory, {
	LazyIssueHierarchyDirectory,
} from './ui/spa/admin-pages/issue-types/issue-hierarchy-directory';
import IssueTypeSchemesDirectory, {
	LazyIssueTypeSchemesDirectory,
} from './ui/spa/admin-pages/issue-types/issue-type-schemes-directory';
import IssueTypesDirectory, {
	LazyIssueTypesDirectory,
} from './ui/spa/admin-pages/issue-types/issue-types-directory';

export const issueTypesRouteEntries: Route[] = [
	createEntry(globalSettingsIssueHierarchyRoute, {
		ufoName: 'global-admin.issues.issue-hierarchy-config',
		component: IssueHierarchyDirectory,
		layout: globalSettingsLayout,

		navigation: {
			sidebarId: SIDEBAR_ID.GLOBAL_ISSUES_SETTINGS,
			menuId: MENU_ID.SETTINGS,
			sidebar: AsyncSidebarGlobalSettingsIssues,
		},

		resources: [
			...getNavigationResources(),
			navigationSidebarGlobalResource,
			issueHierarchyPageResource,
		],

		forPaint: [LazyAtlassianNavigation, LazyIssueHierarchyDirectory],
	}),
	createEntry(globalSettingsIssueHierarchyRedirectRoute, {
		component: () => <ScreenRedirect to="/jira/settings/issues/issue-hierarchy" />,
		isRedirect: true,
		layout: globalSettingsLayout,
		navigation: {
			sidebarId: SIDEBAR_ID.GLOBAL_ISSUES_SETTINGS,
			menuId: MENU_ID.SETTINGS,
			sidebar: AsyncSidebarGlobalSettingsIssues,
		},
		resources: [
			...getNavigationResources(),
			navigationSidebarGlobalResource,
			issueHierarchyPageResource,
		],
		forPaint: [LazyAtlassianNavigation, LazyIssueHierarchyDirectory],
	}),
	createEntry(globalSettingsIssueTypesRoute, {
		ufoName: 'global-admin.issues.issue-types',
		component: IssueTypesDirectory,
		layout: globalSettingsLayout,

		navigation: {
			sidebarId: SIDEBAR_ID.GLOBAL_ISSUES_SETTINGS,
			menuId: MENU_ID.SETTINGS,
			sidebar: AsyncSidebarGlobalSettingsIssues,
		},

		resources: [...getNavigationResources(), navigationSidebarGlobalResource, issueTypesResource],

		forPaint: [LazyAtlassianNavigation, LazyIssueTypesDirectory],
	}),
	createEntry(globalSettingsIssueTypesSchemesRoute, {
		ufoName: 'global-admin.issues.view-issue-type-schemes',
		component: IssueTypeSchemesDirectory,
		layout: globalSettingsLayout,

		navigation: {
			sidebarId: SIDEBAR_ID.GLOBAL_ISSUES_SETTINGS,
			menuId: MENU_ID.SETTINGS,
			sidebar: AsyncSidebarGlobalSettingsIssues,
		},

		resources: [
			...getNavigationResources(),
			navigationSidebarGlobalResource,
			issueTypeSchemeResource,
		],

		forPaint: [LazyAtlassianNavigation, LazyIssueTypeSchemesDirectory],
	}),
];
