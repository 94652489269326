import type { FetchOptions } from '@atlassian/jira-insights-common-constants/src/common/types/fetch.tsx';
import { fetchJson } from '@atlassian/jira-insights-fetch/src/services/fetch/fetch-json/index.tsx';
import type { BoardSettingsResponse } from '../../common/types';
import type { BoardSettingsParams } from './types';

export const fetchInsightsSettings = async (
	{ boardId }: BoardSettingsParams,
	options?: FetchOptions,
): Promise<BoardSettingsResponse> =>
	fetchJson<BoardSettingsResponse>(`/gateway/api/jira-insights/settings/board/${boardId}`, options);
