import type { Action } from '@atlassian/react-sweet-state';
import { KEY_TASK_GROUP, ROOT_TASK } from '../../../common/constants';
import { pushToBackend } from '../../../common/utils';
import type { State } from '../../model/types';

type TaskGroup = {
	previousTaskGroupParents: string[];
	currentTaskGroupParent: string;
};

const setTaskGroup =
	(taskGroup: TaskGroup): Action<State> =>
	({ setState }) => {
		setState({ ...taskGroup, openTask: '' });

		pushToBackend({
			key: KEY_TASK_GROUP,
			value: {
				taskGroup,
			},
		});
	};

export const nextChangeTaskGroupParent =
	(newCurrentTaskGroupParent: string): Action<State> =>
	({ getState, dispatch }) => {
		const { previousTaskGroupParents, currentTaskGroupParent } = getState();

		if (previousTaskGroupParents.includes(newCurrentTaskGroupParent)) {
			// prevent infinite loop nesting
			return;
		}

		previousTaskGroupParents.push(currentTaskGroupParent);

		const taskGroup = {
			previousTaskGroupParents,
			currentTaskGroupParent: newCurrentTaskGroupParent,
		};
		dispatch(setTaskGroup(taskGroup));
	};

export const nextGoBackToPreviousTaskGroup =
	(): Action<State> =>
	({ dispatch, getState }) => {
		const { previousTaskGroupParents } = getState();

		const newTaskGroupParent =
			previousTaskGroupParents.length === 0 ? ROOT_TASK : previousTaskGroupParents.pop();

		const taskGroup = { previousTaskGroupParents, currentTaskGroupParent: newTaskGroupParent };
		// @ts-expect-error - TS2345 - Argument of type '{ previousTaskGroupParents: string[]; currentTaskGroupParent: string | undefined; }' is not assignable to parameter of type 'TaskGroup'.
		dispatch(setTaskGroup(taskGroup));
	};
