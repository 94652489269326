import React, { memo } from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import { SidebarBoundary } from '@atlassian/jira-navigation-apps-sidebar-nav4-common/src/ui/sidebar-boundary';
import { Skeleton } from '@atlassian/jira-navigation-apps-sidebar-nav4-project-settings-common/src/common/ui/skeleton';
import Placeholder from '@atlassian/jira-placeholder';
import type { Props } from './types';

const LazySidebarContent = lazyForPaint(
	() =>
		import(
			/* webpackChunkName: "async-jira-navigation-apps-sidebar-nav4-software-team-managed-project-settings" */ './main'
		),
);

const AsyncSidebar = ({ project }: Props) => (
	<SidebarBoundary
		id="nav4.sidebar.project-settings.software.team-managed"
		packageName="jiraNavigationAppsSidebarNav4SoftwareTeamManagedProjectSettings"
	>
		<Placeholder name="nav4.sidebar.project-settings.software.team-managed" fallback={<Skeleton />}>
			<LazySidebarContent project={project} />
		</Placeholder>
	</SidebarBoundary>
);

export default memo(AsyncSidebar);
