import {
	ROUTE_GROUPS_DIRECTORIES,
	ROUTE_NAMES_PLANS_DIRECTORY,
} from '@atlassian/jira-common-constants/src/spa-routes';

export const plansDirectoryRoute = {
	group: ROUTE_GROUPS_DIRECTORIES,
	name: ROUTE_NAMES_PLANS_DIRECTORY,
	path: '/jira/plans',
};
