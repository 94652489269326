import { ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK } from '@atlassian/jira-common-constants/src/spa-routes';

export const SERVICE_DESK_PRODUCT = 'servicedesk';
const projectSettingsUrl = (product: string) => `/jira/${product}/projects/:projectKey/settings`;
export const routeGroupsProjectSettingsServicedeskKbRoute = {
	group: ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK,
	name: `${ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK}-kb`,
	path: `${projectSettingsUrl(SERVICE_DESK_PRODUCT)}/confluence-kb`,
	exact: true,
};
