// This is an INTENTIONAL FORK of @atlaskit/image
// copied 2023-11-09 v1.1.6
// with permission from @DDC from Design System,
// in order to refine the API and use cases.
// Will be back-ported to @atlaskit/image https://hello.atlassian.net/browse/ERAI-1660

/** @jsx jsx */
import React, { type ImgHTMLAttributes } from 'react';
import { css, jsx } from '@compiled/react';
import { useThemeObserver } from '@atlaskit/tokens';
import type { IllustrationProps } from '../../types';

export interface AsImgProps extends IllustrationProps, ImgHTMLAttributes<HTMLImageElement> {
	alt: string; // clarify the conflict = alt is mandatory

	/**
	 * This option (default false) provides an option to size the image, by filling its container (contain mode).
	 * if fill is true, the img will try to fill the parent container.
	 * This is usually what we want for easily sizing illustrations.
	 * REQUIRES the parent to be "positioned", i.e. just add `position: relative` to the container.
	 */
	fill?: boolean;
}

// suggested defaults, the consumer is free to override
const defaultStyles = css({
	// to remove the "magic space" https://www.joshwcomeau.com/css/understanding-layout-algorithms/#inline-magic-space-5
	display: 'block',

	// credits https://photo.stackexchange.com/questions/104337/how-i-can-losslessly-rotate-photos#comment217649_104340
	imageOrientation: 'from-image',

	// defensive CSS
	// credits https://ishadeed.com/article/defensive-css/
	maxWidth: '100%',
	height: 'auto',
	// help imgs to properly fill their container (not enough on its own but good defaults)
	objectFit: 'contain', // we seldom want cropping with Atlassian illustrations
	objectPosition: 'center',
});

const fillStyles = css({
	// this well known centering technique https://stackoverflow.com/a/22760896/587407
	// is the one that adds the fewer requirements on the consumer:
	// It only requires the parent to be "positioned" (for ex. position: relative)
	position: 'absolute',
	inset: 0,
	width: '100%',
	height: '100%',
});

const autoDarkModeStyles = css({
	// credits https://blog.simonfarshid.com/adapting-illustrations-to-dark-mode
	filter: 'invert(1) hue-rotate(180deg)',
});

export function AsImg({
	srcLight,
	srcDark = srcLight,
	autoDark = true,
	alt,
	fill = false,
	testId,
	...imgProps
}: AsImgProps) {
	const { colorMode } = useThemeObserver();

	return (
		<img
			src={colorMode === 'dark' ? srcDark : srcLight}
			alt={alt}
			css={[
				defaultStyles,
				fill && fillStyles,
				colorMode === 'dark' && srcDark === srcLight && autoDark && autoDarkModeStyles,
			]}
			data-testid={testId}
			{...imgProps}
		/>
	);
}
