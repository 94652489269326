import {
	ROUTE_GROUPS_REDIRECTS,
	ROUTE_NAMES_JIRA_SEARCH,
} from '@atlassian/jira-common-constants/src/spa-routes';

export const jiraSearchRoute = {
	group: ROUTE_GROUPS_REDIRECTS,
	name: ROUTE_NAMES_JIRA_SEARCH,
	path: '/jira/search',
	exact: true,
};
