import {
	ROUTE_GROUPS_DIRECTORIES,
	ROUTE_NAMES_PROJECTS_DIRECTORY,
	ROUTE_NAMES_PROJECTS_DIRECTORY_MANAGE,
	ROUTE_NAMES_PROJECTS_DIRECTORY_LEGACY_PATH_SPA,
} from '@atlassian/jira-common-constants/src/spa-routes';

export const projectsDirectoryRoute = {
	group: ROUTE_GROUPS_DIRECTORIES,
	name: ROUTE_NAMES_PROJECTS_DIRECTORY,
	path: '/jira/projects',
	exact: true,
};

export const manageProjectsDirectoryRoute = {
	group: ROUTE_GROUPS_DIRECTORIES,
	name: ROUTE_NAMES_PROJECTS_DIRECTORY_MANAGE,
	path: '/jira/settings/projects/manage',
};

export const projectsDirectoryRedirectRoute = {
	group: ROUTE_GROUPS_DIRECTORIES,
	name: `${ROUTE_NAMES_PROJECTS_DIRECTORY}-redirect`,
	path: '/projects',
	exact: true,
};
export const projectsDirectoryLegacyPathRedirectRoute = {
	group: ROUTE_GROUPS_DIRECTORIES,
	name: `${ROUTE_NAMES_PROJECTS_DIRECTORY_LEGACY_PATH_SPA}-redirect`,
	path: '/secure/BrowseProjects.jspa',
};
export const projectsDirectoryManageRedirectRoute = {
	name: `${ROUTE_NAMES_PROJECTS_DIRECTORY_MANAGE}-redirect`,
	path: '/secure/project/ViewProjects.jspa',
};
export const projectsDirectoryManageRootRedirectRoute = {
	name: `${ROUTE_NAMES_PROJECTS_DIRECTORY_MANAGE}-root-redirect`,
	path: '/jira/settings/projects',
	exact: true,
};
