import {
	ROUTE_GROUPS_GLOBAL_SETTINGS,
	ROUTE_NAMES_GLOBAL_SETTINGS_EDIT_PERMISSION_SCHEMES,
	ROUTE_NAMES_GLOBAL_SETTINGS_PERMISSION_SCHEMES,
} from '@atlassian/jira-common-constants/src/spa-routes';

export const globalSettingsEditPermissionSchemesRoute = {
	group: ROUTE_GROUPS_GLOBAL_SETTINGS,
	name: ROUTE_NAMES_GLOBAL_SETTINGS_EDIT_PERMISSION_SCHEMES,
	path: '/jira/settings/issues/permission-schemes/:schemeId',
};

export const globalSettingsPermissionSchemesRoute = {
	group: ROUTE_GROUPS_GLOBAL_SETTINGS,
	name: ROUTE_NAMES_GLOBAL_SETTINGS_PERMISSION_SCHEMES,
	path: '/jira/settings/issues/permission-schemes-new/:schemeId',
};
