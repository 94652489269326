import React, { type ReactNode } from 'react';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import { isDevelopmentEnv } from './utils/node-env';

type Props = {
	hasServiceDeskAccess: boolean | undefined;
	hasCoreAccess: boolean | undefined;
	hasSoftwareAccess: boolean | undefined;
	children: ReactNode;
};
/**
 * Manages the visibility of child components based on user permissions within a multi-tenant environment.
 * It leverages the application's permission settings to determine access rights, ensuring components are only accessible to users with the appropriate permissions.
 * In development environments, it issues a warning when no access-related properties are provided, aiding in debug and development processes.
 */
const AppPermissionsCheck = (props: Props) => {
	const { appPermissions } = useTenantContext();
	if (props.hasServiceDeskAccess !== undefined && appPermissions.hasServiceDeskAccess) {
		return <>{props.children}</>;
	}
	if (props.hasCoreAccess !== undefined && appPermissions.hasCoreAccess === props.hasCoreAccess) {
		return <>{props.children}</>;
	}
	if (props.hasSoftwareAccess !== undefined && appPermissions.hasSoftwareAccess) {
		return <>{props.children}</>;
	}
	if (
		props.hasServiceDeskAccess === undefined &&
		props.hasCoreAccess === undefined &&
		props.hasSoftwareAccess === undefined
	) {
		if (isDevelopmentEnv()) {
			// eslint-disable-next-line no-console
			console.warn('AppPermissionsCheck should have defined at least one of access prop');
		}
		return <>{props.children}</>;
	}
	return null;
};
AppPermissionsCheck.defaultProps = {
	hasCoreAccess: undefined,
	hasSoftwareAccess: undefined,
	hasServiceDeskAccess: undefined,
};
export default AppPermissionsCheck;
