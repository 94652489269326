import { useMemo, useCallback, useRef } from 'react';
import type { collectAll, collectAny } from '@atlassian/experience-tracker';
import { useCurrentRoute } from '@atlassian/jira-platform-router-utils';
import type { Application } from '@atlassian/jira-shared-types/src/application.tsx';
import type { ApplicationEdition } from '@atlassian/jira-shared-types/src/edition.tsx';
import { useExperienceContext } from '../../common/ui/use-experience-context';

type Props = {
	isExperienceReady?: boolean;
	disableSli?: boolean;
	experience: string;
	timeout?: number;
	analyticsSource: string | null;
	application?: Application;
	edition?: ApplicationEdition;
	experienceId?: string | null; // example: issueId (changes to this prop reset the experience tracking),
	collect?: ReturnType<typeof collectAll> | ReturnType<typeof collectAny>;
};
/**
 * Initializes and manages a user experience tracking session based on the user's current route and
 * provided properties. It prepares the session with all necessary parameters and returns a function
 * that, when called, begins tracking the user's experience with these configured settings. This
 * setup is crucial for collecting and analyzing user interaction data in a structured manner.
 */
export const useExperienceStart = ({
	experienceId,
	experience,
	timeout,
	analyticsSource,
	application,
	edition,
	disableSli = false,
	collect = () => undefined,
}: Props) => {
	const route = useCurrentRoute();
	const experienceTracker = useExperienceContext();
	const userLocation = `${route.group ?? 'unknown'}:${route.name}`;
	const self = useRef({
		experienceId,
		experience,
		timeout,
		analyticsSource,
		application,
		edition,
		experienceTracker,
		disableSli,
		userLocation,
		collect,
	});
	self.current.experienceId = experienceId;
	self.current.experience = experience;
	self.current.timeout = timeout;
	self.current.analyticsSource = analyticsSource;
	self.current.application = application;
	self.current.edition = edition;
	self.current.experienceTracker = experienceTracker;
	self.current.userLocation = userLocation;
	self.current.collect = collect;
	return useCallback(() => {
		const { current } = self;
		current.experienceTracker.start({
			// @ts-expect-error - TS2322 - Type 'string | null | undefined' is not assignable to type 'string'.
			id: current.experienceId,
			name: current.experience,
			timeout: current.timeout,
			attributes: {
				analyticsSource: current.analyticsSource,
				experience: current.experience,
				experienceId: current.experienceId,
				application: current.application,
				edition: current.edition,
				disableSli: current.disableSli,
				userLocation: current.userLocation,
			},
			collect: current.collect,
			onSuccess: () => undefined,
		});
	}, [self]);
};
export const ExperienceStart = ({
	experienceId,
	experience,
	timeout,
	analyticsSource,
	application,
	edition,
	disableSli = false,
	collect,
}: Props) => {
	const onStart = useExperienceStart({
		experienceId,
		experience,
		timeout,
		analyticsSource,
		application,
		edition,
		disableSli,
		collect,
	});
	useMemo(() => {
		onStart();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		onStart,
		experienceId,
		experience,
		timeout,
		analyticsSource,
		application,
		edition,
		disableSli,
		collect,
	]);
	return null;
};
