import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import { HelpCenterSettingsRedirect } from '@atlassian/jira-servicedesk-customer-service-common/src/ui/help-center-settings-redirect';
import type ProjectSettingsServiceDeskCustomerNotificationsType from '@atlassian/jira-spa-apps-project-settings-service-desk-customer-notifications';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';

export const LazyProjectSettingsServiceDeskCustomerNotifications = lazyForPaint<
	typeof ProjectSettingsServiceDeskCustomerNotificationsType
>(
	() =>
		import(
			/* webpackChunkName: "async-project-settings-service-desk-customer-notifications", jiraSpaEntry: "async-project-settings-service-desk-customer-notifications" */ '@atlassian/jira-spa-apps-project-settings-service-desk-customer-notifications'
		),
);

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default () => (
	<HelpCenterSettingsRedirect page="customer-notifications">
		<LazyPage
			Page={LazyProjectSettingsServiceDeskCustomerNotifications}
			pageId="project-settings-customer-notifications"
			shouldShowSpinner
		/>
	</HelpCenterSettingsRedirect>
);
