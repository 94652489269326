import {
	ROUTE_GROUPS_SERVICEDESK,
	ROUTE_NAMES_SERVICEDESK_CALENDAR_EAP,
	ROUTE_NAMES_SERVICEDESK_CALENDAR,
} from '@atlassian/jira-common-constants/src/spa-routes';

export const servicedeskCalendarEapRoute = {
	group: ROUTE_GROUPS_SERVICEDESK,
	name: ROUTE_NAMES_SERVICEDESK_CALENDAR_EAP,
	path: '/jira/servicedesk/projects/:projectKey/calendar',
	exact: true,
};

export const servicedeskCalendarRoute = {
	group: ROUTE_GROUPS_SERVICEDESK,
	name: ROUTE_NAMES_SERVICEDESK_CALENDAR,
	path: '/jira/servicedesk/projects/:projectKey/boards/:boardId/calendar',
	exact: true,
};
