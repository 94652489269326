import {
	ROUTE_GROUPS_EOC,
	ROUTE_NAMES_EOC_ON_CALL,
} from '@atlassian/jira-common-constants/src/spa-routes';

export const routeNamesEocOnCallRedirectRoute = {
	group: ROUTE_GROUPS_EOC,
	name: `${ROUTE_NAMES_EOC_ON_CALL}-redirect`,
	path: '/jira/ops/teams/:teamId',
	exact: true,
};

export const eocOnCallRoute = {
	group: ROUTE_GROUPS_EOC,
	name: ROUTE_NAMES_EOC_ON_CALL,
	path: '/jira/ops/teams/:teamId/on-call',
	exact: true,
};
