import {
	ROUTE_NAMES_PLANS_DIRECTORY,
	ROUTE_NAMES_SOFTWARE_ADVANCED_ROADMAPS,
	ROUTE_NAMES_SOFTWARE_ADVANCED_ROADMAPS_GETTING_STARTED_REDIRECT,
	ROUTE_NAMES_SOFTWARE_ADVANCED_ROADMAPS_REDIRECT,
	ROUTE_NAMES_SOFTWARE_ADVANCED_ROADMAPS_SETUP,
	ROUTE_NAMES_SOFTWARE_ARJ_SETTINGS_DEPENDENCIES,
	ROUTE_NAMES_SOFTWARE_ARJ_SETTINGS_DEPENDENCIES_REDIRECT,
	ROUTE_NAMES_SOFTWARE_ARJ_SETTINGS_FINANCIAL_YEAR,
	ROUTE_NAMES_SOFTWARE_ARJ_SETTINGS_LOADING_LIMITS,
	ROUTE_NAMES_SOFTWARE_ARJ_SETTINGS_LOADING_LIMITS_REDIRECT,
	ROUTE_NAMES_SOFTWARE_ARJ_SETTINGS_PERMISSIONS,
	ROUTE_NAMES_SOFTWARE_ARJ_SETTINGS_PERMISSIONS_REDIRECT,
	ROUTE_NAMES_SOFTWARE_PLAN_CALENDAR,
	ROUTE_NAMES_SOFTWARE_PLAN_DEPENDENCIES,
	ROUTE_NAMES_SOFTWARE_PLAN_INCREMENT,
	ROUTE_NAMES_SOFTWARE_PLAN_INCREMENTS,
	ROUTE_NAMES_SOFTWARE_PLAN_RELEASES,
	ROUTE_NAMES_SOFTWARE_PLAN_SUMMARY,
	ROUTE_NAMES_SOFTWARE_PLAN_TEAMS,
	ROUTE_NAMES_SOFTWARE_PLAN_TIMELINE,
	ROUTE_NAMES_TRASHED_PLAN_DIRECT_LINK,
	ROUTE_NAMES_TRASH_PLANS_DIRECTORY,
} from '@atlassian/jira-common-constants/src/spa-routes';
import type { RouterContext } from '@atlassian/jira-router';
import { MENU_ID_PLANS, MENU_ID_PLANS_VIEW_ALL } from '../../../../constants';
import type { SelectedPath } from '../../../../types';

const routes = new Set<string>([
	ROUTE_NAMES_PLANS_DIRECTORY,
	ROUTE_NAMES_SOFTWARE_ADVANCED_ROADMAPS,
	ROUTE_NAMES_SOFTWARE_ADVANCED_ROADMAPS_GETTING_STARTED_REDIRECT,
	ROUTE_NAMES_SOFTWARE_ADVANCED_ROADMAPS_REDIRECT,
	ROUTE_NAMES_SOFTWARE_ADVANCED_ROADMAPS_SETUP,
	ROUTE_NAMES_SOFTWARE_ARJ_SETTINGS_DEPENDENCIES,
	ROUTE_NAMES_SOFTWARE_ARJ_SETTINGS_DEPENDENCIES_REDIRECT,
	ROUTE_NAMES_SOFTWARE_ARJ_SETTINGS_FINANCIAL_YEAR,
	ROUTE_NAMES_SOFTWARE_ARJ_SETTINGS_LOADING_LIMITS,
	ROUTE_NAMES_SOFTWARE_ARJ_SETTINGS_LOADING_LIMITS_REDIRECT,
	ROUTE_NAMES_SOFTWARE_ARJ_SETTINGS_PERMISSIONS,
	ROUTE_NAMES_SOFTWARE_ARJ_SETTINGS_PERMISSIONS_REDIRECT,
	ROUTE_NAMES_SOFTWARE_PLAN_CALENDAR,
	ROUTE_NAMES_SOFTWARE_PLAN_DEPENDENCIES,
	ROUTE_NAMES_SOFTWARE_PLAN_INCREMENT,
	ROUTE_NAMES_SOFTWARE_PLAN_INCREMENTS,
	ROUTE_NAMES_SOFTWARE_PLAN_RELEASES,
	ROUTE_NAMES_SOFTWARE_PLAN_SUMMARY,
	ROUTE_NAMES_SOFTWARE_PLAN_TEAMS,
	ROUTE_NAMES_SOFTWARE_PLAN_TIMELINE,

	ROUTE_NAMES_TRASHED_PLAN_DIRECT_LINK,
	ROUTE_NAMES_TRASH_PLANS_DIRECTORY,
]);

function convert(routeContext: RouterContext): SelectedPath {
	const routeName = routeContext.route.name;
	// const { match, query } = routeContext;
	const path: string[] = [MENU_ID_PLANS];

	switch (routeName) {
		case ROUTE_NAMES_PLANS_DIRECTORY:
		default:
			path.push(MENU_ID_PLANS_VIEW_ALL);
	}

	return path;
}

export const plansConverter = { routes, convert };
