import {
	ROUTE_GROUPS_EOC,
	ROUTE_NAMES_EOC_ASSIGN_CUSTOM_ROLES,
} from '@atlassian/jira-common-constants/src/spa-routes';

export const eocAssignCustomRolesRoute = {
	group: ROUTE_GROUPS_EOC,
	name: ROUTE_NAMES_EOC_ASSIGN_CUSTOM_ROLES,
	path: '/jira/settings/products/ops/role-assignment',
	exact: true,
};
