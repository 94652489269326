import { createRelayResource } from '@atlassian/jira-relay-utils/src/services/resources/index.tsx';
import DEPLOYMENTS_PRECONDITION_QUERY, {
	type uiDeploymentsFeaturePreconditionQuery,
} from '@atlassian/jira-relay/src/__generated__/uiDeploymentsFeaturePreconditionQuery.graphql';
import { createResource } from '@atlassian/jira-router';
import {
	RESOURCE_TYPE_DEPLOYMENTS_PRECONDITION,
	RESOURCE_TYPE_DEPLOYMENTS_PRECONDITION_RELAY,
} from '../constants';
import type { PreconditionResult } from '../types';

export const deploymentPreconditionResource = createResource<PreconditionResult>({
	type: RESOURCE_TYPE_DEPLOYMENTS_PRECONDITION,
	getKey: ({ query }) => query.projectKey,
	getDataLoader: () =>
		import(
			/* webpackChunkName: "async-resource-deployments-precondition", jiraSpaEntry: "async-resource-deployments-precondition" */ './get-data'
		),
	maxAge: 60 * 1000,
});

export const deploymentPreconditionRelayResource =
	createRelayResource<uiDeploymentsFeaturePreconditionQuery>({
		type: RESOURCE_TYPE_DEPLOYMENTS_PRECONDITION_RELAY,
		getQuery: ({ match }, { tenantContext }) => ({
			parameters: DEPLOYMENTS_PRECONDITION_QUERY,
			variables: {
				cloudId: tenantContext.cloudId,
				projectKey: String(match.params.projectKey),
			},
			options: { fetchPolicy: 'store-and-network' },
		}),
	});
