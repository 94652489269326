/**
 * @generated SignedSource<<5ffd4525189cde640f4bbf2304a5a8ed>>
 * @relayHash 90aa4766778162aaa1f07507b1a474b6
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID bc66dced586cb3b2ae52b3cc9fb1e611aac659d7f3cbe578af5b089555107240

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type JiraWorkManagementUserLicenseSeatEdition = "FREE" | "PREMIUM" | "STANDARD" | "%future added value";
export type JiraContainerNavigationQueryInput = {
  projectKeyQuery?: JiraContainerNavigationByProjectKeyQueryInput | null | undefined;
  scopeId?: string | null | undefined;
};
export type JiraContainerNavigationByProjectKeyQueryInput = {
  cloudId: string;
  projectKey: string;
};
export type projectNavigation_horizontalNavigationQuery$variables = {
  cloudId: string;
  containerNavigationInput: JiraContainerNavigationQueryInput;
  first: number;
  projectAri: string;
  projectIdOrKey: string;
  projectKey: string;
  useContainerNavigation: boolean;
  useProjectKey: boolean;
};
export type projectNavigation_horizontalNavigationQuery$data = {
  readonly jira: {
    readonly jwmLicensing: {
      readonly currentUserSeatEdition: JiraWorkManagementUserLicenseSeatEdition | null | undefined;
    } | null | undefined;
    readonly projectDataById?: {
      readonly avatar: {
        readonly large: string | null | undefined;
      } | null | undefined;
      readonly name: string;
      readonly projectId: string;
      readonly projectKey: string;
      readonly " $fragmentSpreads": FragmentRefs<"projectBoardHeader_horizontalNavHeader">;
    } | null | undefined;
    readonly projectDataByKey?: {
      readonly avatar: {
        readonly large: string | null | undefined;
      } | null | undefined;
      readonly name: string;
      readonly projectId: string;
      readonly projectKey: string;
      readonly " $fragmentSpreads": FragmentRefs<"projectBoardHeader_horizontalNavHeader">;
    } | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"tabs_businessHorizontalNav_TabsContent">;
  };
};
export type projectNavigation_horizontalNavigationQuery = {
  response: projectNavigation_horizontalNavigationQuery$data;
  variables: projectNavigation_horizontalNavigationQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cloudId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "containerNavigationInput"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "projectAri"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "projectIdOrKey"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "projectKey"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "useContainerNavigation"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "useProjectKey"
},
v8 = {
  "kind": "Variable",
  "name": "cloudId",
  "variableName": "cloudId"
},
v9 = [
  (v8/*: any*/),
  {
    "kind": "Variable",
    "name": "key",
    "variableName": "projectKey"
  }
],
v10 = {
  "concreteType": "JiraAvatar",
  "kind": "LinkedField",
  "name": "avatar",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "large"
    }
  ]
},
v11 = {
  "kind": "ScalarField",
  "name": "projectId"
},
v12 = {
  "alias": "projectKey",
  "kind": "ScalarField",
  "name": "key"
},
v13 = {
  "kind": "ScalarField",
  "name": "name"
},
v14 = {
  "kind": "FragmentSpread",
  "name": "projectBoardHeader_horizontalNavHeader"
},
v15 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "projectAri"
  }
],
v16 = {
  "args": [
    (v8/*: any*/)
  ],
  "concreteType": "JiraWorkManagementLicensing",
  "kind": "LinkedField",
  "name": "jwmLicensing",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "currentUserSeatEdition"
    }
  ]
},
v17 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "first"
},
v18 = [
  (v8/*: any*/),
  (v17/*: any*/),
  {
    "kind": "Variable",
    "name": "projectIdOrKey",
    "variableName": "projectIdOrKey"
  }
],
v19 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v20 = {
  "kind": "ScalarField",
  "name": "id"
},
v21 = {
  "kind": "ScalarField",
  "name": "label"
},
v22 = {
  "kind": "ClientExtension",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "__id"
    }
  ]
},
v23 = {
  "kind": "ScalarField",
  "name": "iconUrl"
},
v24 = {
  "kind": "ScalarField",
  "name": "url"
},
v25 = {
  "kind": "ScalarField",
  "name": "styleClass"
},
v26 = {
  "kind": "LinkedField",
  "name": "node",
  "plural": false,
  "selections": [
    (v19/*: any*/),
    (v20/*: any*/),
    {
      "kind": "ScalarField",
      "name": "isDefault"
    },
    {
      "kind": "ScalarField",
      "name": "typeKey"
    },
    {
      "kind": "TypeDiscriminator",
      "abstractKey": "__isJiraNavigationItem"
    },
    (v21/*: any*/),
    {
      "kind": "ScalarField",
      "name": "canRemove"
    },
    {
      "kind": "ScalarField",
      "name": "canSetAsDefault"
    },
    (v22/*: any*/),
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "concreteType": "JiraAppSection",
          "kind": "LinkedField",
          "name": "sections",
          "plural": true,
          "selections": [
            (v21/*: any*/),
            {
              "concreteType": "JiraAppNavigationItemNestedLink",
              "kind": "LinkedField",
              "name": "links",
              "plural": true,
              "selections": [
                (v21/*: any*/),
                (v23/*: any*/),
                (v24/*: any*/),
                (v25/*: any*/)
              ]
            }
          ]
        },
        (v24/*: any*/),
        (v23/*: any*/),
        (v25/*: any*/),
        {
          "kind": "ScalarField",
          "name": "appType"
        },
        {
          "kind": "ScalarField",
          "name": "envLabel"
        }
      ],
      "type": "JiraAppNavigationItem"
    }
  ]
},
v27 = [
  {
    "kind": "ScalarField",
    "name": "message"
  },
  {
    "kind": "LinkedField",
    "name": "extensions",
    "plural": true,
    "selections": [
      (v19/*: any*/),
      {
        "kind": "ScalarField",
        "name": "statusCode"
      }
    ]
  }
],
v28 = {
  "concreteType": "QueryError",
  "kind": "LinkedField",
  "name": "errors",
  "plural": true,
  "selections": (v27/*: any*/)
},
v29 = [
  {
    "kind": "Literal",
    "name": "type",
    "value": "EDIT_PROJECT_CONFIG"
  }
],
v30 = [
  {
    "kind": "ScalarField",
    "name": "canPerform"
  }
],
v31 = [
  (v10/*: any*/),
  (v11/*: any*/),
  (v12/*: any*/),
  (v13/*: any*/),
  (v20/*: any*/),
  {
    "kind": "ScalarField",
    "name": "isFavourite"
  },
  {
    "kind": "ScalarField",
    "name": "projectStyle"
  },
  {
    "kind": "ScalarField",
    "name": "projectType"
  },
  {
    "kind": "ScalarField",
    "name": "projectTypeName"
  },
  {
    "kind": "ScalarField",
    "name": "webUrl"
  },
  {
    "alias": "editPermission",
    "args": (v29/*: any*/),
    "concreteType": "JiraProjectAction",
    "kind": "LinkedField",
    "name": "action",
    "plural": false,
    "selections": (v30/*: any*/),
    "storageKey": "action(type:\"EDIT_PROJECT_CONFIG\")"
  },
  {
    "kind": "ScalarField",
    "name": "status"
  },
  (v22/*: any*/),
  {
    "alias": "canEditProjectConfig",
    "args": (v29/*: any*/),
    "concreteType": "JiraProjectAction",
    "kind": "LinkedField",
    "name": "action",
    "plural": false,
    "selections": (v30/*: any*/),
    "storageKey": "action(type:\"EDIT_PROJECT_CONFIG\")"
  },
  {
    "alias": "canViewProjectConfig",
    "args": [
      {
        "kind": "Literal",
        "name": "type",
        "value": "VIEW_PROJECT_CONFIG"
      }
    ],
    "concreteType": "JiraProjectAction",
    "kind": "LinkedField",
    "name": "action",
    "plural": false,
    "selections": (v30/*: any*/),
    "storageKey": "action(type:\"VIEW_PROJECT_CONFIG\")"
  },
  {
    "kind": "ScalarField",
    "name": "softwareBoardCount"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/)
    ],
    "kind": "Fragment",
    "name": "projectNavigation_horizontalNavigationQuery",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "concreteType": "JiraQuery",
          "kind": "LinkedField",
          "name": "jira",
          "plural": false,
          "selections": [
            {
              "kind": "FragmentSpread",
              "name": "tabs_businessHorizontalNav_TabsContent"
            },
            {
              "condition": "useProjectKey",
              "kind": "Condition",
              "passingValue": true,
              "selections": [
                {
                  "alias": "projectDataByKey",
                  "args": (v9/*: any*/),
                  "concreteType": "JiraProject",
                  "kind": "LinkedField",
                  "name": "jiraProjectByKey",
                  "plural": false,
                  "selections": [
                    (v10/*: any*/),
                    {
                      "kind": "RequiredField",
                      "field": (v11/*: any*/),
                      "action": "THROW",
                      "path": "jira.projectDataByKey.projectId"
                    },
                    (v12/*: any*/),
                    (v13/*: any*/),
                    (v14/*: any*/)
                  ]
                }
              ]
            },
            {
              "condition": "useProjectKey",
              "kind": "Condition",
              "passingValue": false,
              "selections": [
                {
                  "alias": "projectDataById",
                  "args": (v15/*: any*/),
                  "concreteType": "JiraProject",
                  "kind": "LinkedField",
                  "name": "jiraProject",
                  "plural": false,
                  "selections": [
                    (v10/*: any*/),
                    {
                      "kind": "RequiredField",
                      "field": (v11/*: any*/),
                      "action": "THROW",
                      "path": "jira.projectDataById.projectId"
                    },
                    (v12/*: any*/),
                    (v13/*: any*/),
                    (v14/*: any*/)
                  ]
                }
              ]
            },
            (v16/*: any*/)
          ]
        },
        "action": "THROW",
        "path": "jira"
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v4/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/),
      (v6/*: any*/),
      (v5/*: any*/),
      (v3/*: any*/),
      (v7/*: any*/)
    ],
    "kind": "Operation",
    "name": "projectNavigation_horizontalNavigationQuery",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          (v16/*: any*/),
          {
            "condition": "useContainerNavigation",
            "kind": "Condition",
            "passingValue": false,
            "selections": [
              {
                "alias": "navigationItems",
                "args": (v18/*: any*/),
                "concreteType": "JiraNavigationItemConnection",
                "kind": "LinkedField",
                "name": "jwmSavedViewsByProject",
                "plural": false,
                "selections": [
                  {
                    "concreteType": "JiraNavigationItemEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      (v26/*: any*/),
                      (v19/*: any*/),
                      {
                        "kind": "ScalarField",
                        "name": "cursor"
                      }
                    ]
                  },
                  (v28/*: any*/),
                  {
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "name": "endCursor"
                      },
                      {
                        "kind": "ScalarField",
                        "name": "hasNextPage"
                      }
                    ]
                  }
                ]
              },
              {
                "alias": "navigationItems",
                "args": (v18/*: any*/),
                "filters": [
                  "cloudId",
                  "projectIdOrKey"
                ],
                "handle": "connection",
                "key": "tabs_jiraNavigation__navigationItems",
                "kind": "LinkedHandle",
                "name": "jwmSavedViewsByProject"
              }
            ]
          },
          {
            "condition": "useContainerNavigation",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "args": [
                  {
                    "kind": "Variable",
                    "name": "input",
                    "variableName": "containerNavigationInput"
                  }
                ],
                "kind": "LinkedField",
                "name": "containerNavigation",
                "plural": false,
                "selections": [
                  {
                    "kind": "TypeDiscriminator",
                    "abstractKey": "__isJiraContainerNavigationResult"
                  },
                  (v19/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v20/*: any*/),
                      {
                        "kind": "ScalarField",
                        "name": "canEdit"
                      },
                      {
                        "args": [
                          (v17/*: any*/)
                        ],
                        "concreteType": "JiraNavigationItemConnection",
                        "kind": "LinkedField",
                        "name": "navigationItems",
                        "plural": false,
                        "selections": [
                          {
                            "concreteType": "JiraNavigationItemEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              (v26/*: any*/),
                              (v19/*: any*/)
                            ]
                          },
                          (v28/*: any*/)
                        ]
                      }
                    ],
                    "type": "JiraContainerNavigation"
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v27/*: any*/),
                    "type": "QueryError"
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v20/*: any*/)
                    ],
                    "type": "Node",
                    "abstractKey": "__isNode"
                  }
                ]
              }
            ]
          },
          {
            "condition": "useProjectKey",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "alias": "projectDataByKey",
                "args": (v9/*: any*/),
                "concreteType": "JiraProject",
                "kind": "LinkedField",
                "name": "jiraProjectByKey",
                "plural": false,
                "selections": (v31/*: any*/)
              }
            ]
          },
          {
            "condition": "useProjectKey",
            "kind": "Condition",
            "passingValue": false,
            "selections": [
              {
                "alias": "projectDataById",
                "args": (v15/*: any*/),
                "concreteType": "JiraProject",
                "kind": "LinkedField",
                "name": "jiraProject",
                "plural": false,
                "selections": (v31/*: any*/)
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "bc66dced586cb3b2ae52b3cc9fb1e611aac659d7f3cbe578af5b089555107240",
    "metadata": {},
    "name": "projectNavigation_horizontalNavigationQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "f4b30a3daeb5ce6644248fe254ee32bf";

export default node;
