import {
	ROUTE_GROUPS_SOFTWARE,
	ROUTE_NAMES_SOFTWARE_FORM,
} from '@atlassian/jira-common-constants/src/spa-routes';

export const softwareFormRoute = {
	group: ROUTE_GROUPS_SOFTWARE,
	name: ROUTE_NAMES_SOFTWARE_FORM,
	path: '/jira/software/projects/:projectKey/form',
	exact: true,
};
