import {
	ROUTE_GROUPS_GLOBAL_SETTINGS,
	ROUTE_NAMES_PRODUCTS_JSW_CONFIGURATION,
	ROUTE_NAMES_PRODUCTS_JSW_STORAGE_MANAGEMENT,
} from '@atlassian/jira-common-constants/src/spa-routes';

export const productsJswConfigurationRoute = {
	group: ROUTE_GROUPS_GLOBAL_SETTINGS,
	name: ROUTE_NAMES_PRODUCTS_JSW_CONFIGURATION,
	path: '/jira/settings/products/jira-software-configuration',
	exact: true,
};

export const productsJswConfigurationStorageManagementRoute = {
	group: ROUTE_GROUPS_GLOBAL_SETTINGS,
	name: ROUTE_NAMES_PRODUCTS_JSW_STORAGE_MANAGEMENT,
	path: '/jira/settings/products/jira-software-configuration/storage-management',
	exact: true,
};
