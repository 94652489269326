/**
 * @generated SignedSource<<f080460ccc8bf247455024d334453137>>
 * @relayHash 035411b27a814a70ae99e8461b1d6a5f
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 7ed9855b018aa83e1bb9d3cb6613d0e2c8dc2820649e7a31a0b51e1cf460e022

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { uiVersionDetailsQuery } from './uiVersionDetailsQuery.graphql';

const node: PreloadableConcreteRequest<uiVersionDetailsQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "7ed9855b018aa83e1bb9d3cb6613d0e2c8dc2820649e7a31a0b51e1cf460e022",
    "metadata": {},
    "name": "uiVersionDetailsQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;
