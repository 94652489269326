import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import {
	LazyHorizontalOperationsOverviewNav,
	AsyncHorizontalOperationsOverviewNav,
} from '@atlassian/jira-operations-horizontal-nav/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry';
import type { Route } from '@atlassian/jira-router';
import { getTeamsResource } from '@atlassian/jira-router-resources-eoc-overview';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/index.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation';
import {
	eocOverviewRoute,
	eocOverviewAllTeamsRoute,
} from '@atlassian/jira-router-routes-eoc-overview-routes';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const LazyOverviewPage = lazyForPaint<any>(() =>
	import(
		/* webpackChunkName: "async-jira-eoc-overview", jiraSpaEntry: "async-jira-eoc-overview" */ '@atlassian/jira-eoc-overview'
	).then((m) => m.OverviewPage),
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const LazyOverviewAllTeamsPage = lazyForPaint<any>(() =>
	import(
		/* webpackChunkName: "async-jira-eoc-all-teams", jiraSpaEntry: "async-jira-eoc-all-teams" */ '@atlassian/jira-eoc-overview'
	).then((m) => m.AllTeamsPage),
);

const EocOverviewComponent = () => (
	<LazyPage Page={LazyOverviewPage} pageId="async-jira-eoc-overview" shouldShowSpinner={false} />
);

const EocOverviewAllTeamsComponent = () => (
	<LazyPage
		Page={LazyOverviewAllTeamsPage}
		pageId="async-jira-eoc-overview-all-teams"
		shouldShowSpinner={false}
	/>
);

export const eocOverviewRouteEntry: Route = createEntry(eocOverviewRoute, {
	exact: true,
	component: EocOverviewComponent,
	resources: [...getNavigationResources(), getConsolidationStateResource(), getTeamsResource],
	navigation: {
		menuId: MENU_ID.HOME,
		horizontal: AsyncHorizontalOperationsOverviewNav,
		horizontalFeatureFlag: 'platform.alx-8775-operations-horizontal-nav-platform_42yfm',
	},
	forPaint: [LazyAtlassianNavigation, LazyHorizontalOperationsOverviewNav, LazyOverviewPage],
});

export const eocOverviewAllTeamsRouteEntry: Route = createEntry(eocOverviewAllTeamsRoute, {
	exact: true,
	component: EocOverviewAllTeamsComponent,
	resources: [...getNavigationResources(), getConsolidationStateResource(), getTeamsResource],
	navigation: {
		menuId: MENU_ID.HOME,
		horizontal: AsyncHorizontalOperationsOverviewNav,
		horizontalFeatureFlag: 'platform.alx-8775-operations-horizontal-nav-platform_42yfm',
	},
	forPaint: [
		LazyAtlassianNavigation,
		LazyHorizontalOperationsOverviewNav,
		LazyOverviewAllTeamsPage,
	],
});
