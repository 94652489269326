import type { CreatePayload } from '@atlaskit/link-create';
import type { EMBEDDED_CONFLUENCE_MODE } from '@atlassian/embedded-confluence-common';

export const ConfluenceContentTypes = {
	WHITEBOARD: 'whiteboard',
	PAGE: 'page',
} as const;
export const DisplayTypes = {
	MODAL: 'modal',
	PANEL: 'panel',
} as const;

export type EmbeddedConfluenceContentType =
	(typeof ConfluenceContentTypes)[keyof typeof ConfluenceContentTypes];

export type EmbeddedConfluenceDisplayType = (typeof DisplayTypes)[keyof typeof DisplayTypes];
export interface EmbeddedConfluenceState {
	confluenceData: {
		contentUrl: string;
		contentData: CreatePayload | null;
		isDraft?: boolean;
		isLive?: boolean;
		mode?: EMBEDDED_CONFLUENCE_MODE;
		title?: string;
		pageSpaceName?: string;
		isFailedRemoteLink?: boolean;
	};
	contentType: EmbeddedConfluenceContentType;
	embeddedConfluenceSource?: string;
	displayType: EmbeddedConfluenceDisplayType;
	onPublish?: () => void;
	onLinkContent?: (url: string) => void;
	panel: {
		initialWidth: number;
		isOpen: boolean;
		wasLeftNavOpen: boolean;
	};
	modal: {
		isOpen: boolean;
	};
}

export type UseEmbeddedConfluenceSidePanelType = (embeddedConfluenceSource?: string) => [
	state: EmbeddedConfluenceState,
	actions: {
		openSidePanel: (params: OpenSidePanelCoreParams) => void;
		closeSidePanel: () => void;
	},
];
export interface OpenSidePanelCoreParams {
	contentType: EmbeddedConfluenceContentType;
	embeddedConfluenceSource?: string;
	data: CreatePayload | null;
	onPublish?: () => void;
	onLinkContent?: (url: string) => void;
	isDraft?: boolean;
	isFailedRemoteLink?: boolean;
	isLive?: boolean;
	mode?: EMBEDDED_CONFLUENCE_MODE;
	title?: string;
	url: string;
}
export interface OpenSidePanelActionParams extends OpenSidePanelCoreParams {
	displayType: (typeof DisplayTypes)[keyof typeof DisplayTypes];
	initialWidth: number;
	shouldOpenModal: boolean;
	wasLeftNavOpen: boolean;
}
