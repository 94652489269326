import React, { useEffect, useRef } from 'react';
import { useAnalyticsEvents, fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { useRouter, Redirect } from '@atlassian/jira-router';
import { useSpaStateTransition } from '@atlassian/jira-spa-state-controller/src/components/transition-state/index.tsx';
import type { ScreenRedirectProps } from './types';
/**
 * Manages navigation within a single-page application by redirecting to a specified path.
 * It optionally maintains search parameters during navigation to preserve state or context.
 * This function integrates with the application's analytics system to track navigational events.
 */
export const ScreenRedirect = ({ to, persistSearchParams = true }: ScreenRedirectProps) => {
	const [{ route, location, query }] = useRouter();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const [{ isInitialRender: isInitial }] = useSpaStateTransition();
	const eventRef = useRef(createAnalyticsEvent({}));
	useEffect(() => {
		if (!route || !eventRef.current) return;
		fireUIAnalytics(eventRef.current, 'route redirected', {
			isInitial,
			routeName: route.name,
		});
		// @ts-expect-error - Type 'null' is not assignable to type 'UIAnalyticsEvent'.
		eventRef.current = null;
	}, [route, isInitial]);
	if (Array.isArray(persistSearchParams)) {
		const persistParams = Object.keys(query) // eslint-disable-next-line @typescript-eslint/no-explicit-any
			.reduce<Array<any>>((result, currentKey) => {
				const shouldPersist = persistSearchParams.some((persistKey) =>
					currentKey.includes(persistKey),
				);
				if (shouldPersist) {
					result.push(`${currentKey}=${query[currentKey]}`);
				}
				return result;
			}, [])
			.join('&');
		if (persistParams !== '') {
			return <Redirect to={`${to}?${persistParams}`} />;
		}
		return <Redirect to={to} />;
	}
	return <Redirect to={`${to}${persistSearchParams ? location.search : ''}`} />;
};
