import type { ComponentType } from 'react';
import { lazyAfterPaint } from 'react-loosely-lazy';
import { CORE_PROJECT } from '@atlassian/jira-common-constants/src/project-types.tsx';
import { projectLayout } from '@atlassian/jira-project-layout';
import { composeLayouts, withProjectContext, type Layout } from '@atlassian/jira-route-layout';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const Onboarding = lazyAfterPaint(
	() => import(/* webpackChunkName: "async-business-project-onboarding" */ './ui/onboarding'),
	{ ssr: false },
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const withAnyBusinessProjectContext = (Component: ComponentType<Record<any, any>>) =>
	withProjectContext(Component, {
		project: {
			style: 'any',
			type: CORE_PROJECT,
		},
	});

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const RightSidebars = lazyAfterPaint(
	() =>
		import(/* webpackChunkName: "async-business-project-right-sidebars" */ './ui/right-sidebars'),
	{ ssr: false },
);

const BusinessOnboarding = withAnyBusinessProjectContext(Onboarding);

const nonSettingsRightSidebars = [withAnyBusinessProjectContext(RightSidebars)];

const sharedBusinessProjectLayout: Layout = composeLayouts(projectLayout);

export const businessProjectLayout: Layout = composeLayouts(sharedBusinessProjectLayout, {
	rightSidebars: nonSettingsRightSidebars,
	globalComponents: [BusinessOnboarding],
});
