import {
	ROUTE_GROUPS_EOC,
	ROUTE_NAMES_EOC_GLOBAL_ALERT_DETAIL,
	ROUTE_NAMES_EOC_GLOBAL_ALERTS,
} from '@atlassian/jira-common-constants/src/spa-routes';

export const eocGlobalAlertDetailRoute = {
	group: ROUTE_GROUPS_EOC,
	name: ROUTE_NAMES_EOC_GLOBAL_ALERT_DETAIL,
	path: '/jira/ops/alerts/:id',
	exact: true,
};

export const eocGlobalAlertListRoute = {
	group: ROUTE_GROUPS_EOC,
	name: ROUTE_NAMES_EOC_GLOBAL_ALERTS,
	path: '/jira/ops/alerts',
};
