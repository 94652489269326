import React from 'react';
import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarGlobalSettingsProducts from '@atlassian/jira-navigation-apps-sidebar-global-settings-products/src/async';
import {
	AsyncHorizontalOperationsConfigurationNav,
	LazyHorizontalOperationsConfigurationNav,
} from '@atlassian/jira-operations-configurations-horizontal-nav/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry';
import { listResources, detailResources } from '@atlassian/jira-router-resources-eoc-incoming-call';
import {
	getNavigationResources,
	getNavigationSidebarProductsSettingResource,
} from '@atlassian/jira-router-resources-navigation';
import {
	globalIncomingCallRoute,
	globalIncomingCallDetailRoute,
} from '@atlassian/jira-router-routes-eoc-incoming-call-routes';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';
import { LazyIncomingCall, LazyIncomingCallDetail } from './async';

export const IncomingCall = () => (
	<LazyPage
		Page={LazyIncomingCall}
		pageId="async-jira-eoc-incoming-call"
		shouldShowSpinner={false}
	/>
);

export const IncomingCallDetail = () => (
	<LazyPage
		Page={LazyIncomingCallDetail}
		pageId="async-jira-eoc-incoming-call-detail"
		shouldShowSpinner={false}
	/>
);

// Global routes
export const globalIncomingCallRouteEntry = createEntry(globalIncomingCallRoute, {
	exact: true,
	component: IncomingCall,
	resources: [
		...getNavigationResources(),
		...getNavigationSidebarProductsSettingResource(),
		...listResources,
	],
	navigation: {
		menuId: MENU_ID.SETTINGS,
		sidebar: AsyncSidebarGlobalSettingsProducts,
		horizontal: AsyncHorizontalOperationsConfigurationNav,
		horizontalFeatureFlag: 'platform.alx-8775-operations-horizontal-nav-platform_42yfm',
	},
	forPaint: [LazyHorizontalOperationsConfigurationNav, LazyAtlassianNavigation, LazyIncomingCall],
});

export const globalIncomingCallDetailRouteEntry = createEntry(globalIncomingCallDetailRoute, {
	exact: true,
	component: IncomingCallDetail,
	resources: [
		...getNavigationResources(),
		...getNavigationSidebarProductsSettingResource(),
		...detailResources,
	],
	navigation: {
		sidebarId: SIDEBAR_ID.GLOBAL_PRODUCTS_SETTINGS,
		menuId: MENU_ID.SETTINGS,
		sidebar: AsyncSidebarGlobalSettingsProducts,
	},
	forPaint: [LazyAtlassianNavigation, LazyIncomingCall],
});

export const eocIncomingCallRouteEntries = [
	globalIncomingCallRouteEntry,
	globalIncomingCallDetailRouteEntry,
];
