import React from 'react';
import { Inline } from '@atlaskit/primitives';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout';

type Props = {
	headerActions?: React.ReactNode;
	children?: React.ReactNode;
};

export const DirHeaderActionsContainer = ({ headerActions, children }: Props) => {
	if (getWillShowNav4()) {
		return (
			<Inline spread="space-between" alignBlock="end">
				{children}
				{headerActions}
			</Inline>
		);
	}
	return children;
};
