import FetchError, { ABORT_ERROR_NAME } from '@atlassian/jira-fetch/src/utils/errors';
import { isClientFetchError } from '@atlassian/jira-fetch/src/utils/is-error.tsx';
import normaliseUrl from '@atlassian/jira-platform-url-normalisation';
import { statusCodeGroups } from './constants';

const getStatusCodeGroup = (statusCode: number | null) => {
	if (statusCode === null) {
		return statusCodeGroups.UNKNOWN;
	}

	if (statusCode >= 100 && statusCode <= 199) {
		return statusCodeGroups.INFORMATIONAL_RESPONSES;
	}
	if (statusCode >= 200 && statusCode <= 299) {
		return statusCodeGroups.SUCCESS_RESPONSES;
	}
	if (statusCode >= 300 && statusCode <= 399) {
		return statusCodeGroups.REDIRECTS;
	}

	if (statusCode >= 400 && statusCode <= 499) {
		return statusCodeGroups.CLIENT_ERRORS;
	}

	if (statusCode >= 500 && statusCode <= 599) {
		return statusCodeGroups.SERVER_ERRORS;
	}

	return statusCodeGroups.UNKNOWN;
};

const stringifySafe = (obj: object): string | null => {
	try {
		return JSON.stringify(obj);
	} catch {
		return null;
	}
};

export class FetchUrlError extends FetchError {
	url: string;

	aborted: boolean;

	isClientError: boolean;

	statusCodeGroup: string;

	constructor(url: string, fetchError: FetchError | Error) {
		if (fetchError instanceof FetchError) {
			super(fetchError.statusCode, fetchError.message, fetchError.traceId);
		} else {
			super(-1, fetchError.message);
		}

		this.name = 'FetchUrlError';
		this.url = normaliseUrl(url);
		this.aborted = fetchError.name === ABORT_ERROR_NAME;
		this.isClientError = isClientFetchError(fetchError) || this.statusCode === -1;
		this.statusCodeGroup = getStatusCodeGroup(
			fetchError instanceof FetchError ? fetchError.statusCode : null,
		);
		// NOTE: logging does not support nested objects!
		this.cause = stringifySafe(fetchError);
	}
}
