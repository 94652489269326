import {
	ROUTE_GROUPS_SOFTWARE,
	ROUTE_NAMES_SOFTWARE_VERSION_DETAIL,
	ROUTE_NAMES_SOFTWARE_VERSION_DETAIL_NO_TAB_PATH,
	ROUTE_NAMES_SOFTWARE_VERSION_DETAIL_LEGACY_REDIRECT,
} from '@atlassian/jira-common-constants/src/spa-routes';

export const softwareVersionDetailRoute = {
	group: ROUTE_GROUPS_SOFTWARE,
	name: ROUTE_NAMES_SOFTWARE_VERSION_DETAIL,
	path: '/projects/:projectKey/versions/:versionId/tab/:tab',
	ufoName: 'software.version-details-page',
};
export const softwareVersionDetailNoTabPathRedirectRoute = {
	group: ROUTE_GROUPS_SOFTWARE,
	name: ROUTE_NAMES_SOFTWARE_VERSION_DETAIL_NO_TAB_PATH,
	path: '/projects/:projectKey/versions/:versionId',
};
export const softwareVersionDetailLegacyRedirectRoute = {
	group: ROUTE_GROUPS_SOFTWARE,
	name: ROUTE_NAMES_SOFTWARE_VERSION_DETAIL_LEGACY_REDIRECT,
	path: '/browse/:projectKey/fixforversion/:versionId',
};
