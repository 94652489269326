import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async';
import { ff } from '@atlassian/jira-feature-flagging';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import {
	AsyncSidebarEocGlobalAlerts,
	LazyGlobalAlertsSidebar,
} from '@atlassian/jira-navigation-apps-sidebar-eoc-global-alerts/src/async';
import AsyncSidebarEocTeamDashboard, {
	LazyTeamDashboardSidebar,
} from '@atlassian/jira-navigation-apps-sidebar-eoc-team-dashboard/src/async';
import { opsTeamLayout } from '@atlassian/jira-ops-team-layout';
import { createEntry } from '@atlassian/jira-route-entry';
import { resources as alertListResources } from '@atlassian/jira-router-resources-eoc-alert-list';
import { resources as groupDetailResources } from '@atlassian/jira-router-resources-eoc-group-detail';
import { resources as teamDashboardResources } from '@atlassian/jira-router-resources-eoc-team-dashboard';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/index.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation';
import ErrorPagesNotFound from '@atlassian/jira-router-routes-common/src/ui/error-pages/not-found/index.tsx';
import {
	eocGlobalGroupDetailRoute,
	eocTeamGroupDetailRoute,
} from '@atlassian/jira-router-routes-eoc-group-detail-routes';
import GroupList, { LazyGroupDetail } from './ui/spa/eoc/group-detail';

export const eocGlobalGroupDetailRouteEntry = createEntry(eocGlobalGroupDetailRoute, {
	component: componentWithCondition(
		() => ff('platform.aiops-smart-view_yf5qp'),
		GroupList,
		ErrorPagesNotFound,
	),
	resources: [
		...getNavigationResources(),
		...groupDetailResources,
		getConsolidationStateResource(),
		...alertListResources,
	],
	navigation: {
		menuId: MENU_ID.HOME,
		sidebar: AsyncSidebarEocGlobalAlerts,
	},
	forPaint: [LazyAtlassianNavigation, LazyGroupDetail, LazyGlobalAlertsSidebar],
});

export const eocTeamGroupDetailRouteEntry = createEntry(eocTeamGroupDetailRoute, {
	component: componentWithCondition(
		() => ff('platform.aiops-smart-view_yf5qp'),
		GroupList,
		ErrorPagesNotFound,
	),
	layout: opsTeamLayout,
	resources: [
		...getNavigationResources(),
		...teamDashboardResources,
		...groupDetailResources,
		getConsolidationStateResource(),
		...alertListResources,
	],
	navigation: {
		menuId: MENU_ID.PEOPLE,
		sidebar: AsyncSidebarEocTeamDashboard,
	},
	forPaint: [LazyAtlassianNavigation, LazyGroupDetail, LazyTeamDashboardSidebar],
});
