import type { ProjectType } from '@atlassian/jira-common-constants/src/project-types';
import type { EntryPointRouteParams } from '@atlassian/jira-entry-points-plugin/src/common/types.tsx';
import { ff } from '@atlassian/jira-feature-flagging';
import { isAri, createAri } from '@atlassian/jira-platform-ari';
import type { FilterInitialState as Filter } from '@atlassian/jira-projects-directory-v3-utils/src/constants';
import pageLayout_projectsDirectoryLayout_RootQuery from '@atlassian/jira-relay/src/__generated__/pageLayout_projectsDirectoryLayout_RootQuery$parameters';
import type { JiraProjectFilterInput } from '@atlassian/jira-relay/src/__generated__/ProjectDirectoryRefetchQuery.graphql';
import type { Query } from '@atlassian/jira-router';
import { JSResourceForUserVisible } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

const DEFAULT_LIMIT = 25;
const PROJECTS_DIRECTORY_MANAGE = 'projects-directory-manage';

const DefaultFilterFields = {
	// sorting
	SortOrder: 'sortOrder',
	SortKey: 'sortKey',

	// pagination
	Page: 'page',
};

// TODO: go/restrict-enums
// eslint-disable-next-line no-restricted-syntax
enum TableColumns {
	Favourites = 'favourites',
	Key = 'key',
	Name = 'name',
	Type = 'type',
	Lead = 'lead',
	Category = 'category',
	LastIssueUpdate = 'last_issue_updated_time',
	Url = 'url',
	Action = 'actions',
}

const SortableColumns = [
	TableColumns.Key,
	TableColumns.Name,
	TableColumns.Lead,
	TableColumns.Category,
	TableColumns.LastIssueUpdate,
];

const FilterFields = {
	Keyword: 'keyword',
	ProjectType: 'types',
	ProjectCategoryId: 'projectCategoryId',
	...DefaultFilterFields,
};

const filterAliasMap = {
	contains: FilterFields.Keyword,
	selectedProjectType: FilterFields.ProjectType,
	selectedCategory: FilterFields.ProjectCategoryId,
};

const filterInitialState = {
	[FilterFields.Keyword]: '',
	[FilterFields.ProjectType]: '',
	[FilterFields.ProjectCategoryId]: '',
	[FilterFields.SortOrder]: 'ASC',
	[FilterFields.SortKey]: TableColumns.Name,
	[FilterFields.Page]: '1',
};

type Dictionary = {
	[key: string]: string;
};

const LEGACY_ALL_VALUE = 'all';

const sanitizeLegacyQuery = (query: Query = {}) => ({
	...query,
	selectedProjectType:
		query.selectedProjectType === LEGACY_ALL_VALUE
			? filterInitialState[FilterFields.ProjectType]
			: query.selectedProjectType,
	selectedCategory:
		query.selectedCategory === LEGACY_ALL_VALUE
			? filterInitialState[FilterFields.ProjectCategoryId]
			: query.selectedCategory,
});

const matchAliases = <T extends Dictionary>(
	filterState: T,
	query: Query,
	aliases: { [alias: string]: keyof T },
): T =>
	Object.keys(query).reduce(
		(filter, key) => {
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			const alias = aliases[key] as string;
			const queryValue = query[key];
			const hasInitialValue = filter[key] !== undefined;

			// override default filter value with query params
			if (hasInitialValue && queryValue) {
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, no-param-reassign
				(filter as Dictionary)[key] = queryValue;
				return filter;
			}

			// if there's no alias and no query param for it return default filter
			if (!alias || !queryValue) {
				return filter;
			}

			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, no-param-reassign
			(filter as Dictionary)[alias] = queryValue;
			return filter;
		},
		{ ...filterState },
	);

const CORE_PROJECT = 'business' as const;
const SOFTWARE_PROJECT = 'software' as const;
const SERVICE_DESK_PROJECT = 'service_desk' as const;
const PRODUCT_DISCOVERY_PROJECT = 'product_discovery' as const;

const PROJECT_TYPES: ProjectType[] = [
	CORE_PROJECT,
	SOFTWARE_PROJECT,
	SERVICE_DESK_PROJECT,
	PRODUCT_DISCOVERY_PROJECT,
];

const mapQueryToFilter = <T extends Dictionary>(
	filter: T,
	{
		cloudId,
		query,
		aliases,
	}: { cloudId: string; query?: Query; aliases?: { [alias: string]: keyof T } },
) => {
	const variables: JiraProjectFilterInput = {};
	const {
		[FilterFields.Keyword]: keyword,
		[FilterFields.ProjectCategoryId]: projectCategoryId,
		[FilterFields.ProjectType]: types,
		[FilterFields.SortOrder]: sortOrder,
		[FilterFields.SortKey]: sortKey,
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	} = (aliases && query ? matchAliases(filter, query, aliases) : filter) as Filter;

	if (keyword) {
		variables.keyword = keyword;
	}

	if (projectCategoryId) {
		variables.projectCategoryId = isAri(projectCategoryId)
			? projectCategoryId
			: createAri({
					resourceOwner: 'jira',
					cloudId,
					resourceType: 'project-category',
					resourceId: projectCategoryId,
				});
	}

	if (sortKey || sortOrder) {
		const sortByValue =
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			sortKey && SortableColumns.includes(sortKey as TableColumns)
				? sortKey
				: filterInitialState[FilterFields.SortKey];

		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		variables.sortBy = {
			order: ['ASC', 'DESC'].includes(sortOrder)
				? sortOrder
				: filterInitialState[FilterFields.SortOrder],
			sortBy: sortByValue.toUpperCase(),
		} as JiraProjectFilterInput['sortBy'];
	}

	if (types && typeof types === 'string') {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		variables.types = types
			.split(',') // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			.filter((type) => PROJECT_TYPES.includes(type as ProjectType))
			.map((type) => type.toUpperCase()) as unknown as JiraProjectFilterInput['types'];
	}
	return variables;
};

export const projectsDirectoryEntryPoint = createEntryPoint({
	root: JSResourceForUserVisible(
		() =>
			import(
				/* webpackChunkName: "async-projects-directory-v3", jiraSpaEntry: "async-projects-directory-v3" */ './src/main'
			),
	),
	getPreloadProps({ context: { query, route }, tenantContext }: EntryPointRouteParams) {
		const { cloudId, isAnonymous } = tenantContext;

		const extraProps = {
			isAdminProjectDirectoryRoute: route.name === PROJECTS_DIRECTORY_MANAGE,
		};

		if (__SERVER__ && isAnonymous) {
			return { extraProps };
		}

		return {
			queries: {
				projectsDirectoryPage: {
					options: {
						fetchPolicy: 'network-only',
					},
					parameters: pageLayout_projectsDirectoryLayout_RootQuery,
					variables: {
						cloudId: tenantContext.cloudId,
						filter: mapQueryToFilter(filterInitialState, {
							cloudId,
							query: sanitizeLegacyQuery(query),
							aliases: filterAliasMap,
						}),
						first: query?.page ? DEFAULT_LIMIT * (parseInt(query.page, 10) || 1) : DEFAULT_LIMIT,
						last: DEFAULT_LIMIT,
						isAdminSettingsContext: route.name === PROJECTS_DIRECTORY_MANAGE,
						isAnonymous,
						skipShouldShowRightSidebar:
							isAnonymous || ff('server-render-projects-directory-sidebar_rj9ki'),
					},
				},
			},
			extraProps,
		};
	},
});
