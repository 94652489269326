import {
	ROUTE_GROUPS_EOC,
	ROUTE_NAMES_EOC_WHO_IS_ON_CALL,
} from '@atlassian/jira-common-constants/src/spa-routes';
import type { Route } from '@atlassian/jira-router';

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const whoIsOnCallRoute = {
	group: ROUTE_GROUPS_EOC,
	name: ROUTE_NAMES_EOC_WHO_IS_ON_CALL,
	path: '/jira/ops/who-is-on-call',
} as const as Route;
