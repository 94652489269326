import {
	createStore,
	createHook,
	createSubscriber,
	createContainer,
} from '@atlassian/react-sweet-state';
import type { BreadcrumbStateData, State } from './types';

const initialState: State = { trail: [] };

export const actions = {
	pushBreadcrumb:
		(breadcrumb: BreadcrumbStateData) =>
		// @ts-expect-error - TS7031 - Binding element 'setState' implicitly has an 'any' type. | TS7031 - Binding element 'getState' implicitly has an 'any' type.
		({ setState, getState }) => {
			setState({
				trail: [...getState().trail, breadcrumb],
			});
		},

	popBreadcrumb:
		() =>
		// @ts-expect-error - TS7031 - Binding element 'setState' implicitly has an 'any' type. | TS7031 - Binding element 'getState' implicitly has an 'any' type.
		({ setState, getState }) => {
			setState({
				trail: getState().trail.slice(0, -1),
			});
		},

	updateBreadcrumb:
		(breadcrumb: BreadcrumbStateData) =>
		// @ts-expect-error - TS7031 - Binding element 'setState' implicitly has an 'any' type. | TS7031 - Binding element 'getState' implicitly has an 'any' type.
		({ setState, getState }) => {
			// @ts-expect-error - TS7006 - Parameter 'item' implicitly has an 'any' type.
			const newTrail = getState().trail.map((item) =>
				item.id === breadcrumb.id ? breadcrumb : item,
			);
			setState({
				trail: newTrail,
			});
		},
} as const;

type Actions = typeof actions;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ContainerProps = Record<any, any>;

const Store = createStore<State, Actions>({
	initialState,
	actions,
	name: 'BreadcrumbSweetState',
});

const _BreadcrumbSubscriber = createSubscriber<State, Actions>(Store);
export const useBreadcrumbs = createHook(Store);

export const BreadcrumbActions = createSubscriber<State, Actions, undefined, undefined>(Store, {
	selector: null,
});

export const BreadcrumbContainer = createContainer<State, Actions, ContainerProps>(Store);
