import Breadcrumb from '@atlassian/jira-breadcrumbs/src/breadcrumb/component.tsx';
import BoardBreadcrumb from '@atlassian/jira-breadcrumbs/src/common-breadcrumbs/board/view.tsx';
import UserBreadcrumb from '@atlassian/jira-breadcrumbs/src/common-breadcrumbs/user/view.tsx';
import withInjectedProps from '@atlassian/jira-common-util-with-injected-props';
import ProjectBreadcrumb from '../../common-breadcrumbs/project';
import LegacyBreadcrumbConnector from './component';
import ContextProvider from './context-provider';

export default withInjectedProps(LegacyBreadcrumbConnector, {
	Breadcrumb,
	ProjectBreadcrumb,
	// @ts-expect-error - TS2322 - Type '{ ({  children, boardId, boardName, isSimplifiedProject, projectId, }: BoardBreadcrumbProps): JSX.Element; defaultProps: { children: null; isSimplifiedProject: boolean; projectId: undefined; }; }' is not assignable to type 'ComponentType<Partial<BoardBreadcrumbProps>> | undefined'.
	BoardBreadcrumb,
	UserBreadcrumb,
	ContextProvider,
});
