import { useCallback } from 'react';
import { useAnalyticsSource } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import type { TrackEmbeddedConfluence } from '../../common/types/types';
import { getParentProduct } from '../utils';
import { fireEmbeddedConfluenceAnalytics } from '../utils/analytics';

export const useEmbeddedPageTracking = () => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const tenantContext = useTenantContext();
	const source = useAnalyticsSource();

	const trackEmbeddedConfluence: TrackEmbeddedConfluence = useCallback(
		({
			eventName,
			action,
			actionSubject,
			projectType,
			embeddedConfluenceSource = 'jiraIssueView',
			eventType = 'ui',
			userHasConfluenceAccess,
			tenantHasConfluence,
			crossJoinStatus,
			originProduct,
			requestedProduct,
		}) => {
			const analyticsEvent = createAnalyticsEvent({
				action,
				actionSubject: actionSubject ?? `${eventName}`,
			});

			const attributes = {
				parentProduct: getParentProduct(projectType),
				tenantEditions: tenantContext?.appEditions,
				productEntitlementDetails: tenantContext?.productEntitlementDetails,
				embeddedConfluenceSource,
				jiraIssueSurfaceArea: source,
				userHasConfluenceAccess,
				tenantHasConfluence,
				crossJoinStatus,
				originProduct,
				requestedProduct,
				isSiteAdmin: tenantContext?.isSiteAdmin,
			};
			fireEmbeddedConfluenceAnalytics({
				analyticsEvent,
				attributes,
				eventType,
				eventName: `${actionSubject ?? eventName} ${action}`,
			});
		},
		[source, tenantContext, createAnalyticsEvent],
	);

	return trackEmbeddedConfluence;
};
