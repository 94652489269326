import {
	ROUTE_GROUPS_ERRORS,
	ROUTE_NAMES_LOGIN_EMBED,
} from '@atlassian/jira-common-constants/src/spa-routes';

export const loginEmbedRoute = {
	group: ROUTE_GROUPS_ERRORS,
	name: ROUTE_NAMES_LOGIN_EMBED,
	path: '/jira-login/embed',
};
