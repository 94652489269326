import {
	ROUTE_GROUPS_ISSUE,
	ROUTE_NAMES_ISSUE,
	ROUTE_NAMES_ISSUE_EMBED,
} from '@atlassian/jira-common-constants/src/spa-routes';
import { NORMAL_EARLY_ASSET_STRATEGY } from '@atlassian/jira-common-constants/src/ssr-early-assets';

export const browseIssueRoute = {
	group: ROUTE_GROUPS_ISSUE,
	name: ROUTE_NAMES_ISSUE,
	path: '/browse/:issueKey(\\w+-\\d+)(.*)?',
	query: ['filter?!=(.*)', 'jql?!=(.*)'],
	preloadOptions: {
		earlyChunks: ['issue-app-early-entry'],
	},
	earlyAssets: NORMAL_EARLY_ASSET_STRATEGY,
};

export const browseIssueEmbedRoute = {
	group: ROUTE_GROUPS_ISSUE,
	name: ROUTE_NAMES_ISSUE_EMBED,
	path: '/browse/:issueKey(\\w+-\\d+)/embed',
	preloadOptions: {
		earlyChunks: ['issue-app-early-entry'],
	},
	earlyAssets: NORMAL_EARLY_ASSET_STRATEGY,
};
