import {
	ROUTE_GROUPS_GLOBAL_SETTINGS,
	ROUTE_NAMES_PRODUCTS_JSM_INCIDENT_MANAGEMENT_TOOLS,
} from '@atlassian/jira-common-constants/src/spa-routes';

export const productsJsmIncidentManagementToolsRoute = {
	group: ROUTE_GROUPS_GLOBAL_SETTINGS,
	name: ROUTE_NAMES_PRODUCTS_JSM_INCIDENT_MANAGEMENT_TOOLS,
	path: '/jira/settings/products/jira-service-management-incident-management-tools',
	exact: true,
};
