import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import Placeholder from '@atlassian/jira-placeholder';
import type { DirectoryHorizontalNav } from './ui';

const LazyDirectoryNav = lazyForPaint<typeof DirectoryHorizontalNav>(() =>
	import(/* webpackChunkName: "async-kb-nav" */ './ui').then(
		({ DirectoryHorizontalNav }) => DirectoryHorizontalNav,
	),
);

export const AsyncDirectoryNav = () => (
	<Placeholder name="horizontal-jsm-directory-nav" fallback={null}>
		<LazyDirectoryNav />
	</Placeholder>
);

export { DirHeaderActionsContainer } from './ui/dir-header-actions-container';
