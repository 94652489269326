import {
	ROUTE_GROUPS_GLOBAL_SETTINGS,
	ROUTE_NAMES_GLOBAL_ADMIN_HELPER_EMAIL_NOTIFICATIONS_LOG,
} from '@atlassian/jira-common-constants/src/spa-routes';

export const emailNotificationsLogAccessRoute = {
	group: ROUTE_GROUPS_GLOBAL_SETTINGS,
	name: ROUTE_NAMES_GLOBAL_ADMIN_HELPER_EMAIL_NOTIFICATIONS_LOG,
	path: '/jira/settings/system/notification-logs',
	exact: true,
};
