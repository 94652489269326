import {
	ROUTE_GROUPS_GLOBAL_SETTINGS,
	ROUTE_NAMES_TRASH_PROJECTS_DIRECTORY,
} from '@atlassian/jira-common-constants/src/spa-routes';

export const trashProjectsDirectoryRoute = {
	group: ROUTE_GROUPS_GLOBAL_SETTINGS,
	name: ROUTE_NAMES_TRASH_PROJECTS_DIRECTORY,
	path: '/secure/projects/trash',
	exact: true,
};

export const trashProjectsDirectory1Route = {
	group: ROUTE_GROUPS_GLOBAL_SETTINGS,
	name: ROUTE_NAMES_TRASH_PROJECTS_DIRECTORY,
	path: '/jira/settings/projects/trash',
	exact: true,
};
