import initAnalytics from '@atlassian/jira-analytics-providers/src/common/utils/init-analytics';
import sendExperienceAnalytics from '@atlassian/jira-common-experience-tracking-analytics';
import { getBrowser } from '@atlassian/jira-common-util-browser';
// eslint-disable-next-line jira/restricted/@atlassian+jira-common-legacy
import { getTenantContext_DEPRECATED_DO_NOT_USE } from '@atlassian/jira-common-util-get-tenant-context';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import {
	CONTAINER_ANALYTICS_SOURCE,
	CONTAINER_EXPERIENCE,
	CONTAINER_APPLICATION,
	CONTAINER_EDITION,
	CONTAINER_BOOTSTRAP_FAIL_LOCATION,
} from '@atlassian/jira-page-container-constants';
import { routeAnalyticsAttributes } from '@atlassian/jira-platform-route-analytics/src/main.tsx';
import type { ErrorFilterCriteria } from './types';

// add errors to filter out here
export const defaultErrorFilteringCriterias: ErrorFilterCriteria[] = [
	// https://jplat.jira.atlassian.cloud/browse/MAGMA-2440
	{
		browser: 'Chrome',
		browserVersion: '116.0.0',
		errorMessage: /Cannot read properties of undefined \(reading 'target'\)/,
	},
];

const checkBrowserCondition = (error: Error, filterCriteria: ErrorFilterCriteria) =>
	filterCriteria.browser != null ? getBrowser().name === filterCriteria.browser : true;
const checkBrowserVersionCondition = (error: Error, filterCriteria: ErrorFilterCriteria) =>
	filterCriteria.browserVersion != null
		? getBrowser().version === filterCriteria.browserVersion
		: true;
const checkErrorMessageCondition = (error: Error, filterCriteria: ErrorFilterCriteria) =>
	filterCriteria.errorMessage != null ? error.message.match(filterCriteria.errorMessage) : true;

export const shouldSendExperienceAnalytics = (
	error: Error,
	errorFilteringCriterias: ErrorFilterCriteria[],
) => {
	try {
		// condition checkers must return true if they don't apply, so an empty filterCriteria will match (and block) ALL errors
		const conditionCheckers = [
			checkBrowserCondition,
			checkBrowserVersionCondition,
			checkErrorMessageCondition,
		];

		// Should send analytics if no filtering conditions are met
		return !errorFilteringCriterias.some((filterCriteria) =>
			conditionCheckers.every((conditionChecker) => conditionChecker(error, filterCriteria)),
		);
	} catch (e) {
		// If any error occurs during filtering, experience analytics should still be sent
		return true;
	}
};

export const reportBootstrapError = async (error: Error) => {
	const tenantContextData = getTenantContext_DEPRECATED_DO_NOT_USE();

	// We probably already have analytics initialised but we could have failed earlier than it
	initAnalytics(tenantContextData);

	// Experience analytics should only be sent if it is not filtered
	if (shouldSendExperienceAnalytics(error, defaultErrorFilteringCriterias)) {
		sendExperienceAnalytics({
			analyticsSource: CONTAINER_ANALYTICS_SOURCE,
			experience: CONTAINER_EXPERIENCE,
			wasExperienceSuccesful: false,
			location: CONTAINER_BOOTSTRAP_FAIL_LOCATION,
			application: CONTAINER_APPLICATION,
			edition: CONTAINER_EDITION,
			additionalAttributes: routeAnalyticsAttributes,
		});
	}

	fireErrorAnalytics({
		meta: {
			packageName: 'jiraBootstrapSlaReporter',
			id: 'bootstrap-failure',
			teamName: 'magma',
		},
		error,
		attributes: routeAnalyticsAttributes,
	});

	throw error;
};
