import { EMBEDDED_CONFLUENCE_MODE } from '@atlassian/embedded-confluence-common';
import type { StoreActionApi } from '@atlassian/react-sweet-state';
import {
	ConfluenceContentTypes,
	DisplayTypes,
	type EmbeddedConfluenceState,
	type OpenSidePanelActionParams,
} from './types';

export const initialState: EmbeddedConfluenceState = {
	confluenceData: {
		contentUrl: '',
		contentData: null,
		mode: EMBEDDED_CONFLUENCE_MODE.VIEW_MODE,
		isDraft: false,
		isLive: false,
	},
	contentType: ConfluenceContentTypes.PAGE,
	embeddedConfluenceSource: '',
	displayType: DisplayTypes.PANEL,
	panel: {
		initialWidth: 0,
		isOpen: false,
		wasLeftNavOpen: false,
	},
	modal: {
		isOpen: false,
	},
};

export const openSidePanel =
	({
		contentType,
		embeddedConfluenceSource,
		data,
		displayType,
		initialWidth,
		isDraft,
		isFailedRemoteLink,
		isLive,
		mode,
		shouldOpenModal,
		url,
		title,
		wasLeftNavOpen,
	}: OpenSidePanelActionParams) =>
	({ setState }: StoreActionApi<EmbeddedConfluenceState>) => {
		setState({
			confluenceData: {
				contentUrl: url,
				contentData: data,
				mode,
				isDraft,
				isFailedRemoteLink,
				isLive,
				title,
			},
			embeddedConfluenceSource,
			contentType,
			displayType,
			panel: {
				initialWidth,
				isOpen: !shouldOpenModal,
				wasLeftNavOpen,
			},
			modal: {
				isOpen: shouldOpenModal,
			},
		});
	};

export const closeSidePanel =
	() =>
	({ setState }: StoreActionApi<EmbeddedConfluenceState>) => {
		setState(initialState);
	};

export const actions = {
	closeSidePanel,
	openSidePanel,
} as const;
