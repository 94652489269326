import {
	ROUTE_GROUPS_SOFTWARE,
	ROUTE_NAMES_SOFTWARE_LIST,
	ROUTE_NAMES_SOFTWARE_LIST_EMBED,
} from '@atlassian/jira-common-constants/src/spa-routes';

export const softwareListRoute = {
	group: ROUTE_GROUPS_SOFTWARE,
	name: ROUTE_NAMES_SOFTWARE_LIST,
	path: '/jira/software/projects/:projectKey/list',
};

export const softwareListEmbedRoute = {
	group: ROUTE_GROUPS_SOFTWARE,
	name: ROUTE_NAMES_SOFTWARE_LIST_EMBED,
	path: '/jira/software/projects/:projectKey/list/embed',
};
