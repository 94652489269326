import { createAri } from '@atlassian/jira-platform-ari';
import { createRelayResource } from '@atlassian/jira-relay-utils/src/services/resources/index.tsx';
import QUERY, {
	type viewBacklogCustomFiltersQuery,
} from '@atlassian/jira-relay/src/__generated__/viewBacklogCustomFiltersQuery.graphql';
import { browserOnlyResourceWithCondition } from '@atlassian/jira-router-resources-utils/src/utils/resource-with-condition/index.tsx';
import { RESOURCE_TYPE_NEXTGEN_BACKLOG_CUSTOM_FILTERS } from '../../constants';

export const customFiltersResource = browserOnlyResourceWithCondition(
	() => __SERVER__,
	createRelayResource<viewBacklogCustomFiltersQuery>({
		type: RESOURCE_TYPE_NEXTGEN_BACKLOG_CUSTOM_FILTERS,
		getQuery: ({ match }, { tenantContext }) => ({
			parameters: QUERY,
			variables: {
				boardId: createAri({
					resourceOwner: 'jira-software',
					cloudId: tenantContext.cloudId,
					resourceType: 'board',
					resourceId: String(match.params.boardId),
				}),
			},
			options: { fetchPolicy: 'store-and-network' },
		}),
		captureErrors: true,
	}),
);
