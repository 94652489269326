import { isFedRamp } from '@atlassian/atl-context';
import {
	ROUTE_NAMES_SOFTWARE_BOARDS,
	ROUTE_NAMES_SOFTWARE_BACKLOG,
	ROUTE_NAMES_SOFTWARE_LIST,
	ROUTE_NAMES_SOFTWARE_LIST_CLASSIC,
	ROUTE_NAMES_PROJECT_SETTINGS_SOFTWARE_ACCESS,
	ROUTE_NAMES_ISSUE,
	ROUTE_NAMES_SOFTWARE_CALENDAR,
	ROUTE_NAMES_SOFTWARE_CALENDAR_CLASSIC,
} from '@atlassian/jira-common-constants/src/spa-routes';
import { UNSAFE_noExposureExp } from '@atlassian/jira-feature-experiments';
import { ff } from '@atlassian/jira-feature-flagging';
import { fg } from '@atlassian/jira-feature-gating';
import { BOARD_CREATE_COLUMN_NUDGE_ID } from '@atlassian/jira-onboarding-core/src/constants';
import { FREE_EDITION, STANDARD_EDITION, PREMIUM_EDITION } from '@atlassian/jira-shared-types';
import {
	REVERSE_TRIAL_ROOT_TASK,
	ROOT_TASK,
	BACKLOG_CREATE_SPRINT_NUDGE_ID,
} from '../../common/constants';
import type { Cards, TaskListItem, TaskList } from '../../common/types';
import { isRoadmapRoute } from '../../common/utils';
import { isTailoredQuickstartEnabled } from '../../utils/tailored-onboarding';
import { USER_ROLE } from '../model/constants';
import { EXPERIENCE_TYPE, type PersonaExperience, type ProjectState } from '../model/types';
import { JSW, JWM, JSM, DEVOPS } from './config';
import { getTasks } from './manager';
import type { GetTasksProps } from './types';
import { isJwmProject, isJsmProject } from './utils';

const getTaskListItem = ({
	variants,
	taskVariants,
	taskList,
	taskGroupParent,
}: {
	variants: string[];
	taskVariants: Cards;
	taskList: TaskList;
	taskGroupParent: string;
}): TaskListItem => {
	if (variants.includes(JSW.experiment.jswReverseTrial)) {
		const { title, items } =
			taskList[taskGroupParent === ROOT_TASK ? REVERSE_TRIAL_ROOT_TASK : taskGroupParent] ??
			taskList[REVERSE_TRIAL_ROOT_TASK];
		return {
			title,
			items: getTasks(variants, taskVariants, items),
		};
	}
	const { title, items } = taskList[taskGroupParent] ?? taskList[ROOT_TASK];

	return {
		title,
		items: getTasks(variants, taskVariants, items),
	};
};

const createJswTasks = (
	projectState: ProjectState | null,
	taskGroupParent: string,
	routeName: string,
	shouldShowScrumOnboardingExperience: boolean,
	mountedNudges: string[],
	canCreateProject: boolean,
	isEligibleToUpdateSitename: boolean,
	isCustomerTemplateProject: boolean,
	isCrossProjectBoardToPlanUpsell: boolean,
	canEditPlans: boolean,
	premiumTrialOnSignup: boolean | undefined,
): TaskListItem => {
	const variants: Set<string> = new Set();
	const isTmpScrumWithSprintPermission = mountedNudges.includes(BACKLOG_CREATE_SPRINT_NUDGE_ID);
	// eslint-disable-next-line jira/ff/unsafe-no-exposure
	const [simplifyKanbanQsExpConfig] = UNSAFE_noExposureExp('jsw_simplify_kanban_qs');

	const shouldSimplifyKanbanQs =
		simplifyKanbanQsExpConfig.get('cohort', 'not-enrolled') === 'variation';

	const isEligibleForKanbanTour = mountedNudges.includes(BOARD_CREATE_COLUMN_NUDGE_ID);

	if (isFedRamp()) {
		variants.add(JSW.experience.fedRamp);
	}

	if (premiumTrialOnSignup && fg('jsw_reverse_trials_feature_gate')) {
		variants.add(JSW.experiment.jswReverseTrial);
	}

	if (ff('jsw.onboarding.rollout.kuro-2250') && isCustomerTemplateProject) {
		variants.add(JSW.projectProperties.isCreatedFromCustomerTemplate);
	}

	if (shouldShowScrumOnboardingExperience && isTmpScrumWithSprintPermission) {
		variants.add(JSW.experiment.scrumOnboarding);
	}

	if (isCrossProjectBoardToPlanUpsell) {
		variants.add(JSW.trait.crossProjectBoardToPlanUpsell);
	}

	if (canEditPlans) {
		variants.add(JSW.permissions.hasPlanEditPermissions);
	}

	if (isEligibleForKanbanTour || routeName !== ROUTE_NAMES_SOFTWARE_BOARDS) {
		variants.add(JSW.experience.kanbanOnboarding);
	}

	if (shouldSimplifyKanbanQs) {
		variants.add(JSW.experiment.simplifyKanbanQS);
	}

	if (canCreateProject === false) {
		variants.add(JSW.permissions.notCanCreateProject);
	}

	if (projectState?.isNextGen) {
		variants.add(JSW.projectStyle.teamManaged);
	} else {
		variants.add(JSW.projectStyle.companyManaged);
	}

	if (projectState?.isProjectAdmin) {
		variants.add(JSW.userRole.projectAdmin);
	}

	if (projectState?.userRole === USER_ROLE.JIRA_ADMIN) {
		variants.add(JSW.userRole.jiraAdmin);
		variants.add(JSW.userRole.admin);
	}

	if (projectState?.userRole === USER_ROLE.SITE_ADMIN) {
		variants.add(JSW.userRole.siteAdmin);
		variants.add(JSW.userRole.admin);
	}

	if (projectState?.edition === FREE_EDITION) {
		variants.add(JSW.edition.free);
	} else if (projectState?.edition === STANDARD_EDITION) {
		variants.add(JSW.edition.standard);
	} else if (projectState?.edition === PREMIUM_EDITION) {
		variants.add(JSW.edition.premium);
	}

	if (isRoadmapRoute(routeName)) {
		variants.add(JSW.route.softwareRoadmap);
	} else if (routeName === ROUTE_NAMES_SOFTWARE_BACKLOG) {
		variants.add(JSW.route.softwareBacklog);
	} else if (routeName === ROUTE_NAMES_SOFTWARE_BOARDS) {
		variants.add(JSW.route.softwareBoard);
	} else if (
		routeName === ROUTE_NAMES_SOFTWARE_LIST ||
		routeName === ROUTE_NAMES_SOFTWARE_LIST_CLASSIC
	) {
		variants.add(JSW.route.softwareList);
	} else if (routeName === ROUTE_NAMES_PROJECT_SETTINGS_SOFTWARE_ACCESS) {
		variants.add(JSW.route.projectSettingsSoftwareAccess);
	} else if (routeName === ROUTE_NAMES_ISSUE) {
		variants.add(JSW.route.issue);
	} else if (
		ff('jsw-calendar-view-onboarding_q3bvy') &&
		(routeName === ROUTE_NAMES_SOFTWARE_CALENDAR ||
			routeName === ROUTE_NAMES_SOFTWARE_CALENDAR_CLASSIC)
	) {
		variants.add(JSW.route.softwareCalendar);
	}

	if (isEligibleToUpdateSitename) {
		variants.add(JSW.experiment.customizeSiteName);
	}

	return getTaskListItem({
		variants: Array.from(variants.values()),
		taskVariants: JSW.taskVariants,
		taskList: JSW.taskList,
		taskGroupParent,
	});
};

const createJwmTasks = (
	projectState: ProjectState | null,
	taskGroupParent: string,
	personaExperience?: PersonaExperience,
	taskList = JWM.taskList,
): TaskListItem => {
	const variants: string[] = [];

	variants.push(JWM.experiments.projectTheming);

	if (projectState?.isProjectAdmin) {
		variants.push(JWM.userRole.projectAdmin);
	}

	if (projectState?.userRole === USER_ROLE.JIRA_ADMIN) {
		variants.push(JWM.userRole.jiraAdmin);
	}

	if (projectState?.userRole === USER_ROLE.SITE_ADMIN) {
		variants.push(JWM.userRole.siteAdmin);
	}

	if (projectState?.isSampleProject) {
		variants.push(JWM.experiments.isSampleProject);
	} else {
		variants.push(JWM.experiments.isNotSampleProject);
	}

	if (projectState?.isTrelloCrossflow) {
		variants.push(JWM.experiments.trelloCrossflow);
	}

	if (isTailoredQuickstartEnabled(personaExperience)) {
		variants.push(JWM.experiments.personalizedExperience);

		switch (personaExperience) {
			case EXPERIENCE_TYPE.MARKETER_LEAD:
				variants.push(JWM.personalizedExperienceExperiments.marketingLeader);
				break;
			case EXPERIENCE_TYPE.MARKETER_PROJECT:
				variants.push(JWM.personalizedExperienceExperiments.projectMarketer);
				break;
			case EXPERIENCE_TYPE.MARKETER_CONTENT:
				variants.push(JWM.personalizedExperienceExperiments.contentMarketer);
				break;
			case EXPERIENCE_TYPE.MARKETER_PMM:
				variants.push(JWM.personalizedExperienceExperiments.productMarketingManager);
				break;
			default:
				break;
		}
	}

	return getTaskListItem({
		variants,
		taskVariants: JWM.taskVariants,
		taskList,
		taskGroupParent,
	});
};

const createJsmTasks = (
	projectState: ProjectState | null,
	taskGroupParent: string,
): TaskListItem => {
	const variants: string[] = [];

	variants.push(JSM.experiments.projectTheming);

	if (projectState?.isProjectAdmin) {
		variants.push(JSM.userRole.projectAdmin);
	}

	if (projectState?.userRole === USER_ROLE.JIRA_ADMIN) {
		variants.push(JSM.userRole.jiraAdmin);
	}

	if (projectState?.userRole === USER_ROLE.SITE_ADMIN) {
		variants.push(JSM.userRole.siteAdmin);
	}

	return getTaskListItem({
		variants,
		taskVariants: JSM.taskVariants,
		taskList: JSM.taskList,
		taskGroupParent,
	});
};

const createDevopsTasks = (taskGroupParent: string): TaskListItem => {
	const variants: string[] = [];

	return getTaskListItem({
		variants,
		taskVariants: DEVOPS.taskVariants,
		taskList: DEVOPS.taskList,
		taskGroupParent,
	});
};

export const getAllTasks = ({
	projectState,
	isDevOpsProject,
	taskGroupParent,
	routeName,
	shouldShowScrumOnboardingExperience,
	mountedNudges,
	canCreateProject,
	isEligibleToUpdateSitename,
	isCustomerTemplateProject,
	isCrossProjectBoardToPlanUpsell,
	canEditPlans,
	premiumTrialOnSignup,
	personaExperience,
	taskList,
}: GetTasksProps): TaskListItem => {
	if (isJwmProject(projectState)) {
		return createJwmTasks(projectState, taskGroupParent, personaExperience, taskList);
	}
	if (isJsmProject(projectState)) {
		return createJsmTasks(projectState, taskGroupParent);
	}
	if (isDevOpsProject) {
		return createDevopsTasks(taskGroupParent);
	}
	return createJswTasks(
		projectState,
		taskGroupParent,
		routeName,
		shouldShowScrumOnboardingExperience,
		mountedNudges,
		canCreateProject,
		isEligibleToUpdateSitename,
		isCustomerTemplateProject,
		isCrossProjectBoardToPlanUpsell,
		canEditPlans,
		premiumTrialOnSignup,
	);
};
