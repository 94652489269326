import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import { HelpCenterSettingsRedirect } from '@atlassian/jira-servicedesk-customer-service-common/src/ui/help-center-settings-redirect';
import type ProjectSettingsServiceDeskEmailType from '@atlassian/jira-spa-apps-project-settings-service-desk-email';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';

export const LazyProjectSettingsServiceDeskEmail = lazyForPaint<
	typeof ProjectSettingsServiceDeskEmailType
>(
	() =>
		import(
			/* webpackChunkName: "async-project-settings-service-desk-email", jiraSpaEntry: "async-project-settings-service-desk-email" */ '@atlassian/jira-spa-apps-project-settings-service-desk-email'
		),
);
// eslint-disable-next-line jira/import/no-anonymous-default-export
export default () => (
	<HelpCenterSettingsRedirect page="email">
		<LazyPage
			Page={LazyProjectSettingsServiceDeskEmail}
			pageId="project-settings-email"
			shouldShowSpinner
		/>
	</HelpCenterSettingsRedirect>
);
