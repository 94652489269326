import {
	ROUTE_GROUPS_GLOBAL_SETTINGS,
	ROUTE_NAMES_GLOBAL_SETTINGS_CUSTOM_FIELDS,
} from '@atlassian/jira-common-constants/src/spa-routes';

export const globalSettingsCustomFieldsRoute = {
	group: ROUTE_GROUPS_GLOBAL_SETTINGS,
	name: ROUTE_NAMES_GLOBAL_SETTINGS_CUSTOM_FIELDS,
	path: '/secure/admin/ViewCustomFields(!default)?.jspa',
};
