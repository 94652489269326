import {
	ROUTE_NAMES_SOFTWARE_SECURITY,
	ROUTE_NAMES_SOFTWARE_SECURITY_CLASSIC,
} from '@atlassian/jira-common-constants/src/spa-routes';
import { getFeatureFlagValue } from '@atlassian/jira-feature-flagging';
import { createAri } from '@atlassian/jira-platform-ari';
import { createRelayResource } from '@atlassian/jira-relay-utils/src/services/resources/index.tsx';
import MAIN_SECURITY_PAGE_QUERY, {
	type mainSecurityInJiraQuery,
} from '@atlassian/jira-relay/src/__generated__/mainSecurityInJiraQuery.graphql';
import type {
	RouterDataContext,
	ResourceStoreContext,
	RouterContext,
} from '@atlassian/jira-router';
import type { QueryParams } from '@atlassian/jira-vulnerability-url-parameter-utils/src/common/types.tsx';
// eslint-disable-next-line jira/import-whitelist
import { getGqlFiltersFromQueryParams } from '@atlassian/jira-vulnerability-url-parameter-utils/src/controllers/get-gql-filters-from-query-params/index.tsx';
import { RESOURCE_TYPE_SECURITY } from '../constants';

const getNumberOfVulnerabilitiesToFetch = (): number =>
	Number(
		getFeatureFlagValue(
			'open-toolchain-core-x-isotopes-security-in-jira-vulnerabilities-count',
			100,
		),
	);

const getNumberOfSecurityContainersToFetch = (): number =>
	Number(getFeatureFlagValue('otc-core-x-isotopes-security-in-jira-max-container-count', 100));

let filters: ReturnType<typeof getGqlFiltersFromQueryParams> | undefined;
export const securityPageResource = createRelayResource<mainSecurityInJiraQuery>({
	type: RESOURCE_TYPE_SECURITY,
	getQuery(
		{ match: { params }, query, route: { name } }: RouterDataContext | RouterContext,
		{ tenantContext: { cloudId, activationId } }: ResourceStoreContext,
	) {
		const isSecRoute =
			name === ROUTE_NAMES_SOFTWARE_SECURITY || name === ROUTE_NAMES_SOFTWARE_SECURITY_CLASSIC;
		// Set filters on initial call only and not on every query param change
		if (!filters && isSecRoute) {
			const connectionAri = createAri({
				resourceOwner: 'jira',
				resourceType: 'security-container',
				cloudId,
				activationId,
				resourceId: '',
			});
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			filters = getGqlFiltersFromQueryParams(query as QueryParams, connectionAri);
		}
		// Reset filters when navigating away from the security page
		if (!isSecRoute) {
			filters = undefined;
		}
		return {
			parameters: MAIN_SECURITY_PAGE_QUERY,
			options: {
				fetchPolicy: 'store-and-network',
			},
			variables: {
				cloudAri: createAri({
					resourceOwner: 'jira',
					resourceType: 'site',
					resourceId: cloudId,
				}),
				cloudId,
				projectKey: params.projectKey ?? '',
				numberOfVulnsToFetch: getNumberOfVulnerabilitiesToFetch(),
				getContainers: false,
				numberOfContainersToFetch: getNumberOfSecurityContainersToFetch(),
				filter: filters,
			},
		};
	},
});
