import {
	ROUTE_GROUPS_GLOBAL_SETTINGS,
	ROUTE_NAMES_PRODUCTS_JSM_COMPLIANCE,
} from '@atlassian/jira-common-constants/src/spa-routes';

export const productsJsmComplianceRoute = {
	group: ROUTE_GROUPS_GLOBAL_SETTINGS,
	name: ROUTE_NAMES_PRODUCTS_JSM_COMPLIANCE,
	path: '/jira/settings/products/jira-service-management-compliance',
	exact: true,
};
