import {
	ROUTE_GROUPS_SOFTWARE,
	ROUTE_NAMES_SOFTWARE_REPORTS_BURNUP,
	ROUTE_NAMES_SOFTWARE_REPORTS_VELOCITY,
	ROUTE_NAMES_SOFTWARE_REPORTS_CUMULATIVE,
	ROUTE_NAMES_SOFTWARE_REPORTS_BURNDOWN,
	ROUTE_NAMES_SOFTWARE_REPORTS_CYCLE_TIME,
	ROUTE_NAMES_SOFTWARE_REPORTS_DEPLOYMENT_FREQUENCY,
	ROUTE_NAMES_SOFTWARE_REPORTS,
	ROUTE_NAMES_SOFTWARE_NEXTGEN_REDIRECT,
	ROUTE_NAMES_SOFTWARE_CODE,
	ROUTE_NAMES_SOFTWARE_ROADMAP_REDIRECT,
	ROUTE_NAMES_SOFTWARE_REPORTS_CONTROL,
} from '@atlassian/jira-common-constants/src/spa-routes';

export const softwareReportsBurnupRoute = {
	group: ROUTE_GROUPS_SOFTWARE,
	name: ROUTE_NAMES_SOFTWARE_REPORTS_BURNUP,
	path: '/jira/software/projects/:projectKey/boards/:boardId/reports/burnup',
};

export const softwareReportsVelocityRoute = {
	group: ROUTE_GROUPS_SOFTWARE,
	name: ROUTE_NAMES_SOFTWARE_REPORTS_VELOCITY,
	path: '/jira/software/projects/:projectKey/boards/:boardId/reports/velocity',
};

export const softwareReportsCumulativeRoute = {
	group: ROUTE_GROUPS_SOFTWARE,
	name: ROUTE_NAMES_SOFTWARE_REPORTS_CUMULATIVE,
	path: '/jira/software/projects/:projectKey/boards/:boardId/reports/cumulative',
};

export const softwareReportsBurndownRoute = {
	group: ROUTE_GROUPS_SOFTWARE,
	name: ROUTE_NAMES_SOFTWARE_REPORTS_BURNDOWN,
	path: '/jira/software/projects/:projectKey/boards/:boardId/reports/burndown',
};

export const softwareReportsCycleTimeRoute = {
	group: ROUTE_GROUPS_SOFTWARE,
	name: ROUTE_NAMES_SOFTWARE_REPORTS_CYCLE_TIME,
	path: '/jira/software/projects/:projectKey/boards/:boardId/reports/cycle-time',
};

export const softwareReportsDeploymentFrequencyRoute = {
	group: ROUTE_GROUPS_SOFTWARE,
	name: ROUTE_NAMES_SOFTWARE_REPORTS_DEPLOYMENT_FREQUENCY,
	path: '/jira/software/projects/:projectKey/boards/:boardId/reports/deployment-frequency',
};

export const softwareReportsControlRoute = {
	group: ROUTE_GROUPS_SOFTWARE,
	name: ROUTE_NAMES_SOFTWARE_REPORTS_CONTROL,
	path: '/jira/software/projects/:projectKey/boards/:boardId/reports/control',
};

export const softwareReportsRoute = {
	group: ROUTE_GROUPS_SOFTWARE,
	name: ROUTE_NAMES_SOFTWARE_REPORTS,
	path: '/jira/software/projects/:projectKey/boards/:boardId/reports',
};

export const softwareNextgenRedirectRoute = {
	group: ROUTE_GROUPS_SOFTWARE,
	name: ROUTE_NAMES_SOFTWARE_NEXTGEN_REDIRECT,
	path: '/secure/NextGen.jspa',
};

export const softwareCodeRoute = {
	group: ROUTE_GROUPS_SOFTWARE,
	name: ROUTE_NAMES_SOFTWARE_CODE,
	path: '/jira/software/projects/:projectKey/code',
};

export const softwareRoadmapRedirectRoute = {
	group: ROUTE_GROUPS_SOFTWARE,
	name: ROUTE_NAMES_SOFTWARE_ROADMAP_REDIRECT,
	path: '/secure/Roadmap.jspa',
};
