import {
	ROUTE_GROUPS_GLOBAL_SETTINGS,
	ROUTE_NAMES_SYSTEM_CONFIGURATION,
} from '@atlassian/jira-common-constants/src/spa-routes';

export const systemConfigurationRoute = {
	group: ROUTE_GROUPS_GLOBAL_SETTINGS,
	name: ROUTE_NAMES_SYSTEM_CONFIGURATION,
	path: '/jira/settings/system/general-configuration',
	exact: true,
};

export const routeNamesSystemConfigurationRedirectRoute = {
	group: ROUTE_GROUPS_GLOBAL_SETTINGS,
	name: `${ROUTE_NAMES_SYSTEM_CONFIGURATION}-redirect`,
	path: '/secure/admin/ViewApplicationProperties.jspa',
	exact: true,
};
