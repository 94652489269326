import {
	ROUTE_GROUPS_DIRECTORIES,
	ROUTE_NAMES_FILTERS_DIRECTORY,
	ROUTE_NAMES_FILTERS_DIRECTORY_SECURE,
} from '@atlassian/jira-common-constants/src/spa-routes';

export const filtersDirectoryRoute = {
	group: ROUTE_GROUPS_DIRECTORIES,
	name: ROUTE_NAMES_FILTERS_DIRECTORY,
	path: '/jira/filters',
};
export const filtersDirectoryRedirectRoute = {
	group: ROUTE_GROUPS_DIRECTORIES,
	name: `${ROUTE_NAMES_FILTERS_DIRECTORY}-redirect`,
	path: '/ManageFilters.jspa',
};

export const secureFiltersDirectoryRedirectRoute = {
	group: ROUTE_GROUPS_DIRECTORIES,
	name: `${ROUTE_NAMES_FILTERS_DIRECTORY_SECURE}-redirect`,
	path: '/secure/ManageFilters.jspa',
};
