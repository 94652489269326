import {
	ROUTE_NAMES_CLASSIC_BUSINESS_OVERVIEW_SUMMARY,
	ROUTE_NAMES_CLASSIC_BUSINESS_PROJECT_SUMMARY,
	ROUTE_NAMES_CLASSIC_BUSINESS_OVERVIEW_CALENDAR,
	ROUTE_NAMES_CLASSIC_BUSINESS_PROJECT_CALENDAR,
	ROUTE_NAMES_CLASSIC_BUSINESS_OVERVIEW_TIMELINE,
	ROUTE_NAMES_CLASSIC_BUSINESS_PROJECT_TIMELINE,
} from '@atlassian/jira-common-constants/src/spa-routes';
import type { Route } from '@atlassian/jira-router';
import type { NavigationOverview } from '@atlassian/jira-router-resources-business-navigation/src/types.tsx';

export const getOverviewUrl = (overview: NavigationOverview, currentRoute?: Route) => {
	switch (currentRoute?.name) {
		case ROUTE_NAMES_CLASSIC_BUSINESS_OVERVIEW_SUMMARY:
		case ROUTE_NAMES_CLASSIC_BUSINESS_PROJECT_SUMMARY:
			return `/jira/core/overviews/${overview.id}/summary`;
		case ROUTE_NAMES_CLASSIC_BUSINESS_OVERVIEW_CALENDAR:
		case ROUTE_NAMES_CLASSIC_BUSINESS_PROJECT_CALENDAR:
			return `/jira/core/overviews/${overview.id}/calendar`;
		case ROUTE_NAMES_CLASSIC_BUSINESS_OVERVIEW_TIMELINE:
		case ROUTE_NAMES_CLASSIC_BUSINESS_PROJECT_TIMELINE:
			return `/jira/core/overviews/${overview.id}/timeline`;
		default: {
			return `/jira/core/overviews/${overview.id}`;
		}
	}
};
