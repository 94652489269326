import {
	ROUTE_GROUPS_SOFTWARE,
	ROUTE_NAMES_SOFTWARE_GOALS,
} from '@atlassian/jira-common-constants/src/spa-routes';

export const softwareGoalsRoute = {
	group: ROUTE_GROUPS_SOFTWARE,
	name: ROUTE_NAMES_SOFTWARE_GOALS,
	exact: true,
	path: '/jira/software/projects/:projectKey/goals',
};
