import {
	ROUTE_GROUPS_SOFTWARE,
	ROUTE_NAMES_SOFTWARE_ROADMAP,
} from '@atlassian/jira-common-constants/src/spa-routes';

export const softwareRoadmapRoute = {
	group: ROUTE_GROUPS_SOFTWARE,
	name: ROUTE_NAMES_SOFTWARE_ROADMAP,
	path: '/jira/software/projects/:projectKey/boards/:boardId/roadmap',
};
