import { functionWithFF } from '@atlassian/jira-feature-flagging-utils';
import { useProjectProperties } from '@atlassian/jira-project-properties-services/src/services/index.tsx';

// TODO BERRY-2997: Replace with data from project context from BE
// When changing this file, make sure to search codebase for references to com.atlassian.jcs:customer-service-management
const useIsJCSUnderFF = (): boolean => {
	const { 'initial.project.template.name': initialProjectTemplate } = useProjectProperties([
		'initial.project.template.name',
	]);

	return initialProjectTemplate === 'com.atlassian.jcs:customer-service-management';
};

export const useIsJCS = functionWithFF('jcs-master-flag', useIsJCSUnderFF, () => false);

// Undefined can be used to check if project properties has been resolved or not
const useAsyncIsJCSUnderFF = (): boolean | undefined => {
	const { 'initial.project.template.name': initialProjectTemplate } = useProjectProperties([
		'initial.project.template.name',
	]);

	return initialProjectTemplate
		? initialProjectTemplate === 'com.atlassian.jcs:customer-service-management'
		: undefined;
};

export const useAsyncIsJCS = functionWithFF('jcs-master-flag', useAsyncIsJCSUnderFF, () => false);

const isJCSTemplateUnderFF = (template: string | undefined) =>
	template === 'com.atlassian.jcs:customer-service-management';

export const isJCSTemplate = functionWithFF('jcs-master-flag', isJCSTemplateUnderFF, () => false);
