import {
	ROUTE_NAMES_SERVICEDESK_CUSTOMERS_V2,
	ROUTE_NAMES_SERVICEDESK_CUSTOMER_DETAILS,
} from '@atlassian/jira-common-constants/src/spa-routes';

export const servicedeskCustomersV2Route = {
	name: ROUTE_NAMES_SERVICEDESK_CUSTOMERS_V2,
	path: '/jira/servicedesk/projects/:projectKey/customers',
};

export const servicedeskCustomerDetailsRoute = {
	name: ROUTE_NAMES_SERVICEDESK_CUSTOMER_DETAILS,
	path: '/jira/servicedesk/projects/:projectKey/customers/:customerId',
};
