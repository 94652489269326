import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	issues: {
		id: 'spa-apps-sidebar-common.issues.issues',
		defaultMessage: 'Issues and filters',
		description: 'Navigation item which links to or from a issues page in a software project',
	},
	issuesFilters: {
		id: 'spa-apps-sidebar-common.issues.issues-filters',
		defaultMessage: 'Issues and Filters',
		description: '"Issues and Filters" menu item.',
	},
	myOpenIssues: {
		id: 'spa-apps-sidebar-common.issues.my-open-issues',
		defaultMessage: 'My open issues',
		description: 'Filter link on the issues view in software navigation',
	},
	reportedByMe: {
		id: 'spa-apps-sidebar-common.issues.reported-by-me',
		defaultMessage: 'Reported by me',
		description: 'Filter link on the issues view in software navigation',
	},
	allIssues: {
		id: 'spa-apps-sidebar-common.issues.all-issues',
		defaultMessage: 'All issues',
		description: 'Filter link on the issues view in software navigation',
	},
	allOpenIssues: {
		id: 'spa-apps-sidebar-common.issues.all-open-issues',
		defaultMessage: 'Open issues',
		description: 'Filter link on the issues view in software navigation',
	},
	doneIssues: {
		id: 'spa-apps-sidebar-common.issues.done-issues',
		defaultMessage: 'Done issues',
		description: 'Filter link on the issues view in software navigation',
	},
	viewedRecently: {
		id: 'spa-apps-sidebar-common.issues.viewed-recently',
		defaultMessage: 'Viewed recently',
		description: 'Filter link on the issues view in software navigation',
	},
	createdRecently: {
		id: 'spa-apps-sidebar-common.issues.created-recently',
		defaultMessage: 'Created recently',
		description: 'Filter link on the issues view in software navigation',
	},
	resolvedRecently: {
		id: 'spa-apps-sidebar-common.issues.resolved-recently',
		defaultMessage: 'Resolved recently',
		description: 'Filter link on the issues view in software navigation',
	},
	updatedRecently: {
		id: 'spa-apps-sidebar-common.issues.updated-recently',
		defaultMessage: 'Updated recently',
		description: 'Filter link on the issues view in software navigation',
	},
	viewAllFilters: {
		id: 'spa-apps-sidebar-common.issues.view-all-filters',
		defaultMessage: 'View all filters',
		description: 'Filter link on the issues view in software navigation',
	},
});
