import React from 'react';
import { usePathParam } from '@atlassian/jira-router';
import ErrorPagesNotFound from '@atlassian/jira-router-routes-common/src/ui/error-pages/not-found/index.tsx';
import { helpCenterSettingsEmailRoute } from '@atlassian/jira-router-routes-help-center-settings-routes';
import { ScreenRedirect } from '@atlassian/jira-screen-redirect/src/ui/index.tsx';

export const Redirect = () => {
	const [projectKey = ''] = usePathParam('projectKey');

	const helpCenterSettingsEmail = helpCenterSettingsEmailRoute.path.replace(
		':projectKey',
		projectKey,
	);

	return projectKey ? (
		<ScreenRedirect to={helpCenterSettingsEmail} persistSearchParams />
	) : (
		<ErrorPagesNotFound />
	);
};
