import {
	ROUTE_GROUPS_DEVOPS,
	ROUTE_NAMES_DEVOPS_CONNECTED_PROJECT,
} from '@atlassian/jira-common-constants/src/spa-routes';

export const devopsConnectedProjectRoute = {
	group: ROUTE_GROUPS_DEVOPS,
	name: ROUTE_NAMES_DEVOPS_CONNECTED_PROJECT,
	path: '/jira/devops/connected-project',
};
