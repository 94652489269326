import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	labelNonFinal: {
		id: 'periscope.skeleton.label-non-final',
		defaultMessage: 'Loading',
		description: 'Label associated to the page skeleton while data is being requested.',
	},
	suggestionsLabelNonFinal: {
		id: 'periscope.suggestions-skeleton.label-non-final',
		defaultMessage: 'Suggestions loading',
		description: 'Label associated to the page suggestions skeleton while data is being requested.',
	},
	insightsLabelNonFinal: {
		id: 'periscope.insights-skeleton.label-non-final',
		defaultMessage: 'Developer insights loading',
		description: 'Label associated to the page insights skeleton while data is being requested.',
	},
});
