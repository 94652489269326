import {
	ROUTE_GROUPS_COMPONENTS,
	ROUTE_NAMES_PROJECT_PAGES,
} from '@atlassian/jira-common-constants/src/spa-routes';

export const routeNamesProjectPagesLegacyRoute = {
	group: ROUTE_GROUPS_COMPONENTS,
	name: `${ROUTE_NAMES_PROJECT_PAGES}-legacy`,
	path: '/projects/:projectKey',
	query: ['selectedItem=com.atlassian.jira.jira-projects-plugin:project-pages-page'],
};

export const projectPagesRoute = {
	group: ROUTE_GROUPS_COMPONENTS,
	name: ROUTE_NAMES_PROJECT_PAGES,
	path: '/jira/:projectType(software|software/c|servicedesk)/projects/:projectKey/pages',
};
