import {
	ROUTE_GROUPS_GLOBAL_SETTINGS,
	ROUTE_NAMES_GLOBAL_SETTINGS_MIGRATIONS_HOME,
	ROUTE_NAMES_GLOBAL_SETTINGS_MIGRATIONS_DASHBOARD,
	ROUTE_NAMES_GLOBAL_SETTINGS_MIGRATIONS_FOCUS_PAGE,
} from '@atlassian/jira-common-constants/src/spa-routes';

export const globalSettingsMigrationsHomeRoute = {
	group: ROUTE_GROUPS_GLOBAL_SETTINGS,
	name: ROUTE_NAMES_GLOBAL_SETTINGS_MIGRATIONS_HOME,
	path: '/jira/settings/system/migration/home',
};

export const globalSettingsMigrationsDashboardRoute = {
	group: ROUTE_GROUPS_GLOBAL_SETTINGS,
	name: ROUTE_NAMES_GLOBAL_SETTINGS_MIGRATIONS_DASHBOARD,
	path: '/jira/settings/system/migration/dashboard',
};

export const globalSettingsMigrationsFocusPageRoute = {
	group: ROUTE_GROUPS_GLOBAL_SETTINGS,
	name: ROUTE_NAMES_GLOBAL_SETTINGS_MIGRATIONS_FOCUS_PAGE,
	path: '/jira/settings/system/migration/:step(how-it-works|plan-configuration|choose-projects|choose-group-membership|select-customer|check-for-errors|review|view-details)/:migrationId?',
};
