import { performGetRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import { createResource } from '@atlassian/jira-router';
import { RESOURCE_TYPE_USER_HOMEPAGE } from '../common/constants';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const userHomepageResource = createResource<any>({
	type: RESOURCE_TYPE_USER_HOMEPAGE,
	getKey: () => 'state',
	getData: async (_, { tenantContext: { baseUrl } }) => {
		const { homepagePreference } = await performGetRequest(`${baseUrl}/rest/internal/3/homepage/`);

		return { homepagePreference };
	},
	maxAge: Infinity,
});
