import { token } from '@atlaskit/tokens';

export const PACKAGE_NAME = 'jiraNativeIssueTable';
export const TEAM_NAME = 'empanada';

export const SORT_ASC = 'ASC';
export const SORT_DESC = 'DESC';
export const SORT_NONE = 'NONE';

// Modification to the list of visible columns, either by adding or removing columns
export const MODIFY_COLUMNS = 'MODIFY_COLUMNS';
// Reordering of columns via a drag & drop operation
export const REORDER_COLUMNS = 'REORDER_COLUMNS';

// Target positions when moving columns
export const MOVE_COLUMN_FIRST = 'MOVE_COLUMN_FIRST';
export const MOVE_COLUMN_LEFT = 'MOVE_COLUMN_LEFT';
export const MOVE_COLUMN_RIGHT = 'MOVE_COLUMN_RIGHT';
export const MOVE_COLUMN_LAST = 'MOVE_COLUMN_LAST';

export const BORDER_WIDTH = '1px';

export const HEADER_HEIGHT = 40;
export const ROW_HEIGHT = 40;

export const getDragIconSize = () => token('space.300', '24px');
export const getMarginBetweenHeaders = () => token('space.050', '4px');

export const COLUMN_ID_MEATBALL_MENU = '__issuetable_internal_meatball';
export const COLUMN_ID_EXPAND_BUTTON = '__issuetable_internal_expand_button';

export const ACTION_COLUMNS = [COLUMN_ID_EXPAND_BUTTON, COLUMN_ID_MEATBALL_MENU];

// LDR: https://hello.atlassian.net/wiki/spaces/JFE/pages/2625316871/LDR+Maximum+number+of+columns+to+allow+in+NIN+in+M7.3
export const MAX_COLUMNS = 500;

export const KNOWN_COLUMN_TYPES = {
	ASSIGNEE: 'assignee',
	DESCRIPTION: 'description',
	ENVIRONMENT: 'environment',
	EXPAND_BUTTON: '__issuetable_internal_expand_button',
	ISSUE_KEY: 'issuekey',
	ISSUE_OPERATIONS: '__issuetable_internal_meatball',
	STATUS: 'status',
	SUMMARY: 'summary',
	ISSUE_TYPE: 'issuetype',
	RESOLUTION: 'resolution',
	PARENT: 'parent',
	LABELS: 'labels',
	COMPONENTS: 'components',
	FIX_VERSIONS: 'fixVersions',
	STATUS_CATEGORY: 'statusCategory',
	PROJECT: 'project',
	READ_ONLY: 'com.atlassian.jira.plugin.system.customfieldtypes:readonlyfield',
	SPRINT: 'com.pyxis.greenhopper.jira:gh-sprint',
	TEXT: 'com.atlassian.jira.plugin.system.customfieldtypes:textfield',
	EPIC_LINK: 'com.pyxis.greenhopper.jira:gh-epic-link',
	CREATED: 'created',
	RESOLUTION_DATE: 'resolutiondate',
	TEXT_AREA: 'com.atlassian.jira.plugin.system.customfieldtypes:textarea',
	UPDATED: 'updated',
	DUE_DATE: 'duedate',
	REPORTER: 'reporter',
	CREATOR: 'creator',
	PRIORITY: 'priority',
	AFFECTS_VERSIONS: 'versions',
	REQUEST_TYPE: 'com.atlassian.servicedesk:vp-origin',
} as const;

export const COLUMN_TYPE_EPIC_LINK = 'com.pyxis.greenhopper.jira:gh-epic-link';
export const COLUMN_TYPE_PARENT_LINK = 'com.atlassian.jpo:jpo-custom-field-parent';

export const NATURAL_LANGUAGE_TO_JQL = 'NATURAL_LANGUAGE_TO_JQL';

// Column Widths
export const MINIMUM_COLUMN_WIDTH = 69; // 2 x 8px cell padding + 2 x 24px icon size + 4px gap + 1px border
export const DEFAULT_COLUMN_WIDTH = 170;
export const MAXIMUM_COLUMN_WIDTH = 999;

export const COLUMN_WIDTH_TEXT_LONG = 400;
export const COLUMN_WIDTH_TEXT_SHORT = 300;
export const COLUMN_WIDTH_TEXT_SUMMARY = 450;

// Column operations
export const COLUMN_OPERATION_REORDER = 'reorder';
export const COLUMN_OPERATION_RESIZE = 'resize';

// Column resize handle
export const COLUMN_RESIZE_HANDLE_FULL_HEIGHT = 'FULL_HEIGHT';
export const COLUMN_RESIZE_HANDLE_HEADER_ONLY = 'HEADER_ONLY';

export const DENSITY_COMPACT = 'COMPACT';
export const DENSITY_FULL = 'FULL';

export const LAZY_EDITABILITY_LOADER_CONTAINER_SCOPE = 'LAZY_EDITABILITY_LOADER_CONTAINER_SCOPE';
export const LAZY_MEDIA_VIEW_CONTEXT_LOADER_CONTAINER_SCOPE =
	'LAZY_MEDIA_VIEW_CONTEXT_LOADER_CONTAINER_SCOPE';
