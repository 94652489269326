import {
	ROUTE_NAMES_SOFTWARE_BOARDS_EMBED,
	ROUTE_GROUPS_SOFTWARE,
	ROUTE_NAMES_SOFTWARE_BOARDS,
} from '@atlassian/jira-common-constants/src/spa-routes';

export const softwareBoardRoute = {
	group: ROUTE_GROUPS_SOFTWARE,
	name: ROUTE_NAMES_SOFTWARE_BOARDS,
	exact: true,
	path: '/jira/software/projects/:projectKey/boards/:boardId',
};

export const softwareBoardEmbedRoute = {
	group: ROUTE_GROUPS_SOFTWARE,
	name: ROUTE_NAMES_SOFTWARE_BOARDS_EMBED,
	exact: true,
	path: '/jira/software/projects/:projectKey/boards/:boardId/embed',
};
