import {
	ROUTE_NAMES_SOFTWARE_BACKLOG_EMBED,
	ROUTE_GROUPS_SOFTWARE,
	ROUTE_NAMES_SOFTWARE_BACKLOG,
} from '@atlassian/jira-common-constants/src/spa-routes';

export const softwareBacklogRoute = {
	group: ROUTE_GROUPS_SOFTWARE,
	name: ROUTE_NAMES_SOFTWARE_BACKLOG,
	path: '/jira/software/projects/:projectKey/boards/:boardId/backlog',
};

export const softwareBacklogEmbedRoute = {
	group: ROUTE_GROUPS_SOFTWARE,
	name: ROUTE_NAMES_SOFTWARE_BACKLOG_EMBED,
	path: '/jira/software/projects/:projectKey/boards/:boardId/backlog/embed',
	exact: true,
};
