import { ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK } from '@atlassian/jira-common-constants/src/spa-routes';

export const SERVICE_DESK_PRODUCT = 'servicedesk';
export const routeGroupsProjectSettingsServicedeskUnassignedTicketTypeFieldsRoute = {
	exact: true,
	group: ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK,
	name: `${ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK}-unassigned-ticket-type-fields`,
	path: `/jira/${SERVICE_DESK_PRODUCT}/projects/:projectKey/settings/request-types/unassigned/request-type/:requestTypeId/:tabKey?`,
};

export const routeGroupsProjectSettingsServicedeskItsmTicketTypeFieldsRoute = {
	exact: true,
	group: ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK,
	name: `${ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK}-itsm-ticket-type-fields`,
	path: `/jira/${SERVICE_DESK_PRODUCT}/projects/:projectKey/settings/request-types/category/:practice/request-type/:requestTypeId/:tabKey?`,
};

export const routeGroupsProjectSettingsServicedeskTicketTypeFieldsRoute = {
	exact: true,
	group: ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK,
	name: `${ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK}-ticket-type-fields`,
	path: `/jira/${SERVICE_DESK_PRODUCT}/projects/:projectKey/settings/request-types/request-type/:requestTypeId/:tabKey?`,
};
