import {
	ROUTE_GROUPS_PEOPLE_PROFILES,
	ROUTE_NAMES_PEOPLE_INDEX,
} from '@atlassian/jira-common-constants/src/spa-routes';

export const peopleProfilesRoute = {
	group: ROUTE_GROUPS_PEOPLE_PROFILES,
	name: ROUTE_NAMES_PEOPLE_INDEX,
	path: '/jira/people/:subPath?',
};
