import type { GadgetUserPreferences } from '@atlassian/jira-dashboard-user-preference/src/types.tsx';
import CUSTOM_REPORT_DEFINITION from '@atlassian/jira-react-custom-report-gadget';
import type { GadgetDefinition } from '@atlassian/jira-react-gadgets-common/src/types.tsx';
import {
	FILTER_RESULTS_DEFINITION,
	ASSIGNED_TO_ME_DEFINITION,
	ISSUES_IN_PROGRESS_DEFINITION,
	VOTED_ISSUES_DEFINITION,
	WATCHED_ISSUES_DEFINITION,
} from '@atlassian/jira-react-issue-table-gadgets';
import { REACT_KEY } from './constants';

/** This is a gadgets definition file.  */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const GADGETS_DEFINITION: Record<ReactKey, GadgetDefinition<any, any>> = {
	[REACT_KEY.FILTER_RESULTS]: FILTER_RESULTS_DEFINITION,
	[REACT_KEY.ASSIGNED_TO_ME]: ASSIGNED_TO_ME_DEFINITION,
	[REACT_KEY.ISSUES_IN_PROGRESS]: ISSUES_IN_PROGRESS_DEFINITION,
	[REACT_KEY.VOTED_ISSUES]: VOTED_ISSUES_DEFINITION,
	[REACT_KEY.WATCHED_ISSUES]: WATCHED_ISSUES_DEFINITION,
	[REACT_KEY.CUSTOM_REPORT]: CUSTOM_REPORT_DEFINITION,
};
export function isReactKey(key?: string): key is ReactKey {
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	return !!(key && (Object.values(REACT_KEY) as string[]).includes(key));
}

export default GADGETS_DEFINITION;

export type ReactKey = (typeof REACT_KEY)[keyof typeof REACT_KEY];

export type ReactGadgetIdentity = {
	reactKey: ReactKey;
	id: string;
	userPrefs?: GadgetUserPreferences;
};

export type ReactGadgetsResourceSharedValue = {
	reactGadgets: ReactGadgetIdentity[];
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ReactGadgetState = Record<any, any>;

export type ReactGadgetsState = {
	[key: string]: ReactGadgetState | Error | null;
};

export type DashboardReactGadgetsResourceData = {
	sharedValue: ReactGadgetsResourceSharedValue | Error | null;
	reactGadgetsState: ReactGadgetsState | null;
};
