import React, { memo } from 'react';
import { useIntl } from '@atlassian/jira-intl';
import { ProjectCreateDrawerConsumer } from '@atlassian/jira-project-create-drawer-context';
import { useProjectCreatePermissionsResource } from '@atlassian/jira-router-resources-project-permissions/src/services/project-permissions/index.tsx';
import SectionHeader from '../../../common/ui/section-header';
import { messages } from './messages';
import OneClickProject from './one-click-project';

const ProjectsHeader = () => {
	const { formatMessage } = useIntl();
	const { data } = useProjectCreatePermissionsResource();

	const canCreateProject = Boolean(data?.canCreateProject);

	return (
		<SectionHeader title={formatMessage(messages.projectsHeader)}>
			{canCreateProject && (
				<ProjectCreateDrawerConsumer>
					{({ methods: { open } }) => <OneClickProject openProjectCreateDrawer={open} />}
				</ProjectCreateDrawerConsumer>
			)}
		</SectionHeader>
	);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default memo<Record<any, any>>(ProjectsHeader);
