import { JiraSoftwareBoardAri } from '@atlassian/ari/jira-software/board';
import { JiraProjectAri } from '@atlassian/ari/jira/project';
import { getScopeIdentifier } from '@atlassian/jira-common-navigation/src/get-scope-identifier';
import { fg } from '@atlassian/jira-feature-gating';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout';
import { NAVIGATION_ITEMS_PAGE_SIZE } from '@atlassian/jira-navigation-kit-common';
import type { Scope } from '@atlassian/jira-navigation-types';
// eslint-disable-next-line jira/restricted/@atlassian+jira-relay-utils
import { createRelayResource } from '@atlassian/jira-relay-utils';
import QUERY, {
	type uiHorizontalSoftwareProjectNavQuery,
} from '@atlassian/jira-relay/src/__generated__/uiHorizontalSoftwareProjectNavQuery.graphql';
import {
	resourceWithCondition2,
	getProjectKeyId,
	getBoardId,
} from '@atlassian/jira-router-resources-utils';

export const getQuery: Parameters<
	typeof createRelayResource<uiHorizontalSoftwareProjectNavQuery>
>[0]['getQuery'] = ({ match, query }, { tenantContext }) => {
	let scope: Scope;
	const { projectKey, projectId } = getProjectKeyId(match, query);
	const boardId = getBoardId(match, query);
	const isTMPBoardRoute = boardId ? match.url.includes('/software/projects/') : false;
	if (isTMPBoardRoute) {
		// TMP software projects always use projectKey even on board routes because they only have one board
		scope = getScopeIdentifier({ projectKey, projectId });
	} else {
		scope = getScopeIdentifier({ projectKey, projectId, boardId });
	}
	const boardAri =
		scope.identifierType === 'boardId'
			? JiraSoftwareBoardAri.create({
					siteId: tenantContext.cloudId,
					boardId: String(match.params.boardId),
				}).toString()
			: null;
	const projectAri = projectId
		? JiraProjectAri.create({
				siteId: tenantContext.cloudId,
				projectId: String(projectId),
			}).toString()
		: '';

	const containerNavigationInput =
		boardAri || projectAri
			? { scopeId: String(boardAri || projectAri) }
			: { projectKeyQuery: { cloudId: tenantContext.cloudId, projectKey } };

	return {
		parameters: QUERY,
		variables: {
			cloudId: tenantContext.cloudId,
			projectKey,
			projectAri,
			boardId: boardAri,
			containerNavigationInput,
			first: NAVIGATION_ITEMS_PAGE_SIZE,
			useContainerNavigation: fg('custom_project_navigation_m2'),
			useProjectKey: Boolean(projectKey),
			useProjectAri: Boolean(projectAri),
		},
		options: { fetchPolicy: 'store-and-network' },
	};
};

export const staticSoftwareProjectHorizontalNavResource = resourceWithCondition2(
	() => getWillShowNav4(),
	createRelayResource<uiHorizontalSoftwareProjectNavQuery>({
		type: 'HORIZONTAL_NAVIGATION_PROJECT_QUERY',
		getQuery,
	}),
);
