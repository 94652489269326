import {
	ROUTE_GROUPS_COMPONENTS,
	ROUTE_NAMES_PROJECT_SETTINGS_VERSIONS,
} from '@atlassian/jira-common-constants/src/spa-routes';

export const projectSettingsVersionsRoute = {
	group: ROUTE_GROUPS_COMPONENTS,
	name: ROUTE_NAMES_PROJECT_SETTINGS_VERSIONS,
	path: '/plugins/servlet/project-config/:projectKey/administer-versions',
};
