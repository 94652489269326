import {
	ROUTE_GROUPS_GLOBAL_SETTINGS,
	ROUTE_NAMES_PRODUCTS_JSM_CUSTOMER_ACCESS,
} from '@atlassian/jira-common-constants/src/spa-routes';

export const productsJsmCustomerAccessRoute = {
	group: ROUTE_GROUPS_GLOBAL_SETTINGS,
	name: ROUTE_NAMES_PRODUCTS_JSM_CUSTOMER_ACCESS,
	path: '/jira/settings/products/servicedesk/customer-access',
	exact: true,
};
