import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import type ServicedeskOpsgenieScheduleType from '@atlassian/jira-spa-apps-servicedesk-opsgenie-schedule/src/ui/index.tsx';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';

export const LazyServicedeskOpsgenieSchedule = lazyForPaint<typeof ServicedeskOpsgenieScheduleType>(
	() =>
		import(
			/* webpackChunkName: "async-servicedesk-opsgenie-schedule", jiraSpaEntry: "async-servicedesk-opsgenie-schedule" */ '@atlassian/jira-spa-apps-servicedesk-opsgenie-schedule'
		),
);

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default () => (
	<LazyPage
		Page={LazyServicedeskOpsgenieSchedule}
		pageId="spa-apps/servicedesk/opsgenie-schedule"
		shouldShowSpinner
	/>
);
