import React, { useMemo } from 'react';
import { useOverviewTheme } from '@atlassian/jira-business-entity-overview/src/services/overview-theme/index.tsx';
import { useProjectTheme } from '@atlassian/jira-business-entity-project/src/services/project-theme/index.tsx';
import { ThemeTokensSetter } from '@atlassian/jira-business-theme-components/src/ui/theme-tokens-setter/index.tsx';
import {
	ThemeContainer,
	ThemeContainerOld,
} from '@atlassian/jira-business-theme-providers/src/controllers/theme/index.tsx';
import {
	ROUTE_NAMES_CLASSIC_BUSINESS_PROJECT_FORM_SUBMIT,
	ROUTE_NAMES_CLASSIC_BUSINESS_PROJECT_FORM_SUBMIT_EMBED,
} from '@atlassian/jira-common-constants/src/spa-routes';
import { ff } from '@atlassian/jira-feature-flagging';
import { useCurrentRoute } from '@atlassian/jira-platform-router-utils';

export const ThemeSetter = () => {
	const projectTheme = useProjectTheme();
	const overviewTheme = useOverviewTheme();
	const route = useCurrentRoute();

	const entityTheme = useMemo(() => {
		let result = projectTheme ?? overviewTheme;

		if (result != null) {
			const isFormSubmitView =
				route.name === ROUTE_NAMES_CLASSIC_BUSINESS_PROJECT_FORM_SUBMIT ||
				route.name === ROUTE_NAMES_CLASSIC_BUSINESS_PROJECT_FORM_SUBMIT_EMBED;

			// the form submit view doesn't use the project theme colors
			// since it is accessed externally to the project
			if (isFormSubmitView) {
				result = { ...result, themeSetting: null };
			}
		}

		return result;
	}, [projectTheme, overviewTheme, route.name]);
	if (ff('sea-2744-jsw-tabs-nav')) {
		return (
			<>
				<ThemeContainer isGlobal entityTheme={entityTheme} />
				<ThemeTokensSetter />
			</>
		);
	}
	return (
		<>
			{entityTheme != null && <ThemeContainerOld isGlobal {...entityTheme} />}
			<ThemeTokensSetter />
		</>
	);
};
