import type {
	CalendarIssueEventFields,
	CalendarScopePlan,
	CalendarViewMode,
	CalendarWeekStart,
} from '@atlassian/jira-calendar';
import type { EntryPointRouteParams } from '@atlassian/jira-entry-points-plugin';
import { ff } from '@atlassian/jira-feature-flagging';
import { fg } from '@atlassian/jira-feature-gating';
import parameters from '@atlassian/jira-relay/src/__generated__/ui_jiraCalendarQuery$parameters';
import type {
	JiraViewScopeInput,
	JiraCalendarViewConfigurationInput,
	JiraCalendarIssuesInput,
	JiraCalendarVersionsInput,
	JiraCalendarSprintsInput,
	JiraCalendarMode,
	JiraCalendarWeekStart,
	JiraCalendarCrossProjectVersionsInput,
} from '@atlassian/jira-relay/src/__generated__/ui_jiraCalendarQuery.graphql';
import type { RouterContext } from '@atlassian/jira-router';
import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

function toPlanScenarioAri({
	siteId,
	activationId,
	planId,
	scenarioId,
}: {
	siteId: string;
	activationId: string;
	planId: string;
	scenarioId: string;
}) {
	return `ari:cloud:jira:${siteId}:plan-scenario/activation/${activationId}/${planId}/scenario/${scenarioId}`;
}

function getStorageKeyFromScope(context: RouterContext): string {
	const {
		match: {
			params: { planId, scenarioId },
		},
	} = context;
	const LOCALSTORAGE_KEY_PREFIX = 'persist:calendar';
	return `${LOCALSTORAGE_KEY_PREFIX}/plan/${planId}/scenario/${scenarioId}`;
}

function getStorageValueFromScope(key: string): string {
	const storedData = globalThis.localStorage?.getItem(key) || '';
	return storedData;
}

function toMidnightUTCString(date: Date) {
	const utcMilliseconds = Date.UTC(date.getFullYear(), date.getMonth(), date.getDate());
	return new Date(utcMilliseconds).toISOString();
}

function toDate(date: string | null | undefined): Date {
	if (!date) {
		return new Date();
	}
	try {
		const _date = new Date(date);
		return new Date(_date.getFullYear(), _date.getMonth(), _date.getDate());
	} catch (e) {
		return new Date();
	}
}

function createScope({ context, tenantContext }: EntryPointRouteParams): CalendarScopePlan {
	const {
		match: {
			params: { planId, scenarioId },
		},
	} = context;
	const { cloudId, activationId } = tenantContext;

	const planScenarioAri = toPlanScenarioAri({
		siteId: cloudId,
		activationId,
		planId: String(planId),
		scenarioId: String(scenarioId),
	});

	return {
		type: 'plan',
		ids: [planScenarioAri],
	};
}

function buildScopeInput(scope: CalendarScopePlan): JiraViewScopeInput {
	return {
		ids: scope.ids,
	};
}

function buildViewId(scope: CalendarScopePlan): string {
	// plan scenario ARI is the view id
	return scope.ids?.[0] ?? '';
}

function toViewMode(mode: string): JiraCalendarMode {
	switch (mode) {
		case 'week':
			return 'WEEK';
		case 'day':
			return 'DAY';
		default:
			return 'MONTH';
	}
}

function toWeekStart(weekStart: string): JiraCalendarWeekStart {
	switch (weekStart) {
		case 'monday':
			return 'MONDAY';
		case 'saturday':
			return 'SATURDAY';
		default:
			return 'SUNDAY';
	}
}

function buildConfigurationInput({
	selectedDate,
	viewMode,
	weekStartsOn,
	startDateField,
	endDateField,
	viewId,
}: {
	selectedDate: Date;
	viewMode: CalendarViewMode;
	weekStartsOn: CalendarWeekStart;
	startDateField: string;
	endDateField: string;
	viewId: string;
}): JiraCalendarViewConfigurationInput {
	if (ff('update-calendar-query_n6opw')) {
		return {
			date: toMidnightUTCString(selectedDate),
			mode: toViewMode(viewMode),
			weekStart: toWeekStart(weekStartsOn),
			viewId,
		};
	}
	return {
		date: toMidnightUTCString(selectedDate),
		mode: toViewMode(viewMode),
		weekStart: toWeekStart(weekStartsOn),
		startDateField,
		endDateField,
	};
}

function buildIssuesSearchInput(storageKey: string): JiraCalendarIssuesInput {
	return { additionalFilterQuery: getStorageValueFromScope(storageKey) };
}

function buildUnscheduledIssuesSearchInput(): JiraCalendarIssuesInput {
	return { additionalFilterQuery: '' };
}

function buildVersionsSearchInput(): JiraCalendarVersionsInput {
	return { versionStatuses: ['RELEASED', 'UNRELEASED'] };
}

function buildCalendarCrossProjectVersionsInput(): JiraCalendarCrossProjectVersionsInput {
	return { statuses: ['RELEASED', 'UNRELEASED'] };
}

function buildSprintsSearchInput(): JiraCalendarSprintsInput {
	return { sprintStates: ['ACTIVE', 'FUTURE', 'CLOSED'] };
}

export const calendarEntryPoint = createEntryPoint({
	root: JSResourceForInteraction(
		() => import(/* webpackChunkName: "async-plan-calendar-entrypoint" */ './main'),
	),
	getPreloadProps: (entryPointRouteParams: EntryPointRouteParams) => {
		const { context, tenantContext } = entryPointRouteParams;
		const { cloudId } = tenantContext;

		const scope = createScope(entryPointRouteParams);

		const scopeInput = buildScopeInput(scope);

		const { date } = context.query;
		const selectedDate = toDate(date);

		const viewMode: CalendarViewMode = 'month';
		const weekStartsOn: CalendarWeekStart = 'monday';
		const startDateField = 'startdate';
		const endDateField = 'duedate';
		const issueEventFields: CalendarIssueEventFields = {
			startDateField,
			endDateField,
		};

		const storageKey = getStorageKeyFromScope(context);
		const viewId = buildViewId(scope);

		return {
			queries: {
				calendarData: {
					options: {
						fetchPolicy: 'network-only' as const,
					},
					parameters,
					variables: {
						cloudId,
						scopeInput,
						configurationInput: buildConfigurationInput({
							selectedDate,
							viewMode,
							weekStartsOn,
							startDateField,
							endDateField,
							viewId,
						}),
						issuesSearchInput: buildIssuesSearchInput(storageKey),
						unscheduledIssuesSearchInput: buildUnscheduledIssuesSearchInput(),
						versionsSearchInput: buildVersionsSearchInput(),
						sprintsSearchInput: buildSprintsSearchInput(),
						crossProjectVersionsSearchInput: buildCalendarCrossProjectVersionsInput(),
						viewId,
						skipSprintSearch: false,
						skipVersionSearch: false,
						skipPanelIssueSearch: true,
						skipCrossProjectVersionSearch: false,
						queryV2Enabled: ff('update-calendar-query_n6opw'),
						filterProjectContextEnabled: fg('calendar_set_filter_context'),
					},
				},
			},
			entryPoints: {},
			extraProps: {
				scope,
				selectedDate,
				viewMode,
				weekStartsOn,
				issueEventFields: ff('update-calendar-query_n6opw') ? issueEventFields : undefined,
				storageKey,
			},
		};
	},
});
