import {
	ROUTE_GROUPS_ISSUE_NAVIGATOR,
	ROUTE_NAMES_PROJECT_ISSUE_NAVIGATOR,
	ROUTE_NAMES_SOFTWARE_ISSUE_NAVIGATOR,
	ROUTE_NAMES_PROJECT_ISSUE_NAVIGATOR_CLASSIC,
	ROUTE_NAMES_SERVICEDESK_ISSUES,
	ROUTE_NAMES_PROJECT_ISSUE_NAVIGATOR_CORE,
	ROUTE_NAMES_GLOBAL_ISSUE_NAVIGATOR,
	ROUTE_NAMES_GLOBAL_ISSUE_NAVIGATOR_BROWSE_ISSUE_REDIRECT,
} from '@atlassian/jira-common-constants/src/spa-routes';

export const projectIssueNavigatorRoute = {
	group: ROUTE_GROUPS_ISSUE_NAVIGATOR,
	name: ROUTE_NAMES_PROJECT_ISSUE_NAVIGATOR,
	path: '/projects/:projectKey/issues/:issueKey?',
};

export const softwareIssueNavigatorRoute = {
	group: ROUTE_GROUPS_ISSUE_NAVIGATOR,
	name: ROUTE_NAMES_SOFTWARE_ISSUE_NAVIGATOR,
	path: '/jira/software/projects/:projectKey/issues/:issueKey?',
};

export const projectIssueNavigatorClassicRoute = {
	group: ROUTE_GROUPS_ISSUE_NAVIGATOR,
	name: ROUTE_NAMES_PROJECT_ISSUE_NAVIGATOR_CLASSIC,
	path: '/jira/software/c/projects/:projectKey/issues/:issueKey?',
};

export const servicedeskIssuesRoute = {
	group: ROUTE_GROUPS_ISSUE_NAVIGATOR,
	name: ROUTE_NAMES_SERVICEDESK_ISSUES,
	path: '/jira/servicedesk/projects/:projectKey/issues/:issueKey?',
};

export const projectIssueNavigatorCoreRoute = {
	group: ROUTE_GROUPS_ISSUE_NAVIGATOR,
	name: ROUTE_NAMES_PROJECT_ISSUE_NAVIGATOR_CORE,
	path: '/jira/core/projects/:projectKey/issues/:issueKey?',
};

export const globalIssueNavigatorRoute = {
	group: ROUTE_GROUPS_ISSUE_NAVIGATOR,
	name: ROUTE_NAMES_GLOBAL_ISSUE_NAVIGATOR,
	path: '/issues/:issueKey?',
};

export const globalIssueNavigator1Route = {
	group: ROUTE_GROUPS_ISSUE_NAVIGATOR,
	name: ROUTE_NAMES_GLOBAL_ISSUE_NAVIGATOR,
	path: '/jira/issues/:issueKey?',
};

export const globalIssueNavigatorBrowseIssueRedirectRoute = {
	group: ROUTE_GROUPS_ISSUE_NAVIGATOR,
	name: ROUTE_NAMES_GLOBAL_ISSUE_NAVIGATOR_BROWSE_ISSUE_REDIRECT,
	path: '/browse/:issueKey?',
	query: ['jql|filter'],
};
