import { useState } from 'react';
import { createEntryPointsPlugin } from '@atlassian/jira-entry-points-plugin/src/services/create-plugin/index.tsx';
import getRelayEnvironment from '@atlassian/jira-relay-environment';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import type { Plugin } from '@atlassian/react-resource-router';
import {
	createResourcesPlugin,
	type ResourceStoreContext,
	type ResourceStoreData,
} from '@atlassian/react-resource-router/src/resources';

export const usePlugins = ({
	resourceContext,
	resourceData,
}: {
	resourceContext?: ResourceStoreContext | undefined;
	resourceData?: ResourceStoreData | undefined;
}) => {
	const tenantContext = useTenantContext();
	// make sure "plugins" are created only once, ignore prop change/rerender
	const [pluginsWithOverride] = useState(() => {
		const jiraPlugins: Plugin[] = [];

		const resourcesPlugin = createResourcesPlugin({
			context: resourceContext,
			resourceData,
		});

		jiraPlugins.push(resourcesPlugin);

		jiraPlugins.push(
			createEntryPointsPlugin({
				environment: getRelayEnvironment(),
				entryPointParams: { tenantContext },
			}),
		);

		return jiraPlugins;
	});

	return pluginsWithOverride;
};
